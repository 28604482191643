import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./css/fonts.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import { TESTING } from "./config/configAuction.js";
import { privateTestnet } from './config/configTestnet'

import { WagmiConfig, createClient, configureChains, mainnet } from 'wagmi'
// import { alchemyProvider } from 'wagmi/providers/alchemy'
import { infuraProvider } from 'wagmi/providers/infura'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { publicProvider } from 'wagmi/providers/public';

import '@rainbow-me/rainbowkit/styles.css';

import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme
} from '@rainbow-me/rainbowkit';


const projectId = 'f393415aef327bb9bd7707c1da90890b';
const INFURA_API_KEY = '4d8f1219bc5646beb085a797fdc50992'

const { chains, provider } = TESTING ? configureChains(
    [privateTestnet],
    [
        jsonRpcProvider({
          rpc: (chain) => ({
            http: `http://54.37.68.15:8501`,
          }),
        }),
      ],
  ) : configureChains(
    [mainnet],
    [infuraProvider({ apiKey: INFURA_API_KEY })],
    [publicProvider()]
  )

const { connectors } = getDefaultWallets({
    appName: 'Le Anime App',
    projectId,
    chains
  });

const client = createClient({
    autoConnect: true,
    connectors,
    provider
  })

const CustomAvatar = ({ address, ensImage, size }) => {
  const color = address ? `#${address.slice(2, 8)}` : '#000';
  return ensImage ? (
    <img
      src={ensImage}
      width={size}
      height={size}
      style={{ borderRadius: 999 }}
    />
  ) : (
    <div
      style={{
        backgroundColor: color,
        borderRadius: 999,
        height: size,
        width: size,
      }}
    >
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
      <HashRouter>
        <WagmiConfig client={client}>
            <RainbowKitProvider theme={darkTheme()} avatar={CustomAvatar} chains={chains}>
                <App />
            </RainbowKitProvider>
        </WagmiConfig>
      </HashRouter>
    
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
