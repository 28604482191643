import React from "react";
import Router from "./Router";
import { useLocation } from "react-router-dom";
import { Footer } from "./components";

function App() {
  const { pathname } = useLocation();
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        color: "white",
        display: "flex",
        flexDirection: "column",
        background:
          pathname === "/builder" ||
          pathname === "/editor" ||
          pathname === "/library" ||
          pathname === "/demo-builder" ||
          pathname === "/talanji" ||
          pathname === "/exp-raffle" ||
          pathname === "/ordinals-day"
            ? ""
            : "#111111",
        overflow: pathname === "/map" ? "hidden" : "auto",
      }}
    >
      <Router />
      {pathname !== '/collection' && pathname !== '/wallet' && pathname !== '/builder'  && pathname !== '/editor' && pathname !== '/library' && pathname !== '/library' && <Footer />}
    </div>
  );
}

export default App;
