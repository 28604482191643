import React, { useState, useEffect } from "react";
import { Popper, Button, ClickAwayListener, useMediaQuery } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  traitsMapping,
  traitsMappingReference,
} from "../assets/heroesData/TraitEncodingHeroes";
import clonedeep from "lodash.clonedeep";
const extraRegex = /(Extra)\ \d/;
const SelectTrait = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [level, setLevel] = useState(0);
  const [max, setMax] = useState(1);
  const [data, setData] = useState([]);

  const matchesMedium = useMediaQuery("(max-width:1400px)");
  const matchesSmall = useMediaQuery("(max-width:1024px)");
  const matchesMobile = useMediaQuery("(max-width:600px)");
  const [maxAscendReached, setMaxAscendReached] = useState(
    props.title === "Hero Level"
      ? false
      : props.title === "Background Color"
      ? false
      : true
  );
  const [minDescendReached, setMinDescendReached] = useState(
    props.title === "Hero Level"
      ? false
      : props.title === "Background Color"
      ? true
      : true
  );
  useEffect(() => {}, [maxAscendReached, minDescendReached]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const increaseLevel = () => {
    if (level !== max) setLevel((pastLevel) => pastLevel + 1);
  };
  const decreaseLevel = () => {
    if (level !== 1 && level !== 0) {
      setLevel((pastLevel) => pastLevel - 1);
    }
  };
  const ascendTrait = () => {
    const { trait, traitValue, index } = selectedItem;
    if (trait === "Hero Level" ) {
      if (index + 1 === props.data.options.length) {
        setMaxAscendReached(true);
      }
      if (index < props.data.options.length) {
        handleSelectTrait(traitValue + 1, index + 1, trait);
      }
    } else if (trait === "Background Color") {
      if (index + 2 === props.data.options.length) {
        setMaxAscendReached(true);
      }
      if (index + 1 < props.data.options.length) {
        handleSelectTrait(traitValue + 1, index + 1, trait);
      }
    } else if (trait === "Runes") {
      let foundSelectedTraitIndex = 0;
      props.data.options.forEach((option, i) => {
        if (option[0] === traitValue && option[1] === max)
          foundSelectedTraitIndex = i;
      });
      const nextIndex = props.data.options[foundSelectedTraitIndex + 1];
      const nextNextIndex = props.data.options[foundSelectedTraitIndex + 2];
      if (!nextNextIndex) {
        setMaxAscendReached(true);
      }
      if (nextIndex)
        handleSelectTrait(
          nextIndex[0],
          nextIndex[0],
          trait,
          nextIndex[1],
          nextIndex[1]
        );
    } else if (trait.match(extraRegex)) {
      let foundSelectedTraitIndex = 0;
      props.data.options.forEach((option, i) => {
        if (option[0] === traitValue && option[1] === max)
          foundSelectedTraitIndex = i;
      });
      let nextIndex;
      let initialNextIndex = props.data.options[foundSelectedTraitIndex + 1];
      if (initialNextIndex) {
        if (!props.chosenExtras[initialNextIndex[0]]) {
          nextIndex = props.data.options[foundSelectedTraitIndex + 1];
        } else {
          let chosenIndexes = props.data.options.map((item, i) => {
            return [item[0], item[1], props.chosenExtras[item[0]]];
          });
          let foundNextUnselectedIndex = 0;
          chosenIndexes.forEach((item, i) => {
            if (item[2] === false) {
              foundNextUnselectedIndex = i;
            }
          });

          chosenIndexes = chosenIndexes.filter((item) => item[2] !== true);
          const randomIndex = Math.floor(Math.random() * chosenIndexes.length);

          nextIndex = chosenIndexes[randomIndex];
        }
      }
      if (nextIndex) {
        handleSelectTrait(
          nextIndex[0],
          nextIndex[0],
          trait,
          nextIndex[1],
          nextIndex[1]
        );
      } else {
        setMaxAscendReached(true);
      }
    } else {
      let foundSelectedTraitIndex = 0;

      props.data.options.forEach((option, i) => {
        if (option === traitValue) foundSelectedTraitIndex = i;
      });
      const nextIndex = props.data.options[foundSelectedTraitIndex + 1];
      const nextNextIndex = props.data.options[foundSelectedTraitIndex + 2];
      if (!nextNextIndex) {
        setMaxAscendReached(true);
      }
      if (nextIndex) handleSelectTrait(nextIndex, nextIndex, trait);
    }
  };
  const descendTrait = () => {

    const { trait, traitValue, index, level, max } = selectedItem;
    if (trait === "Hero Level") {
      if (index > 1) {
        handleSelectTrait(traitValue - 1, index - 1, trait);
      }
      if (index - 2 < 1) {
        setMinDescendReached(true);
      }
    } else if (trait === "Background Color") {
        if (index > 0) {
          handleSelectTrait(traitValue - 1, index - 1, trait);
        }
        if (index == 1) {
          setMinDescendReached(true);
        }
    } else if (trait === "Runes") {
      let foundSelectedTraitIndex = 0;

      props.data.options.forEach((option, i) => {
        if (option[0] === traitValue && option[1] === max)
          foundSelectedTraitIndex = i;
      });

      const previousIndex = props.data.options[foundSelectedTraitIndex - 1];
      if (previousIndex) {
        handleSelectTrait(
          previousIndex[0],
          previousIndex[0],
          trait,
          previousIndex[1],
          previousIndex[1]
        );
      }
      const previousPrevious = props.data.options[foundSelectedTraitIndex - 2];
      if (!previousPrevious) {
        setMinDescendReached(true);
      }
    } else if (trait.match(extraRegex)) {
      let foundSelectedTraitIndex = 0;
      props.data.options.forEach((option, i) => {
        if (option[0] === traitValue && option[1] === max)
          foundSelectedTraitIndex = i;
      });
      let previousIndex;
      let initialNextIndex = props.data.options[foundSelectedTraitIndex - 1];
      if (initialNextIndex) {
        if (!props.chosenExtras[initialNextIndex[0]]) {
          previousIndex = props.data.options[foundSelectedTraitIndex - 1];
          const previousPreviousIndex =
            props.data.options[foundSelectedTraitIndex - 2];
          if (!previousPreviousIndex) {
            setMinDescendReached(true);
          }
        } else {
          let chosenIndexes = props.data.options.map((item, i) => {
            return [item[0], item[1], props.chosenExtras[item[0]]];
          });

          chosenIndexes = chosenIndexes.filter((item) => item[2] !== true);
          const randomIndex = Math.floor(Math.random() * chosenIndexes.length);
          previousIndex = chosenIndexes[randomIndex];
        }
      }
      if (previousIndex) {
        handleSelectTrait(
          previousIndex[0],
          previousIndex[0],
          trait,
          previousIndex[1],
          previousIndex[1]
        );
      }
    } else {
      let foundSelectedTraitIndex = 0;

      props.data.options.forEach((option, i) => {
        if (option === traitValue) foundSelectedTraitIndex = i;
      });
      const previousIndex = props.data.options[foundSelectedTraitIndex - 1];

      if (previousIndex !== undefined && previousIndex >= 0) {
        handleSelectTrait(previousIndex, previousIndex, trait);
      }
      const previousPreviousIndex =
        props.data.options[foundSelectedTraitIndex - 2];
      if (!previousPreviousIndex) {
        setMinDescendReached(true);
      }
    }
  };

  const initialLoadFromMainToken = () => {
    const { trait, options } = props.data;

    if (!props.initialData || !Object.keys(props.initialData).length > 0) {
      if (trait === "Hero Level") {
        handleSelectTrait(
          options[options.length - 1],
          options[options.length - 1],
          trait
        );
      } else if (trait === "Background Color") {
        handleSelectTrait(options[0], options[0], trait);
      } else if (trait === "Runes") {
        // to do: if extra more than 1, fall back to invisible

        handleSelectTrait(
          options[0][0],
          options[0][0],
          trait,
          options[0][1],
          options[0][1]
        );
      } else if (trait.match(extraRegex)) {
        // to do: if extra more than 1, fall back to invisible

        handleSelectTrait(
          options[0][0],
          options[0][0],
          trait,
          options[0][1],
          options[0][1]
        );
      } else {
        handleSelectTrait(options[0], options[0], trait);
      }
    } else {
      const { level, skin, clA, clB, bg, extras, fullBgColor, runes, halo } =
        props.initialData;

      switch (props.title) {
        case "Hero Level":
          handleSelectTrait(level, level, "Hero Level");
          setMaxAscendReached(
            level === props.data.options[props.data.options.length - 1]
          );
          break;
        case "Background Color":
          handleSelectTrait(fullBgColor, fullBgColor, "Background Color");
          setMaxAscendReached(
            fullBgColor === props.data.options[props.data.options.length - 1]
          );
          setMinDescendReached(fullBgColor === props.data.options[0]);
          break;
        case "Skin":
          handleSelectTrait(skin, skin, "Skin");
          break;
        case "Background":
          handleSelectTrait(bg, bg, "Background");
          break;
        case "Clothes A":
          handleSelectTrait(clA, clA, "Clothes A");
          break;
        case "Clothes B":
          handleSelectTrait(clB, clB, "Clothes B");
          break;
        case "Runes":
          handleSelectTrait(runes[0], runes[0], "Runes", runes[1], runes[1]);
          break;
        case "Halo":
          handleSelectTrait(halo, halo, "Halo");
          break;
        case props.title.match(extraRegex)?.input:
          let extranumb = trait.match(/\d/g).join("");
          extranumb = parseInt(extranumb);
          if (extranumb <= extras.length) {
            handleSelectTrait(
              extras[extranumb - 1][0],
              extras[extranumb - 1][0],
              `Extra ${extranumb}`,
              extras[extranumb - 1][1],
              extras[extranumb - 1][1]
            );
          } else {
            // Add invisible to the remaining unselected extra
            handleSelectTrait(0, 0, `Extra ${extranumb}`, 0, 0);
          }

          break;
        default:
          break;
      }
    }
  };
  const handleSelectTrait = (
    traitValue,
    buttonIndex,
    trait,
    level = null,
    max = null,
    extraNumber = null
  ) => {
    if (trait === "Runes" || trait.match(extraRegex)) {
      setSelectedItem({
        name:
          trait === "Runes"
            ? traitsMapping[5][traitValue]
            : traitsMapping[6][traitValue],
        trait,
        traitValue,
        index: buttonIndex,
        level,
        max,
        extraNumber,
      });
      setLevel(level);
      setMax(max);
      props.setSelectedTrait({
        name:
          trait === "Runes"
            ? traitsMapping[5][traitValue]
            : traitsMapping[6][traitValue],
        trait,
        traitValue,
        index: buttonIndex,
        level,
        max,
        extraNumber,
      });
    } else if (trait === "Background Color") {
      setSelectedItem({
        name: traitsMapping[traitsMappingReference.indexOf(trait)][traitValue],
        trait,
        traitValue,
        index: buttonIndex,
      });
      props.setSelectedTrait({
        name: traitsMapping[traitsMappingReference.indexOf(trait)][traitValue],
        trait,
        traitValue,
        index: buttonIndex,
      });
    } else if (trait !== "Hero Level") {
      setSelectedItem({
        name: traitsMapping[traitsMappingReference.indexOf(trait)][traitValue],
        trait,
        traitValue,
        index: buttonIndex,
      });
      props.setSelectedTrait({
        name: traitsMapping[traitsMappingReference.indexOf(trait)][traitValue],
        trait,
        traitValue,
        index: buttonIndex,
      });
    } else {
      setSelectedItem({
        trait,
        traitValue,
        index: buttonIndex,
      });
      props.setSelectedTrait({
        trait,
        traitValue,
        index: buttonIndex,
      });
    }
  };
  useEffect(() => {
    if (!props.scoreTooLow) initialLoadFromMainToken();
  }, []);
  useEffect(() => {
    // initialLoadFromMainToken();

    if (selectedItem) {
      //change max
      const foundSelectedItemNewMax = props.data.options.find(
        (item) =>
          item[0] === selectedItem.traitValue && item[1] !== selectedItem.max
      );

      if (foundSelectedItemNewMax) {
        handleSelectTrait(
          foundSelectedItemNewMax[0],
          foundSelectedItemNewMax[0],
          props.title,
          foundSelectedItemNewMax[1],
          foundSelectedItemNewMax[1]
        );
      }
      const traitRemoved = props.data.option;
      const selectedRemoved = props.data.options.find(
        (item) =>
          item[0] === selectedItem.traitValue &&
          selectedItem.trait === props.title
      );
      if (!selectedRemoved) {
        initialLoadFromMainToken();
      }
    }

    if (props.initialData && Object.keys(props.initialData).length > 0) {
      let isMax;
      if (props.title !== "Hero Level") {
        if (props.data.options.length > 1) {
          isMax = false;
        } else {
          isMax = true;
        }
        setMaxAscendReached(isMax);
      }
    } else {
      setMaxAscendReached(
        props.title === "Hero Level"
          ? true
          : props.title === "Background Color"
          ? false
          : props.data.options.length > 1
          ? false
          : true
      );
    }
  }, [props.data]);

  useEffect(() => {
    if (selectedItem && props.data.trait.match(extraRegex)) {
      let extranumb = props.data.trait.match(/\d/g).join("");
      extranumb = parseInt(extranumb);
      if (extranumb > 1) {
        if (selectedItem.traitValue === props.extra1[0]) {
          handleSelectTrait(
            props.data.options[0][0],
            props.data.options[0][0],
            props.data.trait,
            props.data.options[0][1],
            props.data.options[0][1]
          );
        }
      }
    }
  }, [props.extra1]);
  useEffect(() => {
    if (level !== 0) {
      setSelectedItem({ ...selectedItem, level });
      props.setSelectedTrait({ ...selectedItem, level });
    }
  }, [level]);

  useEffect(() => {
    if (selectedItem){
      const { trait, traitValue, index, level, max } = selectedItem;
      let foundSelectedTraitIndex = 0;

      if (trait === "Runes") {
        props.data.options.forEach((option, i) => {
          if (option[0] === traitValue && option[1] === max)
            foundSelectedTraitIndex = i;
        }); 
      } else if (trait.match(extraRegex)) {
          props.data.options.forEach((option, i) => {
            if (option[0] === traitValue && option[1] === max)
              foundSelectedTraitIndex = i;
          });
      } else {
        props.data.options.forEach((option, i) => {
          if (option === traitValue) foundSelectedTraitIndex = i;
        });
      }
      const previousIndex =
        props.data.options[foundSelectedTraitIndex - 1];
      if (previousIndex === undefined || previousIndex < 0 )  {
        setMinDescendReached(true);
      }
      else {
        setMinDescendReached(false);
      }

      const nextIndex = props.data.options[foundSelectedTraitIndex + 1];
      if (!nextIndex) {
        setMaxAscendReached(true);
      }
      else {
        setMaxAscendReached(false);
      }
    }
  }, [selectedItem]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",
        alignItems: "end",
        marginBottom: "8px",
        userSelect: "none",
      }}
    >
      {props.isActivated ? (
        <>
          {selectedItem && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "end",
              }}
            >
              {" "}
              <svg
                style={{
                  cursor: minDescendReached ? "default" : "pointer",
                  marginRight: "5px",
                }}
                height="30"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
                fill={minDescendReached ? "grey" : "#ffb631"}
                viewBox="0 0 10 20"
                onClick={descendTrait}
              >
                {" "}
                <path d="m14 7-5 5 5 5V7z"></path>
              </svg>{" "}
              <svg
                style={{ cursor: maxAscendReached ? "default" : "pointer" }}
                height="30"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
                fill={maxAscendReached ? "grey" : "#ffb631"}
                viewBox="0 0 20 20"
                onClick={ascendTrait}
              >
                {" "}
                <path d="m8 17 5-5-5-5v10z"></path>
              </svg>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "start",
            }}
          >
            <span
              style={{
                fontSize: matchesSmall ? "14px" : "18px",
                fontFamily: "EurostilObq",
              }}
            >
              {props.title}:{" "}
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                width: "300px",
              }}
            >
              {" "}
              <span
                style={{
                  color: "#ffb631",
                  fontFamily: "EurostilDemi",
                  fontSize: matchesSmall ? "14px" : "18px",
                  padding: 0,
                  textAlign: "left",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              >
                {selectedItem
                  ? selectedItem.name
                    ? selectedItem.name
                    : selectedItem.traitValue
                  : "Select Trait"}
              </span>
              {selectedItem &&
                (props.title === "Runes" || props.title.match(extraRegex)) && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: matchesSmall ? "14px" : "16px",
                    }}
                  >
                    {" "}
                    <svg
                      style={{ cursor: "pointer", marginRight: "5px" }}
                      height="30"
                      width="30"
                      xmlns="http://www.w3.org/2000/svg"
                      fill={level > 1 ? "white" : "grey"}
                      viewBox="0 0 10 20"
                      onClick={decreaseLevel}
                    >
                      {" "}
                      <path d="m14 7-5 5 5 5V7z"></path>
                    </svg>{" "}
                    {level}
                    <svg
                      style={{ cursor: level < max ? "pointer" : "default" }}
                      height="30"
                      width="30"
                      xmlns="http://www.w3.org/2000/svg"
                      fill={level < max ? "white" : "grey"}
                      viewBox="0 0 20 20"
                      onClick={increaseLevel}
                    >
                      {" "}
                      <path d="m6 17 5-5-5-5v10z"></path>
                    </svg>
                  </div>
                )}
            </div>

            <Popper
              id={props.id + props.title + Math.random(1, 500)}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    background: "#ffb631",
                    color: "black",
                  }}
                >
                  {props.title === "Runes" || props.title.match(extraRegex)
                    ? props.data.options.map((trait, i) => {
                        const isItemSelected = selectedItem
                          ? selectedItem.index === trait[0]
                            ? true
                            : false
                          : false;

                        return (
                          <Button
                            disabled={
                              props.title.match(extraRegex) &&
                              props.chosenExtras[trait[0]]
                            }
                            onClick={() => {
                              handleSelectTrait(
                                trait[0],
                                trait[0],
                                props.title,
                                trait[1],
                                trait[1]
                              );
                              handleClose();
                            }}
                            style={{
                              border: isItemSelected
                                ? "1px solid black"
                                : "none",
                              borderRadius: 0,
                              background: "none",
                              fontSize: "12px",
                              fontFamily: "EurostilDemi",
                              color:
                                props.title.match(extraRegex) &&
                                props.chosenExtras[trait[0]]
                                  ? "grey"
                                  : "black",
                            }}
                          >
                            {props.title === "Runes"
                              ? traitsMapping[5][trait[0]]
                              : props.title.match(extraRegex)
                              ? traitsMapping[6][trait[0]]
                              : ""}
                          </Button>
                        );
                      })
                    : props.data.options.map((trait, i) => {
                        const isItemSelected = selectedItem
                          ? selectedItem.index === trait
                            ? true
                            : false
                          : false;
                        return (
                          <Button
                            disabled={
                              props.title.match(extraRegex) &&
                              props.chosenExtras[trait[0]]
                            }
                            onClick={() => {
                              handleSelectTrait(trait, trait, props.title);
                              handleClose();
                            }}
                            style={{
                              border: isItemSelected
                                ? "1px solid black"
                                : "none",
                              borderRadius: 0,
                              background: "none",
                              fontSize: "12px",
                              fontFamily: "EurostilDemi",
                              color:
                                props.title.match(extraRegex) &&
                                props.chosenExtras[trait[0]]
                                  ? "grey"
                                  : "black",
                            }}
                          >
                            {props.title === "Hero Level" //||
                            // props.title === "Background Color"
                              ? trait
                              : traitsMapping[
                                  traitsMappingReference.indexOf(props.title)
                                ][trait]}
                          </Button>
                        );
                      })}
                </div>
              </ClickAwayListener>
            </Popper>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "end",
            }}
          >
            {" "}
            <svg
              style={{ marginRight: "5px" }}
              height="30"
              width="30"
              xmlns="http://www.w3.org/2000/svg"
              fill="grey"
              viewBox="0 0 10 20"
            >
              {" "}
              <path d="m14 7-5 5 5 5V7z"></path>
            </svg>{" "}
            <svg
              style={{}}
              height="30"
              width="30"
              xmlns="http://www.w3.org/2000/svg"
              fill="grey"
              viewBox="0 0 20 20"
            >
              {" "}
              <path d="m8 17 5-5-5-5v10z"></path>
            </svg>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "start",
            }}
          >
            <span
              style={{
                fontSize: "18px",
                fontFamily: "EurostilObq",
                color: "grey",
              }}
            >
              {props.title}:{" "}
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                width: "300px",
              }}
            >
              {" "}
              <span
                style={{
                  color: "grey",
                  fontFamily: "EurostilDemi",
                  fontSize: "18px",
                  padding: 0,
                  textAlign: "left",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              >
                Score Too Low
              </span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <svg
                  style={{ cursor: "default", marginRight: "5px" }}
                  height="30"
                  width="30"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={"grey"}
                  viewBox="0 0 10 20"
                >
                  {" "}
                  <path d="m14 7-5 5 5 5V7z"></path>
                </svg>{" "}
                <span style={{ color: "grey" }}>0</span>
                <svg
                  style={{ cursor: "default" }}
                  height="30"
                  width="30"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={"grey"}
                  viewBox="0 0 20 20"
                >
                  {" "}
                  <path d="m6 17 5-5-5-5v10z"></path>
                </svg>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SelectTrait;
