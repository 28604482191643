import React, { useEffect, useState, useCallback, useRef } from "react";

import { Link } from "react-router-dom";

import { ethers } from "ethers";
import { useAccount, useSigner, useProvider, useNetwork } from "wagmi";

import {
  SACRIFICE_ADDRESS,
  SACRIFICE_ABI,
} from "../../config/configSacrifice.js";
import {
    WRAPPER_ABI,
    WRAPPER_ADDRESS,
    SOULSLOCKER_ABI,
    SOULSLOCKER_ADDRESS,
} from "../../config/configMerging.js";
import {
    calculateRedeemableExp,
    spiritsToExp,
    approveSacrifice,
    isSacrificeApprovedCheck,
    getSacrificeCounter,
    sacrificeActive,
} from "../../helpers/sacrificeHelpers.js";

import {
  EXPERIENCE_ABI,
  EXPERIENCE_ADDRESS,
} from "../../config/configExperience.js";

import {
  MULTICALL2_ABI,
  MULTICALL2_ADDRESS
} from "../../config/configMulticall.js";


import "./style.css";
import {
    Grid,
    useMediaQuery,
    Input,
    Button,
    Modal,
    TextField,
    CircularProgress,
    Snackbar,
    Stack,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { listAnimeSpiritsHeroesOfOwner_separate } from "../../helpers/mergerHelpers.js";
import { getBatchHeroExpDataAndClaimableExp } from "../../helpers/leaderboardHelpers.js";
import Fade from "@mui/material/Fade";

import labImage from "../../assets/ordinalsDay/Lab_V0.jpg";
// import talanjiVideo from "../../assets/AE_Dark Talanji_LOOP_V2_50mb.mp4";
import conclaveImgV2 from "../../assets/conclave/Conclave_web-grad_V3_Full.jpg";
import titleConclave from "../../assets/conclave/title_conclave_V0.png";

const Conclave = () => {
    //wagmi hooks for account, signer, provider and network
    let { address, isConnected } = useAccount();
    const provider = useProvider();

    const targetTimestamp = 1700502335;
    
    const { data: signer, isError, isLoading } = useSigner();
    const {chain} = useNetwork()

    const matchesExtraLarge = useMediaQuery("(max-width:1600px)");
    const matchesLarge = useMediaQuery("(max-width:1500px)");
    const matchesMedium = useMediaQuery("(max-width:1200px)");
    const matchesSmall = useMediaQuery("(max-width:1024px)");
    const matchesMobile = useMediaQuery("(max-width:600px)");

    const [isChainSupported, setIsChainSupported] = useState(false)

    const [raffleOpen, setRaffleOpen] = useState(true);

    const [allHeroes, setAllHeroes] = useState([]);
    const [allSpirits, setAllSpirits] = useState([]);

    const [expOfHeroes, setExpOfHeroes] = useState({});

    const [heroesInRaffle, setHeroesInRaffle] = useState([]);
    const [userEntries,  setUserEntries] = useState([]);

    const [raffleModalOpen, setRaffleModalOpen] = useState(false);
    const [selectedHero, setSelectedHero] = useState(null);

    const [editionsRemaining, setEditionsRemaining] = useState(0);
    const [editionsToMint, setEditionsToMint] = useState(0);

    const [confirmedSacrifice, setConfirmedSacrifice] = useState(false);
    const [isSacrificeActive, setIsSacrificeActive] = useState(false);

    const [dayLeft, setDayLeft] = useState(0);
    const [hourLeft, setHourLeft] = useState(0);
    const [minuteLeft, setMinuteLeft] = useState(0);

    // CONTRACTS
    const [wrapperContract, setWrapperContract] = useState(null);
    const [experienceContract, setExperienceContract] = useState(null);
    const [multicallContract, setMulticallContract] = useState(null);

    const [isSacrificeApproved, setIsSacrificeApproved] = useState(false);

    const [isApproveLoading, setIsApproveLoading] = useState(false);

    const [isMessageSignLoading, setIsMessageSignLoading] = useState(false);

    const [openCelebrationModal, setOpenCelebrationModal] = useState(false);

    const [notification, setNotification] = useState({});

    const [videoSrc, setVideoSrc] = useState(null);


    return (
        <Grid
            container
            direction="column"
            justifyContent="start"
            alignItems="center"
            style={{
                paddingTop: "100px",
                width: "100%",
                height: "100%",
            }}
        >
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                    width: "100%",
                }}
            >
                
                
                    <>
                        {/* <span
                            style={{
                                fontSize: matchesMobile? "20px" : matchesMedium
                                    ? "25px"
                                    : matchesSmall
                                    ? "20px"
                                    : "32px",
                                fontFamily: "PPNeueMachina",
                                marginBottom: "5px",
                                fontWeight: "900",
                                color: "#ffb631",
                            }}
                        >
                            EXP Raffle
                        </span> */}
                        <div style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "50%",
                              maxWidth: "600px",
                              // paddingTop: "25px",  
                            }}>
                                      <img
                                          style={{
                                              width: "100%",
                                              marginTop: "0px",
                                              marginBottom: "20px",
                                              
                                          }}

                                          src={titleConclave}
                                      />
                                    </div>
                        
                        <p
                            style={{
                                fontSize: matchesSmall ? "12px" : "14px",
                                // margin: "12px 0",
                                letterSpacing: 2,
                                // fontWeight: 100,
                                width: matchesMobile ? "80%" : "80%",
                                fontFamily: "EurostilNormal",
                                whiteSpace: "pre-line",
                                textAlign: "center",
                                fontStyle: "italic",
                            }}
                        >
                                In a chamber shrouded in the rich tapestries of time, the <br/> 
                                Conclave convenes beneath the flicker of candlelight and <br/> 
                                the watchful eyes of ancestral minds.
                                <br/> <br/> 
                                The Regent Ascendant, stands central amidst the summit. <br/> 
                                Each attendee a high ranking general.
                                <br/> <br/> 
                                The Conclave is no ordinary gathering but a ritual as old as <br/>
                                the empire itself, convened to decide the fates not just of <br/>
                                kingdoms, but of the cosmos.

                        </p>
                        {raffleOpen && (
                            <div style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "80%",
                              paddingTop: "10px",
                            }}>

                                <Grid 
                                      item xs={12} sm={12} md={12}
                                      style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                            marginTop: "0px",
                                        }}>                                    
                                    <div>
                                      <img
                                          style={{
                                              width: "100%",
                                              maxWidth: "1500px",
                                              marginTop: "0px",
                                          }}

                                          src={conclaveImgV2}
                                      />
                                    </div>
                                   
                                      
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                
                                    style={{ width: "100%", paddingTop: "30px", marginBottom: "150px"}}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ marginTop: "0px", display: 'flex', justifyContent: 'center' }}
                                    >
                                        <Button
                                            style={{
                                                // background: (address && isConnected && isChainSupported && isSacrificeActive) ? "#ffb631" : "gray",
                                                // backgroundImage: `url(${buttonLarge})`,
                                                // backgroundSize: matchesMobile ? "300px auto" : "500px auto", // Add this line
                                                // backgroundRepeat: 'no-repeat', // Add this line
                                                // backgroundPosition: 'center', // Add this line
                                                backgroundColor: "#ffb631",
                                                color: "black",
                                                width: matchesMobile ? "200px" : "300px",
                                                padding: "12px 0",
                                                marginTop: "0px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderRadius: 0,
                                                fontSize: matchesMobile ? "14px" : "18px",
                                                fontFamily: "EurostilDemi",
                                            }}
                                            disabled={false}
                                           onClick={() => {
                                              window.location.hash = "/exp-editions";
                                              window.scrollTo(0, 0); // Add this line
                                            }}
                                        >
                                            <span> Join the Summit </span>
                                        </Button>{" "}
                                    </Grid>
                                </Grid>
                            </div>
                        )}

                    </>
               
            </Grid>
        </Grid>
    );
};

export default Conclave;
