import React, { useState, useRef, useEffect } from "react";

import { Grid, useMediaQuery, Modal } from "@mui/material";

import { Link } from "react-router-dom";

import Fade from "@mui/material/Fade";
import banner from "../assets/comic/banner.jpg";
import cover from "../assets/comic/COMIC-COVER_V2.png";
import cover2 from "../assets/comic2/COMIC-COVER2_V1.png";
import arrow from "../assets/libraryArrow.png";
import images from "../assets/comic";
import images2 from "../assets/comic2";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Comic = ({ scrollPosition }) => {
  const matchesLarge = useMediaQuery("(max-width:1600px)");
  const matchesMedium = useMediaQuery("(max-width:1400px)");
  const matchesSmall = useMediaQuery("(max-width:1200px)");
  const matchesMobile = useMediaQuery("(max-width:800px)");

  const [page, setPage] = useState(1);
  const [page2, setPage2] = useState(1);
  const [openModal, setOpenModal] = useState(false);

  const [modalData, setModalData] = useState(undefined);
  const previousPage = () => {
    setPage((prevPage) => prevPage - 1);
  };
  const nextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const previousPage2 = () => {
    setPage2((prevPage) => prevPage - 1);
  };

  const nextPage2 = () => {
    setPage2((prevPage) => prevPage + 1);
  };

 

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOpen = (index) => {
    // setModalData(index);
    // setOpenModal(true);
  };
  return (
      <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
              paddingTop: "100px",
              marginBottom: "30px",
              width: "100%",
              fontFamily: "EurostilNormal",
              fontWeight: "100",
          }}
      >
          {/* fake loading */}

          {Object.keys(images).map((src) => {
              return (
                  <img
                      src={images[src]}
                      style={{ visibility: "hidden", display: "none" }}
                  />
              );
          })}
          {Object.keys(images2).map((src) => {
              return (
                  <img
                      src={images2[src]}
                      style={{ visibility: "hidden", display: "none" }}
                  />
              );
          })}
          <Modal
              disableScrollLock={true}
              style={{ border: "none" }}
              open={openModal}
              onClose={handleClose}
          >
              <Fade in={openModal}>
                  <div
                      style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "90vw",
                          maxWidth: "1600px",
                          height: matchesLarge ? "auto" : "90%",
                          maxHeight: "90%",
                          background: "#111111",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "10px",
                          outline: "none",
                      }}
                  >
                      <img
                          src={images[`com${modalData + 1}`]}
                          style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "contain",
                          }}
                      />
                  </div>
              </Fade>
          </Modal>
          {/* Chapter 1 */}
          <div
              style={{
                  width: matchesSmall ? "90%" : "35%",
                  textAlign: matchesSmall ? "center" : "center",
                  marginBottom: "20px",
              }}
          >
              {" "}
              <span
                  style={{
                      fontFamily: "PPNeueMachina",
                      fontSize: matchesSmall ? "18px" : "32px",
                      color: "#ffb631",
                  }}
              >
                  Le Anime Comic [VOL 1]
              </span>
          </div>
          <div
              style={{
                  width: matchesSmall ? "90%" : "35%",
                  textAlign: matchesSmall ? "center" : "center",
                  marginBottom: "20px",
              }}
          >
              {" "}
              <span
                  style={{
                      letterSpacing: 2,
                      fontFamily: "EurostilNormal",
                      fontWeight: 100,
                      color: "white",
                  }}
              >
                  Collectors received the Comic as an{" "}
                  <Link
                      style={{
                          textDecoration: "none",
                          color: "rgb(255, 182, 49)",
                          cursor: "pointer",
                      }}
                      target="_blank"
                      to="https://opensea.io/collection/le-anime-comic"
                  >
                      NFT
                  </Link>
                  !
              </span>
          </div>
          <div
              style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
              }}
          >
              {matchesMobile && (
                  <>
                      <LazyLoadImage
                          key={"cover"}
                          style={{
                              marginBottom: "10px",

                              width: "100%",
                          }}
                          width="100%"
                          height="auto"
                          effect="blur"
                          src={cover}
                      />

                      {Object.keys(images).map((index, key) => {
                          return (
                              <LazyLoadImage
                                  key={key}
                                  style={{
                                      marginBottom: "10px",
                                      cursor: "pointer",
                                      width: "100%",
                                  }}
                                  scrollPosition={scrollPosition}
                                  width="90%"
                                  height="auto"
                                  effect="blur"
                                  onClick={() => {
                                      handleOpen(key);
                                  }}
                                  src={images[index]}
                              />
                          );
                      })}
                  </>
              )}
          </div>
          <div
              style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
              }}
          >
              {page > 1 && !matchesMobile && (
                  <img
                      style={{
                          height: matchesSmall ? "30px" : "50px",
                          transform: "rotate(180deg)",
                          cursor: page > 1 ? "pointer" : "not-allowed",
                      }}
                      src={arrow}
                      onClick={previousPage}
                  />
              )}
              {page === 1 && !matchesMobile && (
                  <LazyLoadImage
                      style={{
                          width: "98%",
                          maxHeight: "800px",
                      }}
                      width={
                          matchesMobile
                              ? "80%"
                              : matchesMedium
                              ? "50%"
                              : matchesLarge
                              ? "40%"
                              : "40%"
                      }
                      effect="blur"
                      src={cover}
                  />
              )}
              {page > 1 && !matchesMobile && (
                  <LazyLoadImage
                      style={{
                          width: "98%",
                          maxHeight: "800px",
                          // cursor: "pointer",
                          margin: "0 10px",
                      }}
                      width="70%"
                      effect="blur"
                      onClick={() => handleOpen(page - 2)}
                      src={images[`com${page - 1}`]}
                  />
              )}
              {page <= Object.keys(images).length && !matchesMobile && (
                  <img
                      style={{
                          height: matchesSmall ? "30px" : "50px",
                          cursor: "pointer",
                      }}
                      src={arrow}
                      onClick={nextPage}
                  />
              )}{" "}
          </div>
          {/* Chapter 2 */}
          <div
              style={{
                  width: matchesSmall ? "90%" : "35%",
                  textAlign: matchesSmall ? "center" : "center",
                  marginTop: "50px",
                  marginBottom: "20px",
              }}
          >
              {" "}
              <span
                  style={{
                      fontFamily: "PPNeueMachina",
                      fontSize: matchesSmall ? "18px" : "32px",
                      color: "#ffb631",
                  }}
              >
                  Le Anime Comic [VOL 2]
              </span>
          </div>
          <div
              style={{
                  width: matchesSmall ? "90%" : "35%",
                  textAlign: matchesSmall ? "center" : "center",
                  marginBottom: "20px",
              }}
          >
              {" "}
              <span
                  style={{
                      letterSpacing: 2,
                      fontFamily: "EurostilNormal",
                      fontWeight: 100,
                      color: "white",
                  }}
              >
                  Eligible Heroes can claim their cover NFT{" "}
                  <Link
                      style={{
                          textDecoration: "none",
                          color: "rgb(255, 182, 49)",
                          cursor: "pointer",
                      }}
                      // target="_blank"
                      to="/comic-claim"
                  >
                      HERE
                  </Link>
                  !
              </span>
          </div>
          <div
              style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
              }}
          >
              {matchesMobile && (
                  <>
                      <LazyLoadImage
                          key={"cover2"}
                          style={{
                              marginBottom: "10px",

                              width: "100%",
                          }}
                          width="100%"
                          height="auto"
                          effect="blur"
                          src={cover2}
                      />

                      {Object.keys(images2).map((index, key) => {
                          return (
                              <LazyLoadImage
                                  key={key}
                                  style={{
                                      marginBottom: "10px",
                                      cursor: "pointer",
                                      width: "100%",
                                  }}
                                  scrollPosition={scrollPosition}
                                  width="90%"
                                  height="auto"
                                  effect="blur"
                                  onClick={() => {
                                      handleOpen(key);
                                  }}
                                  src={images2[index]}
                              />
                          );
                      })}
                  </>
              )}
          </div>
          <div
              style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
              }}
          >
              {page2 > 1 && !matchesMobile && (
                  <img
                      style={{
                          height: matchesSmall ? "30px" : "50px",
                          transform: "rotate(180deg)",
                          cursor: page2 > 1 ? "pointer" : "not-allowed",
                      }}
                      src={arrow}
                      onClick={previousPage2}
                  />
              )}
              {page2 === 1 && !matchesMobile && (
                  <LazyLoadImage
                      style={{
                          width: "98%",
                          maxHeight: "800px",
                      }}
                      width={
                          matchesMobile
                              ? "80%"
                              : matchesMedium
                              ? "50%"
                              : matchesLarge
                              ? "40%"
                              : "40%"
                      }
                      effect="blur"
                      src={cover2}
                  />
              )}
              {page2 > 1 && !matchesMobile && (
                  <LazyLoadImage
                      style={{
                          width: "98%",
                          maxHeight: "800px",
                          // cursor: "pointer",
                          margin: "0 10px",
                      }}
                      width="70%"
                      effect="blur"
                      onClick={() => handleOpen(page2 - 2)}
                      src={images2[`com${page2 - 1}`]}
                  />
              )}
              {page2 <= Object.keys(images2).length && !matchesMobile && (
                  <img
                      style={{
                          height: matchesSmall ? "30px" : "50px",
                          cursor: "pointer",
                      }}
                      src={arrow}
                      onClick={nextPage2}
                  />
              )}{" "}
          </div>
      </Grid>
  );
};
export default trackWindowScroll(Comic);
