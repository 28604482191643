import React, { useEffect, useState, useCallback, useRef } from "react";

import { Link } from "react-router-dom";

import { ethers } from "ethers";
import { useAccount, useSigner, useProvider, useNetwork } from "wagmi";

import {
  ZENITH_MINT_ADDRESS,
  ZENITH_MINT_ABI,
} from "../../config/configZenith.js";
import {
    WRAPPER_ABI,
    WRAPPER_ADDRESS,
    SOULSLOCKER_ABI,
    SOULSLOCKER_ADDRESS,
} from "../../config/configMerging.js";
import {
    calculateRedeemableExp,
    spiritsToMint,
    approveSacrifice,
    isSacrificeApprovedCheck,
    // getSacrificeCounter,
    sacrificeActive,
    // getSpiritsEditionsCounter,
    ethToMint,
    getEthEditionsCounter,
} from "../../helpers/zenithHelpers.js";

import "./style.css";
import {
    Grid,
    useMediaQuery,
    Input,
    Button,
    Modal,
    TextField,
    CircularProgress,
    Snackbar,
    Stack,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { listAnimeSpiritsHeroesOfOwner_separate } from "../../helpers/mergerHelpers.js";
import Fade from "@mui/material/Fade";

import zenithImage from "../../assets/zenith_website_V2.png";
import zenithVideo from "../../assets/toomuchlag_Zenith_50mb.mp4";



const ZenithMint = () => {
    //wagmi hooks for account, signer, provider and network
    let { address, isConnected } = useAccount();
    const provider = useProvider();

    const targetTimestamp = 1700502335;
    
    const { data: signer, isError, isLoading } = useSigner();
    const {chain} = useNetwork()

    const matchesExtraLarge = useMediaQuery("(max-width:1600px)");
    const matchesLarge = useMediaQuery("(max-width:1500px)");
    const matchesMedium = useMediaQuery("(max-width:1200px)");
    const matchesSmall = useMediaQuery("(max-width:1024px)");
    const matchesMobile = useMediaQuery("(max-width:600px)");

    const [isChainSupported, setIsChainSupported] = useState(false)

    const [zenithOpen, setZenithOpen] = useState(true);

    const [allHeroes, setAllHeroes] = useState([]);
    const [allSpirits, setAllSpirits] = useState([]);

    const [sacrificeOpen, setSacrificeOpen] = useState(false);
    const [selectedHero, setSelectedHero] = useState(null);
    const [selectedSpirits, setSelectedSpirits] = useState([]);
    const [expToGain, setExpToGain] = useState(0);
    const [sacrificeCounter, setSacrificeCounter] = useState(0);

    const [editionsRemaining, setEditionsRemaining] = useState(null);

    const [confirmedSacrifice, setConfirmedSacrifice] = useState(false);
    const [isSacrificeActive, setIsSacrificeActive] = useState(false);

    const [fallenSpirits, setFallenSpirits] = useState([]);

    const [dayLeft, setDayLeft] = useState(0);
    const [hourLeft, setHourLeft] = useState(0);
    const [minuteLeft, setMinuteLeft] = useState(0);

    // CONTRACTS
    const [wrapperContract, setWrapperContract] = useState(null);
    const [lockerContract, setLockerContract] = useState(null);
    const [sacrificeContract, setSacrificeContract] = useState(null);

    const [isSacrificeApproved, setIsSacrificeApproved] = useState(false);

    const [isApproveLoading, setIsApproveLoading] = useState(false);

    const [isSacrificeLoading, setIsSacrificeLoading] = useState(false);

    const [openCelebrationModal, setOpenCelebrationModal] = useState(false);

    const [notification, setNotification] = useState({});

    const [videoSrc, setVideoSrc] = useState(null);



    // Refresh sacrificeActive and sacrificeCounter every 30 seconds
    function useInterval(callback, delay) {
      const savedCallback = useRef();
    
      // Remember the latest callback.
      useEffect(() => {
        savedCallback.current = callback;
      }, [callback]);
    
      // Set up the interval.
      useEffect(() => {
        function tick() {
          savedCallback.current();
        }
        if (delay !== null) {
          let id = setInterval(tick, delay);
          return () => clearInterval(id);
        }
      }, [delay]);
    }
    
    useInterval(async () => {
      if (signer && address && provider && isChainSupported) {
        const sacrificeActive_ = await sacrificeActive(sacrificeContract);
        setIsSacrificeActive(sacrificeActive_);
      }
      const spiritsEditionsRemaining = await getEthEditionsCounter(
        sacrificeContract,
        ZENITH_MINT_ADDRESS
    );

      setEditionsRemaining(spiritsEditionsRemaining);

      if(spiritsEditionsRemaining == 0) {
        setIsSacrificeActive(false);
      }
   
    }, 30000); // 60000 milliseconds = 60 seconds

    // USE EFFECTS
    useEffect(() => {
        //STYLE
        document.body.style.background = ` linear-gradient(rgba(0,0,0,0.9),rgba(0,0,0,0.9)), url(${zenithImage})`;
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundSize = "cover";
        document.body.style.backgroundColor = "#000000";
        
        return () => {
            document.body.style.background = "#111111";
            // Clear interval on component unmount
            // return () => clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (signer && address && provider && isChainSupported) {
            const sacrificeContract = new ethers.Contract(
                ZENITH_MINT_ADDRESS,
                ZENITH_MINT_ABI,
                signer
            );
            setSacrificeContract(sacrificeContract);


            const setAllHeroesAndSpiritsFunction = async () => {
                const sacrificeActive_ = await sacrificeActive(sacrificeContract)
                setIsSacrificeActive(sacrificeActive_);

                const spiritsEditionsRemaining = await getEthEditionsCounter(
                    sacrificeContract,
                    ZENITH_MINT_ADDRESS
                );

                setEditionsRemaining(spiritsEditionsRemaining);

                if(spiritsEditionsRemaining == 0) {
                  setIsSacrificeActive(false);
                }

            };

            setAllHeroesAndSpiritsFunction();

          //   const setAllFallenSpirits = async () => {
          //     let allTokens = await listAnimeSpiritsHeroesOfOwner_separate(
          //         ZENITH_MINT_ADDRESS,
          //         wrapperContract,
          //         lockerContract
          //     );

          //     // let allHeroes = allTokens.heroIDs;

          //     // const sortedallHeroes = allHeroes.sort((a, b) => {
          //     //     return a.id - b.id;
          //     // });

          //     // setAllHeroes(sortedallHeroes);

          //     let allSouls = allTokens.tokenIDs;

          //     const sortedAllSouls = allSouls.sort((a, b) => {
          //         return a.id - b.id;
          //     });

          //     // remove all ids < 1574 - Spirits Only
          //     const filteredAllSpirits = sortedAllSouls.filter((soul) => {
          //         return soul.soulNumber >= 1574;
          //     });

          //     // sort filtered spirits by .traits[0][1] ascending
          //     filteredAllSpirits.sort((a, b) => {
          //         return a.traits[0][1] - b.traits[0][1];
          //     });

          //     // console.log("filteredAllSpirits", filteredAllSpirits)
          //     // console.log("filteredAllSpirits length", filteredAllSpirits.length)

          //     // from filteredAllSpirits, create an array with element.soulNumber
          //     // const filteredAllSpiritsSoulNumbers = filteredAllSpirits.map((spirit) => {
          //     //   return spirit.soulNumber;
          //     // });
          //     // console.log("filteredAllSpiritsSoulNumbers", filteredAllSpiritsSoulNumbers)

          //     setFallenSpirits(filteredAllSpirits);
          // };
          // setAllFallenSpirits();
        }

        
    }, [signer, address, provider, isChainSupported]);

    useEffect(() => {
      if (typeof chain !== "undefined" && typeof chain.unsupported !== "undefined") {
        setIsChainSupported(!chain.unsupported)
      }
      // console.log("chain changed", chain)
    }, [chain])

    useEffect(() => {
        // let currentTimestamp = Math.floor(Date.now() / 1000);
    }, [selectedSpirits]);


    const handleImageClick = () => {
      setVideoSrc(zenithVideo);
    };
    
    const handleReload = () => {
      window.location.reload();
  };
 
    const handleMintEdition = () => {
        setIsSacrificeLoading(true);

        // add 100000 to all selected spirits
        // let selectedSpiritsToExp = selectedSpirits.map((spirit) => {
        //     return spirit + 100000;
        // });
        ethToMint(sacrificeContract)
            .then(() => {
                // setSacrificeOpen(false);
                setOpenCelebrationModal(true);
                setIsSacrificeLoading(false);
                setNotification({
                    state: true,
                    message: "Mint succesfully completed",
                    type: "success",
                });
            })
            .catch((err) => {
              // setOpenCelebrationModal(true);
                setIsSacrificeLoading(false);
                setNotification({
                    state: true,
                    message: err.data ? err.data.message : err.message,
                    type: "error",
                });
            });
    };

    const handleCloseSacrifice = () => {
        setSacrificeOpen(false);
        setSelectedHero(null);
        setConfirmedSacrifice(false);
        setSelectedSpirits([]);
        setOpenCelebrationModal(false);
        handleReload();
    };

    const handleDismissSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setNotification({ status: false });
    };
    return (
        <Grid
            container
            direction="column"
            justifyContent="start"
            alignItems="center"
            style={{
                paddingTop: "100px",
                width: "100%",
                height: "100%",
            }}
        >
            <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                    autoHideDuration={2000}
                    open={notification.state && notification.type === "error"}
                    onClose={handleDismissSnackBar}
                    key={"error"}
                >
                    <MuiAlert
                        onClose={handleDismissSnackBar}
                        variant="filled"
                        severity="error"
                    >
                        {notification.message}
                    </MuiAlert>
                </Snackbar>
                <Snackbar
                    autoHideDuration={2000}
                    open={notification.state && notification.type === "success"}
                    onClose={handleDismissSnackBar}
                    key={"success"}
                >
                    <MuiAlert
                        onClose={handleDismissSnackBar}
                        variant="filled"
                        severity="success"
                    >
                        {notification.message}
                    </MuiAlert>
                </Snackbar>
            </Stack>
            <Modal
                disableScrollLock={true}
                style={{ border: "none" }}
                open={openCelebrationModal}
                onClose={handleCloseSacrifice}
            >
                <Fade in={openCelebrationModal}>
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: matchesMobile ? "100%" : "70%",
                            height: "80%",
                            // maxHeight: "80%",
                            background: "#111111",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "center",
                            borderRadius: "10px",
                            outline: "none",
                            paddingTop: "25px",
                            color: "white",
                            overflowY: "auto",
                            overflowX: "hidden",
                        }}
                    >
                        {openCelebrationModal && (
                            <div
                                style={{
                                    width: "90%",
                                    height: "100%",
                                    textAlign: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        marginBottom: "0px",
                                    }}
                                >
                                    {/* <Grid item xs={3}></Grid> */}
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "PPNeueMachina",
                                            fontSize: "22px",
                                            textAlign: "center",
                                            color: "#ffffff",
                                            marginBottom: "20px",
                                        }}
                                      >
                                        CONGRATULATIONS, THE MINT WAS SUCCESSFUL <br/>
                                        </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                          fontFamily: "PPNeueMachina",
                                          fontSize: "22px",
                                          textAlign: "center",
                                          color: "#ffffff",
                                          marginBottom: "20px",
                                      }}
                                      >

                                        {/* {editionsToMint > 0 ? `+ ${editionsToMint} EDITION` : ""}{editionsToMint > 1 ? "S" : ""} */}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "EurostilNormal",
                                            fontSize: "15px",
                                            textAlign: "center",
                                            // italic
                                            fontStyle: "italic",
                                            color: "#ffffff",
                                            marginBottom: "20px",
                                        }}
                                    >
                                    {/* Talanji feasts on your Spirits, the echo of the ancestors resonates, <br/> soon illuminating the one who will inherit the ancient power. */}
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        // marginBottom: "10px",
                                    }}
                                >
                                    
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        marginBottom: "5px",
                                    }}
                                >
                                    <Grid item>
                                        <Button
                                            onClick={handleCloseSacrifice}
                                            style={{
                                                background: "#ffb631",
                                                color: "black",
                                                width: "280px",
                                                padding: "10px 0",
                                                marginTop: "15px",
                                                // margin: "10px 0",
                                                borderRadius: 0,
                                                fontSize: "20px",
                                                fontFamily: "EurostilDemi",
                                            }}
                                        >
                                            {"CLOSE"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        )} 
                    </div>
                </Fade>
            </Modal>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                    width: "100%",
                }}
            >
                
                
                    <>
                        <span
                            style={{
                                fontSize: matchesMobile? "20px" : matchesMedium
                                    ? "25px"
                                    : matchesSmall
                                    ? "20px"
                                    : "32px",
                                fontFamily: "PPNeueMachina",
                                marginBottom: "5px",
                                fontWeight: "900",
                                color: "#ffb631",
                            }}
                        >
                            
                          Zenith
                        </span> 
                        <p
                            style={{
                                fontSize: matchesSmall ? "12px" : "14px",
                                // margin: "12px 0",
                                letterSpacing: 2,
                                fontWeight: 100,
                                width: matchesMobile ? "80%" : "50%",
                                fontFamily: "EurostilNormal",
                                whiteSpace: "pre-line",
                                textAlign: "center",
                                fontStyle: "italic",
                            }}
                        >
                           High above the clouds, hidden from the eyes of the unworthy, <br/> lies an ancient kingdom of unparalleled majesty. <br/> <br/>Guarded by twin giants whose stone form looms over the kingdom, <br/> it is home to the wisest minds in the known universe.{" "}
                            
                        </p>
                        {zenithOpen && (
                            <div style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "80%",
                              paddingTop: "25px",
                            }}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="start"
                                    style={{ width: "100%" }}
                                >
                                    <Grid 
                                      item xs={12} sm={12} md={12}
                                      style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                            maxWidth: "700px",
                                            // marginTop: "20px",
                                        }}>
                                    <div className="videoContainer">
                                      <img
                                          style={{
                                              width: "100%",
                                              marginTop: "0%",
                                          }}
                                          onClick={handleImageClick}
                                          src={zenithImage}
                                      />
                                      {videoSrc && (
                                      <video controls autoPlay style={{width: '100%', height: '100%', objectFit: 'contain' }} >
                                        <source src={videoSrc} type="video/mp4" />
                                      </video>)}
                                    </div>
                                    <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                            color: "grey",
                                            fontSize: "12px",
                                            fontWeight: 100,
                                            fontFamily: "EurostilNormal", 
                                            fontStyle: "italic",
                                            paddingTop: "5px",
                                            paddingBottom: "0px",
                                        }}>
                                          {"Click to play video"}
                                    </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ marginTop: "0px" }}
                                    >
                                     
                                    
                                      <div
                                        style={{
                                           marginTop: "50px",
                                            fontSize: matchesMobile ? "20px" : "24px",
                                            fontFamily: "EurostilDemi",
                                            color: "#ffb631",
                                            textAlign: "center",
                                            fontStyle: "italic", 
                                          }}
                                      >
                                      
                                        <span> Limited Edition </span>
                                      </div>

                                    

                                    <div
                                        style={{
                                           marginTop: "20px",
                                            fontSize: matchesMobile ? "16px" : "16px",
                                            fontFamily: "EurostilDemi",
                                            color: "#ffffff",
                                            textAlign: "center",
                                            fontStyle: "bold",
                                          }}
                                      >
                                        1 Edition of Zenith = 0.1 ETH <br/>
                                        <br/>
                                        Only 20 Editions available for sale - first come first served <br/>
                                        <br/>
                                        20 extra editions are available  <Link style={{ color: "#ffb631" }} target="_blank" to="/zenith-burn">here</Link>
                                        {" "} via Spirits Burn <br/>
                                        {/* will receive an edition of "The Conclave". <br/> */}
                                        {/* span in eurostilnormal */}
                                        <br/>
                                        {/* <span style={{fontFamily: "EurostilNormal", fontSize: "14px"}}>
                                          There are 31 winning spots, but 32 editions..
                                        </span> */}
                                      </div>

                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                
                                    style={{ 
                                      width: "100%",
                                      marginBottom: "100px",

                                    }}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ marginTop: "25px", display: 'flex', justifyContent: 'center' }}
                                    >
                                        <Button
                                            style={{
                                                background: (address && isConnected && isChainSupported && isSacrificeActive && editionsRemaining > 0) ? "#ffb631" : "gray",
                                                color: "black",
                                                width: matchesMobile ? "80%" : "50%",
                                                padding: "25px 0",
                                                marginTop: "0px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderRadius: 0,
                                                fontSize: "16px",
                                                fontFamily: "EurostilDemi",
                                            }}
                                            disabled={(address && isConnected && isChainSupported && isSacrificeActive && editionsRemaining > 0) ? false : true}
                                            onClick={() => {
                                                handleMintEdition();
                                            }}
                                        >

                                            {isSacrificeLoading ? (
                                              <CircularProgress size={28} />
                                            ) : (
                                            <span>MINT FOR 0.1 ETH{(address && isConnected && isChainSupported) ? "" : "(Connect Wallet)"} </span>)}
                                        </Button>{" "}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ marginTop: "10px" }}
                                    >
                                      {/* <div
                                        style={{
                                           marginTop: "20px",
                                            fontSize: matchesMobile ? "16px" : "30px",
                                            fontFamily: "EurostilDemi",
                                            color: "#ffffff",
                                            textAlign: "center",
                                          }}
                                      >
                                      
                                        TOTAL SPIRITS SACRIFICED:  <span style={{color: "#ffb631"}}> {sacrificeCounter} </span>
                                      </div> */}
                                      {/* <div
                                        style={{
                                           marginTop: "20px",
                                           fontSize: matchesMobile ? "16px" : "30px",
                                            fontFamily: "EurostilDemi",
                                            color: "#ffffff",
                                            textAlign: "center",
                                          }}
                                      >
                                      
                                       EDITIONS REMAINING: <span style={{color: "#ffb631"}}>  {editionsRemaining}</span>
                                      </div> */}
                                    </Grid>
                                    {isConnected && isSacrificeActive && editionsRemaining == 0 && (<Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "EurostilDemi",
                                            fontSize: matchesMobile? "16px" : "20px",
                                            textAlign: "center",
                                            // cursor: "pointer",
                                            color: "rgb(255, 65, 54)",
                                            marginTop: "20px",
                                        }}
                                    >
                                      SOLD OUT  - NO MORE EDITIONS ON SALE
                                    </Grid>)
                                    
                                    }
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "EurostilDemi",
                                            fontSize: matchesMobile? "16px" : "20px",
                                            textAlign: "center",
                                            // cursor: "pointer",
                                            color: "rgb(255, 65, 54)",
                                            marginTop: "20px",
                                        }}
                                    >
                                      NO MORE EDITIONS AVAILABLE
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </>
               
            </Grid>
        </Grid>
    );
};

export default ZenithMint;
