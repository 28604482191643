import com1 from "./1-2.jpg";
import com2 from "./3-4.jpg";
import com3 from "./5-6.jpg";
import com4 from "./7-8.jpg";
import com5 from "./9-10.jpg";
import com6 from "./11-12.jpg";
import com7 from "./13-14.jpg";
import com8 from "./15-16.jpg";
import com9 from "./17-18.jpg";
import com10 from "./19-20.jpg";


export default {
  com1,
  com2,
  com3,
  com4,
  com5,
  com6,
  com7,
  com8,
  com9,
  com10,
};
