import React, {
  useEffect,
  useState,
  useCallback,
  createRef,
} from "react";
import { LIBRARY_ADDRESS, LIBRARY_ABI } from "../../config/configLibrary.js";
import { Link } from "react-router-dom";
import {
  WRAPPER_ABI,
  WRAPPER_ADDRESS,
  SOULSLOCKER_ABI,
  SOULSLOCKER_ADDRESS,
} from "../../config/configMerging";
import {
  getLoreFromID,
  listLoreIds,
  storeLoreHolders,
  isClosed,
  closeLibrary,
  openLibrary,
  getLoreLibrarian,
  librarianAddress,
  storeLoreLibrarian,
} from "../../helpers/libraryHelpers";
import { ethers } from "ethers";
import { useAccount, useSigner, useProvider, useNetwork } from 'wagmi'
import "./style.css";
import {
  Grid,
  useMediaQuery,
  Input,
  Button,
  Modal,
  TextField,
  CircularProgress,
  Snackbar,
  Stack,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { listAnimeSpiritsHeroesOfOwner_separate } from "../../helpers/mergerHelpers";
import Fade from "@mui/material/Fade";
import libraryimage from "../../assets/Library-Background.jpg";
import book from "../../assets/Library-Book.png";
import debounce from "lodash.debounce";
import bg from "../../assets/Library-Book-Background.jpg";
import bookmark from "../../assets/Library-Book-Bookmark.png";
import arrow from "../../assets/libraryArrow.png";

const Library = () => {
    //wagmi hooks for account, signer, provider and network
  const { address, isConnected } = useAccount()
  const provider = useProvider()
  const { data: signer, isError, isLoading } = useSigner()
  const {chain} = useNetwork()

  const matchesExtraLarge = useMediaQuery("(max-width:1600px)");
  const matchesLarge = useMediaQuery("(max-width:1500px)");
  const matchesMedium = useMediaQuery("(max-width:1200px)");
  const matchesSmall = useMediaQuery("(max-width:1024px)");
  const matchesMobile = useMediaQuery("(max-width:600px)");

  const [isChainSupported, setIsChainSupported] = useState(false)

  const [bookOpen, setBookOpen] = useState(false);
  const [heroID, setHeroID] = useState(null);
  const [index, setIndex] = useState(0);
  const [libraryContract, setLibraryContract] = useState(undefined);
  const [availableLoreIds, setAvailableLoreIds] = useState(undefined);
  const [lore, setLore] = useState(undefined);
  const [isLoreLoading, setIsLoreLoading] = useState(false);
  const [allTokens, setAllTokens] = useState([]);
  const [writeLoreOpen, setWriteLoreOpen] = useState(false);
  const [selectedHeroForLoreModification, setSelectHeroForLoreModification] =
    useState(null);

  const [isConfirmModificationLoading, setIsConfirmModificationLoading] =
    useState(false);
  const [
    isConfirmLibrarianModificationLoading,
    setIsConfirmLibrarianModificationLoading,
  ] = useState(false);
  const [isLibraryClosed, setIsLibraryClosed] = useState(false);
  const [isLibrarianAddress, setIsLibrarianAddress] = useState(false);

  const [librarianSectionOpen, setIsLibrarianSectionOpen] = useState(false);

  const [modifyLibrarianLoreEnabled, enableModifyLibrarianLore] =
    useState(false);

  const [librarianLore, setLibrarianLore] = useState(null);

  const [notification, setNotification] = useState({});
  const [isOpeningLibraryLoading, setIsOpeningLibraryLoading] = useState(false);
  const [isClosingLibraryLoading, setIsClosingLibraryLoading] = useState(false);
  const [textLength, setTextLength] = useState(0);
  //REF
  const textAreaRef = createRef();
  const librarianTextAreaRef = createRef();

  // USE EFFECTS
  useEffect(() => {

    //STYLE
    document.body.style.background = ` linear-gradient(rgba(0,0,0,0.9),rgba(0,0,0,0.7)), url(${libraryimage})`;
    document.body.style.backgroundAttachment = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundColor = "#111111";
    return () => {
      document.body.style.background = "#111111";
    };
  }, []);
  useEffect(() => {
    if (typeof chain !== "undefined" && typeof chain.unsupported !== "undefined") {
      setIsChainSupported(!chain.unsupported)
    }
    // console.log("chain changed", chain)
  }, [chain])

  useEffect(() => {
    if (signer && address && provider && isChainSupported) {
      const libraryContract = new ethers.Contract(
        LIBRARY_ADDRESS,
        LIBRARY_ABI,
        signer
      );
      const wrapperContract = new ethers.Contract(
        WRAPPER_ADDRESS,
        WRAPPER_ABI,
        signer
      );

      const lockerContract = new ethers.Contract(
        SOULSLOCKER_ADDRESS,
        SOULSLOCKER_ABI,
        signer
      );
      const setAllTokensFun = async () => {
        let allTokens = await listAnimeSpiritsHeroesOfOwner_separate(
          address,
          wrapperContract,
          lockerContract
        );

        allTokens = allTokens.heroIDs;

        const sortedAllTokens = allTokens.sort((a, b) => {
          return a.id - b.id;
        });
        setAllTokens(sortedAllTokens);
      };
      setAllTokensFun();
      setLibraryContract(libraryContract);
      listLoreIds(libraryContract).then((res) => setAvailableLoreIds(res));

      librarianAddress(provider).then((res) => {
        if (address.toUpperCase() === res.toUpperCase()) {
          setIsLibrarianAddress(true);
        } else {
          setIsLibrarianAddress(false);
        }
      });
    }
  }, [signer, address, provider, isChainSupported]);
  useEffect(() => {
    // Librarian
    if (libraryContract) {
      isClosed(libraryContract).then((res) => {
        setIsLibraryClosed(res);
      });
    }
  }, [libraryContract]);
  useEffect(() => {
    if (availableLoreIds && availableLoreIds.includes(heroID)) {
      debounceSearch(libraryContract, provider, parseInt(heroID));
    } else {
      setLore(null);
    }
  }, [heroID]);
  useEffect(() => {
    if (availableLoreIds) {
      setHeroID(availableLoreIds[index]);
    }
  }, [index, availableLoreIds]);

  const debounceSearch = useCallback(
    debounce((contract, provider, id, modify = null) => {
      setIsLoreLoading(true);

      if (modify) {
        setTextLength(0);
        getLoreFromID(contract, provider, id)
          .then((res) => {
            res.id = id;

            setSelectHeroForLoreModification(res);
            setTextLength(res.loreString.length);
          })
          .catch((err) => {
            setTextLength(0);
            setSelectHeroForLoreModification(null);
          });
      } else {
        getLoreFromID(contract, provider, id)
          .then((res) => {
            setIsLoreLoading(false);
            const splittedDoubleLines = res.loreString.split(/\n\n/);
            // console.log(splittedDoubleLines)
            const noLines = splittedDoubleLines.map((l) => {
              return (
                l
                  .replace(/\n/g, " ")
                  .replace(/amp;/g, "")
                  .replace(/&lt;/g, "<")
                  .replace(/&gt;/g, ">") + "\n\n"
              );
            });

            res.loreString = noLines.join("");
            setLore(res);
          })
          .catch((err) => {
            setIsLoreLoading(false);
            setLore(null);
          });
      }
    }, 200),
    []
  );
  const openBook = () => {
    setBookOpen(true);
  };
  const nextHero = () => {
    setIndex((prevIndex) => prevIndex + 1);
  };
  const previousHero = () => {
    if (index > 0) {
      setIndex((prevIndex) => prevIndex - 1);
    }
  };

  //GENERAL
  const modifyTextArea = (textRef) => {
    let limit = 42;
    let textarea = textRef.current;
    let spaces = textarea.cols;

    let lines = textarea.value.split("\n");

    for (let i = 0; i < lines.length; i++) {
      if (lines[i].length <= spaces) continue;
      let j = 0;

      let space = spaces;

      while (j++ <= spaces) {
        if (lines[i].charAt(j) === " ") space = j;
      }
      lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
      lines[i] = lines[i].substring(0, space);
    }
    if (lines.length > limit) {
      textarea.style.color = "red";
      setTimeout(function () {
        textarea.style.color = "";
      }, 200);
    }
    const newValue = lines.slice(0, limit).join("\n");
    setTextLength(newValue.length);
    textarea.value = newValue;
  };
  // LORE MODIFICATION
  const handleSetDefaultImage = (e, id) => {
    e.target.src = `https://leanime.art/heroes/m/${id}.jpg`;
  };
  const handlesetSelectHeroForLoreModification = (heroID) => {
    if (availableLoreIds.includes(heroID)) {
      debounceSearch(libraryContract, provider, parseInt(heroID), true);
    } else {
      setSelectHeroForLoreModification({
        id: heroID,
        author: "",
        nameString: "",
        loreString: "",
      });
    }
  };
  const handleModifyHeroName = (text) => {
    const utfeight = Buffer.from(text, "utf-8").toString();

    setSelectHeroForLoreModification((current) => {
      return { ...current, nameString: utfeight };
    });
  };
  const handleModifyHeroLore = (text) => {
    modifyTextArea(textAreaRef);
    setSelectHeroForLoreModification((current) => ({
      ...current,
      loreString: text,
    }));
  };

  const handleSubmitLore = (id, name, loreString) => {
    setIsConfirmModificationLoading(true);

    storeLoreHolders(libraryContract, id, name, loreString)
      .then(() => {
        setWriteLoreOpen(false);
        setIsConfirmModificationLoading(false);
        setNotification({
          state: true,
          message: "You Successfully modified your lore",
          type: "success",
        });
      })
      .catch((err) => {
        setIsConfirmModificationLoading(false);
        setNotification({
          state: true,
          message: err.data ? err.data.message : err.message,
          type: "error",
        });
      });
  };
  const handleCloseWriteLore = () => {
    setWriteLoreOpen(false);
    setSelectHeroForLoreModification(null);
    setTextLength(0);
  };

  // Librarian Section
  const handleEnableModifyLibrarianLore = () => {
    if (libraryContract && provider) {
      getLoreLibrarian(libraryContract, provider).then((res) => {
        setLibrarianLore(res);
        setTextLength(res.loreString?.length);
        enableModifyLibrarianLore(true);
      });
    }
  };
  const handleSubmitLibrarianLore = (loreString) => {
    setIsConfirmLibrarianModificationLoading(true);
    storeLoreLibrarian(libraryContract, loreString)
      .then(() => {
        setIsLibrarianSectionOpen(false);
        enableModifyLibrarianLore(false);
        setIsConfirmLibrarianModificationLoading(false);
      })
      .catch((err) => {
        setIsConfirmLibrarianModificationLoading(false);
      });
  };

  const handleModifyLibrarianLore = (text) => {
    let buff = "";
    let numOfLines = Math.floor(text.length / 109);
    for (let i = 0; i < numOfLines + 1; i++) {
      buff += text.substr(i * 109, 109);
      if (i !== numOfLines) {
        buff += "\n";
      }
    }

    modifyTextArea(librarianTextAreaRef);
    setLibrarianLore((current) => ({
      ...current,
      loreString: text,
    }));
  };

  const handleDismissSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNotification({ status: false });
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="start"
      alignItems="center"
      style={{
        padding: matchesSmall ? "75px 0 0 0" : "100px 20px",
        width: "100%",
        height: "100%",
      }}
    >
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          autoHideDuration={2000}
          open={notification.state && notification.type === "error"}
          onClose={handleDismissSnackBar}
          key={"error"}
        >
          <MuiAlert
            onClose={handleDismissSnackBar}
            variant="filled"
            severity="error"
          >
            {notification.message}
          </MuiAlert>
        </Snackbar>
        <Snackbar
          autoHideDuration={2000}
          open={notification.state && notification.type === "success"}
          onClose={handleDismissSnackBar}
          key={"success"}
        >
          <MuiAlert
            onClose={handleDismissSnackBar}
            variant="filled"
            severity="success"
          >
            {notification.message}
          </MuiAlert>
        </Snackbar>
      </Stack>
      <Modal
        disableScrollLock={true}
        style={{ border: "none" }}
        open={writeLoreOpen}
        onClose={handleCloseWriteLore}
      >
        <Fade in={writeLoreOpen}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: matchesSmall ? "90%" : "70%",
              maxHeight: "100%",
              overflowY: "auto",
              background: "#111111",
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
              outline: "none",
            }}
          >
            {selectedHeroForLoreModification ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  textAlign: "center",
                  display: "flex",
                  padding: "20px 0",
                  flexDirection: "column",
                  // justifyContent: "center",
                }}
              >
                <span
                  style={{
                    fontFamily: "PPNeueMachina",
                    fontSize: matchesSmall ? "12px" : "18px",
                    color: "#ffb631",
                  }}
                >
                  Fill in the form
                </span>

                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    marginTop: "20px",
                    // paddingTop: "20px",
                    paddingLeft: "10px",
                    width: "100%",
                    height: "90%",
                    color: "#ffb631",
                    fontFamily: "PPNeueMachina",
                    fontSize: matchesSmall ? "10px" : "16px",
                  }}
                >
                  <span>Author: {address} </span>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "20px",
                      fontSize: matchesSmall ? "12px" : "16px",
                    }}
                  >
                    <span> Hero Name: </span>
                    <Input
                      type="text"
                      disableUnderline
                      inputProps={{ maxLength: 32 }}
                      style={{
                        color: "black",
                        background: "rgb(255,255,255)",
                        padding: "5px",
                        width: "30%",
                        marginLeft: "10px",
                        fontFamily: "EurostilNormal",
                        fontSize: matchesSmall ? "12px" : "16px",
                      }}
                      onChange={(e) => handleModifyHeroName(e.target.value)}
                      defaultValue={selectedHeroForLoreModification.nameString}
                    />
                  </div>
                  <span style={{ marginTop: "20px", fontSize: matchesSmall ? "12px" : "16px",}}>
                    Hero Lore: ({3500 - textLength} characters left)
                  </span>
                  <textarea
                    type="text"
                    ref={textAreaRef}
                    disableUnderline
                    multiline
                    cols={109}
                    rows={matchesMobile? 25 : matchesSmall ? 10 : 13} 
                    maxLength={3500}
                    style={{
                      color: "black",
                      background: "rgb(255,255,255)",
                      width: matchesMobile ? "90%" : "70%",
                      marginTop: "10px",
                      fontFamily: "EurostilNormal",
                    }}
                    onChange={(e) => handleModifyHeroLore(e.target.value)}
                    defaultValue={selectedHeroForLoreModification.loreString}
                  />
                  <Button
                    style={{
                      background: !(
                        selectedHeroForLoreModification.nameString &&
                        selectedHeroForLoreModification.loreString
                      )
                        ? "grey"
                        : "#ffb631",
                      color: "black",
                      width: matchesLarge ? "70%" : "40%",
                      alignSelf: "center",
                      padding: "25px 0",
                      marginTop: "30px",
                      borderRadius: 0,
                      fontSize: matchesMobile ? "12px" : "14px",
                      fontFamily: "EurostilDemi",
                    }}
                    disabled={
                      !(
                        selectedHeroForLoreModification.nameString &&
                        selectedHeroForLoreModification.loreString
                      )
                    }
                    onClick={() => {
                      const id = selectedHeroForLoreModification.id;
                      const name = selectedHeroForLoreModification.nameString;
                      const loreString =
                        selectedHeroForLoreModification.loreString;
                      handleSubmitLore(id, name, loreString);
                    }}
                  >
                    {isConfirmModificationLoading ? (
                      <CircularProgress />
                    ) : (
                      `confirm and store on the blockchain`
                    )}
                  </Button>{" "}
                </Grid>
              </div>
            ) : (
              <div
                style={{
                  width: "90%",
                  height: "100%",
                  textAlign: "center",
                  display: "flex",
                  padding: "20px",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {allTokens.length > 0 ? (
                  <>
                    <span
                      style={{
                        fontFamily: "PPNeueMachina",
                        fontSize: matchesMobile? "12px" : matchesSmall ? "14px" : "18px",
                        color: "#ffb631",
                      }}
                    >
                      SELECT THE HERO YOU WANT TO WRITE OR MODIFY THE LORE FOR
                    </span>

                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="start"
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        height: "90%",
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="start"
                        alignItems="start"
                        style={{
                          width: "100%",
                          height: "100%",
                          overflowY: "auto",
                          overflowX: "hidden",
                        }}
                        spacing={2}
                      >
                        {allTokens &&
                          allTokens.map((t) => {
                            return (
                              <Grid
                                item
                                xs={matchesMobile ? 6 : 3}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  onClick={() => {
                                    handlesetSelectHeroForLoreModification(
                                      t.soulNumber
                                    );
                                  }}
                                  style={{
                                    width: "100%",
                                    cursor: "pointer",
                                  }}
                                  onError={(e) => {
                                    handleSetDefaultImage(e, t.soulNumber);
                                  }}
                                  src={`https://api.leanime.art/heroes/images/s/${t.soulNumber}.jpg`}
                                />{" "}
                                <span
                                  style={{
                                    marginTop: "15px",
                                    color: "white",
                                    fontFamily: "PPNeueMachina",
                                    fontSize: "13px",
                                  }}
                                >
                                  HERO #{t.soulNumber}
                                </span>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <span
                      style={{
                        fontFamily: "PPNeueMachina",
                        fontSize: matchesSmall ? "16px" : "25px",
                        color: "white",
                      }}
                    >
                      YOU HAVE NO HEROES TO WRITE THE LORE FOR
                    </span>
                    <span
                      style={{
                        fontFamily: "PPNeueMachina",
                        fontSize: "20px",
                      }}
                    >
                      <Button
                        style={{
                          background: "#ffb631",

                          width: matchesSmall ? "70%" : "40%",
                          padding: "10px 0",
                          margin: "10px 0",
                          borderRadius: 0,
                          fontSize: matchesSmall ? "16px" : "25px",
                          fontFamily: "EurostilDemi",
                        }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            cursor: "pointer",
                            color: "black",
                          }}
                          to="/builder"
                        >
                          CREATE NEW HERO
                        </Link>
                      </Button>
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        </Fade>
      </Modal>
      <Modal
        disableScrollLock={true}
        style={{ border: "none" }}
        open={librarianSectionOpen}
        onClose={() => {
          setIsLibrarianSectionOpen(false);
          enableModifyLibrarianLore(false);
          setTextLength(0);
        }}
      >
        <Fade in={librarianSectionOpen}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: matchesSmall ? "90%" : "70%",
              maxHeight: "100%",
              // height: "80%",
              background: "#111111",
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
              outline: "none",
              overflowY: "auto",
            }}
          >
            {modifyLibrarianLoreEnabled ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  textAlign: "center",
                  display: "flex",
                  padding: "20px 0",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    fontFamily: "PPNeueMachina",
                    fontSize: matchesSmall ? "12px" : "18px",
                    color: "#ffb631",
                  }}
                >
                  Fill in the form
                </span>

                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    marginTop: "20px",
                    // paddingTop: "20px",
                    // paddingLeft: "10px",
                    width: "100%",
                    height: "90%",
                    color: "#ffb631",
                    fontFamily: "PPNeueMachina",
                    fontSize: matchesSmall ? "10px" : "16px",
                  }}
                >
                  <span>Author: {address} </span>
                  <span style={{ marginTop: "20px" }}>
                    Librarian Lore: ({3500 - textLength} character left)
                  </span>
                  <textarea
                    type="text"
                    ref={librarianTextAreaRef}
                    disableUnderline
                    multiline
                    cols={109}
                    rows={13}
                    maxLength={3500}
                    style={{
                      color: "black",
                      background: "#f5bd04",
                      width: "80%",
                      marginTop: "10px",
                      fontFamily: "EurostilNormal",
                    }}
                    onChange={(e) => handleModifyLibrarianLore(e.target.value)}
                    defaultValue={librarianLore?.loreString}
                  />
                  <Button
                    style={{
                      background: !librarianLore?.loreString
                        ? "grey"
                        : "#ffb631",
                      color: "black",
                      width: matchesLarge ? "70%" : "40%",
                      alignSelf: "center",
                      padding: "25px 0",
                      marginTop: "30px",
                      borderRadius: 0,
                      fontSize: "14px",
                      fontFamily: "EurostilDemi",
                    }}
                    disabled={!librarianLore?.loreString}
                    onClick={() => {
                      const loreString = librarianLore?.loreString;
                      handleSubmitLibrarianLore(loreString);
                    }}
                  >
                    {isConfirmLibrarianModificationLoading ? (
                      <CircularProgress />
                    ) : (
                      `confirm and store on the blockchain`
                    )}
                  </Button>{" "}
                </Grid>
              </div>
            ) : (
              <div
                style={{
                  width: "90%",
                  height: "100%",
                  textAlign: "center",
                  display: "flex",
                  padding: "20px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  overflowY: "auto",
                }}
              >
                <span
                  style={{
                    margin: matchesMobile ? "0" : "30px 0",
                    width: "100%",
                    color: "#ffb631",
                    fontFamily: "PPNeueMachina",
                  }}
                >
                  Welcome master Librarian, we respond to your call, what is
                  your desire?
                </span>
                <Button
                  style={{
                    background: !isLibraryClosed
                      ? "#ffb631"
                      : "grey",
                    color: "black",
                    width: matchesMobile ? "90%" : "30%",
                    padding: matchesSmall ? "10px 0" : "25px 0",
                    marginTop: "10px",
                    borderRadius: 0,
                    fontSize: "16px",
                    fontFamily: "EurostilDemi",
                  }}
                  disabled={isLibraryClosed}
                  onClick={() => {
                    setIsClosingLibraryLoading(true);
                    closeLibrary(libraryContract)
                      .then(() => {
                        setIsClosingLibraryLoading(false);
                        setIsLibraryClosed(true);
                        setNotification({
                          state: true,
                          message: "Library Closed Successfully",
                          type: "success",
                        });
                      })
                      .catch((err) => {
                        setIsClosingLibraryLoading(false);

                        setNotification({
                          state: true,
                          message: err.data ? err.data.message : err.message,
                          type: "error",
                        });
                      });
                  }}
                >
                  {isClosingLibraryLoading ? (
                    <CircularProgress />
                  ) : (
                    `close the library`
                  )}
                </Button>{" "}
                <Button
                  style={{
                    background: isLibraryClosed
                      ? "#ffb631"
                      : "grey",
                    color: "black",
                    width: matchesMobile ? "90%" : "30%",
                    padding: matchesSmall ? "10px 0" : "25px 0",
                    marginTop: "10px",
                    borderRadius: 0,
                    fontSize: "16px",
                    fontFamily: "EurostilDemi",
                  }}
                  disabled={!isLibraryClosed}
                  onClick={() => {
                    setIsOpeningLibraryLoading(true);
                    openLibrary(libraryContract)
                      .then(() => {
                        setIsOpeningLibraryLoading(false);
                        setIsLibraryClosed(false);
                        setNotification({
                          state: true,
                          message: "Library Closed Successfully",
                          type: "success",
                        });
                      })
                      .catch((err) => {
                        setIsOpeningLibraryLoading(false);

                        setNotification({
                          state: true,
                          message: err.data ? err.data.message : err.message,
                          type: "error",
                        });
                      });
                  }}
                >
                  {isOpeningLibraryLoading ? (
                    <CircularProgress />
                  ) : (
                    `open the library`
                  )}
                </Button>{" "}
                <Button
                  style={{
                    background: "grey",
                    color: "black",
                    width: matchesMobile ? "90%" : "30%",
                    padding: matchesSmall ? "10px 0" : "25px 0",
                    marginTop: "10px",
                    borderRadius: 0,
                    fontSize: "13px",
                    fontFamily: "EurostilDemi",
                  }}
                  disabled={true}
                >
                  burn everything to the ground ??dis?bl>>eD?
                </Button>{" "}
                <Button
                  style={{
                    background: "#ffb631",

                    color: "black",
                    width: matchesMobile ? "90%" : "30%",
                    padding: matchesSmall ? "10px 0" : "25px 0",
                    marginTop: "10px",
                    borderRadius: 0,
                    fontSize: "16px",
                    fontFamily: "EurostilDemi",
                  }}
                  onClick={handleEnableModifyLibrarianLore}
                >
                  write the librarian lore
                </Button>{" "}
              </div>
            )}
          </div>
        </Fade>
      </Modal>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{
          width: "100%",
          maxWidth: "1600px",
        }}
      >
        {" "}
        {/* {address && isConnected && isChainSupported ? ( */}
          <>
            <span
              style={{
                fontSize: matchesMobile ? "16px" : matchesSmall ? "20px" : "32px",
                fontFamily: "PPNeueMachina",
                marginBottom: "10px",
                fontWeight: "900",
                color: "#ffb631",
              }}
            >
              Axis Mundi - The World Library
            </span>
            <p
              style={{
                fontSize: matchesSmall ? "14px" : "18px",
                textAlign: "center",
                margin: "12px 0",
                letterSpacing: 1,
                fontWeight: 100,
                width: matchesSmall ? "70%" : "900px",
                fontFamily: "EurostilNormal",
                whiteSpace: "pre-line",
              }}
            >
              Right at the center of everything a giant endless maze of
              knowledge surrounded the Immortal Tree. Source of unknown power,
              believed to be the very heart of the cosmos. Protector of the
              past, present and future. Here lays the compendium of Le Anime
              Heroes.{" "}
              <a
                style={{ color: "#ffb631" }}
                target="_blank"
                href="https://looksrare.org/collections/0x7B81dedb66b51abED705a081ccFb233168878748"
              >
                {" "}
                The NFT contract
              </a>
            </p>
            {!bookOpen && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: matchesMobile ? "90%" : "70%",
                  padding: 0,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: matchesSmall
                      ? "15px"
                      : matchesMedium
                      ? "20px"
                      : "32px",
                    fontFamily: "PPNeueMachina",
                    fontWeight: "900",
                    marginTop: "-8%",
                    color: "#ffb631",
                    width: matchesMobile ? "40%" : "23%",
                    height: matchesSmall
                      ? "40px"
                      : matchesMedium
                      ? "50px"
                      : "70px",
                    background: "rgba(0,0,0,0.5)",
                    cursor: "pointer",
                  }}
                  onClick={openBook}
                >
                  READ THE LORE
                </div>
                <img style={{ width: "90%", marginTop: "-18%" }} src={book} />
              </div>
            )}
            {bookOpen && address && isConnected && isChainSupported  && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="start"
                style={{ width: "100%" }}
              >
                <Grid item xs={matchesSmall ? 0 : 2} />
                <Grid
                  item
                  xs={matchesSmall ? 12 : 8}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                      width: matchesMobile ? "70%" : "50%",
                      margin: "12px 0",
                    }}
                  >
                    <span
                      style={{
                        fontSize: matchesSmall ? "14px" : "18px",

                        letterSpacing: 1,
                        fontWeight: 100,
                        fontFamily: "EurostilNormal",
                      }}
                    >
                      Look up ID:{" "}
                    </span>{" "}
                    <Input
                      type="tel"
                      disableUnderline
                      onChange={(e) => {
                        setHeroID(e.target.value);
                        if (
                          availableLoreIds.indexOf(parseInt(e.target.value)) !==
                          -1
                        ) {
                          setIndex(
                            availableLoreIds.indexOf(parseInt(e.target.value))
                          );
                        }
                      }}
                      style={{
                        width: "30%",
                        color: "white",
                        background: "rgba(0, 0, 0, 0.8)",
                        padding: "5px",
                        border: "1px solid #ffb631 ",
                        marginLeft: "10px",
                        fontFamily: "PPNeueMachina",
                      }}
                      value={heroID}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    {" "}
                    <img
                      style={{
                        height: matchesSmall
                          ? "60px"
                          : matchesLarge
                          ? "60px"
                          : matchesExtraLarge
                          ? "80px"
                          : "100px",
                        transform: "rotate(180deg)",
                        cursor: heroID > 1 ? "pointer" : "not-allowed",
                      }}
                      src={arrow}
                      onClick={previousHero}
                    />{" "}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "start",
                        height: matchesSmall ? "800px" : "900px",
                        width: matchesLarge ? "90%" : "100%",
                        backgroundImage: `url(${bg})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center top",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "start",
                          width: "100%",
                          padding: 0,
                        }}
                      >
                        <div
                          style={{
                            fontSize: matchesMobile? "12px" : matchesSmall ? "15px" : "18px",
                            padding: "40px 40px 0 40px",
                            letterSpacing: 1,
                            fontWeight: 100,
                            color: "rgb(54,54,54)",
                            display: "flex",
                            flexDirection: "column",
                            width: "50%",
                          }}
                        >
                          <span
                            style={{
                              marginBottom: "10px",
                              fontFamily: "EurostilDemi",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "PPNeueMachina",
                                fontWeight: 900,
                              }}
                            >
                              HERO ID
                            </span>{" "}
                            {""}#{lore !== null && heroID}
                          </span>
                          <span
                            style={{
                              marginBottom: "10px",
                              width: matchesSmall ? "200px" : "400px",
                              whiteSpace: matchesSmall ? "normal" : "nowrap",
                              overflow: "hidden",
                              textOverflow: matchesSmall
                                ? "initial"
                                : "ellipsis",
                              fontFamily: "EurostilDemi",
                              wordBreak: matchesSmall ? "break-all" : "normal",
                            }}
                          >
                            <span style={{ fontFamily: "PPNeueMachina" }}>
                              AUTHOR:
                            </span>{" "}
                            {""}
                            {lore?.author}
                          </span>
                          <span
                            style={{
                              marginBottom: "10px",
                              fontFamily: "EurostilDemi",
                            }}
                          >
                            <span style={{ fontFamily: "PPNeueMachina" }}>
                              HERO NAME:
                            </span>{" "}
                            {""}
                            {lore?.nameString}
                          </span>
                          <span style={{ marginBottom: "10px" }}>
                            <span style={{ fontFamily: "PPNeueMachina" }}>
                              NFT Link:
                            </span>{" "}
                            {""}
                            <a
                              href={lore?.nftLink}
                              target="__blank"
                              style={{
                                fontFamily: "EurostilDemi",
                                color: "rgb(54,54,54)",
                                wordBreak: "break-all",
                              }}
                            >
                              {" "}
                              {lore?.nftLink}
                            </a>
                          </span>
                        </div>
                        <img
                          style={{ width: matchesSmall ? "10%" : "20%" }}
                          src={bookmark}
                        />
                      </div>

                      <div
                        style={{
                          fontSize: matchesSmall ? "15px" : "18px",
                          padding: "0px 40px 0 40px",
                          letterSpacing: 1,
                          fontWeight: 100,
                          color: "rgb(54,54,54)",
                          display: "flex",
                          flexDirection: "column",
                          // width: "90%",
                          maxWidth: "90%",
                          overflow: "auto",
                          fontFamily: "EurostilNormal",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "PPNeueMachina",
                            fontWeight: 900,
                            fontSize: matchesMobile ? "18px" : "25px",
                          }}
                        >
                          HERO LORE:
                        </span>
                        <div style={{}}>
                          <p
                            style={{
                              fontFamily: "EurostilDemi",
                              whiteSpace: "pre-line",
                              overflowWrap: "break-word",
                              fontSize: matchesSmall ? "12px" : "18px",
                            }}
                          >
                            {lore?.loreString}
                          </p>
                        </div>
                      </div>
                    </div>
                    <img
                      style={{
                        height: matchesSmall
                          ? "60px"
                          : matchesLarge
                          ? "100px"
                          : matchesExtraLarge
                          ? "80px"
                          : "100px",
                        cursor: "pointer",
                      }}
                      src={arrow}
                      onClick={nextHero}
                    />
                  </div>
                </Grid>
                <Grid item xs={matchesSmall ? 6 : 2}
                  style={{ 
                    marginTop: matchesSmall ? "20px" : "100px",
                    marginBottom: matchesSmall ? "20px" : "0px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    }}>
                  <Button
                    style={{
                      background: !isLibraryClosed
                        ? "#ffb631"
                        : "grey",
                      color: "black",
                      width: "100%",
                      padding: "25px 0",
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: 0,
                      fontSize: matchesSmall ? "14px" : "16px",
                      fontFamily: "EurostilDemi",
                    }}
                    disabled={isLibraryClosed}
                    onClick={() => {
                      setWriteLoreOpen(true);
                    }}
                  >
                    <span>write / modify lore</span>
                    {isLibraryClosed && `(Library Closed)`}
                  </Button>{" "}
                  <Button
                    style={{
                      background: isLibrarianAddress
                        ? "#ffb631"
                        : "grey",
                      color: "black",
                      width: "100%",
                      padding: "25px 0",
                      marginTop: "10px",
                      borderRadius: 0,
                      fontSize: matchesSmall ? "14px" : "16px",
                      fontFamily: "EurostilDemi",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    disabled={!isLibrarianAddress}
                    onClick={() => {
                      setIsLibrarianSectionOpen(true);
                    }}
                  >
                    <span>librarian section {"   "}</span> (must own Axis Mundy)
                  </Button>{" "}
                </Grid>
              </Grid>
            )}
            {bookOpen && (!address || !isConnected || !isChainSupported)  && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="start"
                style={{ width: "100%" }}
              >
                <span
                  style={{
                    fontSize: "32px",
                    fontFamily: "PPNeueMachina",
                    marginTop: "200px",
                    fontWeight: "900",
                    textAlign: "center",
                  }}
                >
                  Connect wallet or change network
                </span>
                
              </Grid>
            )}

          </>
        {/* ) : (
          <span
            style={{
              fontSize: "32px",
              fontFamily: "PPNeueMachina",
              marginTop: "200px",
              fontWeight: "900",
              textAlign: "center",
            }}
          >
            Connect wallet or change network
          </span>
        )} */}
      </Grid>{" "}
    </Grid>
  );
};

export default Library;
