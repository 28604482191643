import React from "react";
import instagram from "../assets/instagram.png";
import web from "../assets/web.png";
import twitter from "../assets/twitter.png";
import { Grid, useMediaQuery } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import logo from "../assets/logo.png";
import { Footer } from "../components";
const StyledLink = styled("div")({
  width: "100%",
  height: "4px",
  marginBottom: "1%",
  background:
    "linear-gradient(to right, rgba(255, 182, 49,1), rgba(219, 57, 57, 1))",
});
const Links = () => {
  const matchesMedium = useMediaQuery("(max-width:1400px)");
  const matchesSmall = useMediaQuery("(max-width:1200px)");
  const matchesMobile = useMediaQuery("(max-width:850px)");
  return (
    <>
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        paddingTop: "100px",
        marginBottom: "90px",
        width: "100%",
        fontFamily: "EurostilDemi",
        fontSize: matchesSmall ? "20px" : "25px",
        fontWeight: "900",
        textAlign: "center",
      }}
    >
      {" "}
      <div
        style={{
          width: matchesMobile ? "80%" : "50%",
          margin: 0,
          padding: 0,
        }}
      >
        <img style={{ width: "80%", maxHeight: "250px" }} src={logo} />
      </div>
      <div
        style={{
          background: "rgba(255, 255, 255, 0.08)",
          width: matchesMobile ? "80%" : "50%",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledLink></StyledLink>

        <div style={{ marginBottom: "1%" }}>
          <a
            style={{ textDecoration: "none", color: "white" }}
            target="_blank"
            href={"https://twitter.com/leanimeart"}
          >
            Twitter
          </a>
        </div>
      </div>
      <div
        style={{
          background: "rgba(255, 255, 255, 0.03)",
          width: matchesMobile ? "80%" : "50%",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledLink></StyledLink>

        <div style={{ marginBottom: "1%" }}>
          <a
            style={{ textDecoration: "none", color: "white" }}
            target="_blank"
            href={"http://discord.gg/HuNTadkCdx"}
          >
            Discord
          </a>
        </div>
      </div>
      <div
        style={{
          background: "rgba(255, 255, 255, 0.08)",
          width: matchesMobile ? "80%" : "50%",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledLink></StyledLink>

        <div style={{ marginBottom: "1%", color: "transparent" }}>.</div>
      </div>

      {/* <div
        style={{
          background: "rgba(255, 255, 255, 0.08)",
          width: matchesMobile ? "80%" : "50%",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledLink></StyledLink>

        <div style={{ marginBottom: "1%" }}>
          <a
            style={{ textDecoration: "none", color: "white" }}
            target="_blank"
            href={
              "https://opensea.io/collection/le-anime-by-toomuchlag?search[sortAscending]=true&search[sortBy]=PRICE&search[toggles][0]=BUY_NOW"
            }
          >
            OpenSea
          </a>
        </div>
      </div> */}

      <div
        style={{
          background: "rgba(255, 255, 255, 0.03)",
          width: matchesMobile ? "80%" : "50%",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledLink></StyledLink>

        <div style={{ marginBottom: "1%" }}>
        <a
            style={{ textDecoration: "none", color: "white" }}
            target="_blank"
            href={
              "https://opensea.io/collection/le-anime-by-toomuchlag?search[sortAscending]=true&search[sortBy]=PRICE&search[toggles][0]=BUY_NOW"
            }
          >
            Le Anime Collection (OS)
          </a>
          {/* <a
            style={{ textDecoration: "none", color: "white" }}
            target="_blank"
            href={
              "https://niftygateway.com/marketplace/collectible/0x03bebcf3d62c1e7465f8a095bfa08a79ca2892a1?filters[onSale]=true&sortBy=lowest"
            }
          >
            Nifty Gateway
          </a> */}
        </div>
      </div>

      {/* <div
        style={{
          background: "rgba(255, 255, 255, 0.08)",
          width: matchesMobile ? "80%" : "50%",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledLink>
          
        </StyledLink>

        <div style={{ marginBottom: "1%", color: "transparent" }}>.</div>
      </div>
      <div
        style={{
          background: "rgba(255, 255, 255, 0.03)",
          width: matchesMobile ? "80%" : "50%",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledLink></StyledLink>

        <div style={{ marginBottom: "1%" }}>
          <a
            style={{ textDecoration: "none", color: "white" }}
            target="_blank"
            href={
              "https://opensea.io/collection/toomuchlag?search[query]=le%20anime&search[sortAscending]=true&search[sortBy]=PRICE&search[toggles][0]=BUY_NOW"
            }
          >
            Le Anime OG - OS
          </a>
        </div>
      </div> */}
      <div
        style={{
          background: "rgba(255, 255, 255, 0.08)",
          width: matchesMobile ? "80%" : "50%",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledLink></StyledLink>

        <div style={{ marginBottom: "1%" }}>
          <a
            style={{ textDecoration: "none", color: "white" }}
            target="_blank"
            href={
              "https://niftygateway.com/marketplace/collection/0x41113bce4659cb4912ed61d48154839f75131d7a/1?filters[onSale]=true&sortBy=lowest"
            }
          >
            Le Anime Open Edition (NG)
          </a>
        </div>
      </div>
      {/* <div
        style={{
          background: "rgba(255, 255, 255, 0.03)",
          width: matchesMobile ? "80%" : "50%",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledLink></StyledLink>

        <div style={{ marginBottom: "1%" }}>
          <a
            style={{ textDecoration: "none", color: "white" }}
            target="_blank"
            href={"https://leanime.art/phase1"}
          >
            Le Anime OG Website
          </a>
        </div>
      </div>
      <div
        style={{
          background: "rgba(255, 255, 255, 0.08)",
          width: matchesMobile ? "80%" : "50%",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledLink></StyledLink>

        <div style={{ marginBottom: "1%", color: "transparent" }}>.</div>
      </div>
      <div
        style={{
          background: "rgba(255, 255, 255, 0.03)",
          width: matchesMobile ? "80%" : "50%",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledLink></StyledLink>

        <div style={{ marginBottom: "1%" }}>
          <a
            style={{ textDecoration: "none", color: "white" }}
            target="_blank"
            href={"https://leanime.art/thelab"}
          >
            The Lab
          </a>
        </div>
      </div> */}
      <div
        style={{
          background: "rgba(255, 255, 255, 0.08)",
          width: matchesMobile ? "80%" : "50%",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledLink></StyledLink>

        <div style={{ marginBottom: "1%", color: "transparent" }}>.</div>
      </div>
      <div
        style={{
          background: "rgba(255, 255, 255, 0.03)",
          width: matchesMobile ? "80%" : "50%",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledLink></StyledLink>

        <div style={{ marginBottom: "1%" }}>
          <a
            style={{ textDecoration: "none", color: "white" }}
            target="_blank"
            href={"https://leanime.gitbook.io/le-anime/features-guides/video-tutorials"}
          >
            Video Tutorials
          </a>
        </div>
      </div>
      <div
        style={{
          background: "rgba(255, 255, 255, 0.08)",
          width: matchesMobile ? "80%" : "50%",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledLink></StyledLink>

        <div style={{ marginBottom: "1%", color: "transparent" }}>.</div>
      </div>
      <div
        style={{
          background: "rgba(255, 255, 255, 0.03)",
          width: matchesMobile ? "80%" : "50%",
          margin: 0,
          padding: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StyledLink></StyledLink>
        <div style={{ 
          marginBottom: matchesSmall ? "1%" : "10px", 
          fontSize: matchesSmall ? "20px" : "20px",
          }}>
          toomuchlag socials
        </div>
        <div
          style={{
            marginBottom: "1%",
            display: "flex",
            flexDirection: "row",
            width: "100px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <a
            style={{ textDecoration: "none", color: "white" }}
            target="_blank"
            href={"https://twitter.com/toomuchlag"}
          >
            <img style={{ width: "25px" }} src={twitter} />
          </a>

          <a
            style={{ textDecoration: "none", color: "white" }}
            target="_blank"
            href={"https://www.instagram.com/toomuchlag/"}
          >
            <img style={{ width: "25px" }} src={instagram} />
          </a>

          <a
            style={{ textDecoration: "none", color: "white" }}
            target="_blank"
            href={"https://www.toomuchlag.com/"}
          >
            <img style={{ width: "25px" }} src={web} />
          </a>
        </div>
      </div>
    </Grid>
    </>
  );
};

export default Links;
