import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
const AuctionExplained = () => {
  const matches = useMediaQuery("(max-width:800px)");
  const rows = [
    {
      title: "DAY 1",
      gmt: {
        times: "7PM-1PM ",
        dates: "20TH-21ST",
      },
      pst: {
        times: "11AM-5AM ",
        dates: "20TH-21ST",
      },
      jst: {
        times: "4AM-10PM ",
        dates: "21ST-21ST",
      },
    },
    {
      title: "DAY 2",
      gmt: {
        times: "11PM-11AM ",
        dates: "21ST-22ND",
      },
      pst: {
        times: "3PM-3AM ",
        dates: "21ST-22ND",
      },
      jst: {
        times: "8AM-8PM ",
        dates: "22ND-22ND",
      },
    },

    {
      title: "DAY 3",
      gmt: {
        times: "8PM-2AM ",
        dates: "22ND-23RD",
      },
      pst: {
        times: "12PM-6PM ",
        dates: "22ND-22ND",
      },
      jst: {
        times: "5AM-11AM",
        dates: "23RD-23RD",
      },
    },
  ];
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ width: "100%" }}
    >
      <Grid
        item
        style={{
          fontFamily: "PPNeueMachina",
          fontSize: !matches ? "32px" : "26px",
          fontWeight: "900",
          marginBottom: !matches ? "2%" : "5%",
          textAlign: "center",
        }}
      >
        THE AUCTION EXPLAINED
      </Grid>
      <Grid
        item
        style={{
          width: matches ? "90%" : "60%",
          textAlign: "center",
          letterSpacing: "-0.02em",
          lineHeight: "150%",
          fontFamily: "EurostilDemi",
          fontSize: !matches ? "18px" : "15px",
        }}
      >
        No gas war, bid when you want.
        <br />
        Unsuccessful bids will rollover to the next day.
        <br />
        Bid how many units and how much per unit you want.
        <br />
        Increase your bid if you fall out of ranking.
        <br />
        Winning bids will receive NFTs after end of auction.
        <br />
        Top 100 ranked receive bonus Spirit NFTs. <br />
        1573 of 8888 NFTs are gifted to Le Anime NFTs. <br />
        SPIRIT NFTs will be merged to create HEROES NFT.
        <br />
        Scroll down for more F.A.Q.
      </Grid>
      {/* <Grid
        item
        style={{
          fontFamily: "PPNeueMachina",
          fontSize: "32px",
          fontWeight: "900",
          marginTop: "3%",
          marginBottom: "2%",
          textAlign: "center",
        }}
      >
        AUCTION TIMES
      </Grid>
      <TableContainer style={{ width: matches ? "100%" : "60%" }}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                th: {
                  color: "rgba(255, 182, 49,1)",
                  border: "1px solid rgba(17, 17, 17, 1)",
                  background: "rgba(255, 255, 255, 0.08)",
                  fontSize: "15px",
                  fontFamily: "PPNeueMachina",
                  fontWeight: 900,
                },
              }}
            >
              <TableCell></TableCell>
              <TableCell align="center">GMT</TableCell>
              <TableCell align="center">PST</TableCell>
              <TableCell align="center">JST</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                sx={{
                  "td, th": {
                    color: "white",
                    border: "1px solid rgba(17, 17, 17, 1)",
                    fontSize: "14spx",
                    lineHeight: "24px",
                    fontFamily: "EurostilDemi",
                    letterSpacing: "-0.01em",
                    padding: "2%",
                    background:
                      i % 2 === 0
                        ? "rgba(255, 255, 255, 0.03)"
                        : "rgba(255, 255, 255, 0.08)",
                  },
                }}
              >
                <TableCell>{row.title}</TableCell>
                <TableCell align="center">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>{row.gmt.times}</span>
                    <span style={{ color: "rgba(255, 182, 49,1)" }}>
                      {row.gmt.dates}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>{row.pst.times}</span>
                    <span style={{ color: "rgba(255, 182, 49,1)" }}>
                      {row.pst.dates}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>{row.jst.times}</span>
                    <span style={{ color: "rgba(255, 182, 49,1)" }}>
                      {row.jst.dates}
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
    </Grid>
  );
};

export default AuctionExplained;
