import React, { useState, useCallback } from "react";

import { Grid, useMediaQuery, Slider } from "@mui/material";

import { Link } from "react-router-dom";
import banner from "../assets/BannerAbout.jpg";
const History = () => {
  const matchesMedium = useMediaQuery("(max-width:1400px)");
  const matchesSmall = useMediaQuery("(max-width:1200px)");
  const matchesMobile = useMediaQuery("(max-width:850px)");

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        paddingTop: "100px",
        marginBottom: "30px",
        width: "100%",
        fontFamily: "EurostilNormal",

        fontWeight: "100",
      }}
    >
      <div
        style={{
          width: matchesSmall ? "90%" : "70%",
          marginBottom: "60px",
        }}
      >
        <img style={{ width: "100%" }} src={banner} />
      </div>
      <div
        style={{
          width: matchesSmall ? "80%" : "60%",
          marginBottom: "70px",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <span
          style={{
            fontSize: matchesSmall ? "25px" : "32px",
            fontFamily: "PPNeueMachina",
            marginBottom: "10px",
            fontWeight: "900",
            color: "#ffb631",
          }}
        >
          History
        </span>
        <p
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
          }}
        >
          toomuchlag first launched{" "}
          <a
            target="_blank"
            href="https://www.niftygateway.com/collections/toomuchlagoe"
            style={{ color: "#ffb631" }}
          >
            Le Anime
          </a>{" "}
          on March 5th 2021 on{" "}
          <a
            style={{ color: "#ffb631" }}
            target="_blank"
            href="https://www.niftygateway.com/what-is-an-nft"
          >
            Nifty Gateway
          </a>{" "}
          as an Open Edition.
        </p>
        <p
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
          }}
        >
          The sale, open for 5 minutes, resulted in a whopping 1573 editions
          minted, claiming a spot in the Top 3 most successful drops on the
          platform.
        </p>

        <p
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
          }}
        >
          This Open Edition had a twist never seen before: each edition number
          being tied to a unique generative{" "}
          <a
            style={{ color: "#ffb631" }}
            target="_blank"
            href="https://opensea.io/collection/toomuchlag-le-anime"
          >
            Soul
          </a>{" "}
          via a smart contract, with millions of combinations and none alike.
        </p>

        <p
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
          }}
        >
          We wanted to demonstrate how each token #ID of an edition is in itself
          unique, even if the visual is shared across the edition.
        </p>

        <p
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
          }}
        >
          In December '21 a{" "}
          <Link style={{ color: "#ffb631"}} target="_blank" to="/auction">
            custom coded mass auction
          </Link>{" "}
          introduced Spirit NFTs, a simplified variant of Le Anime collectible
          souls. Aiming to reward existing collectors and introduce more supply
          at lower price entry point for the community to expand.
        </p>

        <p
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
          }}
        >
          This resulted in{" "}
          <a
            style={{ color: "#ffb631" }}
            target="_blank"
            href="https://opensea.io/collection/le-anime-by-toomuchlag?search[sortBy]=UNIT_PRICE&search[sortAscending]=true&search[stringTraits][0][name]=Archetype&search[stringTraits][0][values][0]=Spirit"
          >
            9054 Spirit NFTs
          </a>{" "}
          minted by the community.
        </p>

        <p
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
          }}
        >
          To allow for more freedom in January '22 a Le Anime{" "}
          <Link style={{ color: "#ffb631" }} target="_blank" to="/wallet">
            Wrap
          </Link>{" "}
          mechanism was launched to allow holders of Le Anime to withdraw their
          NFTs from Nifty Gateway, and merge Open Edition and respective Soul
          into a single NFT, a{" "}
          <a
            style={{ color: "#ffb631" }}
            target="_blank"
            href="https://opensea.io/collection/le-anime-by-toomuchlag?search[sortBy]=UNIT_PRICE&search[sortAscending]=true&search[stringTraits][0][name]=Archetype&search[stringTraits][0][values][0]=Le%20Anime"
          >
            new version of Le Anime
          </a>
          .
        </p>

        <p
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
          }}
        >
          Initially launched with a temporary graphic, in March '22 Spirit NFTs
          were revealed into what would be the next step of the project:{" "}
          <a
            style={{ color: "#ffb631" }}
            target="_blank"
            href="https://opensea.io/collection/le-anime-by-toomuchlag?search[sortBy]=UNIT_PRICE&search[sortAscending]=true&search[stringTraits][0][name]=Archetype&search[stringTraits][0][values][0]=Hero"
          >
            Heroes
          </a>
          .
        </p>

        <p
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
          }}
        >
          Heroes NFTs are the combination of merging together Le Anime and
          Spirit NFTs into a combined unique NFT thanks to our own custom system of
          smart contracts.
        </p>

        <p
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
          }}
        >
          For this a{" "}
          <Link style={{ color: "#ffb631" }} target="_blank" to="/builder">
            Builder platform{" "}
          </Link>
          launches in May '22 to allow collectors to create their unique Heroes
          NFTs, a complex package of tokens that has mechanics built into it,
          such as leveling of traits, experience accumulation and more...
        </p>

        <p
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
          }}
        >
          We are currently living the next steps of this project, entries of
          major events will be logged here as we progress into the journey..
        </p>

        <p
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
          }}
        >
          Join our{" "}
          <a
            style={{ color: "#ffb631" }}
            target="_blank"
            href="http://discord.gg/HuNTadkCdx"
          >
            Discord
          </a>{" "}
          to keep updated.
        </p>
      </div>
    </Grid>
  );
};
export default History;
