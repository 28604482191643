import React, { useContext, useEffect, useState } from "react";
import { Grid, Button, useMediaQuery } from "@mui/material";
import {
  FAQ,
  Claim,
  NewArt,
  Winners,
  Footer,
  AuctionExplained,
  TopHundred,
} from "../components";
import { experimentalStyled as styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  REVERSE_RECORD_ABI,
  REVERSE_RECORD_ADDRESS,
  CURRENT_CHAIN_ID,
} from "../config/configAuction";
import MetamaskContext from "../store/metamask-context";
import { ethers } from "ethers";
import { getEnsNames } from "../helpers/auctionHelpers";
import spirit from "../assets/newmid.jpg";
import header1 from "../assets/newleft.jpg";
import header2 from "../assets/newright.jpg";

const Overlay = styled("div")({
  position: "absolute",
  top: "0",
  right: "0",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  textAlign: "center",
  background: "rgba(1,1,1,0.3)",
  fontFamily: "EurostilDemi",
  width: "100%",
  height: "99.5%",
  fontSize: "60px",
  fontWeight: "200",
});
const Auction = () => {
  const ctx = useContext(MetamaskContext);

  const [ensContract, setEnsContract] = useState(null);
  const matches = useMediaQuery("(max-width:800px)");
  const matchesMedium = useMediaQuery("(max-width:1200px)");
  useEffect(() => {
    let provider, signer;
    //Setting auction contract
    const getSignerProvider = async () => {
      provider = new ethers.providers.Web3Provider(window.ethereum);
      signer = await provider.getSigner();
    };

    const createEnsContract = async () => {
      const ensContract = new ethers.Contract(
        REVERSE_RECORD_ADDRESS,
        REVERSE_RECORD_ABI,
        signer
      );
      setEnsContract(ensContract);
    };

    const callAsync = async () => {
      await getSignerProvider();
      await createEnsContract();
    };
    if (ctx.network) callAsync();
  }, [ctx.network]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      style={{
        width: "100%",
        height: "100%",
        paddingTop: matchesMedium ? "100px" : 0,
      }}
    >
      <Grid
        item
        style={{
          position: "relative",
          width: "100%",
          maxHeight: "100vh",
        }}
      >
        {!matches ? (
          <>
            <img
              style={{
                width: "33.3%",
                height: "100%",
                opacity: 0.5,
              }}
              src={header1}
            />
            <img
              style={{
                width: "33.3%",
                height: "100%",
                opacity: 1,
              }}
              src={spirit}
            />
            <img
              style={{
                width: "33.3%",
                height: "100%",
                opacity: 0.5,
              }}
              src={header2}
            />
          </>
        ) : (
          <img
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "1%",
              opacity: 1,
            }}
            src={spirit}
          />
        )}
        <Overlay style={{ fontSize: matches ? "20px" : "60px" }}>
          <span></span>
          <span>LE ANIME</span>
          <span>PHASE 2</span>
          <span></span>
          <KeyboardArrowDownIcon
            style={{ position: "absolute", bottom: 20, fontSize: "40px" }}
          />
        </Overlay>
      </Grid>
      {!matches && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            width: "100%",
            background:
              "linear-gradient(119.13deg, rgba(150, 104, 34, 0.25) 22.09%, rgba(219, 57, 57, 0.25) 67.89%), #181818",
            padding: "3%",
            margin: -4,
          }}
        >
          <Claim />
        </Grid>
      )}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ padding: "3%", width: "100%" }}
      >
        <AuctionExplained />
      </Grid>

      {!matches && (
        <Grid
          item
          style={{
            width: "100%",
            padding: "2%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TopHundred
            getEnsNames={getEnsNames}
            ensContract={ensContract}
            user={ctx.accounts[0]}
          />
        </Grid>
      )}
      {!matches && ctx.isMetamaskConnected && ctx.network && (
        <Grid
          item
          style={{
            width: "100%",
            padding: "4%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#181818",
          }}
        >
          <Winners auctionId={4} auctionEnded={true} user={ctx.accounts[0]} />
        </Grid>
      )}
      <Grid
        item
        style={{
          width: "100%",
          padding: "1%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <NewArt />
      </Grid>
      <Grid
        item
        style={{
          width: "100%",
          padding: "4%",
          backgroundColor: "#181818",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FAQ />
      </Grid>
      <Grid
        item
        style={{
          width: "100%",
          padding: "1%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Auction;
