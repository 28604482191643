export const mergeP = {}
    
mergeP.maxMergeUnits = 1000; 
mergeP.animeMultiplier = 20;
mergeP.rankThresholds = [1,30,70,170,390,800,1500,2000,2500,2900,6000,10000,15000,20000,25000] // level thresholds for each hero level

mergeP.additionalExtrasForRank = []
mergeP.additionalExtrasForRank[0] = 0;
mergeP.additionalExtrasForRank[1] = 1;
mergeP.additionalExtrasForRank[2] = 1;
mergeP.additionalExtrasForRank[3] = 2;
mergeP.additionalExtrasForRank[4] = 2;
mergeP.additionalExtrasForRank[5] = 3;
mergeP.additionalExtrasForRank[6] = 3;
mergeP.additionalExtrasForRank[7] = 3;
mergeP.additionalExtrasForRank[8] = 4;
mergeP.additionalExtrasForRank[9] = 4;
mergeP.additionalExtrasForRank[10] = 4;
mergeP.additionalExtrasForRank[11] = 5;
mergeP.additionalExtrasForRank[12] = 5;
mergeP.additionalExtrasForRank[13] = 5;
mergeP.additionalExtrasForRank[14] = 6;

//there are 16 runes (including 0 invisible)
// let runesLevels = [1, 10, 10, 10, 10, 10, 10, 1, 1, 10, 10, 1, 1, 1, 1, 1]
mergeP.traitsLevelsCut = Array(8).fill(0)
mergeP.traitsLevelsCut[6] = []
mergeP.traitsLevelsCut[7] = []

//runes levels thresholds    
mergeP.traitsLevelsCut[6][0] = [1]; // 0 invisible
mergeP.traitsLevelsCut[6][1] = [1,3,6,11,21,39,71,131,242,445]; // 1 fish
mergeP.traitsLevelsCut[6][2] = [1,3,5,10,17,30,52,92,162,285]; // 2 R
mergeP.traitsLevelsCut[6][3] = [1,3,6,10,18,33,59,105,189,338]; // 3 I
mergeP.traitsLevelsCut[6][4] = [1,3,6,12,22,41,77,143,266,496]; // 4 Mother
mergeP.traitsLevelsCut[6][5] = [1,3,5,9,15,26,45,77,132,227]; // 5 Up Only
mergeP.traitsLevelsCut[6][6] = [1,3,5,8,14,23,39,67,112,190]; // 6 Burning S
mergeP.traitsLevelsCut[6][7] = [1]; // 7 Daemon Face
mergeP.traitsLevelsCut[6][8] = [1]; // 8 Up Only fish
mergeP.traitsLevelsCut[6][9] = [1,3,4,7,11,18,29,47,77,124]; // 9 roman
mergeP.traitsLevelsCut[6][10] = [1,2,4,6,10,16,25,39,61,97]; // 10 hieroglyphs
mergeP.traitsLevelsCut[6][11] = [1]; // Andrea  
mergeP.traitsLevelsCut[6][12] = [1]; // gm
mergeP.traitsLevelsCut[6][13] = [1]; // Loom
mergeP.traitsLevelsCut[6][14] = [1]; // path
mergeP.traitsLevelsCut[6][15] = [1]; // Abana

//there are 20 extras (including 0 and 420 which is a special case)
// let extraLevels = [1, 8, 5, 5, 8, 10, 8, 9, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]

//extras levels thresholds
mergeP.traitsLevelsCut[7][0] = [1]; // 0 invisible
mergeP.traitsLevelsCut[7][1] = [1,4,7,14,27,53,103,201]; // 1 book
mergeP.traitsLevelsCut[7][2] = [1,5,12,28,64]; // 2 sword
mergeP.traitsLevelsCut[7][3] = [1,6,16,39,98]; // 3 laurel
mergeP.traitsLevelsCut[7][4] = [1,4,9,18,36,74,152,312]; // 4 heart
mergeP.traitsLevelsCut[7][5] = [1,4,7,13,25,47,89,170,323,613]; // 5 skull
mergeP.traitsLevelsCut[7][6] = [1,4,8,17,35,72,147,300]; // 6 lyre
mergeP.traitsLevelsCut[7][7] = [1,4,8,15,30,58,115,227,447]; // 7 crystal
mergeP.traitsLevelsCut[7][8] = [1]; // 8 upOnly
mergeP.traitsLevelsCut[7][9] = [1]; // 9 69
mergeP.traitsLevelsCut[7][10] = [1]; // 10 777
mergeP.traitsLevelsCut[7][11] = [1]; // Lightsaber
mergeP.traitsLevelsCut[7][12] = [1]; // Gold Book
mergeP.traitsLevelsCut[7][13] = [1]; // Gold Bow
mergeP.traitsLevelsCut[7][14] = [1]; // Gold Lyre
mergeP.traitsLevelsCut[7][15] = [1]; // Gold Scyte
mergeP.traitsLevelsCut[7][16] = [1]; // Gold Staff
mergeP.traitsLevelsCut[7][17] = [1]; // Gold Sword
mergeP.traitsLevelsCut[7][18] = [1]; // Gold Wings
mergeP.traitsLevelsCut[7][19] = [1]; // 420 special!
    