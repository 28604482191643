import React from "react";

import { Grid, useMediaQuery } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import all from "../assets/leanimeSpiritsHeroes.png";

import { Link } from "react-router-dom";
import map from "../assets/mapAbout.jpg";
import runes from "../assets/runesAbout.jpg";
import banner from "../assets/Bannerv0.jpg";
import simona from "../assets/team_simona.jpg";
import teeshaped from "../assets/team-teeshaped.jpg";
import mauro from "../assets/team_mauro.jpg";
import toomuchlag from "../assets/team_toomuchlag.jpg";
import hired from "../assets/team_hired.jpg";
import traitsExplained from "../assets/traitsExplained.png";
const About = () => {
  const matchesMedium = useMediaQuery("(max-width:1400px)");
  const matchesSmall = useMediaQuery("(max-width:1200px)");
  const matchesMobile = useMediaQuery("(max-width:850px)");
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        paddingTop: "100px",
        marginBottom: "30px",
        width: "100%",
        fontWeight: 100,
        fontFamily: "EurostilNormal",
      }}
    >
      <div
        style={{
          width: matchesSmall ? "90%" : "70%",
          marginBottom: "60px",
        }}
      >
        <img style={{ width: "100%" }} src={banner} />
      </div>
      <div
        style={{
          width: matchesSmall ? "80%" : "60%",
          marginBottom: "70px",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <span
          style={{
            fontSize: matchesSmall ? "25px" : "32px",
            fontFamily: "PPNeueMachina",
            marginBottom: "10px",
            fontWeight: "900",
            color: "#ffb631",
          }}
        >
          What is Le Anime?
        </span>
        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontWeight: 100,
            fontFamily: "EurostilNormal",
          }}
        >
          <a
            style={{ color: "#ffb631" }}
            target="_blank"
            href="https://twitter.com/leanimeart"
          >
            Le Anime
          </a>{" "}
          is an interactive NFT art project by{" "}
          <a
            target="_blank"
            href="https://twitter.com/toomuchlag"
            style={{ color: "#ffb631" }}
          >
            toomuchlag
          </a>
          . A collection of 1573 Le Anime and 9054 Spirits which are the
          building blocks of customizable Heroes NFTs.
        </p>
        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          A community focused project that enables owners to build their unique
          characters using our platform powered with custom{" "}
          <a
            target="_blank"
            href="https://ethereum.org/en/what-is-ethereum/"
            style={{ color: "#ffb631" }}
          >
            Ethereum
          </a>{" "}
          smart contracts. The potential of which has just been scraped and will
          grow with its community.
        </p>
        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          This breed of art-gamified approach allows for unique mechanics such
          as levelling of traits, unlockable exclusive features, experience
          accumulation and much more. Challenges are on the near horizon..
        </p>
        <div style={{ textAlign: "center" }}>
          <img style={{ width: matchesSmall ? "90%" : "90%" }} src={all}></img>
        </div>
      </div>
      <div
        style={{
          width: matchesSmall ? "80%" : "60%",
          marginBottom: "70px",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <span
          style={{
            fontSize: matchesSmall ? "25px" : "32px",
            fontFamily: "PPNeueMachina",
            marginBottom: "10px",
            color: "#ffb631",
          }}
        >
          Heroes? Who are they?
        </span>
        <div style={{ display: "flex", flexDirection: "row", margin: 0 }}>
          <div style={{ width: "100%" }}>
            <img
              align="right"
              style={{ width: "40%", height: "auto" }}
              src={traitsExplained}
            ></img>
            <p
              style={{
                fontSize: matchesSmall ? "20px" : "22px",
                margin: "12px 0",
                letterSpacing: 2,
                fontFamily: "EurostilNormal",
                fontWeight: 100,
              }}
            >
              <a
                target="_blank"
                href="https://opensea.io/collection/le-anime-by-toomuchlag?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Archetype&search[stringTraits][0][values][0]=Hero"
                style={{ color: "#ffb631" }}
              >
                Heroes NFTs
              </a>{" "}
              are the result of combining multiple{" "}
              <a
                target="_blank"
                href="https://opensea.io/collection/le-anime-by-toomuchlag?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Archetype&search[stringTraits][0][values][0]=Le%20Anime"
                style={{ color: "#ffb631" }}
              >
                Le Anime
              </a>{" "}
              and{" "}
              <a
                target="_blank"
                href="https://opensea.io/collection/le-anime-by-toomuchlag?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Archetype&search[stringTraits][0][values][0]=Spirit"
                style={{ color: "#ffb631" }}
              >
                Spirits
              </a>{" "}
              together into a single, more powerful NFT.
            </p>
            <p
              style={{
                fontSize: matchesSmall ? "20px" : "22px",
                margin: "12px 0",
                letterSpacing: 2,
                fontFamily: "EurostilNormal",
                fontWeight: 100,
              }}
            >
              Heroes are the non-destructive cumulative process of adding every
              trait and score into one NFT. A character that can be customized
              at will based on the attributes merged. Heroes can at any time be
              altered, strengthened or dismantled (coming soon).
            </p>
          </div>
          <div style={{ textAlign: "center" }}></div>
        </div>
      </div>
      <div
        style={{
          width: matchesSmall ? "80%" : "60%",
          marginBottom: "70px",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <span
          style={{
            fontSize: matchesSmall ? "25px" : "32px",
            fontFamily: "PPNeueMachina",
            marginBottom: "10px",
            color: "#ffb631",
          }}
        >
          How do I get a Hero
        </span>
        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          The original sale of Le Anime was March 5th, 2021, followed by a
          custom coded ranked auction of Spirits December 20th 2021, but you can
          still buy both from the secondary market on{" "}
          <span style={{ color: "#ffb631" }}>
            <a
              style={{ color: "#ffb631" }}
              target="_blank"
              href="https://niftygateway.com/marketplace/collectible/0x03bebcf3d62c1e7465f8a095bfa08a79ca2892a1?filters[onSale]=true&sortBy=lowest"
            >
              Nifty Gateway
            </a>
            ,{" "}
            <a
              style={{ color: "#ffb631" }}
              target="_blank"
              href="https://opensea.io/collection/le-anime-by-toomuchlag?search[sortAscending]=true&search[sortBy]=PRICE"
            >
              OpenSea
            </a>
            ,{" "}
            <a
              style={{ color: "#ffb631" }}
              target="_blank"
              href="https://looksrare.org/collections/0x03BEbcf3D62C1e7465f8a095BFA08a79CA2892A1?queryID=7750f6e7a41e298619702efbb444a6d7"
            >
              LooksRare
            </a>
            {" "}
          </span>
          or other NFT marketplaces.
          <p
            style={{
              fontSize: matchesSmall ? "20px" : "22px",
              margin: "12px 0",
              letterSpacing: 2,
              fontFamily: "EurostilNormal",
              fontWeight: 100,
            }}
          >
            Once you posses Le Anime, Spirits or a combination of both, you can
            forge your Hero using our{" "}
            <Link
              style={{
                color: "#ffb631",
                cursor: "pointer",
              }}
              to="/builder"
              target="_blank"
            >
              Hero Builder
            </Link>
            .
          </p>
          <p
            style={{
              fontSize: matchesSmall ? "20px" : "22px",
              margin: "12px 0",
              letterSpacing: 2,
              fontFamily: "EurostilNormal",
              fontWeight: 100,
            }}
          >
            Instruction on how to use the builder can be found{" "}
            <Link
              target="_blank"
              style={{
                color: "#ffb631",
                cursor: "pointer",
              }}
              to="/builder-guide"
            >
              here
            </Link>
            .
          </p>
        </p>
      </div>
      <div
        style={{
          width: matchesSmall ? "80%" : "60%",
          marginBottom: "70px",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <span
          style={{
            fontSize: matchesSmall ? "25px" : "32px",
            fontFamily: "PPNeueMachina",
            marginBottom: "10px",
            color: "#ffb631",
          }}
        >
          The world and lore
        </span>
        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          Le Anime world is built upon a combination of community decisions and
          toomuchlag's very own inputs. Filled with trivia and mysteries the
          journey is being written as the project evolves. We are writing the
          story together.
        </p>
        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          Our first continent named by the community: Tresoria a 1/1 NFT.
        </p>
        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          Many more await to be discovered. Each hero story is also built by
          the community and its collected... More on Lore coming soon.
        </p>
        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          Explore the{" "}
          <Link
            style={{
              color: "#ffb631",
              cursor: "pointer",
            }}
            target="_blank"
            to="/map"
          >
            world
          </Link>
          .
        </p>
      </div>

      <div
        style={{
          width: matchesSmall ? "80%" : "60%",
          marginBottom: "70px",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <span
          style={{
            fontSize: matchesSmall ? "25px" : "32px",
            fontFamily: "PPNeueMachina",
            marginBottom: "10px",
            color: "#ffb631",
          }}
        >
          What benefits come with owning a Le Anime Hero?
        </span>
        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          Le Anime is an innovative and unique project that has a tight
          community of passionate builders, artists collectors and supporters
          that interact together since its inception.
        </p>

        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          Contribute producing high quality content and exploring the full
          potential of decentralized NFT art while writing a unique story.
        </p>
        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          The team behind Le Anime is a blockchain and art driven collective of
          creative minds and passionate humans. We are all doxxed and our info
          are available below.
        </p>
        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          We believe art is the utility but as extra, owning a Hero allows you
          to participate in exclusive interactive mechanics, partake in building
          and shaping this incredible journey, toomuchlag's limited editions
          drop access, token gated events both digital and physical, exclusive
          merch, private chats in our community and of course, your own
          customized Hero art.
        </p>
      </div>
      <div
        style={{
          width: matchesSmall ? "80%" : "60%",
          marginBottom: "70px",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <span
          style={{
            fontSize: matchesSmall ? "25px" : "32px",
            fontFamily: "PPNeueMachina",
            marginBottom: "10px",
            color: "#ffb631",
          }}
        >
          What does the future of Le Anime hold?
        </span>
        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          We are currently developing the{" "}
          <Link
            style={{
              color: "#ffb631",
              cursor: "pointer",
            }}
            to="/builder"
            target="_blank"
          >
            Hero builder
          </Link>{" "}
          further adding functionalities and improvements. Hero challenges are
          on the way and we are working on exclusive physicals related to the Le
          Anime world.
        </p>
        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          Collaborations with amazing artists (names TBA).
        </p>
        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          We like to keep an element of freedom and surprise to our future
          steps, to allow for innovative and experimental liberty but we aim to
          keep you informed of future steps on the horizon as these are being
          developed.
        </p>
        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          Cool community events and rewards will be held regularly. And much
          more to come, but we will announce in due time..
        </p>
      </div>
      <div
        style={{
          width: matchesSmall ? "80%" : "60%",
          marginBottom: "70px",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <span
          style={{
            fontSize: matchesSmall ? "25px" : "32px",
            fontFamily: "PPNeueMachina",
            marginBottom: "10px",
            color: "#ffb631",
          }}
        >
          Want to know more and get involved?
        </span>
        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          Our{" "}
          <a
            style={{ color: "#ffb631" }}
            target="_blank"
            href="https://twitter.com/leanimeart"
          >
            Twitter
          </a>{" "}
          is a good place to keep updated with news and updates relative to the
          project.
        </p>
        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          Our Discord is hands down the best place to get involved with the
          project and the community. On top of a{" "}
          <a
            style={{ color: "#ffb631" }}
            target="_blank"
            href="https://discord.gg/HuNTadkCdx"
          >
            FAQ section
          </a>
          , we have a warm welcoming family that can help and guide you in
          moving your first steps with this project. Always up for a chat on
          trending topics from art, crypto, NFT to any of your fantasy related
          discussions.
        </p>
        <p
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          We are working on more guides and blogs to help you further navigate
          Le Anime{" "}
          <Link
            style={{
              color: "#ffb631",
              cursor: "pointer",
            }}
            to="/history"
            target="_blank"
          >
            history
          </Link>
          ,{" "}
          <Link
            style={{
              color: "#ffb631",
              cursor: "pointer",
            }}
            to="/map"
            target="_blank"
          >
            world
          </Link>{" "}
          and{" "}
          <Link
            style={{
              color: "#ffb631",
              cursor: "pointer",
            }}
            to="/entities"
            target="_blank"
          >
            entities
          </Link>
          .
        </p>
      </div>
      <div
        style={{
          width: matchesSmall ? "80%" : "60%",
          marginBottom: "70px",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <span
          style={{
            fontSize: matchesSmall ? "25px" : "32px",
            fontFamily: "PPNeueMachina",
            marginBottom: "10px",
            color: "#ffb631",
          }}
        >
          Who is behind this project?
        </span>
        <Grid
          container
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Grid
            item
            xs={matchesMobile ? 6 : matchesSmall ? 4 : matchesMedium ? 3 : 2.4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "20%",
            }}
          >
            <img
              style={{ width: "85%", marginBottom: "10px" }}
              src={toomuchlag}
            />
            <a
              style={{ color: "#ffb631", textDecoration: "none" }}
              target="_blank"
              href="https://twitter.com/toomuchlag"
            >
              toomuchlag
            </a>{" "}
            <span style={{ fontSize: "12px" }}>founder & artist</span>
          </Grid>
          <Grid
            item
            xs={matchesMobile ? 6 : matchesSmall ? 4 : matchesMedium ? 3 : 2.4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "20%",
            }}
          >
            <img style={{ width: "85%", marginBottom: "10px" }} src={mauro} />
            <a
              style={{ color: "#ffb631", textDecoration: "none" }}
              target="_blank"
              href="https://twitter.com/divalvi"
            >
              mauro
            </a>{" "}
            <span style={{ fontSize: "12px" }}>dev & musician</span>
          </Grid>
          <Grid
            item
            xs={matchesMobile ? 6 : matchesSmall ? 4 : matchesMedium ? 3 : 2.4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "20%",
            }}
          >
            <img
              style={{ width: "85%", marginBottom: "10px" }}
              src={teeshaped}
            />
            <a
              style={{ color: "#ffb631", textDecoration: "none" }}
              target="_blank"
              href="https://twitter.com/teeshapedman"
            >
              teeshapedman
            </a>{" "}
            <span style={{ fontSize: "12px" }}>website dev</span>
          </Grid>
          <Grid
            item
            xs={matchesMobile ? 6 : matchesSmall ? 4 : matchesMedium ? 3 : 2.4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "20%",
            }}
          >
            <img style={{ width: "85%", marginBottom: "10px" }} src={simona} />
            <a
              style={{ color: "#ffb631", textDecoration: "none" }}
              target="_blank"
              href="https://twitter.com/algoriste"
            >
              simona
            </a>{" "}
            <span style={{ fontSize: "12px" }}>operations</span>
          </Grid>
          <Grid
            item
            xs={matchesMobile ? 6 : matchesSmall ? 4 : matchesMedium ? 3 : 2.4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "20%",
            }}
          >
            <img style={{ width: "85%", marginBottom: "10px" }} src={hired} />
            <a
              style={{ color: "#ffb631", textDecoration: "none" }}
              target="_blank"
              href="https://twitter.com/HIREDily"
            >
              hired
            </a>{" "}
            <span style={{ fontSize: "12px" }}>community</span>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          width: matchesSmall ? "80%" : "60%",
          marginBottom: "70px",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <span
          style={{
            fontSize: matchesSmall ? "25px" : "32px",
            fontFamily: "PPNeueMachina",
            marginBottom: "10px",
            color: "#ffb631",
          }}
        >
          Our notable discord contributors:
        </span>
        <div
          style={{
            fontSize: matchesSmall ? "20px" : "22px",
            margin: "12px 0",
            letterSpacing: 2,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>Abana</span>
          <span>Felipe</span>
          <span>JT</span>
          <span>Leonidas</span>
          <span>Musicalnetta</span>
          <span>Neurex</span>
          <span>Zoref</span>
          <span>Blu3orgy</span>
          <span>ChirsMannto</span>
          <span>MariusMoller82</span>
          <span>Cat, The Imperium</span>
          <span style={{ marginTop: "20px" }}>
            You can contact us via{" "}
            <a
              style={{ color: "#ffb631" }}
              target="_blank"
              href="https://twitter.com/leanimeart"
            >
              Twitter
            </a>
            ,{" "}
            <a
              style={{ color: "#ffb631" }}
              target="_blank"
              href="https://discord.gg/HuNTadkCdx"
            >
              Discord
            </a>{" "}
            or emailing at{" "}
            <a
              style={{ color: "#ffb631" }}
              target="_blank"
              href="mailto: info@toomuchlag.com"
            >
              email Le Anime
            </a>
          </span>
        </div>
      </div>
    </Grid>
  );
};
export default About;
