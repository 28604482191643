import React, { useState, useEffect } from "react";
import { Grid, Button, useMediaQuery, Modal } from "@mui/material";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    CircularProgress,
} from "@mui/material";
import Fade from "@mui/material/Fade";

import axios from "axios";
import { experimentalStyled as styled } from "@mui/material/styles";
import {
    traitsMapping,
    traitsMappingReference,
    traitsOrder,
  } from "../assets/heroesData/TraitEncodingHeroes";
import metadataHeroes from "../assets/heroesData/HeroesMetaData.json";
import traitCountFile from "../assets/heroesData/TraitToCount_V5_Heroes.json";

import sum from "lodash.sum";
import { Traits} from "./";
const StyledTag = styled("div")`
    border-radius: 32px;
    padding: 4px;
    color: #111111;
    background: #ffb631;
    min-width: 70px;
    min-height: 24px;
    text-align: center;
    margin-left: 10px;
    font-family: PPNeueMachina;
    font-weight: 900;
    font-size: 11px;
`;
export default function TopHeroesLeaderboard(props) {
    const matchesMedium = useMediaQuery("(max-width:1440px)");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [rows, setRows] = useState([]);
    const [ensRows, setEnsRows] = useState([]);

    const [heroSelected, setHeroSelected] = useState(0);
    const [lockedSouls, setLockedSouls] = useState([]);
    const [lockedSoulsAndTraits, setLockedSoulsAndTraits] = useState([]);
    const [openLockedSouls, setOpenLockedSouls] = useState(false);
    const [heroToDisplay, setHeroToDisplay] = useState(0);
    const [openHeroData, setOpenHeroData] = useState(false);

    const matchesMobile = useMediaQuery("(max-width:600px)");

    // const fetchAndStoreLeaderBoard = async () => {
    //     // axios.get local json file

    //     axios.get("heroLeaderboard.json").then((response) => {
    //         // setRows(response.data.data.slice(0, 100));
    //         setRows(response.data.data);
    //     });
    // };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newLimit = +event.target.value;

        setRowsPerPage(newLimit);
        setPage(0);
    };

    //   useEffect(() => {
    //     let ensRows = [];

    //     setEnsRows(ensRows);
    //     if (props.ensContract) {
    //       const ensNames = async () => {
    //         const data = rows;
    //         const addresses = data.map((d) => d.bidder);
    //         ensRows = await props.getEnsNames(addresses, props.ensContract);
    //         setEnsRows(ensRows);
    //       };
    //       ensNames();
    //     }
    //   }, [rows, props.ensContract]);

    useEffect(() => {
        if (props.leaderboardData) {
            //   fetchAndStoreLeaderBoard();
            setRows(props.leaderboardData);
        }
    }, [props.leaderboardData]);
    return (
        <>
            <Modal
                id={"hero image"}
                disableScrollLock={true}
                style={{ border: "none" }}
                open={openHeroData}
                onClose={() => {
                    setOpenHeroData(false);
                }}
            >
                <Fade in={openHeroData}>
                <div
                    style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "auto",
                    height: matchesMedium ? "auto" : "70%",
                    maxHeight: "90%",
                    // background: "#111111",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                    outline: "none",
                    }}
                >
                    <img
                    src={`https://api.leanime.art/heroes/images/m/${heroToDisplay}.jpg`}
                    style={{
                        // height: "100%",
                        maxHeight: "90%",
                        maxWidth: "90%",
                    }}
                    />
                </div>
                </Fade>
            </Modal>
            <Modal
                id={"locked souls"}
                disableScrollLock={true}
                style={{ border: "none" }}
                ModalProps={{
                    keepMounted: true,
                }}
                open={openLockedSouls}
                onClose={() => {
                    setOpenLockedSouls(false);
                }}
            >
                <Fade in={openLockedSouls}>
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: matchesMobile? "90%" : "70%",
                            height: "700px",
                            maxHeight: "90%",
                            background: "#111111",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "center",
                            borderRadius: "10px",
                            outline: "none",
                            paddingTop: "20px",

                            color: "white",
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                                width: "100%",
                                marginTop: "5px",
                                marginBottom: "20px",
                                fontFamily: "PPNeueMachina",
                                fontSize: matchesMobile ? "12px" : "35px",
                            }}
                        >
                            NFTS MERGED IN HERO #{heroSelected}
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="start"
                            style={{ width: "100%", height: "90%" }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="start"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                }}
                                // spacing={0}
                            >
                                    {lockedSoulsAndTraits &&
                                    <Traits spirits={lockedSoulsAndTraits} />
                                    }
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
            <div style={{ width: "100%" }}>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {rows && rows.length > 0 && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                                fontFamily: "PPNeueMachina",
                                fontWeight: "900",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: matchesMobile ? "20px" : "32px",  

                                    marginBottom: "1%",
                                }}
                            >
                                TOP
                                <span
                                    style={{ color: "rgba(255, 182, 49,1)" }}
                                >
                                    {" "}
                                    HEROES
                                </span>{" "}
                                BY SCORE
                            </span>
                            <span style={{ 
                              fontSize: matchesMobile ? "12px" : "14px", 
                             }}>
                                {" "}
                                <span>
                                    Only merged Heroes appear in the Leaderboard
                                </span>{" "}
                            </span>
                        </div>
                    )}
                </div>
                {rows && rows.length > 0 ? (
                    <>
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                // fontSize: matchesMobile ? "8px" : "15px",
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    style={{ 
                                      color: "white",
                                    }}
                                    sx={{
                                      '& .MuiTablePagination-input, & .MuiTablePagination-displayedRows, & .MuiTablePagination-selectLabel': {
                                        fontSize: matchesMobile ? "8px" : "15px",
                                      },
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                    }
                                />
                            </div>
                        </div>

                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <TableContainer
                                style={{ width: "100%", overflow: "auto" }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow
                                            sx={{
                                                th: {
                                                    color: "rgba(255, 182, 49,1)",
                                                    border: "1px solid rgba(17, 17, 17, 1)",
                                                    background:
                                                        "rgba(255, 255, 255, 0.08)",
                                                    fontSize: matchesMobile ? "8px" : "15px",
                                                    fontFamily: "PPNeueMachina",
                                                    fontWeight: 900,
                                                },
                                            }}
                                        >
                                            <TableCell style={{width: "8%"}}>RANK</TableCell>
                                            <TableCell align="center">
                                                HERO ID
                                            </TableCell>
                                            <TableCell align="center">
                                                SCORE
                                            </TableCell>
                                            <TableCell align="center">
                                                EXP
                                            </TableCell>
                                            <TableCell align="center">
                                                NFTS
                                            </TableCell>
                                            {/* <TableCell align="center">IMAGE</TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row, i) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{
                                                        "td, th": {
                                                            color: "white",
                                                            border: "1px solid rgba(17, 17, 17, 1)",
                                                            fontSize: matchesMobile ? "8px" : "14px",
                                                            lineHeight: "24px",
                                                            fontFamily:
                                                                "EurostilDemi",
                                                            letterSpacing:
                                                                "-0.01em",
                                                            padding: "1%",
                                                            background:
                                                                i % 2 !== 0
                                                                    ? "rgba(255, 255, 255, 0.08)"
                                                                    : "rgba(255, 255, 255, 0.03)",
                                                        },
                                                    }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        #{" "}
                                                        {i +
                                                            1 +
                                                            page * rowsPerPage}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <a
                                                            href={`https://opensea.io/assets/ethereum/0x03bebcf3d62c1e7465f8a095bfa08a79ca2892a1/${row.heroId + 100000}`}
                                                            target="_blank"
                                                            style={{ textDecoration: "none", color: "white" }}>
                  
                                                        {row.heroId}
                                                        </a>
                                                        <span
                                                            style={{
                                                            // background: "#ffb631",
                                                            cursor: "pointer",
                                                            color: "#ffb631",
                                                            width: "30%",
                                                            height: "30px",
                                                            marginLeft: "15px",
                                                            borderRadius: 0,
                                                            fontSize: matchesMobile? "10px" : "15px",
                                                            fontFamily: "EurostilDemi",
                                                            }}
                                                            onClick={() => {
                                                            setHeroToDisplay(row.heroId)
                                                            
                                                    
                                                            setOpenHeroData(true)
                                                            }}
                                                        >
                                                            VIEW
                                                        </span>{" "}
                            
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.score}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.exp}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.soulsInHero.length} 
                                                        <span
                                                            style={{
                                                            // background: "#ffb631",
                                                            cursor: "pointer",
                                                            color: "#ffb631",
                                                            width: "30%",
                                                            height: "30px",
                                                            marginLeft: "15px",
                                                            borderRadius: 0,
                                                            fontSize: matchesMobile? "10px" : "15px",
                                                            fontFamily: "EurostilDemi",
                                                            }}
                                                            onClick={() => {
                                                            setHeroSelected(row.heroId)
                                                            setLockedSouls(row.soulsInHero)
                                                            

                                                            // convert soul numers to souls and traits to feed in <Traits /> format for modal
                                                            let soulsAndTraits = []
                                                            // row.soulsInHero = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]
                                                            // row.soulsInHero = [1,2,3,4,5]
                                                            row.soulsInHero.forEach((soul) => {
                                                                let token = {}
                                                                token.id = soul + 100000
                                                                token.soulNumber = soul
                                                                token.traits = metadataHeroes[soul - 1]
                                                                soulsAndTraits.push(token)
                                                            })
                                                                                                                   
                                                            let formattedSoulsAndTraits = soulsAndTraits.map((token) => {
                                        
                                                                const newFormat = Array(7)
                                                                  .fill(0)
                                                                  .map((cell, j) => {
                                                                    if (traitsMappingReference[j] === "Runes") {
                                                                      const value = traitsMapping[5][token.traits[0][8][0]];
                                                                      return {
                                                                        trait_type: "Runes",
                                                                        value,
                                                                        trait_count: sum(traitCountFile["runes"][value]),
                                                                      };
                                                                    }
                                                                    if (traitsMappingReference[j] === "Extra") {
                                                                      const value = traitsMapping[6][token.traits[1][0][0]];
                                                                      return {
                                                                        trait_type: "Extra",
                                                                        value,
                                                                        trait_count: sum(traitCountFile["extra"][value]),
                                                                      };
                                                                    }
                                                                    const value = traitsMapping[j][token.traits[0][j + 3]];
                                                                    return {
                                                                      trait_type: traitsMappingReference[j],
                                                                      value,
                                                                      trait_count:
                                                                        traitCountFile[traitsMappingReference[j].toLowerCase()][
                                                                          value
                                                                        ],
                                                                    };
                                                                  });
                                                                newFormat.push({
                                                                  trait_type: "Spirit",
                                                                  value: "Score",
                                                                  trait_count: token.traits[0][1],
                                                                });
                                                                return {
                                                                  id: token.id,
                                                                  soulNumber: token.soulNumber,
                                                                  traits: newFormat,
                                                                };
                                                              });
                                                              // order by descending score
                                                            formattedSoulsAndTraits = formattedSoulsAndTraits.sort((a, b) =>  b.traits[7].trait_count - a.traits[7].trait_count);
                                                      
                                                            setLockedSoulsAndTraits(formattedSoulsAndTraits);
                                                            // setLockedSouls([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19])

                                                            setOpenLockedSouls(true);
                                                            }}
                                                        >
                                                            VIEW
                                                        </span>{" "}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </>
                ) : (
                  <>
                  <span
                    style={{
                      fontSize: "22px",
                      fontFamily: "PPNeueMachina",
                      marginTop: "20px",
                      fontWeight: "900",
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    On-chain leaderboard loading...
                  </span>
                  <span
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <CircularProgress />
                  </span>
                  </>
                  
                )}
            </div>
        </>
    );
}
