import React from "react";

import { Link } from "react-router-dom";
import { Grid, Button, useMediaQuery } from "@mui/material";
import hero from "../assets/Homepage.jpg";
import heroMobile from "../assets/MobileHomeLowRes.jpg";
import { Footer } from "../components";
import { experimentalStyled as styled } from "@mui/material/styles";

import all from "../assets/leanimeSpiritsHeroes.jpg";
import banner from "../assets/Bannerv0.jpg";
import teeshaped from "../assets/team-teeshaped.jpg";
import traitsExplained from "../assets/traitsExplained.png";
import bookClosed from "../assets/Library-Book-small.png";
import comicCover from "../assets/COMIC-COVER_V2_small.png";

const Home = () => {
  const matchesMedium = useMediaQuery("(max-width:1400px)");
  const matchesSmall = useMediaQuery("(max-width:1000px)");
  const matchesMobile = useMediaQuery("(max-width:850px)");
  return (
    <>
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="row"
      style={{
        width: "100%",
        height: "80vh",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "80vh",
          maxHeight: "100vh",
          backgroundImage: `url(${!matchesSmall ? hero : heroMobile})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Button
          style={{
            position: "absolute",
            right: matchesSmall ? 30 : 80,
            bottom: 200,
            color: "black",
            background: "#ffb631 ",
            fontFamily: "EuroStileBoldObq",
            fontSize: matchesSmall ? "15px" : "18px",
            padding: matchesSmall ? "15px" : "18px",
            borderRadius: 0,
          }}
        >
          {" "}
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to="/collection"
          >
            {"TO COLLECTION >>"}
          </Link>
        </Button>
      </div>
    </Grid>
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        paddingTop: "100px",
        paddingLeft: matchesSmall ? "40px" : "0px",
        paddingRight: matchesSmall ? "40px" : "0px",
        marginBottom: "30px",
        width: "100%",
        fontWeight: 100,
        fontFamily: "EurostilNormal",
      }}
    >
      <div
        style={{
          width: matchesSmall ? "90%" : "1000px",
          marginBottom: "60px",
        }}
      >
        <img style={{ width: "100%" }} src={banner} />
      </div>

      <div
        style={{
          width: matchesSmall ? "80%" : "900px",
          marginBottom: "70px",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <span
          style={{
            fontSize: matchesSmall ? "25px" : "32px",
            fontFamily: "PPNeueMachina",
            marginBottom: "10px",
            fontWeight: "900",
            color: "#ffb631",
          }}
        >
          Le Anime Collection
        </span>
        <p
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontWeight: 100,
            fontFamily: "EurostilNormal",
          }}
        >
          <a
            style={{ color: "#ffb631", textDecoration: "none", }}
            target="_blank"
            href="https://twitter.com/leanimeart"
          >
            Le Anime
          </a>{" "}
          is an interactive NFT art project by{" "}
          <a
            target="_blank"
            href="https://twitter.com/toomuchlag"
            style={{ color: "#ffb631", textDecoration: "none", }}
          >
            toomuchlag
          </a>
          . A collection of 1573 Le Anime and 9054 Spirits which are the
          building blocks of customizable Heroes NFTs.
        </p>
        <p
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          A community focused project that enables owners to build their unique
          characters using our platform powered with custom{" "}
          <a
            target="_blank"
            href="https://ethereum.org/en/what-is-ethereum/"
            style={{ color: "#ffb631", textDecoration: "none",}}
          >
            Ethereum
          </a>{" "}
          smart contracts. The potential of which has just been scraped and will
          grow with its community.
        </p>
        <p
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          This breed of art-gamified approach allows for unique mechanics such
          as levelling of traits, unlockable exclusive features, experience 
          accumulation and much more. <br/><br/> Challenges are on the near horizon..
        </p>
        <div style={{ textAlign: "center" }}>
          <img style={{ width: matchesSmall ? "100%" : "100%" }} src={all}></img>
        </div>
      </div>

      {/* what are heroes? */}
      <Grid 
        container 
        direction="row"
        style={{
          width: matchesSmall ? "80%" : "900px",
          marginBottom: "20px",
          padding: 0,
          display: 'flex', // Add this line
          alignItems: 'center', // Add this line
        }}>
      <Grid item xs={12} sm={5} style={{ width: "100%", marginBottom: "40px" }}>
      <img
              style={{ width: "100%", height: "auto" }}
              src={traitsExplained}
            ></img>
      </Grid>

      <Grid item xs={12} sm={7} style={{ width: "100%", marginBottom: "40px" }}>
      <span
          style={{
            fontSize: matchesSmall ? "25px" : "32px",
            fontFamily: "PPNeueMachina",
            marginBottom: "10px",
            color: "#ffb631",
          }}
        >
          What are Heroes?
        </span>
            <p
              style={{
                fontSize: matchesSmall ? "18px" : "18px",
                margin: "12px 0",
                letterSpacing: 1,
                fontFamily: "EurostilNormal",
                fontWeight: 100,
              }}
            >
              <a
                target="_blank"
                href="https://opensea.io/collection/le-anime-by-toomuchlag?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Archetype&search[stringTraits][0][values][0]=Hero"
                style={{ color: "#ffb631", textDecoration: "none", }}
              >
                Heroes NFTs
              </a>{" "}
              are the result of combining multiple{" "}
              <a
                target="_blank"
                href="https://opensea.io/collection/le-anime-by-toomuchlag?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Archetype&search[stringTraits][0][values][0]=Le%20Anime"
                style={{ color: "#ffb631", textDecoration: "none", }}
              >
                Le Anime
              </a>{" "}
              and{" "}
              <a
                target="_blank"
                href="https://opensea.io/collection/le-anime-by-toomuchlag?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Archetype&search[stringTraits][0][values][0]=Spirit"
                style={{ color: "#ffb631", textDecoration: "none", }}
              >
                Spirits
              </a>{" "}
              together into a single, more powerful NFT.
            </p>
            <p
              style={{
                fontSize: matchesSmall ? "18px" : "18px",
                margin: "12px 0",
                letterSpacing: 1,
                fontFamily: "EurostilNormal",
                fontWeight: 100,
              }}
            >
              Heroes are the non-destructive cumulative process of adding every trait and score into one NFT. 
              <br/><br/>
              <Link
            style={{
              color: "#ffb631",
              cursor: "pointer",
              textDecoration: "none",
            }}
            target="_blank"
            to="https://leanime.gitbook.io/le-anime/features-guides/video-tutorials"
          >
            Get one now, start here.
          </Link>
            </p>
      </Grid>
      </Grid>
      {/* world and lore */}
      <Grid 
        container 
        direction="row"
        style={{
          width: matchesSmall ? "80%" : "900px",
          marginBottom: "20px",
          padding: 0,
          display: 'flex', // Add this line
          alignItems: 'center', // Add this line
        }}>
      <Grid item xs={12} sm={7} style={{ width: "100%", marginBottom: "40px" }}>
      <span
          style={{
            fontSize: matchesSmall ? "25px" : "32px",
            fontFamily: "PPNeueMachina",
            marginBottom: "10px",
            color: "#ffb631",
          }}
        >
          The World and Lore
        </span>
        <p
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          Le Anime world is built and written together. <br/><br/>

          Community named continents and tomes filled with collectors stories, puzzles and dungeons await to be discovered. <br/><br/>

          Each Hero can add their story to the 
          <Link
            style={{
              color: "#ffb631",
              cursor: "pointer",
              textDecoration: "none",
            }}
            target="_blank"
            to="/library"
          >
          {" "}Great Library.
          </Link>
          <br/><br/>
          <Link
            style={{
              color: "#ffb631",
              cursor: "pointer",
              textDecoration: "none",
            }}
            target="_blank"
            to="/map"
          >
          Explore the world.
          </Link>
        </p>
      </Grid>    
      <Grid item xs={12} sm={5} style={{ width: "100%", marginBottom: "40px" }}>
      <img
              style={{ width: "100%", height: "auto" }}
              src={bookClosed}
            ></img>
      </Grid>
      </Grid>
      {/* Comic? */}
      <Grid 
        container 
        direction="row"
        style={{
          width: matchesSmall ? "80%" : "900px",
          marginBottom: "20px",
          padding: 0,
          display: 'flex', // Add this line
          alignItems: 'center', // Add this line
        }}>
      <Grid item xs={12} sm={5} style={{ width: "100%", marginBottom: "40px", paddingRight: "20px" }}>
      <img
              style={{ width: "100%", height: "auto" }}
              src={comicCover}
            ></img>
      </Grid>

      <Grid item xs={12} sm={7} style={{ width: "100%", marginBottom: "40px" }}>
      <span
          style={{
            fontSize: matchesSmall ? "25px" : "32px",
            fontFamily: "PPNeueMachina",
            marginBottom: "10px",
            color: "#ffb631",
          }}
        >
          Le Anime Comic
        </span>
        <p
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
          }}
        >
          Read 
          <Link
            style={{
              color: "#ffb631",
              cursor: "pointer",
              textDecoration: "none",
            }}
            target="_blank"
            to="/comic"
          >
          
          {" "} the coolest comic in web3
          </Link>
          
          , every issue is an NFT given to token holders with multiple covers. It’s fantasy and hilarious mixed in the right proportions. 

          <br/><br/>  New issues coming out. 
        </p>
      </Grid>
      </Grid>

      <div
        style={{
          width: matchesSmall ? "80%" : "900px",
          marginBottom: "70px",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <span
          style={{
            fontSize: matchesSmall ? "25px" : "32px",
            fontFamily: "PPNeueMachina",
            marginBottom: "10px",
            color: "#ffb631",
          }}
        >
          The Team
        </span>
        <Grid
          container
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Grid
            item
            xs={matchesMobile ? 6 : matchesSmall ? 6 : matchesMedium ? 3 : 3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "20%",
            }}
          >
            <img
              style={{ width: "85%", marginBottom: "10px" }}
              src={"https://api.leanime.art/heroes/images/s/17.jpg"}
            />
            <a
              style={{ color: "#ffb631", textDecoration: "none" }}
              target="_blank"
              href="https://twitter.com/toomuchlag"
            >
              toomuchlag
            </a>{" "}
            <span style={{ fontSize: "12px", marginBottom: "10px"}}>founder & artist</span>
          </Grid>
          <Grid
            item
            xs={matchesMobile ? 6 : matchesSmall ? 6 : matchesMedium ? 3 : 3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "20%",
            }}
          >
            <img style={{ width: "85%", marginBottom: "10px" }} src={"https://api.leanime.art/heroes/images/s/414.jpg"} />
            <a
              style={{ color: "#ffb631", textDecoration: "none" }}
              target="_blank"
              href="https://twitter.com/divalvi"
            >
              mauro
            </a>{" "}
            <span style={{ fontSize: "12px", marginBottom: "10px" }}>dev & musician</span>
          </Grid>
          <Grid
            item
            xs={matchesMobile ? 6 : matchesSmall ? 6 : matchesMedium ? 3 : 3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "20%",
            }}
          >
            <img
              style={{ width: "85%", marginBottom: "10px" }}
              src={teeshaped}
            />
            <a
              style={{ color: "#ffb631", textDecoration: "none" }}
              target="_blank"
              href="https://twitter.com/teeshapedman"
            >
              teeshapedman
            </a>{" "}
            <span style={{ fontSize: "12px", marginBottom: "10px" }}>website dev</span>
          </Grid>
          <Grid
            item
            xs={matchesMobile ? 6 : matchesSmall ? 6 : matchesMedium ? 3 : 3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "20%",
            }}
          >
            <img style={{ width: "85%", marginBottom: "10px" }} src={"https://api.leanime.art/heroes/images/s/807.jpg"} />
            <a
              style={{ color: "#ffb631", textDecoration: "none" }}
              target="_blank"
              href="https://twitter.com/algoriste"
            >
              simona
            </a>{" "}
            <span style={{ fontSize: "12px", marginBottom: "10px" }}>operations</span>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          width: matchesSmall ? "80%" : "900px",
          marginBottom: "70px",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <span
          style={{
            fontSize: matchesSmall ? "25px" : "32px",
            fontFamily: "PPNeueMachina",
            marginBottom: "10px",
            color: "#ffb631",
          }}
        >
          Discord Legends
        </span>
        <div
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
            fontWeight: 100,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>Abana, Blu3orgy, Cat, The Imperium, ChirsMannto, Felipe, JT, Leonidas, MariusMoller82, Musicalnetta, Neurex, Zoref</span>
          {/* <span>Felipe</span>
          <span>JT</span>
          <span>Leonidas</span>
          <span>Musicalnetta</span>
          <span>Neurex</span>
          <span>Zoref</span>
          <span>Blu3orgy</span>
          <span>ChirsMannto</span>
          <span>MariusMoller82</span>
          <span>Cat, The Imperium</span>
          <span style={{ marginTop: "20px" }}>
            You can contact us via{" "}
            <a
              style={{ color: "#ffb631" }}
              target="_blank"
              href="https://twitter.com/leanimeart"
            >
              Twitter
            </a>
            ,{" "}
            <a
              style={{ color: "#ffb631" }}
              target="_blank"
              href="https://discord.gg/HuNTadkCdx"
            >
              Discord
            </a>{" "}
            or emailing at{" "}
            <a
              style={{ color: "#ffb631" }}
              target="_blank"
              href="mailto: info@toomuchlag.com"
            >
              email Le Anime
            </a>
          </span> */}
        </div>
      </div>
    </Grid>
    </>
  );
};

export default Home;
