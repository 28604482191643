import { ethers } from "ethers";
import Papa from "papaparse";

const BigNumber = ethers.BigNumber;

const spiritsClaimed = async (account, claimContract) => {
  const claimedEvents = await claimContract.queryFilter(
    claimContract.filters.Transfer(
      "0x0000000000000000000000000000000000000000",
      account,
      null
    ) //mint event
  );

  //let anime2offset = 100000;
  let claimedIDs = [];
  claimedEvents.forEach((event) => {
    claimedIDs.push(parseInt(event.args.tokenId.toString(), 10) - 100000);
  });
  return claimedIDs; //[0].args.tokenId
};

// const readClaimFile = async (account) => {
//     const fileName = `${account}.csv`

//     let csvData = []
//     let claimData = []
//     return new Promise((resolve, reject) => {
//         fs.createReadStream(fileName)
//             .on('error', error => {
//                 reject(error);
//             })
//             .pipe(parse({delimiter: ';'}))
//             .on('data', function(csvrow) {
//                 csvData.push(csvrow);
//             })
//             .on('end',function() {

//             csvData.forEach(element => {
//                 claimData.push()
//             });

//             resolve(claimData)
//             });
//         })

// }

const readClaimFile = async (account) => {
  let claimData = [];
  const formattedAccount = ethers.utils.getAddress(account);
  return import(`../assets/claimData/${formattedAccount}.csv`)
    .then(async (csvFile) => {
      const fetchcsv = () => {
        return fetch(csvFile.default).then((res) => {
          let reader = res.body.getReader();
          let decoder = new TextDecoder("utf-8");
          return reader.read().then((result) => {
            return decoder.decode(result.value);
          });
        });
      };
      const csv = await fetchcsv();
      const csvData = Papa.parse(csv, { delimiter: ";" }).data;

      csvData.forEach((element) => {
        claimData.push(JSON.parse(element[0]), JSON.parse(element[1]));
      });
      return claimData;
    })
    .catch((err) => {});
};
export const listOfSpirits = async (account, claimContract) => {
  let spiritsToClaim = await readClaimFile(account);
  let claimedSpirits = await spiritsClaimed(account, claimContract);

  let spirits = {};
  if (spiritsToClaim) {
    spirits = {
      tokenIds: spiritsToClaim[0],
      proof: spiritsToClaim[1],
    };
  }
  const firstTokenId = spiritsToClaim?.length > 0 && spiritsToClaim[0][0];

  return claimedSpirits.includes(firstTokenId) ? null : spirits;
};

export const formatSpirits = (list) => {
  let tokenIDs = [];
  let proof = [];
  list.forEach((element) => {
    tokenIDs.push(element.tokenId);
    proof.push(element.proof);
  });
  return { tokenIDs, proof };
};

export const claimAndWithdraw = async (
  account,
  tokenIDs,
  traits,
  signatures,
  claimContract
) => {
  let claimTx = await claimContract.claimAndWithdrawBatch(
    account,
    tokenIDs,
    traits,
    signatures
  );

  return claimTx.wait();
};

export const claimSpiritsMerkle = async (
  account,
  tokenIDs,
  proof,
  claimContract
) => {
  let claimTx = await claimContract.claimSpirit(account, tokenIDs, proof);

  return claimTx.wait();
};
