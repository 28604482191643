import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
} from "@mui/material";

import axios from "axios";
import { experimentalStyled as styled } from "@mui/material/styles";
const StyledTag = styled("div")`
  border-radius: 32px;
  padding: 4px;
  color: #111111;
  background: #ffb631;
  min-width: 70px;
  min-height: 24px;
  text-align: center;
  margin-left: 10px;
  font-family: PPNeueMachina;
  font-weight: 900;
  font-size: 11px;
`;
export default function Winners(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalWinners, setTotalWinners] = useState(null);
  const [rows, setRows] = useState([]);
  const [userBid, setUserBid] = useState({});
  let currentAllocations = 0;
  let previousAllocations = 0;
  const fetchAndStoreWinners = async (page, limit) => {
    const params = { user: props.user, page, limit };
    axios
      .get("https://api.leanime.art/leaderboard/winners", { params })
      .then((response) => {
        setRows(response.data.winners.selectedWinners.data);
        setTotalWinners(response.data.winners.selectedWinners.total);
        setUserBid(response.data.winners.userWins);
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchAndStoreWinners(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newLimit = +event.target.value;

    setRowsPerPage(newLimit);
    setPage(0);
    fetchAndStoreWinners(1, newLimit);
  };
  useEffect(() => {
    if (props.user) {
      fetchAndStoreWinners(1, 10);
    }
  }, [props.auctionId, props.user]);
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: "32px",
            fontWeight: "900",
            marginBottom: "2%",
            fontFamily: "PPNeueMachina",
          }}
        >
          {props.auctionId === 2 && " Winners of Auction 1"}{" "}
          {props.auctionId === 3 && " Winners of Auctions 1 & 2"}{" "}
          {props.auctionId === 4 && "Auction Winners"}
        </div>
      </div>
      {rows && rows.length > 0 ? (
        <>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "90%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalWinners}
                rowsPerPage={rowsPerPage}
                page={page}
                style={{ color: "white" }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TableContainer style={{ width: "90%", overflow: "hidden" }}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      th: {
                        color: "rgba(255, 182, 49,1)",
                        border: "1px solid rgba(17, 17, 17, 1)",
                        background: "rgba(255, 255, 255, 0.08)",
                        fontSize: "15px",
                        fontFamily: "PPNeueMachina",
                        fontWeight: 900,
                      },
                    }}
                  >
                    <TableCell>RANK</TableCell>
                    <TableCell>BIDDER</TableCell>
                    <TableCell align="center">QUANTITY</TableCell>
                    <TableCell align="center">AVG. UNIT PRICE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(userBid).length > 0 && (
                    <TableRow
                      sx={{
                        "td, th": {
                          color: "white",
                          border: "1px solid rgba(17, 17, 17, 1)",
                          fontSize: "14px",
                          lineHeight: "24px",
                          fontFamily: "EurostilDemi",
                          letterSpacing: "-0.01em",
                          padding: "1%",
                          background: "rgba(255, 255, 255, 0.03)",
                        },
                      }}
                    >
                      <TableCell
                        style={{
                          borderTop: "5px solid  #ffb631 ",
                          borderBottom: "5px solid  #ffb631 ",
                          borderLeft: "5px solid  #ffb631 ",
                        }}
                        component="th"
                        scope="row"
                      >
                        # {userBid.rank}
                      </TableCell>
                      <TableCell
                        style={{
                          borderTop: "5px solid  #ffb631 ",
                          borderBottom: "5px solid  #ffb631 ",
                        }}
                        component="th"
                        scope="row"
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {userBid.bidder} <StyledTag>YOU</StyledTag>{" "}
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          borderTop: "5px solid  #ffb631 ",
                          borderBottom: "5px solid  #ffb631 ",
                        }}
                        align="center"
                      >
                        {userBid.allocation}
                      </TableCell>
                      <TableCell
                        style={{
                          borderTop: "5px solid  #ffb631 ",
                          borderBottom: "5px solid  #ffb631 ",
                          borderRight: "5px solid  #ffb631 ",
                        }}
                        align="center"
                      >
                        {parseFloat(userBid.averageUnitPrice.toFixed(3))}
                      </TableCell>
                    </TableRow>
                  )}
                  {rows.map((row, i) => {
                    const currentRank = i + 1 + page * rowsPerPage;
                    currentAllocations += row.allocation;
                    previousAllocations =
                      i > 0
                        ? (previousAllocations += rows[i - 1].allocation - 1)
                        : 0;
                    return (
                      <TableRow
                        key={row.name}
                        sx={{
                          "td, th": {
                            color: "white",
                            border: "1px solid rgba(17, 17, 17, 1)",
                            fontSize: "14px",
                            lineHeight: "24px",
                            fontFamily: "EurostilDemi",
                            letterSpacing: "-0.01em",
                            padding: "1%",
                            background:
                              Object.keys(userBid).length > 0
                                ? i % 2 !== 0
                                  ? "rgba(255, 255, 255, 0.03)"
                                  : "rgba(255, 255, 255, 0.08)"
                                : i % 2 !== 0
                                ? "rgba(255, 255, 255, 0.08)"
                                : "rgba(255, 255, 255, 0.03)",
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          # {currentRank}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            {row.bidder}{" "}
                            <StyledTag>
                              WON {row.allocation}
                              {row.allocation === 1 ? " UNIT" : " UNITS"}
                            </StyledTag>
                            {/* {currentRank + previousAllocations !==
                            currentAllocations ? (
                              <StyledTag>
                                #
                                {i > 0
                                  ? currentRank + previousAllocations
                                  : currentRank}{" "}
                                -#
                                {currentAllocations}
                              </StyledTag>
                            ) : (
                              <StyledTag>#{currentAllocations}</StyledTag>
                            )} */}
                            {Object.keys(userBid).length > 0 &&
                              userBid.bidder === row.bidder && (
                                <StyledTag>YOU</StyledTag>
                              )}
                          </div>
                        </TableCell>
                        <TableCell align="center">{row.allocation}</TableCell>
                        <TableCell align="center">
                          {parseFloat(row.averageUnitPrice.toFixed(4))}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
