import React, { useEffect, useState } from "react";
import { Grid, Button, useMediaQuery } from "@mui/material";
import {
  TopHeroesLeaderboard,
} from "../components";
import { experimentalStyled as styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { ethers } from "ethers";
import { useAccount, useNetwork } from "wagmi";
import { useSigner, useProvider } from 'wagmi'
import spirit from "../assets/newmid.jpg";
import header1 from "../assets/newleft.jpg";
import header2 from "../assets/newright.jpg";
import {
    WRAPPER_ABI,
    WRAPPER_ADDRESS,
    SOULSLOCKER_ABI,
    SOULSLOCKER_ADDRESS,
} from "../config/configMerging";
  
import {
    EXPERIENCE_ABI,
    EXPERIENCE_ADDRESS,
} from "../config/configExperience";

import {
    MULTICALL2_ABI,
    MULTICALL2_ADDRESS
} from "../config/configMulticall";


import { generateLeaderboard } from "../helpers/leaderboardHelpers";
const Overlay = styled("div")({
  position: "absolute",
  top: "0",
  right: "0",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  textAlign: "center",
  background: "rgba(1,1,1,0.3)",
  fontFamily: "EurostilDemi",
  width: "100%",
  height: "99.5%",
  fontSize: "60px",
  fontWeight: "200",
});
const HeroLeaderboard = () => {
    const { address, isConnected } = useAccount()
    const provider = useProvider()
    const { data: signer, isError, isLoading } = useSigner() 

    const matches = useMediaQuery("(max-width:200px)");
    const matchesMedium = useMediaQuery("(max-width:1200px)");
    const matchesMobile = useMediaQuery("(max-width:600px)");
    ///// CONTRACTS ////
    const [wrapperContract, setWrapperContract] = useState(null);
    const [lockerContract, setLockerContract] = useState(null);
    const [experienceContract, setExperienceContract] = useState(null);
    const [multicallContract, setMulticallContract] = useState(null);
    const [leaderboardData, setLeaderboardData] = useState({});
    
    useEffect(() => {
        if (provider) {
          const setContractAndTokens = async () => {
            // contracts
            const wrapperContract = new ethers.Contract(
              WRAPPER_ADDRESS,
              WRAPPER_ABI,
              provider
            );
            setWrapperContract(wrapperContract);
            const lockerContract = new ethers.Contract(
              SOULSLOCKER_ADDRESS,
              SOULSLOCKER_ABI,
              provider
            );
            setLockerContract(lockerContract);
            const experienceContract = new ethers.Contract(
                EXPERIENCE_ADDRESS,
                EXPERIENCE_ABI,
                provider
              );
            setExperienceContract(experienceContract);
            const multicallContract = new ethers.Contract(
                MULTICALL2_ADDRESS,
                MULTICALL2_ABI,
                provider
              );
              setMulticallContract(multicallContract);
    
          };
    
          setContractAndTokens();
    
        }
      }, [address && provider]);
    
    useEffect(() => {
        if ((lockerContract && experienceContract && wrapperContract && multicallContract && provider)) {
            (async () => {
            // await getHeroAdditionalLayers(
            //     addLayersContract,
            //     lockerContract,
            //     location.state.hero,
            // ).then((data) => {
            //     setAllAdditionalLayers(data);
            // })
            // .catch((err) => {});

            await generateLeaderboard(multicallContract, experienceContract, lockerContract, wrapperContract).then((data) => {
                setLeaderboardData(data);
            })
            .catch((err) => {});
    
            })();
        }
        // console.log("provider", provider)
    }, [wrapperContract, multicallContract, lockerContract, experienceContract, provider]);

    useEffect(() => {
        // console.log(leaderboardData)

    }, [leaderboardData]);


  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      style={{
        width: "100%",
        height: "100%",
        paddingTop: matchesMedium ? "100px" : 0,
      }}
    >
      <Grid
        item
        style={{
          position: "relative",
          width: "100%",
          maxHeight: "100vh",
        }}
      >
        {!matches ? (
          <>
            <img
              style={{
                width: "33.3%",
                height: "100%",
                opacity: 0.5,
              }}
              src={header1}
            />
            <img
              style={{
                width: "33.3%",
                height: "100%",
                opacity: 1,
              }}
              src={spirit}
            />
            <img
              style={{
                width: "33.3%",
                height: "100%",
                opacity: 0.5,
              }}
              src={header2}
            />
          </>
        ) : (
          <img
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "1%",
              opacity: 1,
            }}
            src={spirit}
          />
        )}
        <Overlay style={{ 
          fontSize: matchesMobile ? "20px" : "60px" }}>
          <span></span>
          <span>HEROES LEADERBOARD</span>
          <span></span>
          <KeyboardArrowDownIcon
            style={{ position: "absolute", bottom: 20, fontSize: "40px" }}
          />
        </Overlay>
      </Grid>
      

      {!matches && (
        <Grid
          item
          style={{
            width: "100%",
            padding: "2%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        > {provider ? (
          <TopHeroesLeaderboard
            // getEnsNames={getEnsNames}
            // ensContract={ensContract}
            user={address}
            leaderboardData={leaderboardData}
          />) : (
            <span
              style={{
                fontSize: matchesMobile ? "20px" : "32px",
                fontFamily: "PPNeueMachina",
                marginTop: "20px",
                fontWeight: "900",
              }}
            >
              Connect wallet to load the on-chain leaderboard
            </span>
          )}

        </Grid>
      )}
      
    </Grid>
  );
};

export default HeroLeaderboard;
