import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";

import axios from "axios";
import { experimentalStyled as styled } from "@mui/material/styles";
const StyledTag = styled("div")`
  border-radius: 32px;
  padding: 4px;
  color: #111111;
  background: #ffb631;
  min-width: 70px;
  min-height: 24px;
  text-align: center;
  margin-left: 10px;
  font-family: PPNeueMachina;
  font-weight: 900;
  font-size: 11px;
`;
export default function TopHundred(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [ensRows, setEnsRows] = useState([]);
  const fetchAndStoreLeaderBoard = async () => {
    axios
      .get("https://api.leanime.art/leaderboard/topOverall")
      .then((response) => {
        setRows(response.data.results.topOverall.data.slice(0, 100));
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newLimit = +event.target.value;

    setRowsPerPage(newLimit);
    setPage(0);
  };

  useEffect(() => {
    let ensRows = [];

    setEnsRows(ensRows);
    if (props.ensContract) {
      const ensNames = async () => {
        const data = rows;
        const addresses = data.map((d) => d.bidder);
        ensRows = await props.getEnsNames(addresses, props.ensContract);
        setEnsRows(ensRows);
      };
      ensNames();
    }
  }, [rows, props.ensContract]);

  useEffect(() => {
    if (props.user) {
      fetchAndStoreLeaderBoard();
    }
  }, [props.user]);
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {rows && rows.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              fontFamily: "PPNeueMachina",
              fontWeight: "900",
            }}
          >
            <span
              style={{
                fontSize: "32px",

                marginBottom: "1%",
              }}
            >
              TOP
              <span style={{ color: "rgba(255, 182, 49,1)" }}> 100</span>{" "}
              LEADERBOARD OVERALL (DAY 1 + DAY 2 + DAY 3)
            </span>
            <span style={{ fontSize: "20px" }}>
              {" "}
              <span>FOR BONUS NFT RANKING</span>{" "}
            </span>
          </div>
        )}
      </div>
      {rows && rows.length > 0 && (
        <>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "90%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                style={{ color: "white" }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TableContainer style={{ width: "90%", overflow: "hidden" }}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      th: {
                        color: "rgba(255, 182, 49,1)",
                        border: "1px solid rgba(17, 17, 17, 1)",
                        background: "rgba(255, 255, 255, 0.08)",
                        fontSize: "15px",
                        fontFamily: "PPNeueMachina",
                        fontWeight: 900,
                      },
                    }}
                  >
                    <TableCell>RANK</TableCell>
                    <TableCell>BIDDER</TableCell>
                    <TableCell align="center">QUANTITY</TableCell>
                    <TableCell align="center">UNIT PRICE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "td, th": {
                            color: "white",
                            border: "1px solid rgba(17, 17, 17, 1)",
                            fontSize: "14px",
                            lineHeight: "24px",
                            fontFamily: "EurostilDemi",
                            letterSpacing: "-0.01em",
                            padding: "1%",
                            background:
                              i % 2 !== 0
                                ? "rgba(255, 255, 255, 0.08)"
                                : "rgba(255, 255, 255, 0.03)",
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          # {row.rank + 1}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            {ensRows.length > 0
                              ? ensRows[i + page * rowsPerPage]
                                ? ensRows[i + page * rowsPerPage]
                                : row.bidder
                              : row.bidder}{" "}
                          </div>
                        </TableCell>
                        <TableCell align="center">{row.quantity}</TableCell>
                        <TableCell align="center">{row.unitPrice}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
    </div>
  );
}
