import React, { useState, useEffect } from "react";
import { Grid, useMediaQuery, Button } from "@mui/material/";
import { Wallet, Nav } from ".";
import { Link, useLocation } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../assets/leanime-black.png";
const Item = styled("div")({
  textAlign: "center",
  padding: "1% 0",
  width: "100%",
});
const Overlay = styled("div")({
  position: "fixed",
  top: "0",
  left: "0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
  background: "rgba(255,255,255,0.97)",
  fontFamily: "EurostilDemi",
  width: "100vw",
  height: "100%",

  fontWeight: "200",
  zIndex: 1200,
});

const Footer = () => {
  const matches = useMediaQuery("(max-width:800px)");
  const matchesSmallLaptop = useMediaQuery("(max-width:1100px)");
  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
        width: "100%",
        minHeight: "260px",
        // paddingTop: "30px",
        // fontSize: !matches ? "20px" : "10px",
        // fontFamily: "PPNeueMachina",
        // fontWeight: "900",
        // lineHeight: "150%",
        // letterSpacing: "-0.02em",
        backgroundColor: "rgb(255, 182, 49)",
        marginTop: 'auto', // Add this line
        // position at the bottom of the page always, after the last element



      }}
    >
      <Grid 
  container
  alignContent={"center"}
  style={{
    width: matchesSmallLaptop ? "80%" : "50%",
  }}
>

        <Grid 
          item xs={4} sm={4}
          style={{
            textAlign: "center",
          }}
        >
          {/* insert logo image */}
          <img src={logo} 
              alt="logo" 
              style={{
                width: "70%",
                paddingTop: "55px",
                }}/>
        </Grid>
        <Grid 
          item xs={4} sm={4}
          style={{
            textAlign: "center",
          }}
          >
          <div style={{
            paddingTop: "10px",
          }}>
            <Link to="/" style={{
              textDecoration: "none",
              color: "black",
              fontSize: "18px",
              fontFamily: "EurostilDemi",
            }}>
              Home
            </Link>
          </div>
          <div style={{
            paddingTop: "20px",
          }}>
            <Link to="/collection" style={{
              textDecoration: "none",
              color: "black",
              fontSize: "18px",
              fontFamily: "EurostilDemi",
            }}>
              Collection
            </Link>
          </div>
          <div style={{
            paddingTop: "20px",
          }}>
            <Link to="/builder" style={{
              textDecoration: "none",
              color: "black",
              fontSize: "18px",
              fontFamily: "EurostilDemi",
            }}>
              Builder
            </Link>
          </div>
          <div style={{
            paddingTop: "20px",
          }}>
            <Link to="/links" style={{
              textDecoration: "none",
              color: "black",
              fontSize: "18px",
              fontFamily: "EurostilDemi",
            }}>
              Links
            </Link>
          </div>
        </Grid>
        <Grid 
          item xs={4} sm={4}
          style={{
            textAlign: "center",
          }}
          >
          <div style={{
            paddingTop: "10px",
          }}>
            <Link to="https://twitter.com/leanimeart" style={{
              textDecoration: "none",
              color: "black",
              fontSize: "18px",
              fontFamily: "EurostilDemi",
            }}
            target="_blank">
              Twitter
            </Link>
          </div>
          <div style={{
            paddingTop: "20px",
          }}>
            <Link to="https://opensea.io/collection/le-anime-by-toomuchlag" style={{
              textDecoration: "none",
              color: "black",
              fontSize: "18px",
              fontFamily: "EurostilDemi",
            }}
            target="_blank">
              OpenSea
            </Link>
          </div>
          <div style={{
            paddingTop: "20px",
          }}>
            <Link to="https://discord.gg/mhgkeXpwSD" style={{
              textDecoration: "none",
              color: "black",
              fontSize: "18px",
              fontFamily: "EurostilDemi",
            }}
            target="_blank">
              
              Discord
            </Link>
          </div>
          <div style={{
            paddingTop: "20px",
          }}>
            <Link to="https://leanime.gitbook.io/le-anime/" style={{
              textDecoration: "none",
              color: "black",
              fontSize: "18px",
              fontFamily: "EurostilDemi",
            }}
            target="_blank"
            >
              Gitbook
            </Link>
          </div>
        </Grid>

      </Grid>
      
    </div>
  );
};

export default Footer;
