import React from "react";
import stats from "../assets/stats_sheet.jpg";
const LevelsInfo = () => {
  return (
    <div style={{ paddingTop: "100px", width: "100%" }}>
      <div style={{ width: "100%" }}>
        <img style={{ width: "100%" }} src={stats} />
      </div>
    </div>
  );
};

export default LevelsInfo;
