import { ethers } from "ethers";

///////////
///// EXPERIENCE FUNCTIONS
///////////

// number of decimals to display for exp 1'000'000 = 1 EXP
const FORMAT_DIGITS = 6;

const expStart = 1651738261; // May-05-2022 08:11:01
const expEnd = 1735689599; // Dec-31-2024 23:59:59

//return exp data for a hero
export const getExpData = async (expContract, heroId) => {
    let expData = await expContract.getHeroExpData(heroId);

    // FORMATTING FOR DISPLAY

    // format exp balance with FORMAT_DIGITS decimals
    let expBalance = ethers.utils.formatUnits(
        expData.expAvailable,
        FORMAT_DIGITS
    );
    // exp balance add comma separator every 3 digits
    expBalance = expBalance.split(".")[0];
    expBalance = expBalance.replace(/\B(?=(\d{3})+(?!\d))/g, "'");

    let expClaimable = await expContract.calculateClaimableExp(heroId);
    // convert exp claimable to string
    expClaimable = ethers.utils.formatUnits(expClaimable, FORMAT_DIGITS);
    // expClaimable remove . and add comma separator every 3 digits
    expClaimable = expClaimable.split(".")[0];
    expClaimable = expClaimable.replace(/\B(?=(\d{3})+(?!\d))/g, "'");


    let {dailyExp, monthlyExp } = await calculateDailyMonthlyExp(parseInt(expData.score));

    return { expBalance, expClaimable, dailyExp, monthlyExp };
};

export const claimExp = async (expContract, heroId) => {
    let claimTx = await expContract.claimExp(heroId);

    return claimTx.wait();
};

///////////
///// EMPORIUM FUNCTIONS
///////////

// export const getHeroAvailableItems = async (emporiumContract, heroId) => {

//     let expCost1 = "1000"
//     let expCost2 = "5000000"
//     let expCost3 = "500000"
//     let expCost4 = "3999000"
//     expCost1 = expCost1.split(".")[0];
//     expCost1 = expCost1.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
//     expCost2 = expCost2.split(".")[0];
//     expCost2 = expCost2.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
//     expCost3 = expCost3.split(".")[0];
//     expCost3 = expCost3.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
//     expCost4 = expCost4.split(".")[0];
//     expCost4 = expCost4.replace(/\B(?=(\d{3})+(?!\d))/g, "'");

//     let heroInventory = [
//         { index: 0, layer: 1, id: 1, expCost: expCost1, qtyAvailable: 10 },
//         { index: 1, layer: 2, id: 1, expCost: expCost2, qtyAvailable: 3 },
//         { index: 2, layer: 3, id: 1, expCost: expCost3, qtyAvailable: 5 },
//         { index: 3, layer: 3, id: 2, expCost: expCost4, qtyAvailable: 100 },
//         // { index: 0, layer: 1, id: 1, expCost: expCost1, qtyAvailable: 10 },
//         // { index: 1, layer: 2, id: 1, expCost: expCost2, qtyAvailable: 3 },
//         // { index: 2, layer: 3, id: 1, expCost: expCost3, qtyAvailable: 5 },
//         // { index: 3, layer: 3, id: 2, expCost: expCost4, qtyAvailable: 100 },
//     ];

//     return heroInventory;
// };

// this function will return the inventory of the emporium for every hero, heroId is not used but will be used in the future
export const getHeroAvailableItems = async (emporiumContract, heroId) => {
    let inventory = await emporiumContract.getInventory();

    // convert inventory into array of array
    let inventoryArray = [];
    let item = {};
    for (let i = 0; i < inventory.length; i++) {
        let expCost = ethers.utils.formatUnits(
            inventory[i].expCost,
            FORMAT_DIGITS
        );
        expCost = expCost.split(".")[0];
        expCost = expCost.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
        item = {
            index: i,
            layer: parseInt(inventory[i].layer),
            id: parseInt(inventory[i].id),
            expCost: expCost,
            qtyAvailable: parseInt(inventory[i].qtyAvailable),
        };

        inventoryArray.push(item);
    }
    return inventoryArray;
};

// function to get the new item from the emporium spending EXP
export const getNewItem = async (emporiumContract, heroId, itemIndex) => {
    // check exp balance is enough
    // remember the price is in exp with 3 or 6 decimals
    let tx = await emporiumContract.getNewItem(heroId, itemIndex, ethers.constants.AddressZero);

    return tx.wait();
};

// Experience Stats

const  calculateBonusExp = async (score) => {
    let bonus = 0;
    if (score >= 100000) bonus = 50;
    else if (score >= 50000) bonus = 45;
    else if (score >= 25000) bonus = 32;
    else if (score >= 10000) bonus = 25;
    else if (score >= 5000) bonus = 20;
    else if (score >= 2500) bonus = 16;
    else if (score >= 1000) bonus = 13;
    else if (score >= 500) bonus = 10;
    else if (score >= 250) bonus = 8;
    else if (score >= 100) bonus = 6;
    else if (score >= 50) bonus = 4;
    else if (score >= 25) bonus = 2;
    else bonus = 0;
    // else if(score > 10) bonus = 2;
    return bonus;
}

const calculateClaimableExp = async (score, lastTimestamp, timestamp) => {
    let currentTimestamp = timestamp > expEnd ? expEnd : timestamp;

    let bonus = await calculateBonusExp(score);

    let expMultiplier = score * (100 + bonus);
    if (expMultiplier > 150000 * 100) {
        expMultiplier = 150000 * 100;
    }

    let deltaT = currentTimestamp - lastTimestamp;
    let deltaT2 = currentTimestamp - expStart;
    let deltaT1 = lastTimestamp - expStart;
    let duration = expEnd - expStart;

    let expToClaim =
        expMultiplier * deltaT -
        (expMultiplier * (deltaT2 * deltaT2 - deltaT1 * deltaT1)) /
            duration /
            4;

    return expToClaim;
}

const calculateDailyMonthlyExp = async (score) => {
    let nowTimestamp = Math.floor(Date.now() / 1000);
    let secondsInDay = 24 * 60 * 60;
    let secondsInMonth = secondsInDay * 30;

    // console.log(score, nowTimestamp, nowTimestamp + secondsInDay)

    let dailyExp = await calculateClaimableExp(score, nowTimestamp, nowTimestamp + secondsInDay);
    let monthlyExp = await calculateClaimableExp(score, nowTimestamp, nowTimestamp + secondsInMonth);

    // console.log(dailyExp, monthlyExp) 

    dailyExp = ethers.utils.formatUnits(parseInt(dailyExp), FORMAT_DIGITS);
    dailyExp = dailyExp.split(".")[0];
    dailyExp = dailyExp.replace(/\B(?=(\d{3})+(?!\d))/g, "'");

    monthlyExp = ethers.utils.formatUnits(parseInt(monthlyExp), FORMAT_DIGITS);
    monthlyExp = monthlyExp.split(".")[0];
    monthlyExp = monthlyExp.replace(/\B(?=(\d{3})+(?!\d))/g, "'");

    // console.log("dailyExp", dailyExp);
    // console.log("monthlyExp", monthlyExp);
    // console.log(score)

    return {dailyExp, monthlyExp}
    
}

///////////
///// ADDITIONAL LAYERS FUNCTIONS
///////////
//This function returns all the available additional layers for a hero in format [[1,1],[2,1]]

export const getHeroAdditionalLayers = async (
    addLayersContract,
    lockerContract,
    heroId
) => {
    let soulsInHero = await lockerContract.getSoulsInHero(heroId);

    let tokenIds = [heroId].concat(soulsInHero);

    // console.log(await token.name(), 'tokens owned by', account);

    let logs = await addLayersContract.queryFilter(
        addLayersContract.filters.NewAddLayer(tokenIds, null)
    );

    const addLayers = [];

    for (const log of logs) {
        const { newLayer } = log.args;
        addLayers.push([parseInt(newLayer.layer), parseInt(newLayer.id)]);
    }
    //remove duplicates in addLayers
    const uniqueAddLayers = [...new Set(addLayers.map(JSON.stringify))].map(
        JSON.parse
    );

    // order uniqueAddLayers from lowest to highest first element and then second element
    uniqueAddLayers.sort(function (a, b) {
        return a[0] - b[0] || a[1] - b[1];
    });

    return uniqueAddLayers;
};

// additional layers previews:
// https://leanime.art/heroes/additional_layers/previews/1_1.jpg
// https://leanime.art/heroes/additional_layers/previews/2_1.jpg
