import React, { useEffect, useState, useCallback, useRef } from "react";

import { Link } from "react-router-dom";

import { ethers } from "ethers";
import { useAccount, useSigner, useProvider, useNetwork } from "wagmi";

import {
  ZENITH_MINT_ADDRESS,
  ZENITH_MINT_ABI,
} from "../../config/configZenith.js";
import {
    WRAPPER_ABI,
    WRAPPER_ADDRESS,
    SOULSLOCKER_ABI,
    SOULSLOCKER_ADDRESS,
} from "../../config/configMerging.js";
import {
    calculateRedeemableExp,
    spiritsToMint,
    approveSacrifice,
    isSacrificeApprovedCheck,
    // getSacrificeCounter,
    sacrificeActive,
    getSpiritsEditionsCounter,
    getEthEditionsCounter,
} from "../../helpers/zenithHelpers.js";

import "./style.css";
import {
    Grid,
    useMediaQuery,
    Input,
    Button,
    Modal,
    TextField,
    CircularProgress,
    Snackbar,
    Stack,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { listAnimeSpiritsHeroesOfOwner_separate } from "../../helpers/mergerHelpers.js";
import Fade from "@mui/material/Fade";

import zenithImage from "../../assets/zenith_website_V2.png";
import zenithVideo from "../../assets/toomuchlag_Zenith_50mb.mp4";



const ZenithBurn = () => {
    //wagmi hooks for account, signer, provider and network
    let { address, isConnected } = useAccount();
    const provider = useProvider();

    const targetTimestamp = 1700502335;
    
    const { data: signer, isError, isLoading } = useSigner();
    const {chain} = useNetwork()

    const matchesExtraLarge = useMediaQuery("(max-width:1600px)");
    const matchesLarge = useMediaQuery("(max-width:1500px)");
    const matchesMedium = useMediaQuery("(max-width:1200px)");
    const matchesSmall = useMediaQuery("(max-width:1024px)");
    const matchesMobile = useMediaQuery("(max-width:600px)");

    const [isChainSupported, setIsChainSupported] = useState(false)

    const [zenithOpen, setZenithOpen] = useState(true);

    const [allHeroes, setAllHeroes] = useState([]);
    const [allSpirits, setAllSpirits] = useState([]);

    const [sacrificeOpen, setSacrificeOpen] = useState(false);
    const [selectedHero, setSelectedHero] = useState(null);
    const [selectedSpirits, setSelectedSpirits] = useState([]);
    const [expToGain, setExpToGain] = useState(0);
    const [sacrificeCounter, setSacrificeCounter] = useState(0);

    const [editionsRemaining, setEditionsRemaining] = useState(null);

    const [confirmedSacrifice, setConfirmedSacrifice] = useState(false);
    const [isSacrificeActive, setIsSacrificeActive] = useState(false);

    const [fallenSpirits, setFallenSpirits] = useState([]);

    const [dayLeft, setDayLeft] = useState(0);
    const [hourLeft, setHourLeft] = useState(0);
    const [minuteLeft, setMinuteLeft] = useState(0);

    // CONTRACTS
    const [wrapperContract, setWrapperContract] = useState(null);
    const [lockerContract, setLockerContract] = useState(null);
    const [sacrificeContract, setSacrificeContract] = useState(null);

    const [isSacrificeApproved, setIsSacrificeApproved] = useState(false);

    const [isApproveLoading, setIsApproveLoading] = useState(false);

    const [isSacrificeLoading, setIsSacrificeLoading] = useState(false);

    const [openCelebrationModal, setOpenCelebrationModal] = useState(false);

    const [notification, setNotification] = useState({});

    const [videoSrc, setVideoSrc] = useState(null);



    // Refresh sacrificeActive and sacrificeCounter every 30 seconds
    function useInterval(callback, delay) {
      const savedCallback = useRef();
    
      // Remember the latest callback.
      useEffect(() => {
        savedCallback.current = callback;
      }, [callback]);
    
      // Set up the interval.
      useEffect(() => {
        function tick() {
          savedCallback.current();
        }
        if (delay !== null) {
          let id = setInterval(tick, delay);
          return () => clearInterval(id);
        }
      }, [delay]);
    }
    
    useInterval(async () => {
      if (signer && address && provider && isChainSupported) {
        const sacrificeActive_ = await sacrificeActive(sacrificeContract);
        setIsSacrificeActive(sacrificeActive_);
      }
      const spiritsEditionsRemaining = await getSpiritsEditionsCounter(
        sacrificeContract,
        ZENITH_MINT_ADDRESS
    );

      setEditionsRemaining(spiritsEditionsRemaining);

      if(spiritsEditionsRemaining == 0) {
        setIsSacrificeActive(false);
      }
   
    }, 30000); // 60000 milliseconds = 60 seconds

    // USE EFFECTS
    useEffect(() => {
        //STYLE
        document.body.style.background = ` linear-gradient(rgba(0,0,0,0.9),rgba(0,0,0,0.9)), url(${zenithImage})`;
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundSize = "cover";
        document.body.style.backgroundColor = "#000000";
        
        return () => {
            document.body.style.background = "#111111";
            // Clear interval on component unmount
            // return () => clearInterval(interval);
        };
    }, []);

    // useEffect(() => {
    //   if (sacrificeCounter)
    //   {
    //     if (sacrificeCounter < 250) {
    //       let editionsRemaining = parseInt((249 - sacrificeCounter) / 10) + 1;
    //       setEditionsRemaining(editionsRemaining);
    //     } else {
    //       setEditionsRemaining(0);
    //     }
    //   }
    // }, [sacrificeCounter]);

    useEffect(() => {
        if (signer && address && provider && isChainSupported) {
            const sacrificeContract = new ethers.Contract(
                ZENITH_MINT_ADDRESS,
                ZENITH_MINT_ABI,
                signer
            );
            setSacrificeContract(sacrificeContract);

            const wrapperContract = new ethers.Contract(
                WRAPPER_ADDRESS,
                WRAPPER_ABI,
                signer
            );
            setWrapperContract(wrapperContract);

            const lockerContract = new ethers.Contract(
                SOULSLOCKER_ADDRESS,
                SOULSLOCKER_ABI,
                signer
            );
            setWrapperContract(wrapperContract);

            const setAllHeroesAndSpiritsFunction = async () => {
                let allTokens = await listAnimeSpiritsHeroesOfOwner_separate(
                    address,
                    wrapperContract,
                    lockerContract
                );

                let allHeroes = allTokens.heroIDs;

                const sortedallHeroes = allHeroes.sort((a, b) => {
                    return a.id - b.id;
                });

                setAllHeroes(sortedallHeroes);
                // let heroTemp = {
                //     id: "100414",
                //     soulNumber: 414,
                //     traits: "PLACEHOLDER",
                // };
                // let tempHeroes = [
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                // ];
                // setAllHeroes(tempHeroes);

                let allSouls = allTokens.tokenIDs;

                const sortedAllSouls = allSouls.sort((a, b) => {
                    return a.id - b.id;
                });

                // remove all ids < 1574 - Spirits Only
                const filteredAllSpirits = sortedAllSouls.filter((soul) => {
                    return soul.soulNumber >= 1574;
                });

                // sort filtered spirits by .traits[0][1] ascending
                filteredAllSpirits.sort((a, b) => {
                    return a.traits[0][1] - b.traits[0][1];
                });

                let currentTimestamp = Math.floor(Date.now() / 1000);

                // add experience to each spirit
                filteredAllSpirits.forEach((spirit) => {
                    spirit.exp = calculateRedeemableExp(
                        [spirit.soulNumber],
                        currentTimestamp
                    );
                });

                setAllSpirits(filteredAllSpirits);

                // let tempSpirits = [];
                // setAllSpirits(tempSpirits);

                // for i from 2001 to 2010 create a spirit with id i+10000 and soulNumber i, and add it to tempSpirits
                // for (let i = 2000; i <= 2042; i++) {
                //     let spiritTemp = {
                //         id: i + 100000,
                //         soulNumber: i,
                //         traits: "PLACEHOLDER",
                //     };
                //     tempSpirits.push(spiritTemp);
                // }

                // setAllSpirits(tempSpirits);

                const sacrificeActive_ = await sacrificeActive(sacrificeContract)
                setIsSacrificeActive(sacrificeActive_);

                const isSacrificeApprovedBool = await isSacrificeApprovedCheck(
                    address,
                    wrapperContract,
                    ZENITH_MINT_ADDRESS
                );

                setIsSacrificeApproved(isSacrificeApprovedBool);

                const spiritsEditionsRemaining = await getSpiritsEditionsCounter(
                    sacrificeContract,
                    ZENITH_MINT_ADDRESS
                );

                setEditionsRemaining(spiritsEditionsRemaining);

                if(spiritsEditionsRemaining == 0) {
                  setIsSacrificeActive(false);
                }

            };

            setAllHeroesAndSpiritsFunction();

          //   const setAllFallenSpirits = async () => {
          //     let allTokens = await listAnimeSpiritsHeroesOfOwner_separate(
          //         ZENITH_MINT_ADDRESS,
          //         wrapperContract,
          //         lockerContract
          //     );

          //     // let allHeroes = allTokens.heroIDs;

          //     // const sortedallHeroes = allHeroes.sort((a, b) => {
          //     //     return a.id - b.id;
          //     // });

          //     // setAllHeroes(sortedallHeroes);

          //     let allSouls = allTokens.tokenIDs;

          //     const sortedAllSouls = allSouls.sort((a, b) => {
          //         return a.id - b.id;
          //     });

          //     // remove all ids < 1574 - Spirits Only
          //     const filteredAllSpirits = sortedAllSouls.filter((soul) => {
          //         return soul.soulNumber >= 1574;
          //     });

          //     // sort filtered spirits by .traits[0][1] ascending
          //     filteredAllSpirits.sort((a, b) => {
          //         return a.traits[0][1] - b.traits[0][1];
          //     });

          //     // console.log("filteredAllSpirits", filteredAllSpirits)
          //     // console.log("filteredAllSpirits length", filteredAllSpirits.length)

          //     // from filteredAllSpirits, create an array with element.soulNumber
          //     // const filteredAllSpiritsSoulNumbers = filteredAllSpirits.map((spirit) => {
          //     //   return spirit.soulNumber;
          //     // });
          //     // console.log("filteredAllSpiritsSoulNumbers", filteredAllSpiritsSoulNumbers)

          //     setFallenSpirits(filteredAllSpirits);
          // };
          // setAllFallenSpirits();
        }

        
    }, [signer, address, provider, isChainSupported]);

    useEffect(() => {
      if (typeof chain !== "undefined" && typeof chain.unsupported !== "undefined") {
        setIsChainSupported(!chain.unsupported)
      }
      // console.log("chain changed", chain)
    }, [chain])

    useEffect(() => {
        // let currentTimestamp = Math.floor(Date.now() / 1000);
    }, [selectedSpirits]);

    const openTalanji = () => {
        setZenithOpen(true);
    };
    const handleImageClick = () => {
      setVideoSrc(zenithVideo);
    };
    // SACRIFICE MODAL FUNCTIONS
    const handleSetDefaultImage = (e, id) => {
        e.target.src = `https://leanime.art/heroes/m/${id}.jpg`;
    };

    const handleReload = () => {
      window.location.reload();
  };
    // const handlesetSelectedHero = (heroID) => {
    //     setSelectedHero({
    //         id: heroID,
    //     });
    // };

    const handleSelectSpirits = (soulNumber) => {
        const found = selectedSpirits.find((obj) => obj === soulNumber);
        if (!found) {
            // all selected + maintoken [0]
            setSelectedSpirits([...selectedSpirits, soulNumber]);
        } else {
            //deslecting when all selected
            // if (isSelectedAllNfts) {
            //   setIsSelectedAllNfts(false);
            // }

            const filteredSelectedSpirits = selectedSpirits.filter(
                (obj) => obj !== soulNumber
            );

            setSelectedSpirits(filteredSelectedSpirits);
        }
    };

    const handleConfirmSacrifice = () => {
        setConfirmedSacrifice(true);
    };

    const handleApproveSacrifice = () => {
        setIsApproveLoading(true);
        approveSacrifice(wrapperContract, ZENITH_MINT_ADDRESS)
            .then(() => {
                setIsSacrificeApproved(true);
                setIsApproveLoading(false);
                setNotification({
                    state: true,
                    message: "Sacrifice contract succesfully approved",
                    type: "success",
                });
            })
            .catch((err) => {
                setIsApproveLoading(false);
                setIsSacrificeApproved(false);
                setNotification({
                    state: true,
                    message: err.data ? err.data.message : err.message,
                    type: "error",
                });
            });
    };

    const handleSacrificeTransaction = () => {
        setIsSacrificeLoading(true);

        // add 100000 to all selected spirits
        let selectedSpiritsToExp = selectedSpirits.map((spirit) => {
            return spirit + 100000;
        });
        spiritsToMint(sacrificeContract, selectedSpiritsToExp)
            .then(() => {
                // setSacrificeOpen(false);
                setOpenCelebrationModal(true);
                setIsSacrificeLoading(false);
                setNotification({
                    state: true,
                    message: "Sacrifice & Mint succesfully completed",
                    type: "success",
                });
            })
            .catch((err) => {
                setIsSacrificeLoading(false);
                setNotification({
                    state: true,
                    message: err.data ? err.data.message : err.message,
                    type: "error",
                });
            });
    };

    const handleCloseSacrifice = () => {
        setSacrificeOpen(false);
        setSelectedHero(null);
        setConfirmedSacrifice(false);
        setSelectedSpirits([]);
        setOpenCelebrationModal(false);
        handleReload();
    };

    const handleDismissSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setNotification({ status: false });
    };
    return (
        <Grid
            container
            direction="column"
            justifyContent="start"
            alignItems="center"
            style={{
                paddingTop: "100px",
                width: "100%",
                height: "100%",
            }}
        >
            <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                    autoHideDuration={2000}
                    open={notification.state && notification.type === "error"}
                    onClose={handleDismissSnackBar}
                    key={"error"}
                >
                    <MuiAlert
                        onClose={handleDismissSnackBar}
                        variant="filled"
                        severity="error"
                    >
                        {notification.message}
                    </MuiAlert>
                </Snackbar>
                <Snackbar
                    autoHideDuration={2000}
                    open={notification.state && notification.type === "success"}
                    onClose={handleDismissSnackBar}
                    key={"success"}
                >
                    <MuiAlert
                        onClose={handleDismissSnackBar}
                        variant="filled"
                        severity="success"
                    >
                        {notification.message}
                    </MuiAlert>
                </Snackbar>
            </Stack>
            <Modal
                disableScrollLock={true}
                style={{ border: "none" }}
                open={sacrificeOpen}
                onClose={handleCloseSacrifice}
            >
                <Fade in={sacrificeOpen}>
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: matchesMobile ? "100%" : "70%",
                            height: "80%",
                            // maxHeight: "80%",
                            background: "#111111",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "center",
                            borderRadius: "10px",
                            outline: "none",
                            paddingTop: "25px",
                            color: "white",
                            overflowY: "auto",
                            overflowX: "hidden",
                        }}
                    >
                        {!confirmedSacrifice && !openCelebrationModal  && (
                            <div
                                style={{
                                    width: "90%",
                                    height: "100%",
                                    textAlign: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "start",
                                }}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        marginBottom: "0px",
                                    }}
                                >
                                    
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "PPNeueMachina",
                                            fontSize: matchesMobile? "14px" : matchesSmall? "16px" : "18px",
                                            textAlign: "center",
                                            color: "#ffb631",
                                        }}
                                    >
                                        STEP 1: SELECT THE SPIRITS YOU WANT TO SACRIFICE
                                    </Grid>
                                    
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="start"
                                    alignItems="start"
                                    spacing={matchesMobile ? 1 : 2}
                                    style={{
                                        marginTop: "20px",
                                        marginBottom: "20px",
                                        width: "100%",
                                        height: matchesMobile ? "70%" : "80%",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                    }}
                                >
                                  {allSpirits &&
                                      allSpirits.map((t, i) => {
                                          const isSelected =
                                              selectedSpirits.find(
                                                  (obj) =>
                                                      obj === t.soulNumber
                                              );
                                          return (
                                              <Grid
                                                  item
                                                  xs = {matchesMobile ? 6 : matchesSmall? 3 : 1.5}
                                                  key={t.soulNumber}
                                                  style={{
                                                      display: "flex",
                                                      flexDirection:
                                                          "column",
                                                      alignItems:
                                                          "center",
                                                      justifyContent:
                                                          "center",
                                                          // fontSize: matchesMobile? "12px" : "8px",
                                                  }}
                                              >
                                                  <img
                                                      onClick={() =>
                                                          handleSelectSpirits(
                                                              t.soulNumber
                                                          )
                                                      }
                                                      style={{
                                                          width: "100%",
                                                          cursor: "pointer",
                                                          border: "2px solid transparent",
                                                          borderColor:
                                                              isSelected
                                                                  ? "#ffb631"
                                                                  : "transparent",
                                                          boxSizing:
                                                              "border-box",
                                                      }}
                                                      src={`https://leanime.art/heroes/m/${t.soulNumber}.jpg`}
                                                  />{" "}
                                                  <span
                                                      style={{
                                                          marginTop:
                                                              "2px",
                                                      }}
                                                  >
                                                      #{t.soulNumber}
                                                  </span>
                                                  <span
                                                      style={{
                                                          marginTop:
                                                              "3px",
                                                              fontSize: "12px",
                                                      }}
                                                  >
                                                    {t.exp} EXP
                                                  </span>
                                              </Grid>
                                          );
                                      })}
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        marginBottom: "15px",
                                    }}
                                >
                                    <Grid
                                        item
                                        xs={matchesMobile ? 12 : 6}
                                        style={{
                                            fontFamily: "PPNeueMachina",
                                            fontSize: matchesMobile? "14px" : "16px",
                                            textAlign: "center",
                                            color: "#ffb631",
                                        }}
                                    >
                                        SELECT 3 SPIRITS TO SACRIFICE
                                    </Grid>
                                    
                                    <Grid item xs={matchesMobile ? 12 : 6}>
                                        <Button
                                            onClick={handleConfirmSacrifice}
                                            disabled={
                                                selectedSpirits.length !== 3
                                            }
                                            style={{
                                                background:
                                                  selectedSpirits.length !== 3
                                                        ? "rgba(255, 182, 49,0.5)"
                                                        : "#ffb631",
                                                color: "black",
                                                width: "250px",
                                                padding: "10px 0",
                                                margin: "10px 0",
                                                borderRadius: 0,
                                                fontSize: "16px",
                                                fontFamily: "EurostilDemi",
                                            }}
                                        >
                                            CONFIRM
                                        </Button>
                                    </Grid>
                                    
                                    
                                </Grid>
                            </div>
                        )}

                        {confirmedSacrifice && !openCelebrationModal && (
                            <div
                                style={{
                                    width: "90%",
                                    height: "100%",
                                    textAlign: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "start",
                                }}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        marginBottom: "0px",
                                    }}
                                >
                                    {/* <Grid item xs={3}></Grid> */}
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "PPNeueMachina",
                                            fontSize: matchesMobile? "14px" : matchesSmall? "16px" : "18px",
                                            textAlign: "center",
                                            color: "#ffb631",
                                        }}
                                    >
                                        STEP 2: REVIEW AND CONFIRM
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="start"
                                    alignItems="start"
                                    spacing={matchesMobile ? 1 : 2}
                                    style={{
                                        marginTop: "20px",
                                        marginBottom: "20px",
                                        width: "100%",
                                        maxHeight: matchesMobile ? "60%" : "70%",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                    }}
                                >
                                        {selectedSpirits &&
                                            selectedSpirits.map((t, i) => {
                                                return (
                                                    <Grid
                                                        item
                                                        xs = {matchesMobile ? 6 : matchesSmall? 3 : 1.5}
                                                        key={t}
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                width: "100%",
                                                                boxSizing:
                                                                    "border-box",
                                                            }}
                                                            src={`https://leanime.art/heroes/m/${t}.jpg`}
                                                        />{" "}
                                                        <span
                                                            style={{
                                                                marginTop:
                                                                    "5px",
                                                            }}
                                                        >
                                                            #{t}
                                                        </span>
                                                    </Grid>
                                                );
                                            })}
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        marginBottom: "15px",
                                    }}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "PPNeueMachina",
                                            fontSize: matchesMobile? "14px" : matchesSmall? "16px" : "20px",
                                            textAlign: "center",
                                            // cursor: "pointer",
                                            color: "#ffb631",
                                        }}
                                    >
                                        {/* EXP GAINED BY HERO #
                                        {selectedHero.id}:{matchesMobile? <br/> : " "}
                                        <span style={{ color: "#FFFFFF" }}>
                                            {" "}
                                            {expToGain} EXP{" "}
                                        </span> */}
                                        {/* {editionsToMint > 0 ? `+ ${editionsToMint} EDITION` : "+ 0 EDITIONS"}{editionsToMint > 1 ? "S" : ""} */}
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        marginBottom: "10px",
                                    }}
                                >   
                                {editionsRemaining > 0 && (
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "PPNeueMachina",
                                            fontSize: matchesMobile? "12px" : "14px",
                                            textAlign: "center",
                                            // cursor: "pointer",
                                            color: "white",
                                        }}
                                    >
                                         FINALIZE SACRIFICE AND RECEIVE AN EDITION
                                    </Grid>)}
                                    {editionsRemaining == 0 && (<Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "PPNeueMachina",
                                            fontSize: matchesMobile? "16px" : "18px",
                                            textAlign: "center",
                                            // cursor: "pointer",
                                            color: "white",
                                        }}
                                    >
                                      SOLD OUT  - NO MORE EDITIONS
                                    </Grid>)
                                    
                                    }
                                    
                                </Grid>
                                {!isSacrificeApproved && isSacrificeActive && (
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{
                                            width: "100%",
                                            marginBottom: "5px",
                                        }}
                                    >
                                        <Grid item>
                                            <Button
                                                onClick={handleApproveSacrifice}
                                                style={{
                                                    background:
                                                        "#ffb631",
                                                    color: "black",
                                                    width: "250px",
                                                    padding: "10px 0",
                                                    marginTop: "10px",
                                                    // margin: "10px 0",
                                                    borderRadius: 0,
                                                    fontSize: "16px",
                                                    fontFamily: "EurostilDemi",
                                                }}
                                                disabled={isSacrificeApproved}
                                            >
                                                {isApproveLoading ? (
                                                    <CircularProgress  size={28}/>
                                                ) : (
                                                    "1: APPROVE CONTRACT"
                                                )}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        marginBottom: "5px",
                                    }}
                                >
                                  {isSacrificeActive && (
                                    <Grid item>
                                        <Button
                                            onClick={handleSacrificeTransaction}
                                            style={{
                                                background: isSacrificeApproved
                                                    ? "#ffb631"
                                                    : "rgba(255, 182, 49,0.5)",
                                                color: "black",
                                                width: "250px",
                                                padding: "10px 0",
                                                marginTop: "10px",
                                                // margin: "10px 0",
                                                borderRadius: 0,
                                                fontSize: "16px",
                                                fontFamily: "EurostilDemi",
                                            }}
                                            disabled={!isSacrificeApproved}
                                        >
                                            {isSacrificeLoading ? (
                                                <CircularProgress size={28} />
                                            ) : isSacrificeApproved ? (
                                                "1: SACRIFICE SPIRITS"
                                            ) : (
                                                "2: SACRIFICE SPIRITS "
                                            )}
                                        </Button>
                                    </Grid>)}
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "PPNeueMachina",
                                            fontSize: "14px",
                                            textAlign: "center",
                                            // cursor: "pointer",
                                            color: "#ff4136",
                                            paddingTop: "20px",
                                            paddingBottom: "0px",
                                        }}
                                    >
                                        WARNING: NFTS SACRIFICED ARE LOST FOREVER!
                                    </Grid>
                                </Grid>
                            </div>
                        )}

                        {openCelebrationModal && (
                            <div
                                style={{
                                    width: "90%",
                                    height: "100%",
                                    textAlign: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        marginBottom: "0px",
                                    }}
                                >
                                    {/* <Grid item xs={3}></Grid> */}
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "PPNeueMachina",
                                            fontSize: "22px",
                                            textAlign: "center",
                                            color: "#ffffff",
                                            marginBottom: "20px",
                                        }}
                                      >
                                        CONGRATULATIONS, THE MINT WAS SUCCESSFUL <br/>
                                        </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                          fontFamily: "PPNeueMachina",
                                          fontSize: "22px",
                                          textAlign: "center",
                                          color: "#ffffff",
                                          marginBottom: "20px",
                                      }}
                                      >

                                        {/* {editionsToMint > 0 ? `+ ${editionsToMint} EDITION` : ""}{editionsToMint > 1 ? "S" : ""} */}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "EurostilNormal",
                                            fontSize: "15px",
                                            textAlign: "center",
                                            // italic
                                            fontStyle: "italic",
                                            color: "#ffffff",
                                            marginBottom: "20px",
                                        }}
                                    >
                                    {/* Talanji feasts on your Spirits, the echo of the ancestors resonates, <br/> soon illuminating the one who will inherit the ancient power. */}
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        // marginBottom: "10px",
                                    }}
                                >
                                    
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        marginBottom: "5px",
                                    }}
                                >
                                    <Grid item>
                                        <Button
                                            onClick={handleCloseSacrifice}
                                            style={{
                                                background: "#ffb631",
                                                color: "black",
                                                width: "280px",
                                                padding: "10px 0",
                                                marginTop: "15px",
                                                // margin: "10px 0",
                                                borderRadius: 0,
                                                fontSize: "20px",
                                                fontFamily: "EurostilDemi",
                                            }}
                                        >
                                            {"CLOSE"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        )}

                        
                    </div>
                </Fade>
            </Modal>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                    width: "100%",
                }}
            >
                
                
                    <>
                        <span
                            style={{
                                fontSize: matchesMobile? "20px" : matchesMedium
                                    ? "25px"
                                    : matchesSmall
                                    ? "20px"
                                    : "32px",
                                fontFamily: "PPNeueMachina",
                                marginBottom: "5px",
                                fontWeight: "900",
                                color: "#ffb631",
                            }}
                        >
                            
                          Zenith
                        </span>
                        {/* <span
                            style={{
                                fontSize:  matchesMobile? "16px" :matchesMedium
                                    ? "25px"
                                    : matchesSmall
                                    ? "20px"
                                    : "32px",
                                fontFamily: "PPNeueMachina",
                                marginBottom: "5px",
                                fontWeight: "900",
                                color: "#ff4136",
                            }}
                        >
                            Raffle entries are now closed!
                        </span> */}
                        <p
                            style={{
                                fontSize: matchesSmall ? "12px" : "14px",
                                // margin: "12px 0",
                                letterSpacing: 2,
                                fontWeight: 100,
                                width: matchesMobile ? "80%" : "50%",
                                fontFamily: "EurostilNormal",
                                whiteSpace: "pre-line",
                                textAlign: "center",
                                fontStyle: "italic",
                            }}
                        >
                           High above the clouds, hidden from the eyes of the unworthy, <br/> lies an ancient kingdom of unparalleled majesty. <br/> <br/>Guarded by twin giants whose stone form looms over the kingdom, <br/> it is home to the wisest minds in the known universe.{" "}
                            
                        </p>
                        {zenithOpen && (
                            <div style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "80%",
                              paddingTop: "25px",
                            }}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="start"
                                    style={{ width: "100%" }}
                                >
                                    <Grid 
                                      item xs={12} sm={12} md={12}
                                      style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                            maxWidth: "700px",
                                            // marginTop: "20px",
                                        }}>
                                    <div className="videoContainer">
                                      <img
                                          style={{
                                              width: "100%",
                                              marginTop: "0%",
                                          }}
                                          onClick={handleImageClick}
                                          src={zenithImage}
                                      />
                                      {videoSrc && (
                                      <video controls autoPlay style={{ width: '100%', height: '100%', objectFit: 'contain' }} >
                                        <source src={videoSrc} type="video/mp4" />
                                      </video>)}
                                    </div>
                                    <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                            color: "grey",
                                            fontSize: "12px",
                                            fontWeight: 100,
                                            fontFamily: "EurostilNormal", 
                                            fontStyle: "italic",
                                            paddingTop: "5px",
                                            paddingBottom: "0px",
                                        }}>
                                          {"Click to play video"}
                                    </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ marginTop: "0px" }}
                                    >
                                     
                                    
                                      <div
                                        style={{
                                           marginTop: "50px",
                                            fontSize: matchesMobile ? "20px" : "24px",
                                            fontFamily: "EurostilDemi",
                                            color: "#ffb631",
                                            textAlign: "center",
                                            fontStyle: "italic", 
                                          }}
                                      >
                                      
                                        <span> Limited Edition </span>
                                      </div>

                                    

                                    <div
                                        style={{
                                           marginTop: "20px",
                                            fontSize: matchesMobile ? "16px" : "16px",
                                            fontFamily: "EurostilDemi",
                                            color: "#ffffff",
                                            textAlign: "center",
                                            fontStyle: "bold",
                                          }}
                                      >
                                        3 Spirits Sacrificed = 1 Edition of Zenith <br/>
                                        <br/>
                                        Only 20 Editions available via burn - first come first served <br/>
                                        <br/>
                                        20 extra editions are available  <Link style={{ color: "#ffb631" }} target="_blank" to="/zenith-mint">here</Link>
                                        {" "} via 0.1 ETH Sale <br/>
                                        {/* will receive an edition of "The Conclave". <br/> */}
                                        {/* span in eurostilnormal */}
                                        <br/>
                                        {/* <span style={{fontFamily: "EurostilNormal", fontSize: "14px"}}>
                                          There are 31 winning spots, but 32 editions..
                                        </span> */}
                                      </div>

                                    </Grid>
                                    {/* <Grid
                                        item
                                        xs={12} sm={12} md={6}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "100%",
                                                margin: "20px 0",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: matchesSmall
                                                        ? "20px"
                                                        : "22px",

                                                    letterSpacing: 1,
                                                    color: "#ffb631",
                                                    fontWeight: 100,
                                                    fontFamily:
                                                        "PPNeueMachina",
                                                }}
                                            >
                                                {"HOW IT WORKS"}
                                            </span>{" "}
                                        </div>

                                        <div
                                            style={{
                                                padding: "10px",
                                                alignSelf: "start",
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: "16px",
                                                    // letterSpacing: 2,
                                                    fontWeight: 100,
                                                    fontFamily: "EurostilNormal",
                                                    // color: "#ffb631",
                                                    marginBottom: "10px",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                
                                                    {"• Talanji is a special 1/1 NFT and Smart Contract that feeds on "}
                                                    <a
                                                      style={{
                                                        // color: "#ffb631",
                                                        color: "white",
                                                      }}
                                                      target="_blank"
                                                      href="https://opensea.io/collection/le-anime-by-toomuchlag?search[sortBy]=UNIT_PRICE&search[sortAscending]=true&search[stringTraits][0][name]=Archetype&search[stringTraits][0][values][0]=Spirit"
                                                    >
                                                      Spirits
                                                    </a>{" NFTs."}
                                                
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: "16px",
                                                    fontFamily: "EurostilNormal",
                                                    // color: "#ffb631",
                                                    marginBottom: "10px",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                {
                                                    "• Sacrifice your Spirits to Talanji to "} 
                                                    <span
                                                style={{
                                                    
                                                    color: "#ffb631",
                                                    
                                                }}
                                            >{"instantly receive EXP in your HERO."}</span>
                                                    
                                                
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: "16px",
                                                    fontFamily: "EurostilNormal",
                                                    // color: "#ffb631",
                                                    marginBottom: "10px",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                {
                                                    "• EXP received is proportional to the SCORE of the Spirits."
                                                }
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: "16px",
                                                    fontFamily: "EurostilNormal",
                                                    // color: "#ffb631",
                                                    marginBottom: "10px",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                {
                                                    "• EXP received decreases with time, the earlier you sacrifice the more you receive."
                                                }
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: "16px",
                                                    fontFamily: "EurostilNormal",
                                                    // color: "#ffb631",
                                                    marginBottom: "10px",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                {
                                                    "• All Spirits sacrificed to Talanji will be lost forever!"
                                                }
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: "16px",
                                                    fontFamily: "EurostilNormal",
                                                    // color: "#ffb631",
                                                    marginBottom: "10px",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                {
                                                    "• Spirits must be "} 
                                                    <span
                                                style={{
                                                    
                                                    color: "#ffb631",
                                                    
                                                }}
                                            >{"outside of the Hero. "}</span>
                                            {
                                                    "Spirits merged in Heroes cannot be sacrificed."}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "100%",
                                                margin: "20px 0",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: matchesSmall
                                                        ? "20px"
                                                        : "22px",

                                                    letterSpacing: 1,
                                                    color: "#ffb631",
                                                    fontWeight: 100,
                                                    fontFamily:
                                                        "PPNeueMachina",
                                                }}
                                            >
                                                {
                                                    "HOW DO I GET TALANJI ?"
                                                }
                                            </span>{" "}
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px",
                                                alignSelf: "start",
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: "16px",
                                                    fontFamily: "EurostilNormal",
                                                    // color: "#ffb631",
                                                    marginBottom: "10px",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                {
                                                    "• Talanji will be randomly distributed to a Hero that sacrifices Spirits."
                                                }
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: "16px",
                                                    fontFamily: "EurostilNormal",
                                                    color: "#ffb631",
                                                    marginBottom: "10px",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                {
                                                    "• 1 Spirit sacrificed = 1 chance to receive Talanji."
                                                }
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: "16px",
                                                    fontFamily: "EurostilNormal",
                                                    // color: "#ffb631",
                                                    marginBottom: "10px",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                {
                                                    "• A 7 days counter starts when 250 spirits total are sacrificed. The winner will be randomly selected at the end of the countdown."
                                                }
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "100%",
                                                margin: "20px 0",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: matchesSmall
                                                        ? "20px"
                                                        : "22px",

                                                    letterSpacing: 1,
                                                    color: "#ffb631",
                                                    fontWeight: 100,
                                                    fontFamily:
                                                        "PPNeueMachina",
                                                }}
                                            >
                                                {
                                                    "ABOUT TALANJI 1/1"
                                                }
                                            </span>{" "}
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px",
                                                alignSelf: "start",
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: "16px",
                                                    fontFamily: "EurostilNormal",
                                                    // color: "#ffb631",
                                                    marginBottom: "10px",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                { "• Talanji contains a SuperRare 1/1 NFT "}
                                                <a
                                                      style={{
                                                        // color: "#ffb631",
                                                        color: "white",
                                                      }}
                                                      target="_blank"
                                                      href="https://superrare.com/artwork-v2/talanji-17686"
                                                    >
                                                      {"(Talanji, 2020)"}
                                                    </a>
                                                    {" locked in the Smart Contract."}
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: "16px",
                                                    fontFamily: "EurostilNormal",
                                                    // color: "#ffb631",
                                                    marginBottom: "10px",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                {
                                                    "• If 2020 Spirits are cumulatively sacrificed, the owner of Talanji will be able to unlock the original NFT."
                                                }
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: "16px",
                                                    fontFamily: "EurostilNormal",
                                                    // color: "#ffb631",
                                                    marginBottom: "10px",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                {
                                                    "• Every 29th December, the artwork changes to the original for one day."
                                                }
                                            </span>
                                            
                                            
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                width: "90%",
                                                alignItems: "center",
                                                justifyContent: "space-around",
                                            }}
                                        >
                                            {" "}
                                        </div>
                                    </Grid> */}
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                
                                    style={{ 
                                      width: "100%",
                                      marginBottom: "100px",

                                    }}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ marginTop: "25px", display: 'flex', justifyContent: 'center' }}
                                    >
                                        <Button
                                            style={{
                                                background: (address && isConnected && isChainSupported && isSacrificeActive && editionsRemaining > 0) ? "#ffb631" : "gray",
                                                color: "black",
                                                width: matchesMobile ? "80%" : "50%",
                                                padding: "25px 0",
                                                marginTop: "0px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderRadius: 0,
                                                fontSize: "16px",
                                                fontFamily: "EurostilDemi",
                                            }}
                                            disabled={(address && isConnected && isChainSupported && isSacrificeActive && editionsRemaining > 0) ? false : true}
                                            onClick={() => {
                                                setSacrificeOpen(true);
                                            }}
                                        >
                                            <span>Sacrifice Spirits {(address && isConnected && isChainSupported) ? "" : "(Connect Wallet)"} </span>
                                        </Button>{" "}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ marginTop: "10px" }}
                                    >
                                      {/* <div
                                        style={{
                                           marginTop: "20px",
                                            fontSize: matchesMobile ? "16px" : "30px",
                                            fontFamily: "EurostilDemi",
                                            color: "#ffffff",
                                            textAlign: "center",
                                          }}
                                      >
                                      
                                        TOTAL SPIRITS SACRIFICED:  <span style={{color: "#ffb631"}}> {sacrificeCounter} </span>
                                      </div> */}
                                      {/* <div
                                        style={{
                                           marginTop: "20px",
                                           fontSize: matchesMobile ? "16px" : "30px",
                                            fontFamily: "EurostilDemi",
                                            color: "#ffffff",
                                            textAlign: "center",
                                          }}
                                      >
                                      
                                       EDITIONS REMAINING: <span style={{color: "#ffb631"}}>  {editionsRemaining}</span>
                                      </div> */}
                                    </Grid>
                                    {isConnected && isSacrificeActive && editionsRemaining == 0 && (<Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "EurostilDemi",
                                            fontSize: matchesMobile? "16px" : "20px",
                                            textAlign: "center",
                                            // cursor: "pointer",
                                            color: "rgb(255, 65, 54)",
                                            marginTop: "20px",
                                        }}
                                    >
                                      SOLD OUT  - NO MORE EDITIONS VIA BURN
                                    </Grid>)
                                    
                                    }
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "EurostilDemi",
                                            fontSize: matchesMobile? "16px" : "20px",
                                            textAlign: "center",
                                            // cursor: "pointer",
                                            color: "rgb(255, 65, 54)",
                                            marginTop: "20px",
                                        }}
                                    >
                                      SOLD OUT - NO MORE EDITIONS AVAILABLE
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </>
               
            </Grid>
        </Grid>
    );
};

export default ZenithBurn;
