import { ethers } from "ethers";

const FORMAT_DIGITS = 6;

// @todo - modify this to handle the mergeHeroes event, because it keeps track of the hold tokens in heroes
export const getAllHeroes = async (lockerContract) => {
  const heroLogs = await lockerContract.queryFilter(
      lockerContract.filters.DepositedSouls(null, null)
  );

  const heroes = new Set();

  const tokensInHero = {};

  for (const log of heroLogs) {
      const { heroId, tokenId } = log.args;
      heroes.add(heroId.toString());

      // convert deposited tokenId array elements to int
      let tokenIdsToAdd = tokenId.map((id) => {
          return parseInt(id.toString()) - 100000
      })

      // add elements of tokenIdsToAdd to tokensInHero[heroId] if it exists, else create it
      tokensInHero[heroId.toString()] = tokensInHero[heroId.toString()] ? [...tokensInHero[heroId.toString()], ...tokenIdsToAdd] : tokenIdsToAdd
  }
  // console.log("tokensInHero", tokensInHero)

  let heroIDs = Array.from(heroes).map((id) => {
      // let offset = 100000;
      const tokenId = parseInt(id)// + offset;
      return tokenId
  });

  // console.log(JSON.stringify(heroIDs))
  return {heroIDs, tokensInHero};
};

export const getAllHeroesFromExp = async (expContract) => {
    const heroLogs = await expContract.queryFilter(
        expContract.filters.ExpClaimed(null, null)
    );

    const heroes = new Set();

    for (const log of heroLogs) {
        const { heroId } = log.args;
        heroes.add(heroId.toString());
    }

    let heroIDs = Array.from(heroes).map((id) => {
        // let offset = 100000;
        const tokenId = parseInt(id)// + offset;
        return tokenId
    });
    return heroIDs;
}

export const getBatchHeroExpData = async (multicall2Contract, experienceContract, tokenIDs) => {

    const batchCalldata = tokenIDs.map(tokenId => {
        return {
            target: experienceContract.address,
            callData: experienceContract.interface.encodeFunctionData("getHeroExpData", [tokenId])
        }
    })

    // output coded
    const outputE = await multicall2Contract.callStatic.tryAggregate(false, batchCalldata)

    // Decode the data
    const outputs = outputE.map(({ success, returnData }, i) => {
        const tokenId = tokenIDs[i]

        if (!success) {
            console.log(`Failed to retrieve EXP data for ${tokenId}`)
            return [tokenId, ethers.constants.Zero]
        }

        const expData = experienceContract.interface.decodeFunctionResult("getHeroExpData", returnData)[0]
        // console.log(`Hero ${tokenId} expData is ${expData}`)
        return [tokenId, expData]
    })

    // return outputs
    let leaderboard = []
    for (let i = 0; i < outputs.length; i++) {
        let row = {}
        row["heroId"] = outputs[i][0]
        row["exp"] = outputs[i][1].expAvailable
        // row["expClaimable"] = "420"
        row["score"] = outputs[i][1].score
        row["lastTimestamp"] = outputs[i][1].lastTimestamp.toString()
        
        leaderboard.push(row)
    }

    // sort by score descending
    leaderboard.sort((a, b) => b.score - a.score)

    return leaderboard
}

export const getBatchClaimableExp = async (multicall2Contract, experienceContract, tokenIDs) => {

    const batchCalldata = tokenIDs.map(tokenId => {
        return {
            target: experienceContract.address,
            callData: experienceContract.interface.encodeFunctionData("calculateClaimableExp", [tokenId])
        }
    })

    // output coded
    const outputE = await multicall2Contract.callStatic.tryAggregate(false, batchCalldata)

    // Decode the data
    const outputs = outputE.map(({ success, returnData }, i) => {
        const tokenId = tokenIDs[i]

        if (!success) {
            console.log(`Failed to retrieve EXP for ${tokenId}`)
            return [tokenId, ethers.constants.Zero]
        }

        const claimableExp = experienceContract.interface.decodeFunctionResult("calculateClaimableExp", returnData)[0]
        // console.log(`Hero ${tokenId} expData is ${expData}`)
        return [tokenId, claimableExp]
    })

    // return outputs
    let claimableExp = {}
    for (let i = 0; i < outputs.length; i++) {
        claimableExp[outputs[i][0]] = outputs[i][1]
    }

    return claimableExp
}

// the following combines getBatchHeroExpData and getBatchClaimableExp into one batch call
export const getBatchHeroExpDataAndClaimableExp = async (multicall2Contract, experienceContract, tokenIDs) => {

  const batchCalldata = tokenIDs.map(tokenId => {
      return {
          target: experienceContract.address,
          callData: experienceContract.interface.encodeFunctionData("getHeroExpData", [tokenId])
      }
  })

  const batchCalldata2 = tokenIDs.map(tokenId => {
    return {
        target: experienceContract.address,
        callData: experienceContract.interface.encodeFunctionData("calculateClaimableExp", [tokenId])
    }
  })

  batchCalldata.push(...batchCalldata2)

  // console.log("batchCalldata", batchCalldata)




  // output coded
  const outputE = await multicall2Contract.callStatic.tryAggregate(false, batchCalldata)

  // Decode the data
  const outputs = outputE.map(({ success, returnData }, i) => {

      const tokenId = tokenIDs[i>tokenIDs.length-1 ? i-tokenIDs.length : i]

      if (!success) {
          console.log(`Failed to retrieve EXP data for ${tokenId}`)
          return [tokenId, ethers.constants.Zero]
      }

      if(i < tokenIDs.length) {

        const expData = experienceContract.interface.decodeFunctionResult("getHeroExpData", returnData)[0]
        // console.log(`Hero ${tokenId} expData is ${expData}`)
        return [tokenId, expData]
      }
      else {
        const claimableExp = experienceContract.interface.decodeFunctionResult("calculateClaimableExp", returnData)[0]
        // console.log(`Hero ${tokenId} expData is ${expData}`)
        return [tokenId, claimableExp]
      }
  })


  // return outputs
  let leaderboard = []
  for (let i = 0; i < tokenIDs.length; i++) {
      let row = {}
      row["heroId"] = outputs[i][0]
      row["expAvailable"] = outputs[i][1].expAvailable
      // row["expClaimable"] = "420"
      row["score"] = outputs[i][1].score
      row["lastTimestamp"] = outputs[i][1].lastTimestamp.toString()
      row["expClaimable"] = outputs[i+tokenIDs.length][1]
      row["exp"] = parseInt(row["expAvailable"]) + parseInt(row["expClaimable"])
      leaderboard.push(row)
  }

  // sort by score descending
  leaderboard.sort((a, b) => b.score - a.score)
  // console.log("leaderboard", leaderboard)
  return leaderboard
}

export const getBatchSoulsInHero = async (multicall2Contract, lockerContract, tokenIDs) => {

    const batchCalldata = tokenIDs.map(tokenId => {
        return {
            target: lockerContract.address,
            callData: lockerContract.interface.encodeFunctionData("getSoulsInHero", [tokenId])
        }
    })

    // output coded
    const outputE = await multicall2Contract.callStatic.tryAggregate(false, batchCalldata)

    // console.log(outputE)

    // Decode the data
    const outputs = outputE.map(({ success, returnData }, i) => {
        const tokenId = tokenIDs[i]

        if (!success) {
            console.log(`Failed to retrieve souls data for ${tokenId}`)
            return [tokenId, ethers.constants.Zero]
        }

        const soulsInHero = lockerContract.interface.decodeFunctionResult("getSoulsInHero", returnData)[0]
        return [tokenId, soulsInHero]
    })

    // soulsInHero to object heroId: [soul1, soul2, soul3]
    let soulsInHeroObj = {}
    for (let i = 0; i < outputs.length; i++) {
        let heroId = outputs[i][0]
        let souls = outputs[i][1]
        soulsInHeroObj[heroId] = souls
    }

    // return outputs
    return soulsInHeroObj
}

export const getBatchHeroOwners = async (multicall2Contract, wrapperContract, tokenIDs) => {

    // Construct your calldata to multicall2
    const batchCalldata = tokenIDs.map(tokenId => {
        return {
            target: wrapperContract.address,
            callData: wrapperContract.interface.encodeFunctionData("ownerOf", [tokenId + 100000])
        }
    })

    // output coded
    const outputE = await multicall2Contract.callStatic.tryAggregate(false, batchCalldata)

    // Decode the data
    const outputs = outputE.map(({ success, returnData }, i) => {
        const tokenId = tokenIDs[i]

        if (!success) {
            console.log(`Failed to retrieve owner for ${tokenId}`)
            return [tokenId, ethers.constants.Zero]
        }

        const owner = wrapperContract.interface.decodeFunctionResult("ownerOf", returnData)[0]
        // console.log(`Hero ${tokenId} owner is ${owner}`)
        return [tokenId, owner]
    })

    return outputs

}
//
export const generateLeaderboard_old_2calls = async (multicall2Contract, experienceContract, lockerContract, wrapperContract) => {
    const {heroIDs, tokensInHero} = await getAllHeroes(lockerContract)

    let leaderboard = await getBatchHeroExpData(multicall2Contract, experienceContract, heroIDs)

    // let soulsInHero = await getBatchSoulsInHero(multicall2Contract, lockerContract, heroIDs)
    // using data from events to skip a batch call for faster loading
    let soulsInHero = tokensInHero;

    // console.log("souls", soulsInHero)
    // console.log("heroes", leaderboard)
    // let heroOwners = await getBatchHeroOwners(multicall2Contract, wrapperContract, heroIDs)

    let claimableExp = await getBatchClaimableExp(multicall2Contract, experienceContract, heroIDs)

    for (let i = 0; i < leaderboard.length; i++) {
        leaderboard[i]["soulsInHero"] = [leaderboard[i]["heroId"]].concat(soulsInHero[leaderboard[i]["heroId"]])

        // owners needed?
        // leaderboard[i]["owner"] = heroOwners[i][1].toString()

        // Add claimable exp
        leaderboard[i]["expClaimable"] = claimableExp[leaderboard[i]["heroId"]]
        leaderboard[i]["exp"] = parseInt(leaderboard[i]["exp"]) + parseInt(leaderboard[i]["expClaimable"])
        
        // FORMATTING FOR DISPLAY

        // format exp balance with FORMAT_DIGITS decimals
        leaderboard[i]["exp"] = ethers.utils.formatUnits(
            leaderboard[i]["exp"],
            FORMAT_DIGITS
        );
        // exp balance add comma separator every 3 digits
        leaderboard[i]["exp"] = leaderboard[i]["exp"].split(".")[0];
        leaderboard[i]["exp"] = leaderboard[i]["exp"].replace(/\B(?=(\d{3})+(?!\d))/g, "'");

        leaderboard[i]["score"] = leaderboard[i]["score"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    }

    // exp divide 

    // remove heroes with only 1 soul
    leaderboard = leaderboard.filter((hero) => {
        return hero.soulsInHero.length > 1
    })

    return leaderboard

}

// uses getBatchHeroExpDataAndClaimableExp to reduce 1 call
export const generateLeaderboard = async (multicall2Contract, experienceContract, lockerContract, wrapperContract) => {
  const {heroIDs, tokensInHero} = await getAllHeroes(lockerContract)

  let leaderboard = await getBatchHeroExpDataAndClaimableExp(multicall2Contract, experienceContract, heroIDs)

  // let soulsInHero = await getBatchSoulsInHero(multicall2Contract, lockerContract, heroIDs)
  // using data from events to skip a batch call for faster loading
  let soulsInHero = tokensInHero;



  for (let i = 0; i < leaderboard.length; i++) {
      leaderboard[i]["soulsInHero"] = [leaderboard[i]["heroId"]].concat(soulsInHero[leaderboard[i]["heroId"]])
      
      // FORMATTING FOR DISPLAY

      // format exp balance with FORMAT_DIGITS decimals
      leaderboard[i]["exp"] = ethers.utils.formatUnits(
          leaderboard[i]["exp"],
          FORMAT_DIGITS
      );
      // exp balance add comma separator every 3 digits
      leaderboard[i]["exp"] = leaderboard[i]["exp"].split(".")[0];
      leaderboard[i]["exp"] = leaderboard[i]["exp"].replace(/\B(?=(\d{3})+(?!\d))/g, "'");

      leaderboard[i]["score"] = leaderboard[i]["score"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  }

  // exp divide 

  // print leaderboard
  // console.log("leaderboard", leaderboard)

  // remove heroes with only 1 soul
  leaderboard = leaderboard.filter((hero) => {
      return hero.soulsInHero.length > 1
  })

  // remove heroes with score 0 (temp fix for mergeHeroes event)
  leaderboard = leaderboard.filter((hero) => {
      return hero.score !== "0"
  })

  return leaderboard

}

// functiont to get all the scores of the heroes given
export const getHeroScores = async (multicall2Contract, experienceContract, heroIDs) => {
  let leaderboard = await getBatchHeroExpData(multicall2Contract, experienceContract, heroIDs)

  for (let i = 0; i < leaderboard.length; i++) {
      leaderboard[i]["score"] = leaderboard[i]["score"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  }
  // create array with rank, heroid and score
  let scores = leaderboard.map((hero, index) => {
      return {
          rank: index + 1,
          heroId: hero.heroId,
          score: hero.score
      }
  })
  return scores
}