import { ethers } from "ethers";

export const listLoreIds = async (currentContract) => {
  // console.log(await token.name(), 'tokens owned by', account);

  const loreLogs = await currentContract.queryFilter(
    currentContract.filters.LoreUpdated(null, null)
  );

  const logs = loreLogs.sort(
    (a, b) =>
      a.blockNumber - b.blockNumber || a.transactionIndex - b.TransactionIndex
  );

  const owned = new Set();

  for (const log of logs) {
    const { heroId, newLoreData } = log.args;
    owned.add(parseInt(heroId.toString()));
  }

  let tokenIDs = Array.from(owned);
  tokenIDs.sort((a, b) => a - b);
  
  return tokenIDs;
};

// returns object of a specific id in format {nameString: , loreString: , author: , nftLink: }
export const getLoreFromID = async (currentContract, provider, id) => {
  let currentLore = await currentContract.loreAndName(id);
 
  let name = currentLore.name;
  let loreAddress = currentLore.fullLore;

  //convert bytes32 to string
  //convert bytes32 to string, remove 0 values
  let nameString = ethers.utils.toUtf8String(name).replace(/\0/g, "");


  //ethers ready bytecode from address
  let loreBytecode = await provider.getCode(loreAddress);
  //convert loreBytecode to string
  let loreString = ethers.utils.toUtf8String(loreBytecode).replace(/\0/g, "");


  // look for the last "Author: " in loreString and store what is after
  let author = loreString.substring(loreString.lastIndexOf("Author: ") + 8);

  // remove everything starting from the last "Author: " in loreString
  loreString = loreString.substring(0, loreString.lastIndexOf("Author: "));

  let tokenId = parseInt(id) + 100000;
  let nftLink = `https://opensea.io/assets/ethereum/0x03bebcf3d62c1e7465f8a095bfa08a79ca2892a1/${tokenId}`;

  return { nameString, loreString, author, nftLink };
};


///////
// HOLDERS STORE LORE FUNCTIONS
////////

// next 3 functions are utils to format the lore for storage in the blockchain to be used as SVG
const wrap = (s, w) => s.replace(
    new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, 'g'), '$1\n'
);

const cleanString = (input) =>{
    input = input.replace(/["“”]/g, "\"")
    input = input.replace(/[‘’]/g, "'")
    //replace all & with &amp; only if & is not followed by lt or gt or amp
    input = input.replace(/&(?!(lt|gt|amp);)/g, "&amp;")
    input = input.replaceAll("<", "&lt;")
    input = input.replaceAll(">", "&gt;")
    input = input.replace(/…/g, "...")

    var output = "";
    for (var i=0; i<input.length; i++) {
        if (input.charCodeAt(i) <= 255) {
            output += input.charAt(i);
        }
    }

    return output;
}

const formatLoreForSVG = (loreText, walletAddress) => {
    let loreWrapped = loreText.concat("\n\nAuthor: ").concat(walletAddress.toString())
    loreWrapped = wrap(cleanString(loreWrapped), 109)
    
    return loreWrapped
}

// arguments:  libraryContract, heroId, name, lore
export const storeLoreHolders = async (currentContract, id, name, lore) => {
    let heroId = parseInt(id)
    // cut name string to max 32 characters
    let nameString = name.substring(0, 32)

    let walletAddress = await currentContract.signer.getAddress()

    let wrappedLore = formatLoreForSVG(lore, walletAddress)

    let loreBytes = ethers.utils.toUtf8Bytes(wrappedLore)

    let result = await currentContract.storeLoreAndNameSSTORE(heroId, nameString, loreBytes)

    return result.wait()
    
}

////////
// LIBRARIAN FUNCTIONS
////////

// returns address of the Librarian - lowercase
export const librarianAddress = async (provider) => {
    let SR_ADDRESS = "0xb932a70A57673d89f4acfFBE830E8ed7f75Fb9e0"
    let LIBRARY_ID = 39020

    // raw call contract SR_ADDRESS on ethers, function ownerOf(uint256 LIBRARY_ID) external view returns (address)
    let librarian = await provider.call({
        to: SR_ADDRESS,
        data: "0x6352211e000000000000000000000000000000000000000000000000000000000000986c"
    })
    // convert librarian DataHexstring to 160 bit address
    librarian = ethers.utils.hexDataSlice(librarian, 12)

    return librarian

}

// returns true/false if library is closed/open
export const isClosed = async (currentContract) => {
    let frozen = await currentContract.frozen()
    return frozen
}

export const closeLibrary = async (currentContract) => {
    let result = await currentContract.freezeLibrary(true)
    return result.wait()
}

export const openLibrary = async (currentContract) => {
    let result = await currentContract.freezeLibrary(false)
    return result.wait()
}

export const storeLoreLibrarian = async (currentContract, lore) => {   
    // convert lore to bytes
    // get wallet from contract ethers
    let walletAddress = await currentContract.signer.getAddress()

    let wrappedLore = formatLoreForSVG(lore, walletAddress)

    let loreBytes = ethers.utils.toUtf8Bytes(wrappedLore)

    let result = await currentContract.storeLibrarianLore(loreBytes)

    return result.wait()
}

// returns object {loreString:}
export const getLoreLibrarian = async (currentContract, provider) => {

    let currentLore = await currentContract.loreAndName(0)

    let loreAddress = currentLore.fullLore

    let loreBytecode = await provider.getCode(loreAddress)

    let loreString = ethers.utils.toUtf8String(loreBytecode).replace(/\0/g, '')

    // look for the last "Author: " in loreString and store what is after
    let author = loreString.substring(loreString.lastIndexOf("Author: ") + 8);

    // remove everything starting from the last "Author: " in loreString
    loreString = loreString.substring(0, loreString.lastIndexOf("Author: "));

    return {loreString}
}


