import { ethers } from "ethers";

const ownerToTierAndProof = {
    "0x023001937f0e675849E2E43FA7ff40A15aFFdc87": {
        tier: "1",
        proof: [
            "0x124108bf34187a8a67eb3a1e03fb02da160caec1d278f4bbaf4f0e122e766fe4",
            "0x55653534125c805d44dd27ee26cc22aaac07260f4788ed112c60282a7c079296",
            "0x6d1d4b5d37ce75f667de9be69d539305ee41cf1de2ce4df983c4875f5aa98134",
            "0x19d611af781822c70131da60201a959f89f6e49db3d5c97ba016f0c969b2f234",
            "0xe8e31fd302c1d4e3eda4989b0dcd27c600ead257bb6999f0a282d8664637062d",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x64760d2BBeD2e234c8644441B30Ce8C98C5e557B": {
        tier: "2",
        proof: [
            "0x9b8d76130fa58514503a934c17c5e844946eca8f29711f8a0cc44ca18b4516ed",
            "0x55653534125c805d44dd27ee26cc22aaac07260f4788ed112c60282a7c079296",
            "0x6d1d4b5d37ce75f667de9be69d539305ee41cf1de2ce4df983c4875f5aa98134",
            "0x19d611af781822c70131da60201a959f89f6e49db3d5c97ba016f0c969b2f234",
            "0xe8e31fd302c1d4e3eda4989b0dcd27c600ead257bb6999f0a282d8664637062d",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x5042Bb3f06074FDeF05056Ae0b666ab882F2ee4F": {
        tier: "3",
        proof: [
            "0x1967419f37b804df8b69a982973de6e9ea66b6ddbd42fa880f0b7a8e35d45423",
            "0xe5f3379bedd3410315f7d000e762d6a87574bb92417da31fbc96eb4213ffac4c",
            "0x6d1d4b5d37ce75f667de9be69d539305ee41cf1de2ce4df983c4875f5aa98134",
            "0x19d611af781822c70131da60201a959f89f6e49db3d5c97ba016f0c969b2f234",
            "0xe8e31fd302c1d4e3eda4989b0dcd27c600ead257bb6999f0a282d8664637062d",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x4ccd2c80775c2fADa07d46300D270D1420D9A435": {
        tier: "3",
        proof: [
            "0xa5953822d58dca0e1aa493ea9f9c2e6d9271e1bfd9e437e4bdc9fb880dfc9c5e",
            "0xe5f3379bedd3410315f7d000e762d6a87574bb92417da31fbc96eb4213ffac4c",
            "0x6d1d4b5d37ce75f667de9be69d539305ee41cf1de2ce4df983c4875f5aa98134",
            "0x19d611af781822c70131da60201a959f89f6e49db3d5c97ba016f0c969b2f234",
            "0xe8e31fd302c1d4e3eda4989b0dcd27c600ead257bb6999f0a282d8664637062d",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xd716ad4f10eade5aF4Ed10006678f49Bd2E6624f": {
        tier: "3",
        proof: [
            "0x6db7aefbd25bc4868b4001b2d96c75919b502f6e62176b3893d241f4c602a275",
            "0x593ee2b56f3ed715e55925af31d69f58dbf3812aa32e1dee48d47fa55b0f859a",
            "0xd3f4e8491c065487c732836597cec302743618c919e6ff004ab7ac4934794577",
            "0x19d611af781822c70131da60201a959f89f6e49db3d5c97ba016f0c969b2f234",
            "0xe8e31fd302c1d4e3eda4989b0dcd27c600ead257bb6999f0a282d8664637062d",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x50b88F3f1Ea8948731c6af4C0EBfDEf1beccD6Fa": {
        tier: "3",
        proof: [
            "0xa0440da50d29c4332f87e8d2543cf7fa500dcccb782fe7913ac0339a8c0c4c5f",
            "0x593ee2b56f3ed715e55925af31d69f58dbf3812aa32e1dee48d47fa55b0f859a",
            "0xd3f4e8491c065487c732836597cec302743618c919e6ff004ab7ac4934794577",
            "0x19d611af781822c70131da60201a959f89f6e49db3d5c97ba016f0c969b2f234",
            "0xe8e31fd302c1d4e3eda4989b0dcd27c600ead257bb6999f0a282d8664637062d",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xcD8b72710595c69019c109Aef5b1B92eea7F995F": {
        tier: "3",
        proof: [
            "0x82c086b51b4d8e6d985abfb03854df5bac21ccf26f7593bf606496b9493f785f",
            "0xc02c6c43268daa36ffa05c7d526e03a13728bde491c73c1602ce41f399d727bc",
            "0xd3f4e8491c065487c732836597cec302743618c919e6ff004ab7ac4934794577",
            "0x19d611af781822c70131da60201a959f89f6e49db3d5c97ba016f0c969b2f234",
            "0xe8e31fd302c1d4e3eda4989b0dcd27c600ead257bb6999f0a282d8664637062d",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xA83fA9c834BB5ABE25F9c56EB5845b3203Df94F2": {
        tier: "3",
        proof: [
            "0x1c5d863daa52b6eae7a09782ace8f121ede260fc67cbac8147d4f3c6cb82fb78",
            "0xc02c6c43268daa36ffa05c7d526e03a13728bde491c73c1602ce41f399d727bc",
            "0xd3f4e8491c065487c732836597cec302743618c919e6ff004ab7ac4934794577",
            "0x19d611af781822c70131da60201a959f89f6e49db3d5c97ba016f0c969b2f234",
            "0xe8e31fd302c1d4e3eda4989b0dcd27c600ead257bb6999f0a282d8664637062d",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x7F6417eCc59685F4363DD7662D0cB26F2E5e5E5c": {
        tier: "3",
        proof: [
            "0x895b0efd2ff6e5a21e3a01393c18b706bbe5789a2eb485b8424de7dd38e9ed72",
            "0x5e52a27d13a1878546512007417665b365bff905fe0a2f087f561af0d30c3e54",
            "0x6d89756555694be8ab22fee5e2d9afbf08b2d2b320c7a0856144ad295c284103",
            "0x764722363e761d0e487f8d9a12d0b29785720f966b75d3966395ca82c44f0cf9",
            "0xe8e31fd302c1d4e3eda4989b0dcd27c600ead257bb6999f0a282d8664637062d",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x596888345c9e3c6F7d0F4E5d8590941E2CAb52e8": {
        tier: "3",
        proof: [
            "0x4dfe29e3fb1059a439f9a719fa49a2c18a0722ae5a2a88a108f2a99031a63ea4",
            "0x5e52a27d13a1878546512007417665b365bff905fe0a2f087f561af0d30c3e54",
            "0x6d89756555694be8ab22fee5e2d9afbf08b2d2b320c7a0856144ad295c284103",
            "0x764722363e761d0e487f8d9a12d0b29785720f966b75d3966395ca82c44f0cf9",
            "0xe8e31fd302c1d4e3eda4989b0dcd27c600ead257bb6999f0a282d8664637062d",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x1d4B9b250B1Bd41DAA35d94BF9204Ec1b0494eE3": {
        tier: "4",
        proof: [
            "0xfc031e9d2fe23e3fb1732f0e21c02731f75b1a4a77ed5704ece40c739db97f0f",
            "0x0a91d13327d0847aaf48467ca9d8ce6daea2ef8bc2b132f30b9e99770da1a668",
            "0x6d89756555694be8ab22fee5e2d9afbf08b2d2b320c7a0856144ad295c284103",
            "0x764722363e761d0e487f8d9a12d0b29785720f966b75d3966395ca82c44f0cf9",
            "0xe8e31fd302c1d4e3eda4989b0dcd27c600ead257bb6999f0a282d8664637062d",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xC6873dA3CABB143477478020d9c08233c4d1c69F": {
        tier: "4",
        proof: [
            "0xa960c77e9fadda0b37bfade8d47318c01199c411e6c5d41cdc70cd57263d1862",
            "0x0a91d13327d0847aaf48467ca9d8ce6daea2ef8bc2b132f30b9e99770da1a668",
            "0x6d89756555694be8ab22fee5e2d9afbf08b2d2b320c7a0856144ad295c284103",
            "0x764722363e761d0e487f8d9a12d0b29785720f966b75d3966395ca82c44f0cf9",
            "0xe8e31fd302c1d4e3eda4989b0dcd27c600ead257bb6999f0a282d8664637062d",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xa22414e4aF5767448624017C7e0151D22490412b": {
        tier: "4",
        proof: [
            "0x7fe0ed89d58713a7fc558e19c6602fded25c4b661588223953e742a45a523444",
            "0x3f772497371345ccd5f92ced97b7a93c4c7de49d91619df4ee422cf89f0b4c48",
            "0x3c606735c0287225103b821773583867821b60777622f94d06f7384b89726238",
            "0x764722363e761d0e487f8d9a12d0b29785720f966b75d3966395ca82c44f0cf9",
            "0xe8e31fd302c1d4e3eda4989b0dcd27c600ead257bb6999f0a282d8664637062d",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xD20Ce27F650598c2d790714B4f6a7222B8dDcE22": {
        tier: "4",
        proof: [
            "0xf528832be3932596c28f62ccbe4f667d3286b91acce40b5af71e70455fd395af",
            "0x3f772497371345ccd5f92ced97b7a93c4c7de49d91619df4ee422cf89f0b4c48",
            "0x3c606735c0287225103b821773583867821b60777622f94d06f7384b89726238",
            "0x764722363e761d0e487f8d9a12d0b29785720f966b75d3966395ca82c44f0cf9",
            "0xe8e31fd302c1d4e3eda4989b0dcd27c600ead257bb6999f0a282d8664637062d",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x4ef08c86E553250659Ef1c6B420220AACC4aC68d": {
        tier: "4",
        proof: [
            "0x41827603ec4ecc4f7b7047ce18e5f68a09e73c63e5947c823b349854b05ed270",
            "0x1063bceed5bce1c9d0959ca65a668d1cf04ac661cb36ca5ac564b522f10fb087",
            "0x3c606735c0287225103b821773583867821b60777622f94d06f7384b89726238",
            "0x764722363e761d0e487f8d9a12d0b29785720f966b75d3966395ca82c44f0cf9",
            "0xe8e31fd302c1d4e3eda4989b0dcd27c600ead257bb6999f0a282d8664637062d",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xBc32e06c914D55D249e8B544C7a9c79d777DFC3E": {
        tier: "4",
        proof: [
            "0x4a2b1d0dc607ef4cbf262f7e69f7c8cc64dcdd7f82ffddab18133e179bb9af2f",
            "0x1063bceed5bce1c9d0959ca65a668d1cf04ac661cb36ca5ac564b522f10fb087",
            "0x3c606735c0287225103b821773583867821b60777622f94d06f7384b89726238",
            "0x764722363e761d0e487f8d9a12d0b29785720f966b75d3966395ca82c44f0cf9",
            "0xe8e31fd302c1d4e3eda4989b0dcd27c600ead257bb6999f0a282d8664637062d",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x9F5c28dA8F8622D36dC0F596142d66BB1512BAb5": {
        tier: "4",
        proof: [
            "0x075cb31df23f2c3bc266cb66d500212a041c386f7a5043eec92f51cfebe05156",
            "0xbca63592874ae5927824d42aad77f89e8e055c28c87c298c972b4782a68fb8d0",
            "0x4eb778d7a13ed40b6058017d32fb2548baf0911113cb2b2ba7ba48610f1c03f7",
            "0x2816dea32266a847f7a74af2b6cb5c661fd33a33d8c63e81bb4a03e4867ccf25",
            "0x137d2b53987dac4b0c921214c68bf8d5596dbe998b0c9e0c2493549dad14ef6a",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x195b8b9598904b55e9770492bD697529492034a2": {
        tier: "4",
        proof: [
            "0xf99e076dabfbe9912b018b8133053447fad92f3d1e42de67b29aa0b3df1fe741",
            "0xbca63592874ae5927824d42aad77f89e8e055c28c87c298c972b4782a68fb8d0",
            "0x4eb778d7a13ed40b6058017d32fb2548baf0911113cb2b2ba7ba48610f1c03f7",
            "0x2816dea32266a847f7a74af2b6cb5c661fd33a33d8c63e81bb4a03e4867ccf25",
            "0x137d2b53987dac4b0c921214c68bf8d5596dbe998b0c9e0c2493549dad14ef6a",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x036403FBfe5e7349f09cb1b2275E4a652EA565D4": {
        tier: "4",
        proof: [
            "0x54d813d50004f0b376058a9115c04a6e9bd15dbc1cf14b8c2e132b16aeeb58e2",
            "0xaa84b0a19f7a3fff9564c8b240b2464a830f4d0f04c183ff7dc3b3e2214c2a36",
            "0x4eb778d7a13ed40b6058017d32fb2548baf0911113cb2b2ba7ba48610f1c03f7",
            "0x2816dea32266a847f7a74af2b6cb5c661fd33a33d8c63e81bb4a03e4867ccf25",
            "0x137d2b53987dac4b0c921214c68bf8d5596dbe998b0c9e0c2493549dad14ef6a",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x5A1d3693fe0Fc656B4E98Ed93cd39d321dB9E92E": {
        tier: "4",
        proof: [
            "0xe05050b075931039c6b160d7005c6d520b4549c0be000bb7132bc61e895cb010",
            "0xaa84b0a19f7a3fff9564c8b240b2464a830f4d0f04c183ff7dc3b3e2214c2a36",
            "0x4eb778d7a13ed40b6058017d32fb2548baf0911113cb2b2ba7ba48610f1c03f7",
            "0x2816dea32266a847f7a74af2b6cb5c661fd33a33d8c63e81bb4a03e4867ccf25",
            "0x137d2b53987dac4b0c921214c68bf8d5596dbe998b0c9e0c2493549dad14ef6a",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x977D3dbf93174f517a52736E1e556B79300CE3cC": {
        tier: "4",
        proof: [
            "0x482e88edeb5d66b5c4654b86b2673671d0faf8854a2245666f2347951f87444b",
            "0x2db406584d62683c7a86afb043dbcf679a3dce9db92d7eae5b9d50bee73c88ac",
            "0x641430bf6b74cd7ce6994ac6ffd940f8e3c72ce29cc8a964dc2e05655894035f",
            "0x2816dea32266a847f7a74af2b6cb5c661fd33a33d8c63e81bb4a03e4867ccf25",
            "0x137d2b53987dac4b0c921214c68bf8d5596dbe998b0c9e0c2493549dad14ef6a",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x8Fdd38Fbff8a5169397f180807c4550e39599899": {
        tier: "4",
        proof: [
            "0x651ac2a62a25a44f4f6f4ed46a17d628145eaa05e3fa7b776d1fe225d29306d6",
            "0x2db406584d62683c7a86afb043dbcf679a3dce9db92d7eae5b9d50bee73c88ac",
            "0x641430bf6b74cd7ce6994ac6ffd940f8e3c72ce29cc8a964dc2e05655894035f",
            "0x2816dea32266a847f7a74af2b6cb5c661fd33a33d8c63e81bb4a03e4867ccf25",
            "0x137d2b53987dac4b0c921214c68bf8d5596dbe998b0c9e0c2493549dad14ef6a",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x720A4FaB08CB746fC90E88d1924a98104C0822Cf": {
        tier: "4",
        proof: [
            "0x4077561966b9cd1e9bfd4d306a75a51f87760f3f8a6498552ca6f63d97c95d30",
            "0x070517941bb74a4195ba3a7522904bcd6008f2ac0b24c74bd6183b4d81fb0335",
            "0x641430bf6b74cd7ce6994ac6ffd940f8e3c72ce29cc8a964dc2e05655894035f",
            "0x2816dea32266a847f7a74af2b6cb5c661fd33a33d8c63e81bb4a03e4867ccf25",
            "0x137d2b53987dac4b0c921214c68bf8d5596dbe998b0c9e0c2493549dad14ef6a",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xf1bfcD2AcECe6012b18D4Ba5C7e3A6051bAFA202": {
        tier: "4",
        proof: [
            "0xdf4b44cff9483d5ed146825ec7ece74a39b104b996310379a94f075862fcc859",
            "0x070517941bb74a4195ba3a7522904bcd6008f2ac0b24c74bd6183b4d81fb0335",
            "0x641430bf6b74cd7ce6994ac6ffd940f8e3c72ce29cc8a964dc2e05655894035f",
            "0x2816dea32266a847f7a74af2b6cb5c661fd33a33d8c63e81bb4a03e4867ccf25",
            "0x137d2b53987dac4b0c921214c68bf8d5596dbe998b0c9e0c2493549dad14ef6a",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xF61B873a21d767FF0506E8B2630c4ddE7216035D": {
        tier: "4",
        proof: [
            "0xf168240dec67dd0feca3249c0caba5a3d17f33dc444ede6cff72bbf5f1938334",
            "0x83602bf605013f5dd79d7ece003c586424a37f35c58f108595be5fc9a6b3c73d",
            "0x7635cd0c9f253bfa57da1a8d3a0c5d5475d90c6dfd791851ae9ee6e904177d68",
            "0x1fdba04fefc8c234f6c579d90f0dca3a9dd48eea941092859f91ac5613582770",
            "0x137d2b53987dac4b0c921214c68bf8d5596dbe998b0c9e0c2493549dad14ef6a",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xE81cCAB76718AC222c8c9F2d19197c9D39e9FcA5": {
        tier: "4",
        proof: [
            "0x50f45559eeb737bbf9635bc2699ffbb654672ff49287a8a94cf7a984a6af03db",
            "0x83602bf605013f5dd79d7ece003c586424a37f35c58f108595be5fc9a6b3c73d",
            "0x7635cd0c9f253bfa57da1a8d3a0c5d5475d90c6dfd791851ae9ee6e904177d68",
            "0x1fdba04fefc8c234f6c579d90f0dca3a9dd48eea941092859f91ac5613582770",
            "0x137d2b53987dac4b0c921214c68bf8d5596dbe998b0c9e0c2493549dad14ef6a",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xD7f8F9133CB7E53806FE8ccd76068c1806D36bCE": {
        tier: "4",
        proof: [
            "0xe4ee23cb706bc04b6fc9eb9220207e39083b1da41658ffc8a9c1a7b31d46d72c",
            "0x36ae4a9478f993a885b7982a6694f793f75039062d55db1c3cbd129f093bfe64",
            "0x7635cd0c9f253bfa57da1a8d3a0c5d5475d90c6dfd791851ae9ee6e904177d68",
            "0x1fdba04fefc8c234f6c579d90f0dca3a9dd48eea941092859f91ac5613582770",
            "0x137d2b53987dac4b0c921214c68bf8d5596dbe998b0c9e0c2493549dad14ef6a",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x3028cddA69Ff95ae53f77c0933706c79673AEE58": {
        tier: "5",
        proof: [
            "0xbfe064fa722e6e71044b58448c9b65812bdf4646b7a7f4bc34650b51974d583d",
            "0x36ae4a9478f993a885b7982a6694f793f75039062d55db1c3cbd129f093bfe64",
            "0x7635cd0c9f253bfa57da1a8d3a0c5d5475d90c6dfd791851ae9ee6e904177d68",
            "0x1fdba04fefc8c234f6c579d90f0dca3a9dd48eea941092859f91ac5613582770",
            "0x137d2b53987dac4b0c921214c68bf8d5596dbe998b0c9e0c2493549dad14ef6a",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xa5b36A3732937375bC579fa38159347Da9938441": {
        tier: "5",
        proof: [
            "0x6c7deb16e170600c02f915cf66d6b10053f0d7ef073a89d8013c3d9648b2e5f6",
            "0x0c0142dfdefb5c3bdce58d8e48c38fc87df7dc034c21ceca88952e43651ba40e",
            "0x56a9e985cc81b150271d34551908285caf80c90607fa8a6eb1873ef23e34d0fa",
            "0x1fdba04fefc8c234f6c579d90f0dca3a9dd48eea941092859f91ac5613582770",
            "0x137d2b53987dac4b0c921214c68bf8d5596dbe998b0c9e0c2493549dad14ef6a",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xF327D484d393C8B2ee1bB95D4dd5528C5a107f64": {
        tier: "5",
        proof: [
            "0x93cb90235f3b2aef91b5e39719bce29745ae7bed185eaaf64df13a78cfacbc73",
            "0x0c0142dfdefb5c3bdce58d8e48c38fc87df7dc034c21ceca88952e43651ba40e",
            "0x56a9e985cc81b150271d34551908285caf80c90607fa8a6eb1873ef23e34d0fa",
            "0x1fdba04fefc8c234f6c579d90f0dca3a9dd48eea941092859f91ac5613582770",
            "0x137d2b53987dac4b0c921214c68bf8d5596dbe998b0c9e0c2493549dad14ef6a",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x2CFED67C3CE0168583F40Ce6D10A037727695e7A": {
        tier: "5",
        proof: [
            "0x9ccd5bb021d99d62306caf9f88fb0f4f9f47d75f8e99eddbe052d56c0d952377",
            "0x113790a66b71ac1a3fc9345a9f71ea52f328caf9fd8dfe4e837eff6788ea9769",
            "0x56a9e985cc81b150271d34551908285caf80c90607fa8a6eb1873ef23e34d0fa",
            "0x1fdba04fefc8c234f6c579d90f0dca3a9dd48eea941092859f91ac5613582770",
            "0x137d2b53987dac4b0c921214c68bf8d5596dbe998b0c9e0c2493549dad14ef6a",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x84a91De94155a7001bF92685D04A12E653d94BdB": {
        tier: "5",
        proof: [
            "0x9bc5d3cb1e2f637d7540592dc5463812348311a860e90efd0401ba2e699fce5c",
            "0x113790a66b71ac1a3fc9345a9f71ea52f328caf9fd8dfe4e837eff6788ea9769",
            "0x56a9e985cc81b150271d34551908285caf80c90607fa8a6eb1873ef23e34d0fa",
            "0x1fdba04fefc8c234f6c579d90f0dca3a9dd48eea941092859f91ac5613582770",
            "0x137d2b53987dac4b0c921214c68bf8d5596dbe998b0c9e0c2493549dad14ef6a",
            "0x461a9099cfe26cc8b457218ce151a4452823b8d8968e8b886dbbabcc65043853",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xa1Db6E912365Ffe5af4c9C6Dd453c2db259210b8": {
        tier: "5",
        proof: [
            "0x38824bfd228f2ad78b8791e583fff997261774e451bddb4d269e689af13c0423",
            "0xe39ca56cfe80b7cf37114bf5a83f00a1ae2f15c1423bdc3207a149d207ce08e0",
            "0x5a2805af5a630bcd341045ea84c11e4ac423d23471176b864a74d75ae58507ec",
            "0x1bb1e8ad934da495421574816fdb99426c341981913932d55caebadadcb59ffc",
            "0x4c3482ccc068f19c87952782e2068aa7b9d7aed5a31e0bccba7e73d64bea6162",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xf5a4F9407Ef5ACce981A3212C29Eb302ED5Deb23": {
        tier: "5",
        proof: [
            "0xb546ee2bc1098523f391d70315d8bfb1ac2d2771387cf8faeb1fb0977e4ce06a",
            "0xe39ca56cfe80b7cf37114bf5a83f00a1ae2f15c1423bdc3207a149d207ce08e0",
            "0x5a2805af5a630bcd341045ea84c11e4ac423d23471176b864a74d75ae58507ec",
            "0x1bb1e8ad934da495421574816fdb99426c341981913932d55caebadadcb59ffc",
            "0x4c3482ccc068f19c87952782e2068aa7b9d7aed5a31e0bccba7e73d64bea6162",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x89210521D639A1ef9A33Ed63a63E67407933d14B": {
        tier: "5",
        proof: [
            "0xe1996271b6194c4217e9c02686282bf06eb132c5f7b85bc8a4598fd352df832e",
            "0x2533b6a020978cd4dd91c6b87e9f9dd0e8366e74c1dcb7947a6b908435244293",
            "0x5a2805af5a630bcd341045ea84c11e4ac423d23471176b864a74d75ae58507ec",
            "0x1bb1e8ad934da495421574816fdb99426c341981913932d55caebadadcb59ffc",
            "0x4c3482ccc068f19c87952782e2068aa7b9d7aed5a31e0bccba7e73d64bea6162",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x3281e7e392686752a30e2433d20E4aa7f5594d9f": {
        tier: "5",
        proof: [
            "0xbb8ad8057a5b7e25d34535f485e709dc86626f0eed2ab60d9aa9a7890794c43e",
            "0x2533b6a020978cd4dd91c6b87e9f9dd0e8366e74c1dcb7947a6b908435244293",
            "0x5a2805af5a630bcd341045ea84c11e4ac423d23471176b864a74d75ae58507ec",
            "0x1bb1e8ad934da495421574816fdb99426c341981913932d55caebadadcb59ffc",
            "0x4c3482ccc068f19c87952782e2068aa7b9d7aed5a31e0bccba7e73d64bea6162",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x1E8408DF2F2A06822b23c2fAC30D5da0eD36f7CE": {
        tier: "5",
        proof: [
            "0x7e0e776ca35cd66d33a36280c7614fa214a43336969e5effbc871e920670022f",
            "0xc72a603352d3dbcf192db67d110861453b1f426465c604e7cc6cd5ca90430558",
            "0xd270a6a61e81cd4cc5e6618959a1cd40cf07e8938f5fa28dffae0176bfcc3f62",
            "0x1bb1e8ad934da495421574816fdb99426c341981913932d55caebadadcb59ffc",
            "0x4c3482ccc068f19c87952782e2068aa7b9d7aed5a31e0bccba7e73d64bea6162",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xbc49e5dE07dF2F6B63E7a277855285736418D055": {
        tier: "5",
        proof: [
            "0x81c55c5f84d6f7b9d6c9e58904646b0efff5e2303c4b7bba074e00c48fabd732",
            "0xc72a603352d3dbcf192db67d110861453b1f426465c604e7cc6cd5ca90430558",
            "0xd270a6a61e81cd4cc5e6618959a1cd40cf07e8938f5fa28dffae0176bfcc3f62",
            "0x1bb1e8ad934da495421574816fdb99426c341981913932d55caebadadcb59ffc",
            "0x4c3482ccc068f19c87952782e2068aa7b9d7aed5a31e0bccba7e73d64bea6162",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x0544FBed9B72AA036517B21d1Db50201a17D09CE": {
        tier: "5",
        proof: [
            "0x5f9fda2c8a88fb1205f65dd0995b5f89772305a2ffb24cfcd826714c5338235d",
            "0x3365e31dde03f5446a68ccda63deae7fceb204f959afd11ec3f0c222e88fa41e",
            "0xd270a6a61e81cd4cc5e6618959a1cd40cf07e8938f5fa28dffae0176bfcc3f62",
            "0x1bb1e8ad934da495421574816fdb99426c341981913932d55caebadadcb59ffc",
            "0x4c3482ccc068f19c87952782e2068aa7b9d7aed5a31e0bccba7e73d64bea6162",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xeEdEb553941F4e1bc1d5fce152f4c794aFe2EC49": {
        tier: "5",
        proof: [
            "0x71ce2a71b5861f1e6d7b915c203fd655dc68390938fa7d9584e3415a918ad380",
            "0x3365e31dde03f5446a68ccda63deae7fceb204f959afd11ec3f0c222e88fa41e",
            "0xd270a6a61e81cd4cc5e6618959a1cd40cf07e8938f5fa28dffae0176bfcc3f62",
            "0x1bb1e8ad934da495421574816fdb99426c341981913932d55caebadadcb59ffc",
            "0x4c3482ccc068f19c87952782e2068aa7b9d7aed5a31e0bccba7e73d64bea6162",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x89FCb042D49E520b7bF1294BBd26f065B4531B99": {
        tier: "5",
        proof: [
            "0x115c6e5df439c017b1a4efc44874a7c6af0f656e757cfc38bfee7c4b0314af84",
            "0x0298ff3be95db5b05a4c6c8ba029b934f6ed9596cd9642c8d483edd000cdd837",
            "0x19811ac410cb7ef9b2d66079609ac8dc5f0ae2d578205fcb193d1262679a24fb",
            "0x8918e45da9368268405a06bfcdf23e1bd44429edf9dbb01591abec4f92bbc41a",
            "0x4c3482ccc068f19c87952782e2068aa7b9d7aed5a31e0bccba7e73d64bea6162",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xecBdE5878A4127b8DeF2AF355dc7e2be5311F904": {
        tier: "5",
        proof: [
            "0x96b4ab70c56375a2772b48f3b7b25241727ba0039c6ad59dee876c4145e966ff",
            "0x0298ff3be95db5b05a4c6c8ba029b934f6ed9596cd9642c8d483edd000cdd837",
            "0x19811ac410cb7ef9b2d66079609ac8dc5f0ae2d578205fcb193d1262679a24fb",
            "0x8918e45da9368268405a06bfcdf23e1bd44429edf9dbb01591abec4f92bbc41a",
            "0x4c3482ccc068f19c87952782e2068aa7b9d7aed5a31e0bccba7e73d64bea6162",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x46e992299FB47C7ae51Af7F0e7984a3f7d44A400": {
        tier: "5",
        proof: [
            "0xa5778643629a4ed94df57d355594bfc017be9f592fb41bf8c2fe5e882fa556e0",
            "0xf6a072869f1d6eb28e0d46de9a2657cc891ffe5470a05624fd305b9781f78692",
            "0x19811ac410cb7ef9b2d66079609ac8dc5f0ae2d578205fcb193d1262679a24fb",
            "0x8918e45da9368268405a06bfcdf23e1bd44429edf9dbb01591abec4f92bbc41a",
            "0x4c3482ccc068f19c87952782e2068aa7b9d7aed5a31e0bccba7e73d64bea6162",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x562257821731283b842cF689Df9941ddBeC3a2a5": {
        tier: "5",
        proof: [
            "0x6ccf46dbf6a76c02abc2e9efc3d73afd9b84f5e95d116eaebff1022df23f2135",
            "0xf6a072869f1d6eb28e0d46de9a2657cc891ffe5470a05624fd305b9781f78692",
            "0x19811ac410cb7ef9b2d66079609ac8dc5f0ae2d578205fcb193d1262679a24fb",
            "0x8918e45da9368268405a06bfcdf23e1bd44429edf9dbb01591abec4f92bbc41a",
            "0x4c3482ccc068f19c87952782e2068aa7b9d7aed5a31e0bccba7e73d64bea6162",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x31e99699bCCde902afc7C4B6b23bB322b8459d22": {
        tier: "5",
        proof: [
            "0x063bf4ea236f263c19455f8d2f1e93c1619514fb6f4c7b98fddb56cd06a3d730",
            "0x78281fecdf5389536172eadfcc858fcb7ccfc674a994522edf06dd02ac83016d",
            "0xc800afdd2afb929fb674f417d903b18d37b38c4bb5292fc5946ae2eb4bde7834",
            "0x8918e45da9368268405a06bfcdf23e1bd44429edf9dbb01591abec4f92bbc41a",
            "0x4c3482ccc068f19c87952782e2068aa7b9d7aed5a31e0bccba7e73d64bea6162",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x247fFFF746Ba865dCEFD0CE9b5F9d25fD0c46d9d": {
        tier: "5",
        proof: [
            "0xefe6e3793a25e104e9b452dec9aeaf2caf4c8b7a2f2e0c21770d88888ffdce7a",
            "0x78281fecdf5389536172eadfcc858fcb7ccfc674a994522edf06dd02ac83016d",
            "0xc800afdd2afb929fb674f417d903b18d37b38c4bb5292fc5946ae2eb4bde7834",
            "0x8918e45da9368268405a06bfcdf23e1bd44429edf9dbb01591abec4f92bbc41a",
            "0x4c3482ccc068f19c87952782e2068aa7b9d7aed5a31e0bccba7e73d64bea6162",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xdfbDB9b9174862eCB1010C39ca72409C1D63B18F": {
        tier: "5",
        proof: [
            "0xf99b4b03f73511f07f2521627541a820b58809600ca9b467a06504adf58f0b24",
            "0xe2d9fa644c946162de573faa1f1b4dba1a833bdf8278e98dede1f2a1447e6afa",
            "0xc800afdd2afb929fb674f417d903b18d37b38c4bb5292fc5946ae2eb4bde7834",
            "0x8918e45da9368268405a06bfcdf23e1bd44429edf9dbb01591abec4f92bbc41a",
            "0x4c3482ccc068f19c87952782e2068aa7b9d7aed5a31e0bccba7e73d64bea6162",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xf94a8023b6d9a365B38Ca38332e7a09f4fBA737F": {
        tier: "5",
        proof: [
            "0xa3648260b554591a4f4d80da9703066ecb37f18ebd65b9ded5459280f77e5a65",
            "0xe2d9fa644c946162de573faa1f1b4dba1a833bdf8278e98dede1f2a1447e6afa",
            "0xc800afdd2afb929fb674f417d903b18d37b38c4bb5292fc5946ae2eb4bde7834",
            "0x8918e45da9368268405a06bfcdf23e1bd44429edf9dbb01591abec4f92bbc41a",
            "0x4c3482ccc068f19c87952782e2068aa7b9d7aed5a31e0bccba7e73d64bea6162",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xF128EdDf7817092695075cad3c0a4D26aEE622E0": {
        tier: "5",
        proof: [
            "0x39618977997f74405af58a0de19f6b5210354c2afbc3607fea76231956349a41",
            "0xd7cb1aef2c06c32782ce953f8793c583520d9f8b844ca48e4aa3f3744392fd23",
            "0xee1f6da2c5d382386a7b7d149c33bc7d23d71098d2b496ec9d6d712d28262202",
            "0x6a33f0e0bb7948a0bbcfad1433d2d07d3a961df84544f7b0e73a47aca70b27a2",
            "0xa6acd63bc097eaed3b2b27d2a23577f2faf8d36a710253bc999137cc330445e1",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x2a7D69500EDd65535309c9bb6dbDBaA667753A99": {
        tier: "5",
        proof: [
            "0x1741097778e284fc2e1724e6148b5612f5cf4671a65e668bfff645e9d2e6fa84",
            "0xd7cb1aef2c06c32782ce953f8793c583520d9f8b844ca48e4aa3f3744392fd23",
            "0xee1f6da2c5d382386a7b7d149c33bc7d23d71098d2b496ec9d6d712d28262202",
            "0x6a33f0e0bb7948a0bbcfad1433d2d07d3a961df84544f7b0e73a47aca70b27a2",
            "0xa6acd63bc097eaed3b2b27d2a23577f2faf8d36a710253bc999137cc330445e1",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x7Cd74f426Caa3492FcaabFa8DB9Db2Bf4EB5CB54": {
        tier: "5",
        proof: [
            "0xfb2b39488178a72c4c6083e7e68f608e1d41467e213cdd126d1a3aed20d1643e",
            "0xe65759ba5b9a17dc465efff0b9ba665512086e01d3526ed10a1b1a25550f4688",
            "0xee1f6da2c5d382386a7b7d149c33bc7d23d71098d2b496ec9d6d712d28262202",
            "0x6a33f0e0bb7948a0bbcfad1433d2d07d3a961df84544f7b0e73a47aca70b27a2",
            "0xa6acd63bc097eaed3b2b27d2a23577f2faf8d36a710253bc999137cc330445e1",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x585a8D6138F143c7Ba47d65946dD77B19FE1a14A": {
        tier: "5",
        proof: [
            "0x121bab5dd01b496553991f77817f8e56ee9b7251463fbcece82d48261a97a8a8",
            "0xe65759ba5b9a17dc465efff0b9ba665512086e01d3526ed10a1b1a25550f4688",
            "0xee1f6da2c5d382386a7b7d149c33bc7d23d71098d2b496ec9d6d712d28262202",
            "0x6a33f0e0bb7948a0bbcfad1433d2d07d3a961df84544f7b0e73a47aca70b27a2",
            "0xa6acd63bc097eaed3b2b27d2a23577f2faf8d36a710253bc999137cc330445e1",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x66d4908E053F7150038b1bF3579cF384fde0BA72": {
        tier: "5",
        proof: [
            "0x1d85230c4e0568f47bf8cb93896daa4b211621476ba080a66718b3c4781226a0",
            "0x537977095399c56999ced6e48b17e4f91fc6dccc4959a6a3a1efe74c38e8617f",
            "0x45ea7941fd600b1f97e42bfa8234e27f769949ae2770a81bb72fc81ae6ee6175",
            "0x6a33f0e0bb7948a0bbcfad1433d2d07d3a961df84544f7b0e73a47aca70b27a2",
            "0xa6acd63bc097eaed3b2b27d2a23577f2faf8d36a710253bc999137cc330445e1",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xc4eE33C00900DD22F34FA361e2d679A28D513284": {
        tier: "5",
        proof: [
            "0xc4e2283c9a783e68f104630b2201d925a5783f520d813abf82dff9feb7d82335",
            "0x537977095399c56999ced6e48b17e4f91fc6dccc4959a6a3a1efe74c38e8617f",
            "0x45ea7941fd600b1f97e42bfa8234e27f769949ae2770a81bb72fc81ae6ee6175",
            "0x6a33f0e0bb7948a0bbcfad1433d2d07d3a961df84544f7b0e73a47aca70b27a2",
            "0xa6acd63bc097eaed3b2b27d2a23577f2faf8d36a710253bc999137cc330445e1",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xfE9184ba607A976da93227c33592226F73529FAD": {
        tier: "5",
        proof: [
            "0x106fbf055c2aeeda7862371127258ae14922da67e8647643bf7c64850f13bde9",
            "0x653a14547dd4660c830eb7c7f379038f424d0f8cacdaf5e20eaf1552d711c1f3",
            "0x45ea7941fd600b1f97e42bfa8234e27f769949ae2770a81bb72fc81ae6ee6175",
            "0x6a33f0e0bb7948a0bbcfad1433d2d07d3a961df84544f7b0e73a47aca70b27a2",
            "0xa6acd63bc097eaed3b2b27d2a23577f2faf8d36a710253bc999137cc330445e1",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xBbb9f03DA9f5f361E46bD66d69aEf512Fa464a3f": {
        tier: "5",
        proof: [
            "0x111f1950fec3d86c090c4d2bfcbb7e41049d5a220b10a764a6f4f997e5595620",
            "0x653a14547dd4660c830eb7c7f379038f424d0f8cacdaf5e20eaf1552d711c1f3",
            "0x45ea7941fd600b1f97e42bfa8234e27f769949ae2770a81bb72fc81ae6ee6175",
            "0x6a33f0e0bb7948a0bbcfad1433d2d07d3a961df84544f7b0e73a47aca70b27a2",
            "0xa6acd63bc097eaed3b2b27d2a23577f2faf8d36a710253bc999137cc330445e1",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x2E049E867C62CB8B85b07C0A93f62C4e276eC771": {
        tier: "5",
        proof: [
            "0xa9194f975da204047ab0262b304711d40399e6f11b7cfc20722fb436bb291872",
            "0x4e1b4fcf6ea6d2d81ba8f6fe6ada74b868d38e9219c13685efc063f94864fc50",
            "0x3e5514e2e4fbec7fb45f28d8de0993ae79f3872dc09a005064e29c83cb273363",
            "0x67a43101dd0fc5ee4bb81841dfc1af231ae0713787a8bb0d3e5fb70f1f852fee",
            "0xa6acd63bc097eaed3b2b27d2a23577f2faf8d36a710253bc999137cc330445e1",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x105Baf8610D2C2889D60bb4E87CBcA0b6AF69877": {
        tier: "5",
        proof: [
            "0x8072dc1a7e7d03b6ef0798cdc633507b5e820fa313ff096855fce41c47984f03",
            "0x4e1b4fcf6ea6d2d81ba8f6fe6ada74b868d38e9219c13685efc063f94864fc50",
            "0x3e5514e2e4fbec7fb45f28d8de0993ae79f3872dc09a005064e29c83cb273363",
            "0x67a43101dd0fc5ee4bb81841dfc1af231ae0713787a8bb0d3e5fb70f1f852fee",
            "0xa6acd63bc097eaed3b2b27d2a23577f2faf8d36a710253bc999137cc330445e1",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x9795A9Baac2f17E7c7F11686Bc6bC2afc3D84612": {
        tier: "5",
        proof: [
            "0x7e9875dd5b211d9b72c614481af4536419f90faa26689df43749eee6a59bc2ec",
            "0xfdc3b25a57da5e0f0eb06be3eac5f0a501af736275b34aae4b0b375b4433b74f",
            "0x3e5514e2e4fbec7fb45f28d8de0993ae79f3872dc09a005064e29c83cb273363",
            "0x67a43101dd0fc5ee4bb81841dfc1af231ae0713787a8bb0d3e5fb70f1f852fee",
            "0xa6acd63bc097eaed3b2b27d2a23577f2faf8d36a710253bc999137cc330445e1",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x1105408dcCDdBb7cE5d509A5516d4A9A0B6BaaEb": {
        tier: "5",
        proof: [
            "0x633713a4758459decb345bb6bc897f697bc9c1289a4b86f2758d768003845c85",
            "0xfdc3b25a57da5e0f0eb06be3eac5f0a501af736275b34aae4b0b375b4433b74f",
            "0x3e5514e2e4fbec7fb45f28d8de0993ae79f3872dc09a005064e29c83cb273363",
            "0x67a43101dd0fc5ee4bb81841dfc1af231ae0713787a8bb0d3e5fb70f1f852fee",
            "0xa6acd63bc097eaed3b2b27d2a23577f2faf8d36a710253bc999137cc330445e1",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x040D86a5aaAB133D49f59917e1545ad8f0f81f9F": {
        tier: "5",
        proof: [
            "0x9e252b242b33d5488bd07ca4d5f5f2b72482cff31f6e4b222a679927dcc63e4b",
            "0xf36bedf39399d291b23376171fd72d94705a81cf123fb37272ec4f28fde0a81e",
            "0x14c6a31e4c5224df7b1c8443be0c33e666c163e29d6cca2f925155d84cb2d119",
            "0x67a43101dd0fc5ee4bb81841dfc1af231ae0713787a8bb0d3e5fb70f1f852fee",
            "0xa6acd63bc097eaed3b2b27d2a23577f2faf8d36a710253bc999137cc330445e1",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x2Cb3cD0b3338479a59Da6b55e05A751BF0da9347": {
        tier: "5",
        proof: [
            "0x5d6ef7e4d1291133067e4aaf0f3b4bf1aeedb81ecb00a0bf932da5aa603a96cc",
            "0xf36bedf39399d291b23376171fd72d94705a81cf123fb37272ec4f28fde0a81e",
            "0x14c6a31e4c5224df7b1c8443be0c33e666c163e29d6cca2f925155d84cb2d119",
            "0x67a43101dd0fc5ee4bb81841dfc1af231ae0713787a8bb0d3e5fb70f1f852fee",
            "0xa6acd63bc097eaed3b2b27d2a23577f2faf8d36a710253bc999137cc330445e1",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x6fb737D1Ebb73cda6CFA36Fd16D9273065D1b084": {
        tier: "5",
        proof: [
            "0xcf5e1bf1e9ed46983cd464d86748981dcad1777636471bf0783e39662a9e63cd",
            "0xaff4909adbdfb81571feaf64fe7644e0df3ef57f2f07ea31abf953fa16568e49",
            "0x14c6a31e4c5224df7b1c8443be0c33e666c163e29d6cca2f925155d84cb2d119",
            "0x67a43101dd0fc5ee4bb81841dfc1af231ae0713787a8bb0d3e5fb70f1f852fee",
            "0xa6acd63bc097eaed3b2b27d2a23577f2faf8d36a710253bc999137cc330445e1",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x78304FC0e73285917175a0426aa14b6575E26252": {
        tier: "5",
        proof: [
            "0xf04e4a25ef89d84b80b7e9bbe968871fae1c710fb789d519a399dcb084ccbd0d",
            "0xaff4909adbdfb81571feaf64fe7644e0df3ef57f2f07ea31abf953fa16568e49",
            "0x14c6a31e4c5224df7b1c8443be0c33e666c163e29d6cca2f925155d84cb2d119",
            "0x67a43101dd0fc5ee4bb81841dfc1af231ae0713787a8bb0d3e5fb70f1f852fee",
            "0xa6acd63bc097eaed3b2b27d2a23577f2faf8d36a710253bc999137cc330445e1",
            "0xb8b606bf5c8f6523ebea694e4cb43a7374a81c69aa3a46c3b4da47e0535470c1",
            "0x1d5e17f0206d8c40e1a88f00213bf59be7a270d6a6a1d6ad01f6dfc2a960bd96",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xBc99767FaAac1A71cFBe1824026C723Fe45F13Fe": {
        tier: "5",
        proof: [
            "0xcb1a383710b48c7d36b16b76ab78f37b05085def840c0175561823e3971e750f",
            "0xde87dbcbf921c01b5a153920ac18c3214e5d62903806acd1ff757184aa4da0c2",
            "0x571b8f7620dc7ce5d0b0b8a0b9f4a0e0de61cf49ce8f16034e39b2aca18aafb8",
            "0xb1a472834f9b195dc4ce386216421f4e6308eb6b2ed726ff67b606124b11812f",
            "0x0356d8a41d95cbf73e6334b0ab2b208974ec897128bb3817419d953ad2f2b3cf",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x14E9e4F62A085750b874863c7ECe73B2c89CD1C2": {
        tier: "5",
        proof: [
            "0xb074e6c08dee6e1d7a6c6f135e72acea7ba923a15ec218e0118624c382881740",
            "0xde87dbcbf921c01b5a153920ac18c3214e5d62903806acd1ff757184aa4da0c2",
            "0x571b8f7620dc7ce5d0b0b8a0b9f4a0e0de61cf49ce8f16034e39b2aca18aafb8",
            "0xb1a472834f9b195dc4ce386216421f4e6308eb6b2ed726ff67b606124b11812f",
            "0x0356d8a41d95cbf73e6334b0ab2b208974ec897128bb3817419d953ad2f2b3cf",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x9d1b972e7ceE2317e24719DE943b2da0B9435454": {
        tier: "5",
        proof: [
            "0xd777fef373c486b0d9e1a960d12245e17b7008951cbc22554ae8b6224f9f8125",
            "0x8a2d8f9d0961b3c61e0369bb3e92d743fa4e566307f1afcd07873f32db87c3f4",
            "0x571b8f7620dc7ce5d0b0b8a0b9f4a0e0de61cf49ce8f16034e39b2aca18aafb8",
            "0xb1a472834f9b195dc4ce386216421f4e6308eb6b2ed726ff67b606124b11812f",
            "0x0356d8a41d95cbf73e6334b0ab2b208974ec897128bb3817419d953ad2f2b3cf",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x5170CCF3f96c73AAA1F560912e91049CA77aadE6": {
        tier: "5",
        proof: [
            "0xaf5909298374251511f4717aaf9eabb0e3bb1784a34adbd788dca6d8188acd9e",
            "0x8a2d8f9d0961b3c61e0369bb3e92d743fa4e566307f1afcd07873f32db87c3f4",
            "0x571b8f7620dc7ce5d0b0b8a0b9f4a0e0de61cf49ce8f16034e39b2aca18aafb8",
            "0xb1a472834f9b195dc4ce386216421f4e6308eb6b2ed726ff67b606124b11812f",
            "0x0356d8a41d95cbf73e6334b0ab2b208974ec897128bb3817419d953ad2f2b3cf",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x5904147C022eF42608ced0fE6F1464B20A25CAe6": {
        tier: "5",
        proof: [
            "0x814b6f497b8a028d335ac7ce6713e56d365e36cadad607406bb9099effa53e77",
            "0x754f0f0b257761747f6b6d0757db594ecf28a4768f9967d2116b33197e444a06",
            "0xca3e7fa2b66ae596726b430376fe508d903216a2e40adde28699ac499b90b55f",
            "0xb1a472834f9b195dc4ce386216421f4e6308eb6b2ed726ff67b606124b11812f",
            "0x0356d8a41d95cbf73e6334b0ab2b208974ec897128bb3817419d953ad2f2b3cf",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xF90CD961508717f325EFEA56Fd8757F106F8807A": {
        tier: "5",
        proof: [
            "0x6ae8d2d4bbc107d9e7fd715c4c29453775d641bee54d6bcc58d9a5ddd2d1bbc5",
            "0x754f0f0b257761747f6b6d0757db594ecf28a4768f9967d2116b33197e444a06",
            "0xca3e7fa2b66ae596726b430376fe508d903216a2e40adde28699ac499b90b55f",
            "0xb1a472834f9b195dc4ce386216421f4e6308eb6b2ed726ff67b606124b11812f",
            "0x0356d8a41d95cbf73e6334b0ab2b208974ec897128bb3817419d953ad2f2b3cf",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xef2CcDb65F276891fCc615DcDCbCAdE767c894E8": {
        tier: "5",
        proof: [
            "0xd04284395d79823526a9529adf9733595b739f64126f419b14d31ea5ca33f1a7",
            "0xdce45a9b65132c0c70546005d777931c12d48363deebd09f6810d235d4b7b6c9",
            "0xca3e7fa2b66ae596726b430376fe508d903216a2e40adde28699ac499b90b55f",
            "0xb1a472834f9b195dc4ce386216421f4e6308eb6b2ed726ff67b606124b11812f",
            "0x0356d8a41d95cbf73e6334b0ab2b208974ec897128bb3817419d953ad2f2b3cf",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x7DDeBdE4C543fd5335423bDFa788Eb64657A74a0": {
        tier: "5",
        proof: [
            "0xcbfcd1ace87cd24b5cc60f673a89580d6f5226aa96f2e89b7794880f7279f122",
            "0xdce45a9b65132c0c70546005d777931c12d48363deebd09f6810d235d4b7b6c9",
            "0xca3e7fa2b66ae596726b430376fe508d903216a2e40adde28699ac499b90b55f",
            "0xb1a472834f9b195dc4ce386216421f4e6308eb6b2ed726ff67b606124b11812f",
            "0x0356d8a41d95cbf73e6334b0ab2b208974ec897128bb3817419d953ad2f2b3cf",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xabC1da2FB3Abc27ea0d403063280794ce9b841C4": {
        tier: "5",
        proof: [
            "0x825fc916d21292febeae14f8eb183889d6ba2dc3e2802ed02e2036bf44ac0fb1",
            "0x3d9429d64010aba989fd35607a4f101531c9b27ba82ab64752771929cf56d479",
            "0x5d706ead319b2a2f5e19df34a4c47b55a89c0f11114875625874c1baafbdc94b",
            "0xe7e2bb1bb2f03f64fdb93f51ef1e782fd23c596c5691f9484c93a303adc04a6d",
            "0x0356d8a41d95cbf73e6334b0ab2b208974ec897128bb3817419d953ad2f2b3cf",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x3C824b3AeCF99576D604FF33e8c0867063e594dD": {
        tier: "5",
        proof: [
            "0x5c5c046ebcd691b0f965bc3248b096f3a65c242021cd6bde344875d715a987d9",
            "0x3d9429d64010aba989fd35607a4f101531c9b27ba82ab64752771929cf56d479",
            "0x5d706ead319b2a2f5e19df34a4c47b55a89c0f11114875625874c1baafbdc94b",
            "0xe7e2bb1bb2f03f64fdb93f51ef1e782fd23c596c5691f9484c93a303adc04a6d",
            "0x0356d8a41d95cbf73e6334b0ab2b208974ec897128bb3817419d953ad2f2b3cf",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xa4c10A0486e4014376e7Cdc0BB10A4e0AaC1844C": {
        tier: "5",
        proof: [
            "0x7b41cb10e6e097a12e65de09913d48a5fe309f04976aea0cca7d9c9f0373c16c",
            "0xff5b610f52a2483e1f36209592a1640518996cbe782badd0c29e2ff6abd4ca95",
            "0x5d706ead319b2a2f5e19df34a4c47b55a89c0f11114875625874c1baafbdc94b",
            "0xe7e2bb1bb2f03f64fdb93f51ef1e782fd23c596c5691f9484c93a303adc04a6d",
            "0x0356d8a41d95cbf73e6334b0ab2b208974ec897128bb3817419d953ad2f2b3cf",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xF61030d320E71256a43ec22839db345d80ac84B3": {
        tier: "5",
        proof: [
            "0x870316856d6b83be9c51c574edc6ccfbfffa21af9d20733c4a18e3c8f2915c59",
            "0xff5b610f52a2483e1f36209592a1640518996cbe782badd0c29e2ff6abd4ca95",
            "0x5d706ead319b2a2f5e19df34a4c47b55a89c0f11114875625874c1baafbdc94b",
            "0xe7e2bb1bb2f03f64fdb93f51ef1e782fd23c596c5691f9484c93a303adc04a6d",
            "0x0356d8a41d95cbf73e6334b0ab2b208974ec897128bb3817419d953ad2f2b3cf",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xB6A7DF3B2185C880B244c953e30751f511250884": {
        tier: "5",
        proof: [
            "0x4e012defb9b6a2ca533d49889e40cbeeef01dc122217bbf2d391023d3bc77242",
            "0xaa948094139f29393d6d1c086978d9b1bb339146e91e72b16f719af6ad7750f3",
            "0x3d2b3b14faf12af4802dcc65271d73fefe01a4b94084e468e294e6ffb41c37a5",
            "0xe7e2bb1bb2f03f64fdb93f51ef1e782fd23c596c5691f9484c93a303adc04a6d",
            "0x0356d8a41d95cbf73e6334b0ab2b208974ec897128bb3817419d953ad2f2b3cf",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xC1202B2da243467882439944885339f9Fd71279C": {
        tier: "5",
        proof: [
            "0x578bc406afd21622fcaca4f1180a92a3532fd2efe42a42667ddbab1574750ef6",
            "0xaa948094139f29393d6d1c086978d9b1bb339146e91e72b16f719af6ad7750f3",
            "0x3d2b3b14faf12af4802dcc65271d73fefe01a4b94084e468e294e6ffb41c37a5",
            "0xe7e2bb1bb2f03f64fdb93f51ef1e782fd23c596c5691f9484c93a303adc04a6d",
            "0x0356d8a41d95cbf73e6334b0ab2b208974ec897128bb3817419d953ad2f2b3cf",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x0D7652BA32942931Fb6D771551E41aff77331e2A": {
        tier: "5",
        proof: [
            "0x13d0d31df798f0ff1aa3d856204715e098ce269e7c9bebd617a3aa158a4b4f24",
            "0x89dee3fbd37bfa06fd6ea9168691f9759f78f7df2bd67cd735850f7c4dfed22b",
            "0x3d2b3b14faf12af4802dcc65271d73fefe01a4b94084e468e294e6ffb41c37a5",
            "0xe7e2bb1bb2f03f64fdb93f51ef1e782fd23c596c5691f9484c93a303adc04a6d",
            "0x0356d8a41d95cbf73e6334b0ab2b208974ec897128bb3817419d953ad2f2b3cf",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x2585B94bD758107e6D1698a0786e84EFAbC36882": {
        tier: "5",
        proof: [
            "0x5cff54ef4d814b00a7763ef77a3f4baed29f690a3be2a1848a7fedca21e5e320",
            "0x89dee3fbd37bfa06fd6ea9168691f9759f78f7df2bd67cd735850f7c4dfed22b",
            "0x3d2b3b14faf12af4802dcc65271d73fefe01a4b94084e468e294e6ffb41c37a5",
            "0xe7e2bb1bb2f03f64fdb93f51ef1e782fd23c596c5691f9484c93a303adc04a6d",
            "0x0356d8a41d95cbf73e6334b0ab2b208974ec897128bb3817419d953ad2f2b3cf",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x7ecfcA59ED99F135Fb8a41501514f807B1f00655": {
        tier: "5",
        proof: [
            "0xe2eab91786e196d8c1a189a770c5484c5dffb57feef8e0ca6ceb15f539402ff3",
            "0x227cecb1bb86fa4eb53c3803075672281a5e38b938091af48ad44aa208738f23",
            "0xa066efe6572a786bb5ab06ba5d852cbb5c6e0e86c47ff6d3f77986d2aca631d3",
            "0x36711c7a1b3017c1bf99c9dd24d3c927f2d048f9c90d71f97674bf5bb8dae281",
            "0xbd707fa9b9ef4500407c0bbff85c424838cfea6f491dcc5925605a710d2250c9",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x8264e9e0f4CbcBBbb3F8eCAec0A625B590Ae790e": {
        tier: "5",
        proof: [
            "0x6d296b994a3aaa6e8c88a187d8805ce215fffe815a547df0aa086cbcb15b106a",
            "0x227cecb1bb86fa4eb53c3803075672281a5e38b938091af48ad44aa208738f23",
            "0xa066efe6572a786bb5ab06ba5d852cbb5c6e0e86c47ff6d3f77986d2aca631d3",
            "0x36711c7a1b3017c1bf99c9dd24d3c927f2d048f9c90d71f97674bf5bb8dae281",
            "0xbd707fa9b9ef4500407c0bbff85c424838cfea6f491dcc5925605a710d2250c9",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x3811Eb9e4E525765AAAB95A8fb3c04061Ece3321": {
        tier: "6",
        proof: [
            "0x527681eb49987f49e7953967e33086b0fb3d7199d83d29fba6eaf7823c228a69",
            "0x0d59a472a246b3417efb5115c8976ac390eeb683e16b244ad26d4535234fb881",
            "0xa066efe6572a786bb5ab06ba5d852cbb5c6e0e86c47ff6d3f77986d2aca631d3",
            "0x36711c7a1b3017c1bf99c9dd24d3c927f2d048f9c90d71f97674bf5bb8dae281",
            "0xbd707fa9b9ef4500407c0bbff85c424838cfea6f491dcc5925605a710d2250c9",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x5CdA6f6163e94282d3d8dD413A2B5B2da3ECfa7E": {
        tier: "6",
        proof: [
            "0x57be7f7fa803e3bd80d630158d5de7eba0d5f37cfc068fb870340ae0dd93ce10",
            "0x0d59a472a246b3417efb5115c8976ac390eeb683e16b244ad26d4535234fb881",
            "0xa066efe6572a786bb5ab06ba5d852cbb5c6e0e86c47ff6d3f77986d2aca631d3",
            "0x36711c7a1b3017c1bf99c9dd24d3c927f2d048f9c90d71f97674bf5bb8dae281",
            "0xbd707fa9b9ef4500407c0bbff85c424838cfea6f491dcc5925605a710d2250c9",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xA9f732c5FF492f5C85fbA0D1C1E96ac56D40293B": {
        tier: "6",
        proof: [
            "0x13f153fad44c8232b982f1d9fd114037324f9bd5d98592242e86e13787730b05",
            "0x43f249d4b9e348974c85fede9923d10b34355fe3790f78f9a89cd79403c25713",
            "0x5ede49623f0a2af8cc58d82b8ccbe1b9238169f22788e7a2165d251b159fc4aa",
            "0x36711c7a1b3017c1bf99c9dd24d3c927f2d048f9c90d71f97674bf5bb8dae281",
            "0xbd707fa9b9ef4500407c0bbff85c424838cfea6f491dcc5925605a710d2250c9",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xA910C8CD9a67AB773B1d411db32A841d17e2d610": {
        tier: "6",
        proof: [
            "0xebf560580c5acd9712a4bd9b7feacbb51438a7314ad6db747c75ac8377c15413",
            "0x43f249d4b9e348974c85fede9923d10b34355fe3790f78f9a89cd79403c25713",
            "0x5ede49623f0a2af8cc58d82b8ccbe1b9238169f22788e7a2165d251b159fc4aa",
            "0x36711c7a1b3017c1bf99c9dd24d3c927f2d048f9c90d71f97674bf5bb8dae281",
            "0xbd707fa9b9ef4500407c0bbff85c424838cfea6f491dcc5925605a710d2250c9",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xb3C3b3038bD2231B7b61A510102b459e694690A4": {
        tier: "6",
        proof: [
            "0x5689cb7ebf8f195cb7ff7d7d3f4b922712d0662bfcf01b03fa511bcfadf5670d",
            "0xeb197ad2226675cacf6217ca81a4db0f78fa57744d83209d798d842d13659392",
            "0x5ede49623f0a2af8cc58d82b8ccbe1b9238169f22788e7a2165d251b159fc4aa",
            "0x36711c7a1b3017c1bf99c9dd24d3c927f2d048f9c90d71f97674bf5bb8dae281",
            "0xbd707fa9b9ef4500407c0bbff85c424838cfea6f491dcc5925605a710d2250c9",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x83Cd2B925293E1F096C58b68Fb3259A64e156d8a": {
        tier: "6",
        proof: [
            "0xa1294d659bad2ef3f7c923f36352df7bf0b394fa79b310aa0dcdc99a26c65767",
            "0xeb197ad2226675cacf6217ca81a4db0f78fa57744d83209d798d842d13659392",
            "0x5ede49623f0a2af8cc58d82b8ccbe1b9238169f22788e7a2165d251b159fc4aa",
            "0x36711c7a1b3017c1bf99c9dd24d3c927f2d048f9c90d71f97674bf5bb8dae281",
            "0xbd707fa9b9ef4500407c0bbff85c424838cfea6f491dcc5925605a710d2250c9",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xFe5c47D9D9d55afC0feB47De13538eDbE021346D": {
        tier: "6",
        proof: [
            "0xcb31bab1f64f86ef41289350f329f5b61b4025b6f0c4710e195f892cb2cd99f0",
            "0x49555a18b0332fad0dd049fbe7521005b9191c11fa8c5a0497c30f13596de54d",
            "0xa437425bf03313c1581471c11048c2a467e7398ad70fe39b878db3eeec6d3de3",
            "0x37911dfe7aa7eaf231f5a50aa4b2b6e7c6fda1b14d8fd976b4263321edda28b2",
            "0xbd707fa9b9ef4500407c0bbff85c424838cfea6f491dcc5925605a710d2250c9",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xe568ca143592DF06A7EABfd4d9D563F1289dc607": {
        tier: "6",
        proof: [
            "0xebc4b36a58e9ecef0fd3bd67279b19402b4c7744d62bb06a0496262c68345bfc",
            "0x49555a18b0332fad0dd049fbe7521005b9191c11fa8c5a0497c30f13596de54d",
            "0xa437425bf03313c1581471c11048c2a467e7398ad70fe39b878db3eeec6d3de3",
            "0x37911dfe7aa7eaf231f5a50aa4b2b6e7c6fda1b14d8fd976b4263321edda28b2",
            "0xbd707fa9b9ef4500407c0bbff85c424838cfea6f491dcc5925605a710d2250c9",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x5F498F16c790A920d08aA6d2a90e4Ef677a856E6": {
        tier: "6",
        proof: [
            "0xaddbe7f7fe9b1a9af66975239acb61edde6a91019d544d9027ed4637e41c73b8",
            "0x80b60f59e27a85cfccd99a6daf9a2d0b5c21db1f7eec4a3823f462ee3789fea6",
            "0xa437425bf03313c1581471c11048c2a467e7398ad70fe39b878db3eeec6d3de3",
            "0x37911dfe7aa7eaf231f5a50aa4b2b6e7c6fda1b14d8fd976b4263321edda28b2",
            "0xbd707fa9b9ef4500407c0bbff85c424838cfea6f491dcc5925605a710d2250c9",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x79dC911bA6f8424A4b6158EA0B24168A8e0e0Fe4": {
        tier: "6",
        proof: [
            "0xd4b8bc67a5e906cd70bbedd032ba85cf0e9030c3d4101943718d012a3095994b",
            "0x80b60f59e27a85cfccd99a6daf9a2d0b5c21db1f7eec4a3823f462ee3789fea6",
            "0xa437425bf03313c1581471c11048c2a467e7398ad70fe39b878db3eeec6d3de3",
            "0x37911dfe7aa7eaf231f5a50aa4b2b6e7c6fda1b14d8fd976b4263321edda28b2",
            "0xbd707fa9b9ef4500407c0bbff85c424838cfea6f491dcc5925605a710d2250c9",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xBf7DdF2A312956C076a9A0A688a5f7620e446EfE": {
        tier: "6",
        proof: [
            "0x5b90b670b58315a31cb8176813a24a0f267cd160d9b4c6f5abf687fdf7defb7b",
            "0x29f551f6bf1fc0ea8608dbe649eb9364f93a6c9d09611cacc0881fdbbea78178",
            "0x8169470a72616a445dfdfbc3f93e9243b3de90bb1407543c95bc05088ed3a3f7",
            "0x37911dfe7aa7eaf231f5a50aa4b2b6e7c6fda1b14d8fd976b4263321edda28b2",
            "0xbd707fa9b9ef4500407c0bbff85c424838cfea6f491dcc5925605a710d2250c9",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xaD459F381EC15fD94A001CF6686518BE3C58A83B": {
        tier: "6",
        proof: [
            "0x4e2f2cc9c418819a1aa3639fb55d2ad84a09a9c8b66c4159187c2e9ddb23293c",
            "0x29f551f6bf1fc0ea8608dbe649eb9364f93a6c9d09611cacc0881fdbbea78178",
            "0x8169470a72616a445dfdfbc3f93e9243b3de90bb1407543c95bc05088ed3a3f7",
            "0x37911dfe7aa7eaf231f5a50aa4b2b6e7c6fda1b14d8fd976b4263321edda28b2",
            "0xbd707fa9b9ef4500407c0bbff85c424838cfea6f491dcc5925605a710d2250c9",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x42c9B4312c3586e8233BA5DF4853F011aa286d9c": {
        tier: "6",
        proof: [
            "0x4b009d8e03e73acc258a40adf52502498eadcbef8c12813d76c7f876f6cfea2b",
            "0x0c1134e665d46d09b66d371121c26896280ec8e5714b03d9134a552209417716",
            "0x8169470a72616a445dfdfbc3f93e9243b3de90bb1407543c95bc05088ed3a3f7",
            "0x37911dfe7aa7eaf231f5a50aa4b2b6e7c6fda1b14d8fd976b4263321edda28b2",
            "0xbd707fa9b9ef4500407c0bbff85c424838cfea6f491dcc5925605a710d2250c9",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xb1736920C118e50D61a10c3fcFffF043C200AadD": {
        tier: "6",
        proof: [
            "0x66cc0c5e429f45f2633ce9e2749b31c440542304832dfeceb2aac1919ea1187a",
            "0x0c1134e665d46d09b66d371121c26896280ec8e5714b03d9134a552209417716",
            "0x8169470a72616a445dfdfbc3f93e9243b3de90bb1407543c95bc05088ed3a3f7",
            "0x37911dfe7aa7eaf231f5a50aa4b2b6e7c6fda1b14d8fd976b4263321edda28b2",
            "0xbd707fa9b9ef4500407c0bbff85c424838cfea6f491dcc5925605a710d2250c9",
            "0x793349615b5571a5f27904f01826f5b0c781eca3e938de5fce8eb6c5e3846116",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x5e11534344B8C1fDa947B37Dc57B8734232a6B1C": {
        tier: "6",
        proof: [
            "0x9e597aad785c3aa515d74397331081e54e3a26b85724b52e5b8a93e5782979a6",
            "0xd11605b50ee831d33831e5c4805cde20a0e3ea7de4119dc5398cca1d34e6c6af",
            "0xf93fbc0a84c123a8483e19be08cd212d2d5a022c1668455e1c7c4163a89c7043",
            "0x81e19594926b328b385edbc6c3a173d51a66506744f2f66789a550dc9be2f17d",
            "0x3fd472886420b4b6f6b3f10ec6d5893d4ab4f88b492de01ee9a2d01f581ad8f9",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x2BE760D621dA4728cBC8763145B9B6838D7Bab43": {
        tier: "6",
        proof: [
            "0x8895fdbaac05069fe06d68e681b1a20d8cdb077a6d2908560153d225bca95e23",
            "0xd11605b50ee831d33831e5c4805cde20a0e3ea7de4119dc5398cca1d34e6c6af",
            "0xf93fbc0a84c123a8483e19be08cd212d2d5a022c1668455e1c7c4163a89c7043",
            "0x81e19594926b328b385edbc6c3a173d51a66506744f2f66789a550dc9be2f17d",
            "0x3fd472886420b4b6f6b3f10ec6d5893d4ab4f88b492de01ee9a2d01f581ad8f9",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x4cA22a82CfC78bC9F63d3fd95F713bA1449a7092": {
        tier: "6",
        proof: [
            "0xc851735513cbb0a7f71eb3d46763c0b01156e733adf13947ac4b6a8d8cf59d0b",
            "0x22b5beabd645db9a70241d33b386c030e0753b7017b7df7ff1ddff3cb1f55913",
            "0xf93fbc0a84c123a8483e19be08cd212d2d5a022c1668455e1c7c4163a89c7043",
            "0x81e19594926b328b385edbc6c3a173d51a66506744f2f66789a550dc9be2f17d",
            "0x3fd472886420b4b6f6b3f10ec6d5893d4ab4f88b492de01ee9a2d01f581ad8f9",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x56511585A5A23dEa48fcC0Aa0a9A13800dEC0531": {
        tier: "6",
        proof: [
            "0x9a9fdd22e8524b85ee6c7eae97ebf7430d1cb3f3d69e18a19d282fb15aca9d18",
            "0x22b5beabd645db9a70241d33b386c030e0753b7017b7df7ff1ddff3cb1f55913",
            "0xf93fbc0a84c123a8483e19be08cd212d2d5a022c1668455e1c7c4163a89c7043",
            "0x81e19594926b328b385edbc6c3a173d51a66506744f2f66789a550dc9be2f17d",
            "0x3fd472886420b4b6f6b3f10ec6d5893d4ab4f88b492de01ee9a2d01f581ad8f9",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x9281f4d044a1C67E7647148ed5aCE6D63221315A": {
        tier: "6",
        proof: [
            "0xf1a4d4193b1763b55c774d851ed46fd7ac5c563c5da3b711589da9fceee8f9e8",
            "0xd48a9feeaabe5ca3a943fe0ca7fbb010ba4430e7d1cb0bca29deca149e99ba12",
            "0x2fbf15e83edcbea1c2e01be6950c101c138eae04b1d7b733e1ce87a19adcc841",
            "0x81e19594926b328b385edbc6c3a173d51a66506744f2f66789a550dc9be2f17d",
            "0x3fd472886420b4b6f6b3f10ec6d5893d4ab4f88b492de01ee9a2d01f581ad8f9",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x44340F7dc53bF90363E503350bbEDf69e2D7870c": {
        tier: "6",
        proof: [
            "0xf3638a64fe079885a8cbb41a8d5840cab6524e4e3a298f860e41e991df0fd659",
            "0xd48a9feeaabe5ca3a943fe0ca7fbb010ba4430e7d1cb0bca29deca149e99ba12",
            "0x2fbf15e83edcbea1c2e01be6950c101c138eae04b1d7b733e1ce87a19adcc841",
            "0x81e19594926b328b385edbc6c3a173d51a66506744f2f66789a550dc9be2f17d",
            "0x3fd472886420b4b6f6b3f10ec6d5893d4ab4f88b492de01ee9a2d01f581ad8f9",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x21F7dBA8f1B6b8cebf91742F2Fa58f60EC30ad0D": {
        tier: "6",
        proof: [
            "0xf98850578aaf61bf7ae7e4dc05a2ad5d9987cd3bcb9c09b89f24ce0a3832fcfa",
            "0x196769505685153c4c8f7bc808ec08775ed9fcda4b7caec2ba09c17a5d43887d",
            "0x2fbf15e83edcbea1c2e01be6950c101c138eae04b1d7b733e1ce87a19adcc841",
            "0x81e19594926b328b385edbc6c3a173d51a66506744f2f66789a550dc9be2f17d",
            "0x3fd472886420b4b6f6b3f10ec6d5893d4ab4f88b492de01ee9a2d01f581ad8f9",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x75B9B236801B273003beb57F823b434AE49f1be8": {
        tier: "6",
        proof: [
            "0xa5b6697e13a2d2e696444f1f1e88d4e60d512aac51400af2fc17e880f37e80d2",
            "0x196769505685153c4c8f7bc808ec08775ed9fcda4b7caec2ba09c17a5d43887d",
            "0x2fbf15e83edcbea1c2e01be6950c101c138eae04b1d7b733e1ce87a19adcc841",
            "0x81e19594926b328b385edbc6c3a173d51a66506744f2f66789a550dc9be2f17d",
            "0x3fd472886420b4b6f6b3f10ec6d5893d4ab4f88b492de01ee9a2d01f581ad8f9",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x2da97A5A97BE1E683B0336829460207751F1DC72": {
        tier: "6",
        proof: [
            "0xec7d2bbd8a2c2d9ad9d84aa661d563cd126984214f693a6bf25872f661df5c0a",
            "0x46a4b4fc75f540bf7956db7380b7c17778db86cffce91d49b2dc0a171084acbe",
            "0xa37977c00e282ca764150ce92c1381ad8a7f85a2313cf2f8abb0d3686ddbc88a",
            "0x94e72873aa9319632c6f2b3f727d9e970fab3e63abb4bf84f268f5fb20533269",
            "0x3fd472886420b4b6f6b3f10ec6d5893d4ab4f88b492de01ee9a2d01f581ad8f9",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x3E485fE58a30D0C0370a2bB6e9C66f2A21b2384C": {
        tier: "6",
        proof: [
            "0xe714571a486dff7746ba4d1eb8313f87bff08df8a1b3f8e418eb408090ba53d9",
            "0x46a4b4fc75f540bf7956db7380b7c17778db86cffce91d49b2dc0a171084acbe",
            "0xa37977c00e282ca764150ce92c1381ad8a7f85a2313cf2f8abb0d3686ddbc88a",
            "0x94e72873aa9319632c6f2b3f727d9e970fab3e63abb4bf84f268f5fb20533269",
            "0x3fd472886420b4b6f6b3f10ec6d5893d4ab4f88b492de01ee9a2d01f581ad8f9",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x792655Dd35d14E869F157c6932b302B784e86685": {
        tier: "6",
        proof: [
            "0xf980acc564b8b578f179710a96639aed8d4bfb12f8ade3db500dfb45e958d890",
            "0x3e15012d262e897e696bd136691ed5a61d7d5bd3291bc1da34859ca12b2ce05d",
            "0xa37977c00e282ca764150ce92c1381ad8a7f85a2313cf2f8abb0d3686ddbc88a",
            "0x94e72873aa9319632c6f2b3f727d9e970fab3e63abb4bf84f268f5fb20533269",
            "0x3fd472886420b4b6f6b3f10ec6d5893d4ab4f88b492de01ee9a2d01f581ad8f9",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xEf4d102AA7e014032671122979e8c473C5a62b4C": {
        tier: "6",
        proof: [
            "0x2a6b0cb5314b4da59a37b940f2d1c53cf5a29a507e6abbaf4eeb773ad29e19b5",
            "0x3e15012d262e897e696bd136691ed5a61d7d5bd3291bc1da34859ca12b2ce05d",
            "0xa37977c00e282ca764150ce92c1381ad8a7f85a2313cf2f8abb0d3686ddbc88a",
            "0x94e72873aa9319632c6f2b3f727d9e970fab3e63abb4bf84f268f5fb20533269",
            "0x3fd472886420b4b6f6b3f10ec6d5893d4ab4f88b492de01ee9a2d01f581ad8f9",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x6e0a1A858dEfE6d965B88d9acbAfc20436A4aDEf": {
        tier: "6",
        proof: [
            "0x78c7e373319bfb322328019cc5ba93c0e15f62b1373098f1521920217ef1d0d3",
            "0xd38ef3a937aed0cc1f87b9b63d318572d99b8d142c005e49797f3d1b519f4469",
            "0xb8cbf877b5cccfa43f4d84c399c6c28611b2595510cd5bb0573e99298e469f67",
            "0x94e72873aa9319632c6f2b3f727d9e970fab3e63abb4bf84f268f5fb20533269",
            "0x3fd472886420b4b6f6b3f10ec6d5893d4ab4f88b492de01ee9a2d01f581ad8f9",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x0EB47BEabd9CE2cab7CbED57aA6B040975BDc1b7": {
        tier: "6",
        proof: [
            "0x2fd69b4f6351d5f7978991e6f94d58fd3a1b721776d4b35c0509cadf39f71272",
            "0xd38ef3a937aed0cc1f87b9b63d318572d99b8d142c005e49797f3d1b519f4469",
            "0xb8cbf877b5cccfa43f4d84c399c6c28611b2595510cd5bb0573e99298e469f67",
            "0x94e72873aa9319632c6f2b3f727d9e970fab3e63abb4bf84f268f5fb20533269",
            "0x3fd472886420b4b6f6b3f10ec6d5893d4ab4f88b492de01ee9a2d01f581ad8f9",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x0EA9f5D0C97b430D5426465e2832A504Bd6Dd9F9": {
        tier: "6",
        proof: [
            "0x78debb01c9c1410052c6ce9a682cde5e884c8fe6df51a914a22662c8952cff6e",
            "0x5f21c84432749dd61cc3f8e5d9ea2b880b6c073dbdd1f47610cd0c2d12ecdfb7",
            "0xb8cbf877b5cccfa43f4d84c399c6c28611b2595510cd5bb0573e99298e469f67",
            "0x94e72873aa9319632c6f2b3f727d9e970fab3e63abb4bf84f268f5fb20533269",
            "0x3fd472886420b4b6f6b3f10ec6d5893d4ab4f88b492de01ee9a2d01f581ad8f9",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xfd6189774DFF4a944DfC77f2ceD9EF5696d02B6e": {
        tier: "6",
        proof: [
            "0x16e7e29c711ac4489e32ae6266f7a207511f6ca4f0d6a5b4ebe5b44a8ff60dc7",
            "0x5f21c84432749dd61cc3f8e5d9ea2b880b6c073dbdd1f47610cd0c2d12ecdfb7",
            "0xb8cbf877b5cccfa43f4d84c399c6c28611b2595510cd5bb0573e99298e469f67",
            "0x94e72873aa9319632c6f2b3f727d9e970fab3e63abb4bf84f268f5fb20533269",
            "0x3fd472886420b4b6f6b3f10ec6d5893d4ab4f88b492de01ee9a2d01f581ad8f9",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x8625d262e2E2F5529499C79F5E26Ff20D644F3A9": {
        tier: "6",
        proof: [
            "0x5dba2a72e1ea4638d2f511203c33437b63697387410b575d94945941ed5c8e59",
            "0x32b10d321a3b97bcc92152f3c50c703ff38472e485afa6e1273c683745b64a66",
            "0xbe33fb657d93214e6814de1aad3b1db922b9481acca69026d3d57d2fe74834b7",
            "0x59b8be9f17ad61a321a44a430877c826a857964e07cb797a6fbb3b99ddcd605d",
            "0x6780352ca93ad9ac7829eeae46466105427e5239e66e20bcdac3767d5d357c54",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xb38BD68A6F0BA599808D65c7A9cF2a428105b680": {
        tier: "6",
        proof: [
            "0xc6042c124ccf26f92d6b455b945a021d0cf43e72c39e016047d27f0bd7cd9f2a",
            "0x32b10d321a3b97bcc92152f3c50c703ff38472e485afa6e1273c683745b64a66",
            "0xbe33fb657d93214e6814de1aad3b1db922b9481acca69026d3d57d2fe74834b7",
            "0x59b8be9f17ad61a321a44a430877c826a857964e07cb797a6fbb3b99ddcd605d",
            "0x6780352ca93ad9ac7829eeae46466105427e5239e66e20bcdac3767d5d357c54",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x4d85629020765Ee49C80aDDE14C97C14448E3F77": {
        tier: "6",
        proof: [
            "0xd7001aef5d54eaf4337bd660beef92a6c8e2ebc35448e6d3dcdcb786c248c4a2",
            "0x2defb841c533763c353e2ef9833bf092254fb91d9d1c823c4c5e1f074e0c14da",
            "0xbe33fb657d93214e6814de1aad3b1db922b9481acca69026d3d57d2fe74834b7",
            "0x59b8be9f17ad61a321a44a430877c826a857964e07cb797a6fbb3b99ddcd605d",
            "0x6780352ca93ad9ac7829eeae46466105427e5239e66e20bcdac3767d5d357c54",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x6609DFA1cb75d74f4fF39c8a5057bD111FBA5B22": {
        tier: "6",
        proof: [
            "0x423ec782cfad49b2edd4f2911dcad9dde4d09b9c0f571654668d27ae1045bd3f",
            "0x2defb841c533763c353e2ef9833bf092254fb91d9d1c823c4c5e1f074e0c14da",
            "0xbe33fb657d93214e6814de1aad3b1db922b9481acca69026d3d57d2fe74834b7",
            "0x59b8be9f17ad61a321a44a430877c826a857964e07cb797a6fbb3b99ddcd605d",
            "0x6780352ca93ad9ac7829eeae46466105427e5239e66e20bcdac3767d5d357c54",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x65Ba4f92D7DFA813DdBd849D9Faf38a723Dd9b12": {
        tier: "6",
        proof: [
            "0x09aedfd72c52d432981923da48baff8f6d9aa8662b96a8c2b37722c8b20dc3be",
            "0x3f6645eb986815cca99cc0711365f2ff023d4b8079232c737fa6c00dbfc7bc91",
            "0xe9d27116069813c57332601cafd5c2ba17614714352f29b88996a1df2937e030",
            "0x59b8be9f17ad61a321a44a430877c826a857964e07cb797a6fbb3b99ddcd605d",
            "0x6780352ca93ad9ac7829eeae46466105427e5239e66e20bcdac3767d5d357c54",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xd6b8dd8ABE75C848EFAf77Fd86A3b82d51BBac1F": {
        tier: "6",
        proof: [
            "0x679b1b6ea7ed3835ab4ba592562bb458958a69c9db1a1903e0c38c467e278988",
            "0x3f6645eb986815cca99cc0711365f2ff023d4b8079232c737fa6c00dbfc7bc91",
            "0xe9d27116069813c57332601cafd5c2ba17614714352f29b88996a1df2937e030",
            "0x59b8be9f17ad61a321a44a430877c826a857964e07cb797a6fbb3b99ddcd605d",
            "0x6780352ca93ad9ac7829eeae46466105427e5239e66e20bcdac3767d5d357c54",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x861364d1c3E7B43655abd9a913F6a8B4a1E32a7D": {
        tier: "6",
        proof: [
            "0x5942fb271b42f457692d3825e6b7ad36b1116f638d31b6cc6724ed4b0319e8fd",
            "0x2629fd6bd3c617fe02d0400df2d06e74d86cb2607ea1270be2308706c8917def",
            "0xe9d27116069813c57332601cafd5c2ba17614714352f29b88996a1df2937e030",
            "0x59b8be9f17ad61a321a44a430877c826a857964e07cb797a6fbb3b99ddcd605d",
            "0x6780352ca93ad9ac7829eeae46466105427e5239e66e20bcdac3767d5d357c54",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xEf0cbF716347E463b792574e16EE3EdE5fE789fc": {
        tier: "6",
        proof: [
            "0x2e6346d80e08edc92a787a689763037f9c8c86bdae37a867899c9c79a8b0999d",
            "0x2629fd6bd3c617fe02d0400df2d06e74d86cb2607ea1270be2308706c8917def",
            "0xe9d27116069813c57332601cafd5c2ba17614714352f29b88996a1df2937e030",
            "0x59b8be9f17ad61a321a44a430877c826a857964e07cb797a6fbb3b99ddcd605d",
            "0x6780352ca93ad9ac7829eeae46466105427e5239e66e20bcdac3767d5d357c54",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x255EeFD8307B3878be1E620FBd6A0ffA193B1CC5": {
        tier: "6",
        proof: [
            "0x5fc13931047f7c52a1ba1ec769cf2a24145ce64e03276aee726b2183d0ac77ff",
            "0xbebbf19dd0d18cafe1df0bba3e61558c83486ea86be35721a5b574c77f5d01c5",
            "0x2dd88816457aba69a9fd7dee48e409f0623100f9d3e2f2fce4630df4b1e476ea",
            "0x2ba3e9992b3957ea7729126dacadb9e145bf65e63d0f71ca07765ee7ff4ac41b",
            "0x6780352ca93ad9ac7829eeae46466105427e5239e66e20bcdac3767d5d357c54",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xDd707055DcA38025061059dE8bCDeF4e6F1819E5": {
        tier: "6",
        proof: [
            "0x7069bc3bc4052cb37057898c98d34ee6090be8c4c53412f0cbc85323ed31133d",
            "0xbebbf19dd0d18cafe1df0bba3e61558c83486ea86be35721a5b574c77f5d01c5",
            "0x2dd88816457aba69a9fd7dee48e409f0623100f9d3e2f2fce4630df4b1e476ea",
            "0x2ba3e9992b3957ea7729126dacadb9e145bf65e63d0f71ca07765ee7ff4ac41b",
            "0x6780352ca93ad9ac7829eeae46466105427e5239e66e20bcdac3767d5d357c54",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x49505b3770C89e02E2b8522a990327616176698E": {
        tier: "6",
        proof: [
            "0xf3b5c8210298b627567388abd13958a418f7a73dca26c79470ae589ffcbefd2e",
            "0x988831535af870090a797892e56754ac42f551b904f2da605cd078a85289a930",
            "0x2dd88816457aba69a9fd7dee48e409f0623100f9d3e2f2fce4630df4b1e476ea",
            "0x2ba3e9992b3957ea7729126dacadb9e145bf65e63d0f71ca07765ee7ff4ac41b",
            "0x6780352ca93ad9ac7829eeae46466105427e5239e66e20bcdac3767d5d357c54",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x717E8eaA620fCE3BdB190c1d2862DF2FD5288C01": {
        tier: "6",
        proof: [
            "0x511a347fa8a41d2fff55d1a8d6e72b02cfa988da2b096ef5c0773e0f143730bb",
            "0x988831535af870090a797892e56754ac42f551b904f2da605cd078a85289a930",
            "0x2dd88816457aba69a9fd7dee48e409f0623100f9d3e2f2fce4630df4b1e476ea",
            "0x2ba3e9992b3957ea7729126dacadb9e145bf65e63d0f71ca07765ee7ff4ac41b",
            "0x6780352ca93ad9ac7829eeae46466105427e5239e66e20bcdac3767d5d357c54",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x09D57F361f794D150C04443A7B6e369120DC2bea": {
        tier: "6",
        proof: [
            "0xd49437a163028ca5d5b0e165a63156636855472e9383212f78dbd8f4e641316a",
            "0xf11cf080c1a6a740f303f8cb1f4fc3432cec25e33f843c87d29f46b5c9d16036",
            "0x9e3698115b5927593f14ebfe3416417c60ef920a2eff758d36f325a7d14a3437",
            "0x2ba3e9992b3957ea7729126dacadb9e145bf65e63d0f71ca07765ee7ff4ac41b",
            "0x6780352ca93ad9ac7829eeae46466105427e5239e66e20bcdac3767d5d357c54",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0x51427eD2Eb78f8983e54a7Da3F7bafF0578D1223": {
        tier: "6",
        proof: [
            "0xa0c4eb2769b262d38c863f535482d22cd0826d466d2a96e4c55e3c1feb281d67",
            "0xf11cf080c1a6a740f303f8cb1f4fc3432cec25e33f843c87d29f46b5c9d16036",
            "0x9e3698115b5927593f14ebfe3416417c60ef920a2eff758d36f325a7d14a3437",
            "0x2ba3e9992b3957ea7729126dacadb9e145bf65e63d0f71ca07765ee7ff4ac41b",
            "0x6780352ca93ad9ac7829eeae46466105427e5239e66e20bcdac3767d5d357c54",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xEd32A95AaB73bFD52C33cFF8CCa0AEcb0EaeF223": {
        tier: "6",
        proof: [
            "0x47de8e697c7588633b71d15155d9fe6104bc17e1f99a2ef1c5a50cca2f9f8c31",
            "0xb91ce791518073366a5c4082b2b5572241ada51831d388579d68f587b56d80b9",
            "0x9e3698115b5927593f14ebfe3416417c60ef920a2eff758d36f325a7d14a3437",
            "0x2ba3e9992b3957ea7729126dacadb9e145bf65e63d0f71ca07765ee7ff4ac41b",
            "0x6780352ca93ad9ac7829eeae46466105427e5239e66e20bcdac3767d5d357c54",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xbaD0238191c58dfc11C8c1beF23BfD1B2662EBd4": {
        tier: "6",
        proof: [
            "0xb050d49d003994b7896447fd38df12457c051c3ed89da4921b204dc7296c6758",
            "0xb91ce791518073366a5c4082b2b5572241ada51831d388579d68f587b56d80b9",
            "0x9e3698115b5927593f14ebfe3416417c60ef920a2eff758d36f325a7d14a3437",
            "0x2ba3e9992b3957ea7729126dacadb9e145bf65e63d0f71ca07765ee7ff4ac41b",
            "0x6780352ca93ad9ac7829eeae46466105427e5239e66e20bcdac3767d5d357c54",
            "0x3dfb53083ba64c21d157ddb8e6a8081740846cc0d9febc893d6e25e8018bc526",
            "0x1b200855760e676e147f25d0ec817c8b0a5130c929a9c70b5ea3339da15be8a4",
            "0xcf6f26f0a9f337e86d29e3e1fb040f0831f4eb50a5fdc6a9df7d8d583ae42f86",
        ],
    },
    "0xA883B60C5EaD0EFD604b41a7C0509c3c6b81739e": {
        tier: "6",
        proof: [
            "0xcc991ca60ee501b74be894140b973452aeee4a4c165b4cda250deffc087dfd06",
            "0x00bc42d7f9a7473b62d856810c3e5de254eab64dbc1df989449b6c3a9194529f",
            "0xa2fc44c7517070e98ae96b2bb837ed592ae745d3878cb1a0e7f73fd84705426c",
            "0xc3f42a933404048bc84d7d8d902cd1d6b57ad610bdf5e5e257cc549174019937",
            "0x6e76f8dad13c13ddda95e50b1c600d092bbe727ebc3e618c1b513ad5beb106a1",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xE79c263403B0071093bd7968AD1b6EA94D3bCF1c": {
        tier: "6",
        proof: [
            "0xa6d4070519d8bc7e00444a766bc09d336a0d720e50dd0e86d6be499a58291e1c",
            "0x00bc42d7f9a7473b62d856810c3e5de254eab64dbc1df989449b6c3a9194529f",
            "0xa2fc44c7517070e98ae96b2bb837ed592ae745d3878cb1a0e7f73fd84705426c",
            "0xc3f42a933404048bc84d7d8d902cd1d6b57ad610bdf5e5e257cc549174019937",
            "0x6e76f8dad13c13ddda95e50b1c600d092bbe727ebc3e618c1b513ad5beb106a1",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x17fa7F13C8b7633bEDE0718fFd8F3B93FA51A0a4": {
        tier: "6",
        proof: [
            "0x24813902c53535b289e3e9d7e5e332fa14923c4951df91ed28b4b43f925e858e",
            "0x08075df698b9259ff8f72820afd59df62f2dea21a9c0fb7ee3156a2b6e440611",
            "0xa2fc44c7517070e98ae96b2bb837ed592ae745d3878cb1a0e7f73fd84705426c",
            "0xc3f42a933404048bc84d7d8d902cd1d6b57ad610bdf5e5e257cc549174019937",
            "0x6e76f8dad13c13ddda95e50b1c600d092bbe727ebc3e618c1b513ad5beb106a1",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x4f49c8F45d8b6c34E0C271BaCDafe9564Dc2A009": {
        tier: "6",
        proof: [
            "0xe069a516982d1cde7cfd4911fd1dc447ac4500f5710204b0227ef85b4ddbb45b",
            "0x08075df698b9259ff8f72820afd59df62f2dea21a9c0fb7ee3156a2b6e440611",
            "0xa2fc44c7517070e98ae96b2bb837ed592ae745d3878cb1a0e7f73fd84705426c",
            "0xc3f42a933404048bc84d7d8d902cd1d6b57ad610bdf5e5e257cc549174019937",
            "0x6e76f8dad13c13ddda95e50b1c600d092bbe727ebc3e618c1b513ad5beb106a1",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xB0b65Ba07D52C899F9Fd0163874005f735907f58": {
        tier: "6",
        proof: [
            "0xce7b8060dc8a74bc5d32d73e4f65312a6e1ef414cc52c1975a3ad2aa7ad20e4d",
            "0x8d3376d80531e16ca201e8eb2f0b713243321acd6dfdc2cf77273159aaae6460",
            "0xaf7d74a96f1f8e84a148fa84f3201725e4ee416a41bccacbcd88458cad2e6aae",
            "0xc3f42a933404048bc84d7d8d902cd1d6b57ad610bdf5e5e257cc549174019937",
            "0x6e76f8dad13c13ddda95e50b1c600d092bbe727ebc3e618c1b513ad5beb106a1",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xb7e9A12c688963fE142C6FDe173d0Df02514A802": {
        tier: "6",
        proof: [
            "0x9c7f3466ba5532a1aa956c280f3863a7f3b0b0d539dfc99c33a3a9256d7553d7",
            "0x8d3376d80531e16ca201e8eb2f0b713243321acd6dfdc2cf77273159aaae6460",
            "0xaf7d74a96f1f8e84a148fa84f3201725e4ee416a41bccacbcd88458cad2e6aae",
            "0xc3f42a933404048bc84d7d8d902cd1d6b57ad610bdf5e5e257cc549174019937",
            "0x6e76f8dad13c13ddda95e50b1c600d092bbe727ebc3e618c1b513ad5beb106a1",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x97b79e6119599E3479D3936B0322BF6DaE88C604": {
        tier: "6",
        proof: [
            "0x166015cd7b0042bb8032eb78d95fbd327855b44cc90d7cbb113e9d12a1d0a1ee",
            "0x4d069263ecf8f56139caa27dd9baf2e707746a56ea32fbdc9dbb5e8544b7c300",
            "0xaf7d74a96f1f8e84a148fa84f3201725e4ee416a41bccacbcd88458cad2e6aae",
            "0xc3f42a933404048bc84d7d8d902cd1d6b57ad610bdf5e5e257cc549174019937",
            "0x6e76f8dad13c13ddda95e50b1c600d092bbe727ebc3e618c1b513ad5beb106a1",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xDF1e3abB229d42A182aD61ce8a63355a8A3EB0F8": {
        tier: "6",
        proof: [
            "0x2d1decc9e464ea1794075e8221ee94c25f4ca5434303efa3ddeb7b562b00d788",
            "0x4d069263ecf8f56139caa27dd9baf2e707746a56ea32fbdc9dbb5e8544b7c300",
            "0xaf7d74a96f1f8e84a148fa84f3201725e4ee416a41bccacbcd88458cad2e6aae",
            "0xc3f42a933404048bc84d7d8d902cd1d6b57ad610bdf5e5e257cc549174019937",
            "0x6e76f8dad13c13ddda95e50b1c600d092bbe727ebc3e618c1b513ad5beb106a1",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xe236F6b2f8d6329cB4995b2eA7D6A3248fBC9433": {
        tier: "6",
        proof: [
            "0x7ee85e13afaa034afc0df31690f653daec4d91ce0fa0a45ec0f8d27c7c6b4d6c",
            "0xd724d8bcc569c41a0ff27a4d825e06c61ab7e6664f3b4256ed6b4186d334caa6",
            "0xcc18624be4c93f2b3fde83bcd89194de4f4898b37d6c3f7623f2c8ba7d714244",
            "0x8d9878af0da926a3671282c3a5b91920dba762a1da12d29645e8521c1df3c608",
            "0x6e76f8dad13c13ddda95e50b1c600d092bbe727ebc3e618c1b513ad5beb106a1",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x7B131B2634Ab3e2BbBb4CCA2B24cd5EeBfC13E9E": {
        tier: "6",
        proof: [
            "0xbbcc1264b47e922a6c1abbd34a652c90077fac481c4b8f0e29e20880aeba13bb",
            "0xd724d8bcc569c41a0ff27a4d825e06c61ab7e6664f3b4256ed6b4186d334caa6",
            "0xcc18624be4c93f2b3fde83bcd89194de4f4898b37d6c3f7623f2c8ba7d714244",
            "0x8d9878af0da926a3671282c3a5b91920dba762a1da12d29645e8521c1df3c608",
            "0x6e76f8dad13c13ddda95e50b1c600d092bbe727ebc3e618c1b513ad5beb106a1",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xbc307BdF50fE05Db053c7217E904F9C77E9D5125": {
        tier: "6",
        proof: [
            "0xedc8d4de1659c916e7f08ada836f62e70857991636b22b686c16a4ad359900b1",
            "0x01bebb66632d5584716a7853bdd6ae9cfdbec19215aaa6391207186caea69459",
            "0xcc18624be4c93f2b3fde83bcd89194de4f4898b37d6c3f7623f2c8ba7d714244",
            "0x8d9878af0da926a3671282c3a5b91920dba762a1da12d29645e8521c1df3c608",
            "0x6e76f8dad13c13ddda95e50b1c600d092bbe727ebc3e618c1b513ad5beb106a1",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x39a25B5281B1433f6BF1e1DaD3f6F1c21FDc480D": {
        tier: "6",
        proof: [
            "0xe23a7e45b46815f2b153e3c7b59015397abbf93d699728bca44e95d8321161a7",
            "0x01bebb66632d5584716a7853bdd6ae9cfdbec19215aaa6391207186caea69459",
            "0xcc18624be4c93f2b3fde83bcd89194de4f4898b37d6c3f7623f2c8ba7d714244",
            "0x8d9878af0da926a3671282c3a5b91920dba762a1da12d29645e8521c1df3c608",
            "0x6e76f8dad13c13ddda95e50b1c600d092bbe727ebc3e618c1b513ad5beb106a1",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x507D4e199F1f418679dbBd9CC775d846717BeAF2": {
        tier: "6",
        proof: [
            "0xbc8006269f1f822a4fb46f52b35dc25b846233ede60fb44ca3ba20a6a44c57b2",
            "0x871b88b8c6673a172c74ad136242e136c5666883da6ed2ec833355d509aa98fe",
            "0x0ae5194f90657f81274ccf1f13b78f1797a7f7e6b9febeb6587089d9386616cb",
            "0x8d9878af0da926a3671282c3a5b91920dba762a1da12d29645e8521c1df3c608",
            "0x6e76f8dad13c13ddda95e50b1c600d092bbe727ebc3e618c1b513ad5beb106a1",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x267ffa2FcEc7A123FB58f5AD72895025126293E0": {
        tier: "6",
        proof: [
            "0xbb30ddea030a85ae43491e15836405bc455a390e6851f53a64f8e54a79bfb99a",
            "0x871b88b8c6673a172c74ad136242e136c5666883da6ed2ec833355d509aa98fe",
            "0x0ae5194f90657f81274ccf1f13b78f1797a7f7e6b9febeb6587089d9386616cb",
            "0x8d9878af0da926a3671282c3a5b91920dba762a1da12d29645e8521c1df3c608",
            "0x6e76f8dad13c13ddda95e50b1c600d092bbe727ebc3e618c1b513ad5beb106a1",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xCdE70B1BfB2356B9EA02a3b5A19d02ae8EE1473D": {
        tier: "6",
        proof: [
            "0xa73a712dfa02908a718abf70bd6d4d801989c688a574c9508b267ecc5d8d8861",
            "0x74b35ea01bedb8e153babbea6ea21013be41ec123cf5fbeefa5d385995060d0f",
            "0x0ae5194f90657f81274ccf1f13b78f1797a7f7e6b9febeb6587089d9386616cb",
            "0x8d9878af0da926a3671282c3a5b91920dba762a1da12d29645e8521c1df3c608",
            "0x6e76f8dad13c13ddda95e50b1c600d092bbe727ebc3e618c1b513ad5beb106a1",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xFaEAE775e14493A8cc6c2582d588e20AFF1848e1": {
        tier: "6",
        proof: [
            "0x44d1ec95c7e94b73aa90c87ca8bf989577e526f6dfd309ec10e985b32f3ee99e",
            "0x74b35ea01bedb8e153babbea6ea21013be41ec123cf5fbeefa5d385995060d0f",
            "0x0ae5194f90657f81274ccf1f13b78f1797a7f7e6b9febeb6587089d9386616cb",
            "0x8d9878af0da926a3671282c3a5b91920dba762a1da12d29645e8521c1df3c608",
            "0x6e76f8dad13c13ddda95e50b1c600d092bbe727ebc3e618c1b513ad5beb106a1",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x3095d1c08EE2BB66c8aa58676206305AD4fe1617": {
        tier: "6",
        proof: [
            "0xa2b4a4265218a3a75c43457cc49156693621ed1a15be8ce12a9f7ad46013b309",
            "0xc870cf13f26adf8c9c3e5c2bfba4ca5e33806d082e8544055b0f5294d476363c",
            "0xa5f78baf4e7b1863ad863eab4987cff8ba643ab7baf9174c254b5fa4f00c9274",
            "0x31da9a33dacb137aa1ffd60f20ace4e5da50fa20b560cc3abcf66a7270be0512",
            "0x542a60aa8e5dfbea3667f257da77357079f6907d0036b4f675faeaa629d61e4c",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xCA0073964efe7f9422CeB16901018b1DB0cC4785": {
        tier: "6",
        proof: [
            "0xb7948b9d46e7271b8b131fea98a4355dbe2295fbf5d58938e910ba70c25b2dbd",
            "0xc870cf13f26adf8c9c3e5c2bfba4ca5e33806d082e8544055b0f5294d476363c",
            "0xa5f78baf4e7b1863ad863eab4987cff8ba643ab7baf9174c254b5fa4f00c9274",
            "0x31da9a33dacb137aa1ffd60f20ace4e5da50fa20b560cc3abcf66a7270be0512",
            "0x542a60aa8e5dfbea3667f257da77357079f6907d0036b4f675faeaa629d61e4c",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x9a406A109Fe2FB8B239C72808f02C8a114faa209": {
        tier: "6",
        proof: [
            "0x3808614dcae57120959eebe70f61af41d23035975681d9d841de9376a09af6b7",
            "0x24b6662091bc922899eb50ac3af7cf0b0362df31e6ad3aee6cd7171b4afbaecc",
            "0xa5f78baf4e7b1863ad863eab4987cff8ba643ab7baf9174c254b5fa4f00c9274",
            "0x31da9a33dacb137aa1ffd60f20ace4e5da50fa20b560cc3abcf66a7270be0512",
            "0x542a60aa8e5dfbea3667f257da77357079f6907d0036b4f675faeaa629d61e4c",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x3724f1DA2EEa68faDBa7144c392A79bDC63a1154": {
        tier: "6",
        proof: [
            "0x042d0ef568a45596baf31a973e195b82cf31958f474b6687bee320f9b23cc1ce",
            "0x24b6662091bc922899eb50ac3af7cf0b0362df31e6ad3aee6cd7171b4afbaecc",
            "0xa5f78baf4e7b1863ad863eab4987cff8ba643ab7baf9174c254b5fa4f00c9274",
            "0x31da9a33dacb137aa1ffd60f20ace4e5da50fa20b560cc3abcf66a7270be0512",
            "0x542a60aa8e5dfbea3667f257da77357079f6907d0036b4f675faeaa629d61e4c",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x07Cd855bFbEb021B7873B8AAeEAE1f76bee4e07B": {
        tier: "6",
        proof: [
            "0xbd7da10863a7a222d3b872471098734e3444240a3ed64e838b2d2c1be0bad85a",
            "0x741b47c16959fe056a634e4dd8a0fb109d14b99002aacbd6b097c4f7eb11771d",
            "0xf1383e879fc2315e057719a26f0b98550172df0f6045f99d09fb20342371363e",
            "0x31da9a33dacb137aa1ffd60f20ace4e5da50fa20b560cc3abcf66a7270be0512",
            "0x542a60aa8e5dfbea3667f257da77357079f6907d0036b4f675faeaa629d61e4c",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x51463f8Aa958feb3b566d7c8278a1EdCe0C91415": {
        tier: "6",
        proof: [
            "0x1f00f5dd25ec5420b84f5dcbefd01bca87e122757876e3e1c932b7ed5f9e90af",
            "0x741b47c16959fe056a634e4dd8a0fb109d14b99002aacbd6b097c4f7eb11771d",
            "0xf1383e879fc2315e057719a26f0b98550172df0f6045f99d09fb20342371363e",
            "0x31da9a33dacb137aa1ffd60f20ace4e5da50fa20b560cc3abcf66a7270be0512",
            "0x542a60aa8e5dfbea3667f257da77357079f6907d0036b4f675faeaa629d61e4c",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x37E6A1f4f5fCe05517D1f7Aa67123C1AA009D44f": {
        tier: "6",
        proof: [
            "0xf9201714fb4b3a13c11aeef38df791e57009c67388f831f413a643658d0d9ca6",
            "0x7ff887ef68af41924d78f8dbef4621f0fc387958e9a9ca40b95636afd6cf6095",
            "0xf1383e879fc2315e057719a26f0b98550172df0f6045f99d09fb20342371363e",
            "0x31da9a33dacb137aa1ffd60f20ace4e5da50fa20b560cc3abcf66a7270be0512",
            "0x542a60aa8e5dfbea3667f257da77357079f6907d0036b4f675faeaa629d61e4c",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x523861c0a8EBdD96047601F20222d854D1a15EAd": {
        tier: "6",
        proof: [
            "0x7619ad7a903a1105619b6a8e5ad91843b952483573f0ec1d81e8e17c3310e180",
            "0x7ff887ef68af41924d78f8dbef4621f0fc387958e9a9ca40b95636afd6cf6095",
            "0xf1383e879fc2315e057719a26f0b98550172df0f6045f99d09fb20342371363e",
            "0x31da9a33dacb137aa1ffd60f20ace4e5da50fa20b560cc3abcf66a7270be0512",
            "0x542a60aa8e5dfbea3667f257da77357079f6907d0036b4f675faeaa629d61e4c",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xEE0f8399db4A71B8f46e5B253718d1220442a7a3": {
        tier: "6",
        proof: [
            "0x6e4062334f3f8e236d7c41668de9fbb253437d1bd0923c0ea4674acf805eb8d3",
            "0x4d8e6c5c320ca505cf64265c2453c2d73787ca72c651def1fd48dbb2b46ae423",
            "0x4ac9402ad11976d5d812b38482bc5ce22793b5c4a4aafd7308e6ee1179bf59d4",
            "0xebc3f81ecda6b43538ea4a0701d461ed8982d200e7b4b7971e960aa12bb80349",
            "0x542a60aa8e5dfbea3667f257da77357079f6907d0036b4f675faeaa629d61e4c",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x8E998aAb9DffBFbB9eef4FF6C73D0DD7a41287C7": {
        tier: "6",
        proof: [
            "0x72e47af4459de8724e7f30ad7985e0bb9348c497693a1c7ce964fe993730ba36",
            "0x4d8e6c5c320ca505cf64265c2453c2d73787ca72c651def1fd48dbb2b46ae423",
            "0x4ac9402ad11976d5d812b38482bc5ce22793b5c4a4aafd7308e6ee1179bf59d4",
            "0xebc3f81ecda6b43538ea4a0701d461ed8982d200e7b4b7971e960aa12bb80349",
            "0x542a60aa8e5dfbea3667f257da77357079f6907d0036b4f675faeaa629d61e4c",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xb079B3333e2fba074438C42b2C75be2C60a453Fe": {
        tier: "6",
        proof: [
            "0x1baac0ef20e48af7328e188df6d5571200b8110ed909b3fef3425543fa5d7180",
            "0x101b389d14c6a742e73f85472cd01b3fb574bbce27d686a2599306bcc124ca58",
            "0x4ac9402ad11976d5d812b38482bc5ce22793b5c4a4aafd7308e6ee1179bf59d4",
            "0xebc3f81ecda6b43538ea4a0701d461ed8982d200e7b4b7971e960aa12bb80349",
            "0x542a60aa8e5dfbea3667f257da77357079f6907d0036b4f675faeaa629d61e4c",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x3a44deb6159843a584cF6ede188cd9F4161DC215": {
        tier: "6",
        proof: [
            "0x81ce8c9992d6aa77810ef5c6ae1bf3b89daa7dc965e21a5da24c0e3fcad0b281",
            "0x101b389d14c6a742e73f85472cd01b3fb574bbce27d686a2599306bcc124ca58",
            "0x4ac9402ad11976d5d812b38482bc5ce22793b5c4a4aafd7308e6ee1179bf59d4",
            "0xebc3f81ecda6b43538ea4a0701d461ed8982d200e7b4b7971e960aa12bb80349",
            "0x542a60aa8e5dfbea3667f257da77357079f6907d0036b4f675faeaa629d61e4c",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x537037C5ae805b9D4CeCAB5Ee07F12a8e59A15B2": {
        tier: "6",
        proof: [
            "0x9dc8aab596711022ed1f7cebfb924ae57416ecf08a19997e7b80c6db252ed713",
            "0x11ba96deff7f41d7eb199121ece8194891a6c5238671292a6d82f0538d6a4ba5",
            "0xf015d9aebb8377d252827cd7e172becf302c9e378939c2dfa0613efb09538395",
            "0xebc3f81ecda6b43538ea4a0701d461ed8982d200e7b4b7971e960aa12bb80349",
            "0x542a60aa8e5dfbea3667f257da77357079f6907d0036b4f675faeaa629d61e4c",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x998593425E37C954A8d74571A6aE81DE18a41855": {
        tier: "6",
        proof: [
            "0x0e7555ea547173849ab9c31a7a4e149dd17db3c217f6e9cb08c24a66f2bed6ed",
            "0x11ba96deff7f41d7eb199121ece8194891a6c5238671292a6d82f0538d6a4ba5",
            "0xf015d9aebb8377d252827cd7e172becf302c9e378939c2dfa0613efb09538395",
            "0xebc3f81ecda6b43538ea4a0701d461ed8982d200e7b4b7971e960aa12bb80349",
            "0x542a60aa8e5dfbea3667f257da77357079f6907d0036b4f675faeaa629d61e4c",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x36183992FbbB7D759Bb4a20E0437cc9725a35B22": {
        tier: "6",
        proof: [
            "0xd419cbe05388b42e9c11376945b2aa289a2dbc1bde40d79b177498fdddcae7b2",
            "0xf3b3a64137d1c70c0ffeed5bda37f4af21087b14d2edc9ab825d9a9d2e4dc093",
            "0xf015d9aebb8377d252827cd7e172becf302c9e378939c2dfa0613efb09538395",
            "0xebc3f81ecda6b43538ea4a0701d461ed8982d200e7b4b7971e960aa12bb80349",
            "0x542a60aa8e5dfbea3667f257da77357079f6907d0036b4f675faeaa629d61e4c",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x2638F91804b32190622ef82c6a265D8e9dae57A6": {
        tier: "6",
        proof: [
            "0x896d8e33052a000d518673f468bedf52f1408e36896fc9826e110b2bd5dc0f58",
            "0xf3b3a64137d1c70c0ffeed5bda37f4af21087b14d2edc9ab825d9a9d2e4dc093",
            "0xf015d9aebb8377d252827cd7e172becf302c9e378939c2dfa0613efb09538395",
            "0xebc3f81ecda6b43538ea4a0701d461ed8982d200e7b4b7971e960aa12bb80349",
            "0x542a60aa8e5dfbea3667f257da77357079f6907d0036b4f675faeaa629d61e4c",
            "0x5059a939ac74c074ee0e07b9e0c6c07d9cb50170bd7e46011310e57d7b269857",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x4e7dA2321288C83fd760E60D26bC55d50b3CD77B": {
        tier: "6",
        proof: [
            "0x9cde7281e7ccfb0614e742309ec2bdc6a1bdf85348129f35c6bfad7208a05485",
            "0x7311bcf2b066d7e88e06bab397732706905191908265fa799f4f44bf2214ed3d",
            "0xec2359dfdab37b9f7124472cb5ab6f6ab05de1ecfef85dce6d981eba1ea4920d",
            "0xe60ddc72527505ed3f48a31f6af228d72518514dfe3706523cfe7fef92f8d202",
            "0x701dee65303de6d242e95c4afa911c6fc55949ce9e2d733de23ae97fc4b03819",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xb095b4Fe91A626918D3365Da99959Cb9c5deBc91": {
        tier: "6",
        proof: [
            "0x8aa442fe51902c9efb0c733bda7847412208c626895aa18b8afc3823f98aeb60",
            "0x7311bcf2b066d7e88e06bab397732706905191908265fa799f4f44bf2214ed3d",
            "0xec2359dfdab37b9f7124472cb5ab6f6ab05de1ecfef85dce6d981eba1ea4920d",
            "0xe60ddc72527505ed3f48a31f6af228d72518514dfe3706523cfe7fef92f8d202",
            "0x701dee65303de6d242e95c4afa911c6fc55949ce9e2d733de23ae97fc4b03819",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x88a4735388BB75C8b2a6361f1474Ef9D0C89274e": {
        tier: "6",
        proof: [
            "0x899ca018a1e25f45516fa86496361d34a4faca3dcd4148f9f057dd3ef7e19bc8",
            "0x0ce384e8cb6bc5c5c10dda31b04bb2079c9bb2ae7a88c737bc23ba07d139aae3",
            "0xec2359dfdab37b9f7124472cb5ab6f6ab05de1ecfef85dce6d981eba1ea4920d",
            "0xe60ddc72527505ed3f48a31f6af228d72518514dfe3706523cfe7fef92f8d202",
            "0x701dee65303de6d242e95c4afa911c6fc55949ce9e2d733de23ae97fc4b03819",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x90F440e449C47f4cAF78c62c131DFC38ab491d4A": {
        tier: "6",
        proof: [
            "0x66476011a453a922c8ec79d3a32e4bb52ae3c7ba940e3f7d6b3361c360efbdff",
            "0x0ce384e8cb6bc5c5c10dda31b04bb2079c9bb2ae7a88c737bc23ba07d139aae3",
            "0xec2359dfdab37b9f7124472cb5ab6f6ab05de1ecfef85dce6d981eba1ea4920d",
            "0xe60ddc72527505ed3f48a31f6af228d72518514dfe3706523cfe7fef92f8d202",
            "0x701dee65303de6d242e95c4afa911c6fc55949ce9e2d733de23ae97fc4b03819",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x4f802FB0D338Faf6e9518BB0C51E3C06C03b9a4e": {
        tier: "6",
        proof: [
            "0x9b057a43785d20dd62692d561fba5fd78fe028c401df11e36c46bf518e208c46",
            "0xff32b0a9561d38c6292c71188fdd0ab5e43fcde9f231b4990a628eb22ad930cc",
            "0x784924ce782cb60e55c0d8e3e3d9efe169d3e6bca16b7b42fa54394298eeecc9",
            "0xe60ddc72527505ed3f48a31f6af228d72518514dfe3706523cfe7fef92f8d202",
            "0x701dee65303de6d242e95c4afa911c6fc55949ce9e2d733de23ae97fc4b03819",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xeF8dDA4e93C418FaD5aCEdd37D91343c448c7762": {
        tier: "6",
        proof: [
            "0x9d89f4833dfa08ce9d218828ca1a489e239f481cbbe770125de81b3ccf502f28",
            "0xff32b0a9561d38c6292c71188fdd0ab5e43fcde9f231b4990a628eb22ad930cc",
            "0x784924ce782cb60e55c0d8e3e3d9efe169d3e6bca16b7b42fa54394298eeecc9",
            "0xe60ddc72527505ed3f48a31f6af228d72518514dfe3706523cfe7fef92f8d202",
            "0x701dee65303de6d242e95c4afa911c6fc55949ce9e2d733de23ae97fc4b03819",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x88a2505Ab369E000F1Fce1608C2188aEcd1B2ed3": {
        tier: "6",
        proof: [
            "0xee34e8f0485cf8a54026eac0bd6b2ecb6b267cff97515cf38638c52b019562c2",
            "0x4ab3bf71074eba277b13c24a6611ecfeeb813222fb1c0ff0ead750578a1882b0",
            "0x784924ce782cb60e55c0d8e3e3d9efe169d3e6bca16b7b42fa54394298eeecc9",
            "0xe60ddc72527505ed3f48a31f6af228d72518514dfe3706523cfe7fef92f8d202",
            "0x701dee65303de6d242e95c4afa911c6fc55949ce9e2d733de23ae97fc4b03819",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x93B49B77389B47bF1c63a65EFd0f7FA73F02CC50": {
        tier: "6",
        proof: [
            "0x33b0edb85905b3856af82d06ff782b1d4098880cc568e2a6262cd492aeb99689",
            "0x4ab3bf71074eba277b13c24a6611ecfeeb813222fb1c0ff0ead750578a1882b0",
            "0x784924ce782cb60e55c0d8e3e3d9efe169d3e6bca16b7b42fa54394298eeecc9",
            "0xe60ddc72527505ed3f48a31f6af228d72518514dfe3706523cfe7fef92f8d202",
            "0x701dee65303de6d242e95c4afa911c6fc55949ce9e2d733de23ae97fc4b03819",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xED2E15a0B5cc792dc2db9bAE82E704A4402BE8c7": {
        tier: "6",
        proof: [
            "0xba4fc0fdac91746b480bd5f08d57ea5f626cf1cdcde680a7886cd09e6faffb14",
            "0x9735d34dff6ce0ccdb67a9221d48e6db1549760b1a7ffc0243d25b6e8598a37c",
            "0xd886b8ffc174c73993f70f47bf8e61e5fa6a801e34cee78cf9272658c27e4968",
            "0x459a7a2966456111638a22f6c45cf6ef0a904b963e4d6d72c752137f57890786",
            "0x701dee65303de6d242e95c4afa911c6fc55949ce9e2d733de23ae97fc4b03819",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x290789AeD2D6FAE51191BcE9cCE498d0c336Dd89": {
        tier: "6",
        proof: [
            "0x516483bd8fa665806274df4a32c582dd8dd5e3e693d1d4c506b8b5b0c00aea59",
            "0x9735d34dff6ce0ccdb67a9221d48e6db1549760b1a7ffc0243d25b6e8598a37c",
            "0xd886b8ffc174c73993f70f47bf8e61e5fa6a801e34cee78cf9272658c27e4968",
            "0x459a7a2966456111638a22f6c45cf6ef0a904b963e4d6d72c752137f57890786",
            "0x701dee65303de6d242e95c4afa911c6fc55949ce9e2d733de23ae97fc4b03819",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x5216dF4C84C31E7ffe93233f33bB41F6dD7A8e3E": {
        tier: "6",
        proof: [
            "0xf7538b17aacb2c379b44f88ee61b3249d405676d05eee2c1a373f16e1e413269",
            "0xff57a1b5100ac74b87ea5ffebeb5f4d9b67b4b19c82ce21775640335d55d93a9",
            "0xd886b8ffc174c73993f70f47bf8e61e5fa6a801e34cee78cf9272658c27e4968",
            "0x459a7a2966456111638a22f6c45cf6ef0a904b963e4d6d72c752137f57890786",
            "0x701dee65303de6d242e95c4afa911c6fc55949ce9e2d733de23ae97fc4b03819",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xc88A90E06f4b47631AF60D1bEF0Cf3bB5cd3F1FB": {
        tier: "6",
        proof: [
            "0x3359185b40d6f5d8e434e2ec67be44f76e4e5099ea44bd438e2d3c07005936ac",
            "0xff57a1b5100ac74b87ea5ffebeb5f4d9b67b4b19c82ce21775640335d55d93a9",
            "0xd886b8ffc174c73993f70f47bf8e61e5fa6a801e34cee78cf9272658c27e4968",
            "0x459a7a2966456111638a22f6c45cf6ef0a904b963e4d6d72c752137f57890786",
            "0x701dee65303de6d242e95c4afa911c6fc55949ce9e2d733de23ae97fc4b03819",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x70Bc2958A585d5B30623390c48c64061863C84ee": {
        tier: "6",
        proof: [
            "0xd13291c0b1dec09229285efb74b3810ee9f66c4ceedfa1961acf3895227bcb33",
            "0xb705272074f6483b9decd85ab38d3921b9a0353c74d46a05fe3929ad5ea8d061",
            "0x2a932a99e75e480ab13c8903d88e9a328ef3598f8ae951d38695b1ab08551d70",
            "0x459a7a2966456111638a22f6c45cf6ef0a904b963e4d6d72c752137f57890786",
            "0x701dee65303de6d242e95c4afa911c6fc55949ce9e2d733de23ae97fc4b03819",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xF0a9c1dC5315854502F20165d1D180672a66c295": {
        tier: "6",
        proof: [
            "0xa6580c9fe50eadf049562816620ac61f0721aa602856614d967446b0faa72d2b",
            "0xb705272074f6483b9decd85ab38d3921b9a0353c74d46a05fe3929ad5ea8d061",
            "0x2a932a99e75e480ab13c8903d88e9a328ef3598f8ae951d38695b1ab08551d70",
            "0x459a7a2966456111638a22f6c45cf6ef0a904b963e4d6d72c752137f57890786",
            "0x701dee65303de6d242e95c4afa911c6fc55949ce9e2d733de23ae97fc4b03819",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x108289ECB4830E7DE08dC8f9E58526DDeccd2d32": {
        tier: "6",
        proof: [
            "0xd10c80fcb036d10fd9dc405682b31f2d2bd71b959fe24d1de812d8bc8f66ddc8",
            "0xeef9b24367ade84f6a50cb31fa15745f5d72151be27a6ce63ce950ba928bba36",
            "0x2a932a99e75e480ab13c8903d88e9a328ef3598f8ae951d38695b1ab08551d70",
            "0x459a7a2966456111638a22f6c45cf6ef0a904b963e4d6d72c752137f57890786",
            "0x701dee65303de6d242e95c4afa911c6fc55949ce9e2d733de23ae97fc4b03819",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x144c7C6B60cd54518542c0EBcC7397041f39775A": {
        tier: "6",
        proof: [
            "0x5dd58fe875b3a8c60b054096c6d68c89b59f43e22f312a5ae3171de981428e4d",
            "0xeef9b24367ade84f6a50cb31fa15745f5d72151be27a6ce63ce950ba928bba36",
            "0x2a932a99e75e480ab13c8903d88e9a328ef3598f8ae951d38695b1ab08551d70",
            "0x459a7a2966456111638a22f6c45cf6ef0a904b963e4d6d72c752137f57890786",
            "0x701dee65303de6d242e95c4afa911c6fc55949ce9e2d733de23ae97fc4b03819",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x784926a1103B3DC054951d72233FFD1bb46a9bB9": {
        tier: "6",
        proof: [
            "0x7c7ac605345b0458fd1988a9fdabb5d74e38f48d556d8f0216e0d40567037b33",
            "0xf7f075808a96679496bb43c92bcc6c87f84c6ce7f6b038813b7bb103e65cc77f",
            "0x50669992c962b8c75deb3640750bed9477c41a07945564dda160c5c01421fde8",
            "0x6c656b1ede74429dc7f3e8fc3cc2f333eff62132672f9851b8ef6dfdeb84697e",
            "0xa9b3ba9ed83b5d2a5c979532d74e0df85197cdbbaf31105272360e407437482f",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x877167231d569c9fcF5e7a5E2b073c7cd41f2cFF": {
        tier: "6",
        proof: [
            "0x7dcb58b06cb60d469a4ffb7f0ef27a17f095babef24bdcd12daa3197b90fbd41",
            "0xf7f075808a96679496bb43c92bcc6c87f84c6ce7f6b038813b7bb103e65cc77f",
            "0x50669992c962b8c75deb3640750bed9477c41a07945564dda160c5c01421fde8",
            "0x6c656b1ede74429dc7f3e8fc3cc2f333eff62132672f9851b8ef6dfdeb84697e",
            "0xa9b3ba9ed83b5d2a5c979532d74e0df85197cdbbaf31105272360e407437482f",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x33a003FCf9a84d32b40B7946d313c54451ce28b4": {
        tier: "6",
        proof: [
            "0x7835b1357ddcd339bdbf285bc1f8a7b7b5888f835e6aea8de7aaa2ca28f349d2",
            "0x8b5197e24ff9eba5ecfbec3ac367b00c97f92901d7ff12125112c9d9c2e904b4",
            "0x50669992c962b8c75deb3640750bed9477c41a07945564dda160c5c01421fde8",
            "0x6c656b1ede74429dc7f3e8fc3cc2f333eff62132672f9851b8ef6dfdeb84697e",
            "0xa9b3ba9ed83b5d2a5c979532d74e0df85197cdbbaf31105272360e407437482f",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x269C7B1b22bFD5e7882d4627F83e36318E657740": {
        tier: "6",
        proof: [
            "0x32cbe92ab04dad7a3cbe0934dc90fee086a08e23fec3cf26a1930c38a7d50f10",
            "0x8b5197e24ff9eba5ecfbec3ac367b00c97f92901d7ff12125112c9d9c2e904b4",
            "0x50669992c962b8c75deb3640750bed9477c41a07945564dda160c5c01421fde8",
            "0x6c656b1ede74429dc7f3e8fc3cc2f333eff62132672f9851b8ef6dfdeb84697e",
            "0xa9b3ba9ed83b5d2a5c979532d74e0df85197cdbbaf31105272360e407437482f",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x0B7576A64A0F4b4924D55eD328Ede4979446521B": {
        tier: "6",
        proof: [
            "0x75c38b36274fa3304563d193dcd4f4d8e90bd3b8b74b07be6cf6a2d03dca2b5f",
            "0xd3fa9984402902f265d05166dfa7ad0fc12667721856f779ee38987edf5df52f",
            "0xb7407e2448dd13d9e14a9faa2bb48445b773cd023455a55d6ce451ff2d12f6c1",
            "0x6c656b1ede74429dc7f3e8fc3cc2f333eff62132672f9851b8ef6dfdeb84697e",
            "0xa9b3ba9ed83b5d2a5c979532d74e0df85197cdbbaf31105272360e407437482f",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xFAff02F1D7F9315529B64F64611fB35FFaE41c1D": {
        tier: "6",
        proof: [
            "0xd1dbdcd291c149ddf94a7efb36eb781547ee28aad921b5e4d9139c16577f21b1",
            "0xd3fa9984402902f265d05166dfa7ad0fc12667721856f779ee38987edf5df52f",
            "0xb7407e2448dd13d9e14a9faa2bb48445b773cd023455a55d6ce451ff2d12f6c1",
            "0x6c656b1ede74429dc7f3e8fc3cc2f333eff62132672f9851b8ef6dfdeb84697e",
            "0xa9b3ba9ed83b5d2a5c979532d74e0df85197cdbbaf31105272360e407437482f",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xCa288359bA6319c7E7C7377A670183AcB3781CDA": {
        tier: "6",
        proof: [
            "0xc243aa82582560cb000a08fc9a80e63553b5e5351047293fa32d0f9a23fb0587",
            "0x89c6ec9495853920bf2c8f1d02fe8a639f0e69869ef2fac8ba82d578596b5917",
            "0xb7407e2448dd13d9e14a9faa2bb48445b773cd023455a55d6ce451ff2d12f6c1",
            "0x6c656b1ede74429dc7f3e8fc3cc2f333eff62132672f9851b8ef6dfdeb84697e",
            "0xa9b3ba9ed83b5d2a5c979532d74e0df85197cdbbaf31105272360e407437482f",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xFE1f39Dd8C291BF6C54c02518052C7C3CC389260": {
        tier: "6",
        proof: [
            "0x0a0b636b26ea7f9ba0307728ac9a546d019af4a6d60fe333fe56eafb1c06f724",
            "0x89c6ec9495853920bf2c8f1d02fe8a639f0e69869ef2fac8ba82d578596b5917",
            "0xb7407e2448dd13d9e14a9faa2bb48445b773cd023455a55d6ce451ff2d12f6c1",
            "0x6c656b1ede74429dc7f3e8fc3cc2f333eff62132672f9851b8ef6dfdeb84697e",
            "0xa9b3ba9ed83b5d2a5c979532d74e0df85197cdbbaf31105272360e407437482f",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xCF2ad5F86899B9B30387eC12327363E8FFb9a6D2": {
        tier: "6",
        proof: [
            "0xafce08b93a436c76172f1fc611acd07864447260d3335241f7ade636f88560c4",
            "0x5b2698bcc4217d4b3a25d4a3f49ed33a4a5e457d133429f76237c6779a0027b3",
            "0x2507ed275603ffec6fdc19aca00b74c3b79c1397388fe9941800cafc13b38532",
            "0xbac5333f58cb23cacac078185e6154789e00310fe901cc2ff553311c0b74ef89",
            "0xa9b3ba9ed83b5d2a5c979532d74e0df85197cdbbaf31105272360e407437482f",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x066E4b6fdB954714Fd99517b96A9CF254Ed54283": {
        tier: "6",
        proof: [
            "0x58938aeaaea92824e9409c1d272a8f732c06e8303380decd96f4203b68371ed0",
            "0x5b2698bcc4217d4b3a25d4a3f49ed33a4a5e457d133429f76237c6779a0027b3",
            "0x2507ed275603ffec6fdc19aca00b74c3b79c1397388fe9941800cafc13b38532",
            "0xbac5333f58cb23cacac078185e6154789e00310fe901cc2ff553311c0b74ef89",
            "0xa9b3ba9ed83b5d2a5c979532d74e0df85197cdbbaf31105272360e407437482f",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xDaC86E3EcDf4Dc8535dC036e166a89f34A62f278": {
        tier: "6",
        proof: [
            "0xed194790638785c3eed6eaca415330405182c5f4da17eddf42b8b4b972eaef35",
            "0xd632368e001a81c6a3b87dd940b7f8e18cce2b344cf51da7e2aebf518c75c196",
            "0x2507ed275603ffec6fdc19aca00b74c3b79c1397388fe9941800cafc13b38532",
            "0xbac5333f58cb23cacac078185e6154789e00310fe901cc2ff553311c0b74ef89",
            "0xa9b3ba9ed83b5d2a5c979532d74e0df85197cdbbaf31105272360e407437482f",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xc0dBdefEAE07FD11FD6009Db3C98Eb42D05794AD": {
        tier: "6",
        proof: [
            "0x7b470e28bba194e61a132b48cc3278ab760d25981bc5f8faa5451f64fd0f553f",
            "0xd632368e001a81c6a3b87dd940b7f8e18cce2b344cf51da7e2aebf518c75c196",
            "0x2507ed275603ffec6fdc19aca00b74c3b79c1397388fe9941800cafc13b38532",
            "0xbac5333f58cb23cacac078185e6154789e00310fe901cc2ff553311c0b74ef89",
            "0xa9b3ba9ed83b5d2a5c979532d74e0df85197cdbbaf31105272360e407437482f",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xC02D1Eda13D073bd2c245f0a6B5A2aEd9DDF5c35": {
        tier: "6",
        proof: [
            "0xaabfedd7ac7429d2792c59acbb742f229e24f983617c217535c65a418a8b6b6e",
            "0x38e69f9ec40e5578ea46c2dc8ddbf8f4967e1c890b136f180cb3ffa3b73e0b12",
            "0x2fcf127fc3365711daed390c55f7975dec5e1cdc03ee9bcf168678d339e21ce5",
            "0xbac5333f58cb23cacac078185e6154789e00310fe901cc2ff553311c0b74ef89",
            "0xa9b3ba9ed83b5d2a5c979532d74e0df85197cdbbaf31105272360e407437482f",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x2c05ef646619E6Ce1b3162fDce62428309E632fD": {
        tier: "6",
        proof: [
            "0x15ad9e0c82c7d70354dbdfe77851e140728f59359c09dbeb47766fbbf96a533c",
            "0x38e69f9ec40e5578ea46c2dc8ddbf8f4967e1c890b136f180cb3ffa3b73e0b12",
            "0x2fcf127fc3365711daed390c55f7975dec5e1cdc03ee9bcf168678d339e21ce5",
            "0xbac5333f58cb23cacac078185e6154789e00310fe901cc2ff553311c0b74ef89",
            "0xa9b3ba9ed83b5d2a5c979532d74e0df85197cdbbaf31105272360e407437482f",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x9bf85cc1825F21A7B9371805733A0dA6e57a1E96": {
        tier: "6",
        proof: [
            "0x4aa55afb896737a380548661f507e8c49a1df2afac0523dce70017bca141d9a2",
            "0x9e1484985f3758c714f66ac7dcd55376264e76d8769b9a66527e3844534ff3cd",
            "0x2fcf127fc3365711daed390c55f7975dec5e1cdc03ee9bcf168678d339e21ce5",
            "0xbac5333f58cb23cacac078185e6154789e00310fe901cc2ff553311c0b74ef89",
            "0xa9b3ba9ed83b5d2a5c979532d74e0df85197cdbbaf31105272360e407437482f",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x1A73306100f9f27E153EF1044bE99bfBA644e7D2": {
        tier: "6",
        proof: [
            "0x63d820fea8e8ee401f0672d75df464a6be8498ce2a86bdd62cb11b2a9c2a9b2f",
            "0x9e1484985f3758c714f66ac7dcd55376264e76d8769b9a66527e3844534ff3cd",
            "0x2fcf127fc3365711daed390c55f7975dec5e1cdc03ee9bcf168678d339e21ce5",
            "0xbac5333f58cb23cacac078185e6154789e00310fe901cc2ff553311c0b74ef89",
            "0xa9b3ba9ed83b5d2a5c979532d74e0df85197cdbbaf31105272360e407437482f",
            "0x6383a6f80049cbccfd83fc0403e832850974f4f7fa1fc8592991f2a9e09ab2a5",
            "0x157ec603ec1d16cffef843538c296b217f50b6097206ae3fd6db9f59661d8568",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x0d832712EDed614b917e4524D3597574a8aC9D51": {
        tier: "6",
        proof: [
            "0xb6b1d482ff9a950cc04875fc1fc4107f6daf64c2cfbe772e3da0b028783b8be2",
            "0x73c31b53fd6c6e1bd9d345cd8544a798593e0aa581fe304716ee4825b2ba9bab",
            "0xeafa0f8f5989cdd1f3dc2f0ebfdf4a395cce44378debd5010821d57c780c436d",
            "0x9c4b011201f7ecacc791bc43a3214e760a011c4c096c8ed9857ab22b3456ba77",
            "0x95b5e570204749e750b851d16951122844d82735aa6fe994b90a3a1a045636c4",
            "0x270c7c330e12821d036115125c87e7280ab12875f1fdd48a65770511641e079f",
            "0x6c2cd06658e5ac7808fffabd28e2a46789256a67c997c313aa87439f6eb4272b",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xc0BD8ae8D84A1b985eC171A1545dFf5A40624666": {
        tier: "6",
        proof: [
            "0x26e9d2a1e093ea269f2711c382e3217021b43c512a5882e6cbe0873804d35769",
            "0x73c31b53fd6c6e1bd9d345cd8544a798593e0aa581fe304716ee4825b2ba9bab",
            "0xeafa0f8f5989cdd1f3dc2f0ebfdf4a395cce44378debd5010821d57c780c436d",
            "0x9c4b011201f7ecacc791bc43a3214e760a011c4c096c8ed9857ab22b3456ba77",
            "0x95b5e570204749e750b851d16951122844d82735aa6fe994b90a3a1a045636c4",
            "0x270c7c330e12821d036115125c87e7280ab12875f1fdd48a65770511641e079f",
            "0x6c2cd06658e5ac7808fffabd28e2a46789256a67c997c313aa87439f6eb4272b",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xFb1bB9d4a4C50457D339A686212A0716EA0adC80": {
        tier: "6",
        proof: [
            "0x1d5091109f9bf079ee53da053ba53e545c351cf39ea213e49e7ec6661c1104df",
            "0x567a7c86e41c3f80845f0d965be3bd629faee5956f035ed0def29366aec0410a",
            "0xeafa0f8f5989cdd1f3dc2f0ebfdf4a395cce44378debd5010821d57c780c436d",
            "0x9c4b011201f7ecacc791bc43a3214e760a011c4c096c8ed9857ab22b3456ba77",
            "0x95b5e570204749e750b851d16951122844d82735aa6fe994b90a3a1a045636c4",
            "0x270c7c330e12821d036115125c87e7280ab12875f1fdd48a65770511641e079f",
            "0x6c2cd06658e5ac7808fffabd28e2a46789256a67c997c313aa87439f6eb4272b",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x3E03E86E82B611046331E7b16f3e05ACF5a3eF6b": {
        tier: "6",
        proof: [
            "0xcac358c5f1844151ccd454cc877ec88a558a426db930f23ec337344fa5c47846",
            "0x567a7c86e41c3f80845f0d965be3bd629faee5956f035ed0def29366aec0410a",
            "0xeafa0f8f5989cdd1f3dc2f0ebfdf4a395cce44378debd5010821d57c780c436d",
            "0x9c4b011201f7ecacc791bc43a3214e760a011c4c096c8ed9857ab22b3456ba77",
            "0x95b5e570204749e750b851d16951122844d82735aa6fe994b90a3a1a045636c4",
            "0x270c7c330e12821d036115125c87e7280ab12875f1fdd48a65770511641e079f",
            "0x6c2cd06658e5ac7808fffabd28e2a46789256a67c997c313aa87439f6eb4272b",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xe4F945217091E0b868A5621fE0af1B118EF90A1f": {
        tier: "6",
        proof: [
            "0x7b1d0f14b31b42baeb7e377ab7f392ff76c7489580c69d68255f3f23810093a5",
            "0xae1676e1054ae17fd9268d82712abeaa34924ec847c58bd4f26c18cff4120a8c",
            "0x5de4f0bfd3e19c0d22a8709248631d910bd254d973ffe70b94e9c300ca9d48ec",
            "0x9c4b011201f7ecacc791bc43a3214e760a011c4c096c8ed9857ab22b3456ba77",
            "0x95b5e570204749e750b851d16951122844d82735aa6fe994b90a3a1a045636c4",
            "0x270c7c330e12821d036115125c87e7280ab12875f1fdd48a65770511641e079f",
            "0x6c2cd06658e5ac7808fffabd28e2a46789256a67c997c313aa87439f6eb4272b",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0x96c3bB1C9dA186C87a102f39a750745ff0DA843A": {
        tier: "6",
        proof: [
            "0x1a465dc284cfe0ad2a8fbd176afdcf113b6e0f4878361e0004c81252a42ca8fc",
            "0xae1676e1054ae17fd9268d82712abeaa34924ec847c58bd4f26c18cff4120a8c",
            "0x5de4f0bfd3e19c0d22a8709248631d910bd254d973ffe70b94e9c300ca9d48ec",
            "0x9c4b011201f7ecacc791bc43a3214e760a011c4c096c8ed9857ab22b3456ba77",
            "0x95b5e570204749e750b851d16951122844d82735aa6fe994b90a3a1a045636c4",
            "0x270c7c330e12821d036115125c87e7280ab12875f1fdd48a65770511641e079f",
            "0x6c2cd06658e5ac7808fffabd28e2a46789256a67c997c313aa87439f6eb4272b",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
    "0xD0d21BFED77e5228c0519BB63c459D0c945a37f2": {
        tier: "6",
        proof: [
            "0x0aaada20ab8be9494e9c844c5aad708cb659235be8881d31641bf2034f68f821",
            "0xae1676e1054ae17fd9268d82712abeaa34924ec847c58bd4f26c18cff4120a8c",
            "0x41b3de06248c90af4aee77bb6c63ae4548f3183adb8fb9e56c27ea09b5a19b17",
            "0x9c4b011201f7ecacc791bc43a3214e760a011c4c096c8ed9857ab22b3456ba77",
            "0x95b5e570204749e750b851d16951122844d82735aa6fe994b90a3a1a045636c4",
            "0x2a898300e68310e0364508cea9f6065d2cfd26f133cd8d227f80894bfa66c71b",
            "0x6c2cd06658e5ac7808fffabd28e2a46789256a67c997c313aa87439f6eb4272b",
            "0xb332fcf88a6be5aadae2741032ee0f4eae828805d785131849db4cb90910ebc7",
        ],
    },
};


export const claimComic = async (claimContract, claimerAddress) => {
    // function claim(uint256 tier, address claimer, bytes32[] calldata proof)
    let claimerTier = ownerToTierAndProof[claimerAddress].tier;
    let claimerProof = ownerToTierAndProof[claimerAddress].proof;
    // console.log("claimer",claimerAddress, claimerTier,claimerProof);

    let claimTx = await claimContract.claim(claimerTier, claimerAddress, claimerProof);
    return claimTx.wait();
};

export const claimActive = async (claimContract, claimerAddress) => {
    
    // console.log("claimer", claimerAddress);
    let approved = ownerToTierAndProof.hasOwnProperty(claimerAddress);
    return approved;
};

// export const checkClaimed = async (claimContract, claimerAddress) => {
//     // let approved = await claimContract.claimed(claimerAddress);

//     let approved = false;
//     return approved;
// };
// export const checkClaimed = async (claimContract, claimerAddress) => {
//     try {
//         // Simulate claimComic
//         let claimerTier = ownerToTierAndProof[claimerAddress].tier;
//         let claimerProof = ownerToTierAndProof[claimerAddress].proof;
//         await claimContract.callStatic.claim(
//             claimerTier,
//             claimerAddress,
//             claimerProof
//         );
//         return false; // If no error, return false
//     } catch (error) {
//       console.log("error", error);
//         return true; // If error, return true
//     }
// };

