import React, { useState, useEffect, useContext, createContext } from "react";
import axios from "axios";
import { ethers } from "ethers";
import {
  CLAIM_ABI,
  CLAIM_ADDRESS,
  MERKLE_CLAIM_ABI,
  MERKLE_CLAIM_ADDRESS,
} from "../config/configClaim";
import {
  listOfSpirits,
  formatSpirits,
  claimSpiritsMerkle,
} from "../helpers/claimHelpers";
import MetamaskContext from "../store/metamask-context";
import { Grid, Button, CircularProgress } from "@mui/material";

export default function Claim() {
  const [claimContract, setClaimContract] = useState(null);
  const [claimMerkleContract, setClaimMerkleContract] = useState(null);
  const [loading, setLoading] = useState(false);
  const [spirits, setSpirits] = useState({});
  const ctx = useContext(MetamaskContext);

  const handleClaimSpiritsMerkle = () => {
    setLoading(true);
    if (claimContract && claimMerkleContract && ctx.accounts.length > 0) {
      claimSpiritsMerkle(
        ctx.accounts[0],
        spirits["tokenIds"],
        spirits["proof"],
        claimMerkleContract
      )
        .then((res) => {
          setLoading(false);
          const loadSpirits = async () => {
            const spirits = await listOfSpirits(ctx.accounts[0], claimContract);
            setSpirits(spirits);
          };
          loadSpirits();
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (ctx.signer && ctx.accounts.length > 0) {
      const claimContract = new ethers.Contract(
        CLAIM_ADDRESS,
        CLAIM_ABI,
        ctx.signer
      );

      setClaimContract(claimContract);
      const loadSpirits = async () => {
        const spirits = await listOfSpirits(ctx.accounts[0], claimContract);
        setSpirits(spirits);
      };
      loadSpirits();

      const claimMerkleContract = new ethers.Contract(
        MERKLE_CLAIM_ADDRESS,
        MERKLE_CLAIM_ABI,
        ctx.signer
      );
      setClaimMerkleContract(claimMerkleContract);
    }
  }, [ctx.signer, ctx.accounts]);
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {ctx.network && ctx.isMetamaskConnected ? (
            !spirits ? (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                  width: "100%",
                  background:
                    "linear-gradient(119.13deg, rgba(150, 104, 34, 0.25) 22.09%, rgba(219, 57, 57, 0.25) 67.89%), #181818",
                  padding: "2%",
                  fontSize: "30px",
                  lineHeight: "24px",
                  fontFamily: "EurostilDemi",
                  letterSpacing: "-0.01em",
                  textAlign: "center",
                }}
              >
                {" "}
                YOU ALREADY CLAIMED YOUR SPIRITS{" "}
              </Grid>
            ) : spirits && Object.keys(spirits).length > 0 ? (
              <>
                <Button
                  style={{
                    minWidth: "30%",
                    borderRadius: 2,
                    fontSize: "32px",
                    fontWeight: "900",
                    fontFamily: "PPNeueMachina",
                    lineHeight: "23px",
                    letterSpacing: "-.0.02em",
                    marginTop: "1%",
                    padding: "2%",
                  }}
                  onClick={handleClaimSpiritsMerkle}
                  variant="contained"
                  color="error"
                  disabled={loading}
                >
                  {!loading ? "CLAIM" : <CircularProgress />}
                </Button>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    width: "100%",
                    background:
                      "linear-gradient(119.13deg, rgba(150, 104, 34, 0.25) 22.09%, rgba(219, 57, 57, 0.25) 67.89%), #181818",
                    padding: "2%",
                    fontSize: "30px",
                    lineHeight: "24px",
                    fontFamily: "EurostilDemi",
                    letterSpacing: "-0.01em",
                    textAlign: "center",
                  }}
                >
                  <div>
                    CLAIM YOUR
                    <span style={{ color: "rgba(255, 182, 49,1)" }}>
                      {" "}
                      {spirits?.tokenIds?.length}{" "}
                      {spirits?.tokenIds?.length === 1 ? "SPIRIT" : "SPIRITS"}{" "}
                    </span>{" "}
                    IN ONE TRANSACTION
                  </div>
                </Grid>
              </>
            ) : (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                  width: "100%",
                  background:
                    "linear-gradient(119.13deg, rgba(150, 104, 34, 0.25) 22.09%, rgba(219, 57, 57, 0.25) 67.89%), #181818",
                  padding: "2%",
                  fontSize: "30px",
                  lineHeight: "24px",
                  fontFamily: "EurostilDemi",
                  letterSpacing: "-0.01em",
                  textAlign: "center",
                }}
              >
                YOU HAVE NO SPIRITS TO CLAIM{" "}
              </Grid>
            )
          ) : (
            <div
              style={{
                fontSize: "30px",
                lineHeight: "24px",
                fontFamily: "EurostilDemi",
                letterSpacing: "-0.01em",
              }}
            >
              {" "}
              CONNECT TO CLAIM
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
