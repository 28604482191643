import React from "react";

import { Grid, useMediaQuery } from "@mui/material";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import map from "../assets/mapAbout.jpg";
import Tresoria from "../assets/worldImages/Tresoria.jpg";
import TheLab from "../assets/worldImages/Lab.jpg";
import Inferno from "../assets/worldImages/Inferno.jpg";
import TheUnderworld from "../assets/worldImages/The-Underworld.jpg";
import Ethercore from "../assets/worldImages/Ethercore.jpg";
import Hellgate from "../assets/worldImages/Hell-Gate.jpg";
import TabulaRasa from "../assets/worldImages/Tabula-Rasa.jpg";
import Heaven from "../assets/worldImages/Heaven.jpg";
const Map = () => {
  const matchesMedium = useMediaQuery("(max-width:1400px)");
  const matchesSmall = useMediaQuery("(max-width:1200px)");
  const matchesMobile = useMediaQuery("(max-width:850px)");
  const normalizeArgs = (args) => {
    const newArgs = {};

    Object.keys(args).forEach((key) => {
      const normalizedKey = key.split(".");
      const isNested = normalizedKey.length === 2;

      if (isNested) {
        if (!newArgs[normalizedKey[0]]) {
          newArgs[normalizedKey[0]] = {};
        }
        newArgs[normalizedKey[0]][normalizedKey[1]] = args[key];
      } else {
        newArgs[key] = args[key];
      }
    });

    return {
      ...newArgs,
      onWheelStart: undefined,
      onWheel: undefined,
      onWheelStop: undefined,
      onZoomStart: undefined,
      onZoom: undefined,
      onZoomStop: undefined,
      onPanningStart: undefined,
      onPanning: undefined,
      onPanningStop: undefined,
      onPinchStart: undefined,
      onPinch: undefined,
      onPinchStop: undefined,
    };
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        paddingTop: "100px",
        width: "100%",
        fontFamily: "EurostilNormal",
        fontWeight: "100",
      }}
    >
      <div
        style={{
          width: matchesMedium ? "80%" : "55%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          marginBottom: "10px",
          padding: 0,
        }}
      >
        <span
          style={{
            fontSize: matchesMedium ? "25px" : "32px",

            marginBottom: "10px",
            fontFamily: "PPNeueMachina",
            fontWeight: "900",
            color: "#ffb631",
          }}
        >
          World
        </span>
        <p
          style={{
            fontSize: matchesMedium ? "18px" : "18px",
            margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
          }}
        >
          {" "}
          A look at the first world, with it’s first community uncovered
          continent Tresoria. This is the first world of our origin dimensional
          plane. As you will discover below, there are other planes, many
          secrets that await discovery and great challenges past the great seas?
        </p>
      </div>

      <TransformWrapper
        wheel={{ step: "0.2" }}
        doubleClick={{ step: "0.6" }}
        style={{ position: "relative" }}
        initialScale={0.9}
        minScale={0.7}
        centerOnInit={true}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            {/* <div style={{ position: "relative", zIndex: 1, top: 30 }}>
              <button
                style={{
                  width: "50px",
                  marginRight: "10px",
                  height: "30px",
                  cursor: "pointer",
                }}
                onClick={() => zoomIn()}
              >
                +
              </button>
              <button
                style={{ width: "50px", height: "30px", cursor: "pointer" }}
                onClick={() => zoomOut()}
              >
                -
              </button>
            </div> */}
            <TransformComponent
              contentStyle={{
                padding: 0,
                width: matchesMobile ? "80vw" : matchesMedium ? "90vw" : "60vw",
                height: matchesMobile
                  ? "auto"
                  : matchesSmall
                  ? "600px"
                  : "auto",
              }}
            >
              <img src={map} style={{ width: "100%", height: "100%" }} />
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
      {/*  LEEEFTTTT TRESORIA*/}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "start",
          width: matchesMobile ? "80vw" : matchesMedium ? "90vw" : "60vw",
          height: "auto",
          margin: "40px 0",
        }}
      >
        <img src={Tresoria} style={{ width: "45%" }} />
        <div
          style={{
            width: "55%",
            display: "flex",
            marginLeft: "2%",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            marginBottom: "10px",
            padding: 0,
          }}
        >
          <span
            style={{
              fontSize: matchesSmall ? "20px" : "25px",
              fontFamily: "PPNeueMachina",
              marginBottom: "10px",
              fontWeight: "900",
              color: "#ffb631",
            }}
          >
            Tresoria
          </span>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 0,
            }}
          >
            {" "}
            The last homely strongholds north-west of the seas, once home of
            those imbued with magic, these ancient towering structures are
            scattered throughout what today is a fertile retreat.
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            Enchanted writings still echo these valleys. Mortals refer to them
            as "the spires in the clouds".
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            Travelers of these regions murmur prayers to the elders of the
            timeways, high ground sorcerers waging unimaginable power.
          </p>

          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            Owner:{" "}
            <span
              style={{
                color: "#ffb631",
                fontFamily: "PPNeueMachina",
                fontWeight: "900",
                color: "#ffb631",
              }}
            >
              bcdguru
            </span>
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 2,
              width: "100%",
            }}
          >
            {" "}
            Link:{" "}
            <a
              style={{
                color: "#ffb631",
                width: "100%",
                wordWrap: "break-word",
              }}
              href="https://opensea.io/assets/ethereum/ethereum/0x0e847aad9b5b25cea58613851199484be3c4fa13/1"
            >
              {" "}
              https://opensea.io/assets/ethereum/ethereum/0x0e847aad9b5b25cea58613851199484be3c4fa13/1
            </a>
          </p>
        </div>
      </div>
      {/*  RIGHT EtherCore*/}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "start",
          width: matchesMobile ? "80vw" : matchesMedium ? "90vw" : "60vw",
          height: "auto",
          margin: "40px 0",
        }}
      >
        <div
          style={{
            width: "50%",
            display: "flex",
            marginRight: "2%",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            marginBottom: "10px",
            padding: 0,
          }}
        >
          <span
            style={{
              fontSize: matchesSmall ? "20px" : "25px",
              fontFamily: "PPNeueMachina",
              marginBottom: "10px",
              fontWeight: "900",
              color: "#ffb631",
            }}
          >
            Ethercore
          </span>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 0,
            }}
          >
            {" "}
            “Silent watchers, unfathomable creatures untouched by the ticking of
            ages.”
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            Location yet unknown.
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            It’s not yet clear where the energy source of this powerful device
            is being channeled to, but speculations have been made and there is
            a possible answer.
          </p>

          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            Owner:{" "}
            <span
              style={{
                color: "#ffb631",
                fontFamily: "PPNeueMachina",
                fontWeight: "900",
                color: "#ffb631",
              }}
            >
              lyfe_aquatic
            </span>
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 2,
              width: "100%",
            }}
          >
            {" "}
            Link:{" "}
            <a
              style={{
                color: "#ffb631",
                width: "100%",
                wordWrap: "break-word",
              }}
              href="https://superrare.com/artwork-v2/ethercore-15937"
            >
              {" "}
              https://superrare.com/artwork-v2/ethercore-15937
            </a>
          </p>
        </div>
        <img src={Ethercore} style={{ width: "45%" }} />
      </div>
      {/*  LEEEFTTTT Lab*/}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "start",
          width: matchesMobile ? "80vw" : matchesMedium ? "90vw" : "60vw",
          height: "auto",
          margin: "40px 0",
        }}
      >
        <img src={TheLab} style={{ width: "45%" }} />
        <div
          style={{
            width: "55%",
            marginLeft: "2%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            marginBottom: "10px",
            padding: 0,
          }}
        >
          <span
            style={{
              fontSize: matchesSmall ? "20px" : "25px",
              fontFamily: "PPNeueMachina",
              marginBottom: "10px",
              fontWeight: "900",
              color: "#ffb631",
            }}
          >
            The L.A.B.
          </span>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 0,
            }}
          >
            {" "}
            "We knew it had to be somewhere; at long last, buried under a
            fortress of stone, lulled in an endless slumber. The L.A.B has been
            unearthed."
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            The place where Heroes are built!
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            Souls and Spirits gather to the Lab, attracted by mysterious
            energies and channeled into flesh bodies forging unrivaled Heroes.
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            Who built built it in the first place?
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 2,
              width: "100%",
            }}
          >
            {" "}
            Enter the lab:{" "}
            <a
              style={{
                color: "#ffb631",
                width: "100%",
                wordWrap: "break-word",
              }}
              href="https://leanime.art/thelab"
            >
              {" "}
              https://leanime.art/thelab
            </a>
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            The Lab Master:{" "}
            <span
              style={{
                color: "#ffb631",
                fontFamily: "PPNeueMachina",
                fontWeight: "900",
                color: "#ffb631",
              }}
            >
              silverpoet
            </span>
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 2,
              width: "100%",
            }}
          >
            {" "}
            Link:{" "}
            <a
              style={{
                color: "#ffb631",
                width: "100%",
                wordWrap: "break-word",
              }}
              href="https://superrare.com/artwork-v2/the-l.a.b.-28554"
            >
              {" "}
              https://superrare.com/artwork-v2/the-l.a.b.-28554
            </a>
          </p>
        </div>
      </div>
      {/*  RIGHT Hell Gate*/}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "start",
          width: matchesMobile ? "80vw" : matchesMedium ? "90vw" : "60vw",
          height: "auto",
          margin: "40px 0",
        }}
      >
        <div
          style={{
            width: "50%",
            display: "flex",
            marginRight: "2%",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            marginBottom: "10px",
            padding: 0,
          }}
        >
          <span
            style={{
              fontSize: matchesSmall ? "20px" : "25px",
              fontFamily: "PPNeueMachina",
              marginBottom: "10px",
              fontWeight: "900",
              color: "#ffb631",
            }}
          >
            Hell Gate
          </span>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 0,
            }}
          >
            {" "}
            The door to Hell, where the journey starts.
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            This place leads to the deeper dimensional plane of Inferno.
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            Few have ventured through this doors..
          </p>

          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            Owner:{" "}
            <span
              style={{
                color: "#ffb631",
                fontFamily: "PPNeueMachina",
                fontWeight: "900",
                color: "#ffb631",
              }}
            >
              Le Anime Open Edition holders
            </span>
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 2,
              width: "100%",
            }}
          >
            {" "}
            Link:{" "}
            <a
              style={{
                color: "#ffb631",
                width: "100%",
                wordWrap: "break-word",
              }}
              href="https://www.niftygateway.com/marketplace/collection/0x41113bce4659cb4912ed61d48154839f75131d7a/1?filters[onSale]=true&sortBy=lowest"
            >
              {" "}
              https://www.niftygateway.com/marketplace/collection/0x41113bce4659cb4912ed61d48154839f75131d7a/1?filters[onSale]=true&sortBy=lowest
            </a>
          </p>
        </div>
        <img src={Hellgate} style={{ width: "45%" }} />
      </div>
      {/*  LEEEFTTTT Inferno*/}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
          width: matchesMobile ? "80vw" : matchesMedium ? "90vw" : "60vw",
          height: "auto",
          margin: "40px 0",
        }}
      >
        <img src={Inferno} style={{ width: "45%" }} />
        <div
          style={{
            width: "50%",
            display: "flex",
            marginRight: "2%",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            marginBottom: "10px",
            padding: 0,
          }}
        >
          <span
            style={{
              fontSize: matchesSmall ? "20px" : "25px",
              fontFamily: "PPNeueMachina",
              marginBottom: "10px",
              fontWeight: "900",
              color: "#ffb631",
            }}
          >
            Inferno
          </span>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 0,
            }}
          >
            {" "}
            The beginning of this journey and a reckoning with oneself through
            sins, pain, and reflection.
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            This is the initial descent towards enlightenment, self-improvement
            and whatever else might await.
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            Giganti, Amore and Il Diavolo reside in the same dimension of
            Inferno, each in a deeper level of this nether region.
          </p>

          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
            }}
          >
            {" "}
            Owners:{" "}
            <span
              style={{
                color: "#ffb631",
                fontFamily: "PPNeueMachina",
                fontWeight: "900",
                color: "#ffb631",
              }}
            >
              Collectors of My Journey
            </span>
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 2,
              width: "100%",
            }}
          >
            {" "}
            Link:{" "}
            <a
              style={{
                color: "#ffb631",
                width: "100%",
                wordWrap: "break-word",
              }}
              href="https://www.niftygateway.com/marketplace/collection/0xd8d1f01b836f5717bbc5639e91d78032ebae2571"
            >
              {" "}
              https://www.niftygateway.com/marketplace/collection/0xd8d1f01b836f5717bbc5639e91d78032ebae2571
            </a>
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            il Diavolo:{" "}
            <span
              style={{
                color: "#ffb631",
                fontFamily: "PPNeueMachina",
                fontWeight: "900",
                color: "#ffb631",
              }}
            >
              Roger Dickerman
            </span>
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 2,
              width: "100%",
            }}
          >
            {" "}
            Link:{" "}
            <a
              style={{
                color: "#ffb631",
                width: "100%",
                wordWrap: "break-word",
              }}
              href="https://www.niftygateway.com/marketplace/collection/0xd8d1f01b836f5717bbc5639e91d78032ebae2571/4"
            >
              {" "}
              https://www.niftygateway.com/marketplace/collection/0xd8d1f01b836f5717bbc5639e91d78032ebae2571/4
            </a>
          </p>
        </div>
      </div>
      {/*  RIGHT Redemtia*/}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "start",
          width: matchesMobile ? "80vw" : matchesMedium ? "90vw" : "60vw",
          height: "auto",
          margin: "40px 0",
        }}
      >
        <div
          style={{
            width: "50%",
            display: "flex",
            marginRight: "2%",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            marginBottom: "10px",
            padding: 0,
          }}
        >
          <span
            style={{
              fontSize: matchesSmall ? "20px" : "25px",
              fontFamily: "PPNeueMachina",
              marginBottom: "10px",
              fontWeight: "900",
              color: "#ffb631",
            }}
          >
            Redemptia
          </span>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 0,
            }}
          >
            {" "}
            The continent where Our Redemption took place.
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            An historic event in Le Anime timeline. Adventurers scouted the
            lands from East to West and North to South in search of the L.A.B.
            exploring every inch of land.. until the it was uncovered.
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            Redemptia is a very diversified continent with multiple biomes and
            climates.
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            Important settlements inhabit these lands, and plenty of secrets are
            sealed within it’s settlements and populations.
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            Owner:{" "}
            <span
              style={{
                color: "#ffb631",
                fontFamily: "PPNeueMachina",
                fontWeight: "900",
                color: "#ffb631",
              }}
            >
              Our Redemption holders
            </span>
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 2,
              width: "100%",
            }}
          >
            {" "}
            Link:{" "}
            <a
              style={{
                color: "#ffb631",
                width: "100%",
                wordWrap: "break-word",
              }}
              href="https://www.niftygateway.com/marketplace/collection/0x6fe1673c4a4b70903137eebf2deba2cdf5c20af6"
            >
              {" "}
              https://www.niftygateway.com/marketplace/collection/0x6fe1673c4a4b70903137eebf2deba2cdf5c20af6
            </a>
          </p>
        </div>
        <img src={TabulaRasa} style={{ width: "45%" }} />
      </div>
      {/*  LEEEFTTTT The Underworld*/}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "start",
          width: matchesMobile ? "80vw" : matchesMedium ? "90vw" : "60vw",
          height: "auto",
          margin: "40px 0",
        }}
      >
        <img src={TheUnderworld} style={{ width: "45%" }} />
        <div
          style={{
            width: "50%",
            marginLeft: "2%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            marginBottom: "10px",
            padding: 0,
          }}
        >
          <span
            style={{
              fontSize: matchesSmall ? "20px" : "25px",
              fontFamily: "PPNeueMachina",
              marginBottom: "10px",
              fontWeight: "900",
              color: "#ffb631",
            }}
          >
            The Underworld
          </span>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 0,
            }}
          >
            {" "}
            Another dimensional plane, somehow connected to the current world.
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            Past the surface and deep in the murky shadows of the underworld
            exists a place of darkness devoid of life, where the Forbidden Kings
            have been biding time and gaining strength undisturbed.
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            Since ancient times, these forces have prowled the nether, awaiting
            retribution. As they've steeped in their sharp hunger for vengeance,
            the Kings have assembled armies of unimaginable power.
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            Now, the Five Crowns have been awakened...
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            Owners:{" "}
            <span
              style={{
                color: "#ffb631",
                fontFamily: "PPNeueMachina",
                fontWeight: "900",
                color: "#ffb631",
              }}
            >
              Underworld Crowns holders
            </span>
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 2,
              width: "100%",
            }}
          >
            {" "}
            Link:{" "}
            <a
              style={{
                color: "#ffb631",
                width: "100%",
                wordWrap: "break-word",
              }}
              href="https://www.niftygateway.com/marketplace/collection/0x38cb271642fbd19a2592a15504420b1a78288a62"
            >
              {" "}
              https://www.niftygateway.com/marketplace/collection/0x38cb271642fbd19a2592a15504420b1a78288a62
            </a>
          </p>
        </div>
      </div>
      {/*  RIGHT Heaven*/}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "start",
          width: matchesMobile ? "80vw" : matchesMedium ? "90vw" : "60vw",
          height: "auto",
          margin: "40px 0",
        }}
      >
        <div
          style={{
            width: "50%",
            display: "flex",
            marginRight: "2%",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            marginBottom: "10px",
            padding: 0,
          }}
        >
          <span
            style={{
              fontSize: matchesSmall ? "20px" : "25px",
              fontFamily: "PPNeueMachina",
              marginBottom: "10px",
              fontWeight: "900",
              color: "#ffb631",
            }}
          >
            ????HEAV3N??
          </span>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 0,
            }}
          >
            {" "}
            ?????????????????
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 0,
            }}
          >
            {" "}
            ????GODS??????
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 0,
            }}
          >
            {" "}
            ?
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 0,
            }}
          >
            {" "}
            ?
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              margin: 0,
            }}
          >
            {" "}
            ??????DIETY?????
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              margin: 0,
            }}
          >
            {" "}
            ????????????????????????????
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              margin: 0,
            }}
          >
            {" "}
            ?????????????
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              margin: 0,
            }}
          >
            {" "}
            ??????????????????THEONE?????
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              margin: 0,
            }}
          >
            {" "}
            ???OMNIPOT??
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              margin: 0,
            }}
          >
            {" "}
            ??
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              margin: 0,
            }}
          >
            {" "}
            ?
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            ?
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
            }}
          >
            {" "}
            Owner:{" "}
            <span
              style={{
                color: "#ffb631",
                fontFamily: "PPNeueMachina",
                fontWeight: "900",
                color: "#ffb631",
              }}
            >
              ?????
            </span>
          </p>
          <p
            style={{
              fontSize: matchesMedium ? "15px" : "18px",
              fontWeight: 100,
              fontFamily: "EurostilNormal",
              letterSpacing: 1,
              margin: 2,
              width: "100%",
            }}
          >
            {" "}
            Link:{" "}
            <a
              style={{
                color: "#ffb631",
                width: "100%",
                wordWrap: "break-word",
              }}
              href="?????"
            >
              {" "}
              ?????
            </a>
          </p>
        </div>
        <img src={Heaven} style={{ width: "45%" }} />
      </div>
    </Grid>
  );
};
export default Map;
