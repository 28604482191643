import React, { useState, useEffect } from "react";
import { Grid, useMediaQuery, Button } from "@mui/material/";
import { Wallet, Nav } from ".";
import { Link, useLocation } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../assets/leanimelogo.svg";
import logoHorizontal from "../assets/leanime-yellow.png";
const Item = styled("div")({
  textAlign: "center",
  padding: "1% 0",
  width: "100%",
});

const Overlay = styled("div")({
  position: "fixed",
  top: "0",
  left: "0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
  background: "rgba(255,255,255,0.97)",
  fontFamily: "EurostilDemi",
  width: "100vw",
  height: "100%",

  fontWeight: "200",
  zIndex: 1200,
});

const Header = () => {
  const { pathname } = useLocation();

  const [isHamMenuActive, setHamMenuActive] = useState(false);
  const matches = useMediaQuery("(max-width:850px)");
  const matchesMobile = useMediaQuery("(max-width:800px)");
  const matchesLandscape = useMediaQuery("(orientation:landscape)");
  const matchesLandscapeHeight = useMediaQuery("(max-height:500px)");

  const toggleHamMenu = () => {
    setHamMenuActive(!isHamMenuActive);
  };
  return (
    <header>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems={
          pathname === "/" ? (!matches ? "center" : "start") : "center"
        }
        style={{
          padding: matches ? "10px 20px" : "20px 20px",
          position:
            pathname === "/collection"
              ? matches
                ? "fixed"
                : "fixed"
              : matches
              ? "fixed"
              : "absolute",
          zIndex: 1,
          fontSize: "14px",
          fontFamily: "PPNeueMachina",
          background:
            pathname === "/collection"
              ? matches
                ? "#111111"
                : "#111111"
              : matches
              ? pathname === "/"
                ? "transparent"
                : "#111111"
              : "transparent",
          fontWeight: "900",
          width: "100%",
        }}
      >
        {!matches && !matchesLandscapeHeight ? (
          <>
          <Grid
            item
            xs={2}
            // xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                // width: "90%",
              }}
            >
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                  cursor: "pointer",
                }}
                to="/"
              >
                <img style={{ height: "18px" }} src={logoHorizontal} />
              </Link>
            </Grid>
            <Grid
            item
            xs={8}
            // xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: matches ? "15px" : "24px",
                lineHeight: "23px",
                letterSpacing: "-0.01em",
                fontFamily: "PPNeueMachina",
                // width: "90%",
              }}
            >
              <Nav />
            </Grid>

            <Grid
              item
              xs={2}
              // xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
              }}
            >
              <Wallet />
            </Grid>
          </>
        ) : pathname !== "/" ? (
          <>
            {" "}
            <Grid
              item
              xs={3}
              justifyContent="start"
              alignItems="start"
              style={{
                fontSize: "24px",
                fontFamily: "PPNeueMachina",
              }}
            >
              {/* <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                  cursor: "pointer",
                }}
                to="/"
              > */}
                <Wallet mobile={true}/>
              {/* </Link> */}
            </Grid>
            <Grid
              item
              justifyContent="center"
              alignItems="center"
              style={{
                fontSize: matches ? "15px" : "24px",
                fontFamily: "PPNeueMachina",
                cursor: "pointer",
              }}
            >
              {" "}
              <MenuIcon onClick={toggleHamMenu} style={{ cursor: "pointer" }} />
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              xs={3}
              justifyContent="start"
              alignItems="start"
              style={{
                fontSize: "24px",
                fontFamily: "PPNeueMachina",
              }}
            >
              {/* <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                  cursor: "pointer",
                }}
                to="/"
              > */}
                <Wallet mobile={true}/>
              {/* </Link> */}
            </Grid>

            <Grid
              item
              justifyContent="center"
              alignItems="center"
              style={{
                fontSize: matches ? "15px" : "24px",
                fontFamily: "PPNeueMachina",
                cursor: "pointer",
              }}
            >
              {" "}
              <MenuIcon onClick={toggleHamMenu} style={{ cursor: "pointer" }} />
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="start"
              alignItems="start"
              paddingTop="20px"
              style={{
                fontSize: "24px",
                fontFamily: "PPNeueMachina",
              }}
            >
              <img style={{ height: "100px" }} src={logo} />
            </Grid> 
          </>
          
          
        )}
      </Grid>
      {isHamMenuActive && matches && (
        <Overlay style={{ 
          width: "100%", 
          overflowY: "auto",
          }}>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                color: "black",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "30px 20px",
              }}
            >
              {matchesMobile && (
                <Link
                  style={{
                    fontSize: "24px",
                    fontFamily: "PPNeueMachina",
                    textDecoration: "none",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={toggleHamMenu}
                  to="/"
                >
                  LE ANIME
                </Link>
              )}
              {/* {!matchesMobile && <Wallet toggleMenu={toggleHamMenu} />} */}
              <CloseIcon
                onClick={toggleHamMenu}
                style={{ cursor: "pointer" }}
              />
            </div>
            {/* THIS IS THE MOBILE MENU */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                fontSize: "40px",
                padding: "10px 20px",
              }}
            >
              {/* <Link
                onClick={toggleHamMenu}
                style={{ textDecoration: "none", color: "black" }}
                to="/about"
              >
                ABOUT
              </Link>{" "} */}
              <Link
                onClick={toggleHamMenu}
                style={{ textDecoration: "none", color: "black" }}
                to="/entities"
              >
                ENTITIES
              </Link>{" "}
              <Link
                onClick={toggleHamMenu}
                style={{ textDecoration: "none", color: "black" }}
                to="/collection"
              >
                COLLECTION
              </Link>{" "}
              <Link
                onClick={toggleHamMenu}
                style={{ textDecoration: "none", color: "black" }}
                to="/wallet"
              >
                MY NFTS
              </Link>{" "}
              <Link
                onClick={toggleHamMenu}
                style={{ textDecoration: "none", color: "black" }}
                to="/builder"
              >
                BUILDER
              </Link>{" "}
              <Link
                onClick={toggleHamMenu}
                style={{ textDecoration: "none", color: "black" }}
                to="/history"
              >
                HISTORY
              </Link>{" "}
              <Link
                onClick={toggleHamMenu}
                style={{ textDecoration: "none", color: "black" }}
                to="/map"
              >
                WORLD
              </Link>{" "}
              <a
                style={{ textDecoration: "none", color: "black" }}
                target="_blank"
                href={"https://opensea.io/collection/le-anime-by-toomuchlag"}
              >
                OPENSEA{" "}
              </a>
              <Link
                onClick={toggleHamMenu}
                style={{ textDecoration: "none", color: "black" }}
                to="/links"
              >
                LINKS
              </Link>{" "}
              <Link
                onClick={toggleHamMenu}
                style={{ textDecoration: "none", color: "black" }}
                to="/library"
              >
                LIBRARY
              </Link>{" "}
              <Link
                onClick={toggleHamMenu}
                style={{ textDecoration: "none", color: "black" }}
                to="/comic"
              >
                COMIC
              </Link>{" "}
              <Link
                onClick={toggleHamMenu}
                style={{ textDecoration: "none", color: "black" }}
                to="/heroes-leaderboard"
              >
                LEADERBOARD
              </Link>{" "}
              
              <Link
                onClick={toggleHamMenu}
                style={{ textDecoration: "none", color: "black" }}
                to="/talanji"
              >
                TALANJI
              </Link>{" "}
            </div>
          </div>
        </Overlay>
      )}
    </header>
  );
};

export default Header;
