import React, { useEffect, useState, useCallback, useRef } from "react";

import { Link } from "react-router-dom";


import "./style.css";
import {
    Grid,
    useMediaQuery,
    Input,
    Button,
    Modal,
    TextField,
    CircularProgress,
    Snackbar,
    Stack,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { listAnimeSpiritsHeroesOfOwner_separate } from "../../helpers/mergerHelpers.js";
import { getBatchHeroExpDataAndClaimableExp } from "../../helpers/leaderboardHelpers.js";
import Fade from "@mui/material/Fade";

import labImage from "../../assets/ordinalsDay/Lab_V0.jpg";
import ethercoreImage from "../../assets/ordinalsDay/Ethercore_V0.jpg";
import exampleHeroes from "../../assets/ordinalsDay/Heroes_V0.png";

import titleOrdinals from "../../assets/ordinalsDay/Title_V0.png";
import buttonLarge from "../../assets/ordinalsDay/Button_L_V0.png"; 
import buttonSmall from "../../assets/ordinalsDay/Button_S_V0.png";

// import songBg from "../../assets/ordinalsDay/Tetris.mp3";

const OrdinalsDay = () => {
    const matchesExtraLarge = useMediaQuery("(max-width:1600px)");
    const matchesLarge = useMediaQuery("(max-width:1500px)");
    const matchesMedium = useMediaQuery("(max-width:1200px)");
    const matchesSmall = useMediaQuery("(max-width:1024px)");
    const matchesMobile = useMediaQuery("(max-width:600px)");

    // const audioRef = useRef(); // Create a ref


    const [pageIndex, setPageIndex] = useState(0);


    useEffect(() => {
        //STYLE
        document.body.style.background = ` linear-gradient(rgba(0,0,0,0.93),rgba(0,0,0,0.93)), url(${labImage})`;
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundSize = "cover";
        document.body.style.backgroundColor = "#000000";    
        return () => {
            document.body.style.background = "#111111";

            
        
          
        };
    }, []);



  
    const handleNext = () => {
      if (pageIndex == 0) {
        setPageIndex(1);
        // audioRef.current.play();
      }
      if (pageIndex == 1) {
        // direct to exp-raffle page
       window.location.hash = "/exp-raffle";
        
      }
    };

    return (
        <Grid
            container
            direction="column"
            justifyContent="start"
            alignItems="center"
            style={{
                paddingTop: "100px",
                width: "100%",
                height: "100%",
            }}
        >
          {/* <audio ref={audioRef} src={songBg} loop controls hidden /> */}
          {/* <button
        onClick={() => {
          play ? setPlay(false) : setPlay(true);
          play ? audio.pause() : audio.play();
        }}
      ></button> */}
          

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                    width: "100%",
                }}
            >
                
                
                {pageIndex == 0 && ( <>
                        <div style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "50%",
                              maxWidth: "600px",
                              paddingTop: "25px",  
                            }}>
                                      <img
                                          style={{
                                              width: "100%",
                                              marginTop: "0px",
                                              marginBottom: "20px",
                                              
                                          }}

                                          src={titleOrdinals}
                                      />
                                    </div>
                                    
                                    <p
                                        style={{
                                            fontSize: matchesSmall ? "12px" : "14px",
                                            // margin: "12px 0",
                                            letterSpacing: 2,
                                            // fontWeight: 100,
                                            width: matchesMobile ? "80%" : "80%",
                                            fontFamily: "EurostilNormal",
                                            whiteSpace: "pre-line",
                                            textAlign: "center",
                                            fontStyle: "italic",
                                        }}
                                    >
                                            Your journey has brought you here once again..<br/><br/>
                                            You can't quite grasp what's happening, but it's obvious.. <br/><br/>
                                            ..something has changed.. ..the world feels.. different?<br/><br/>                   
                                    </p>
                                    <p
                                        style={{
                                            fontSize: matchesSmall ? "14px" : "16px",
                                            // margin: "12px 0",
                                            letterSpacing: 2,
                                            // fontWeight: 100,
                                            width: matchesMobile ? "80%" : "80%",
                                            fontFamily: "EurostilNormal",
                                            whiteSpace: "pre-line",
                                            textAlign: "center",
                                            fontStyle: "italic",
                                        }}
                                    >  
                                            The LAB has re-awakened?<br/><br/>                      
                                    </p>
                                    
                        
                            <div style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "80%",
                              paddingTop: "0px",
                            }}>

                                <Grid 
                                      item xs={12} sm={12} md={12}
                                      style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                            marginTop: "0px",
                                        }}>
                                    
                                    <div>
                                      <img
                                          style={{
                                              width: "100%",
                                              maxWidth: "500px",
                                              marginTop: "0px",
                                          }}

                                          src={labImage}
                                      />
                                    </div>
                                      
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                
                                    style={{ width: "100%", marginBottom: "150px"}}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ marginTop: "0px", display: 'flex', justifyContent: 'center' }}
                                    >
                                        <Button
                                            style={{
                                                // background: (address && isConnected && isChainSupported && isSacrificeActive) ? "#ffb631" : "gray",
                                                backgroundImage: `url(${buttonSmall})`,
                                                backgroundSize: matchesMobile ? "250px auto" : "250px auto", // Add this line
                                                backgroundRepeat: 'no-repeat', // Add this line
                                                backgroundPosition: 'center', // Add this line
                                                color: "white",
                                                width: matchesMobile ? "250px" : "250px",
                                                padding: "25px 0",
                                                marginTop: "25px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderRadius: 0,
                                                fontSize: matchesMobile ? "14px" : "20px",
                                                fontFamily: "EurostilDemi",
                                            }}
                                            // disabled={(address && isConnected && isChainSupported && isSacrificeActive) ? false : true}
                                            onClick={() => {
                                                handleNext();
                                            }}
                                        >
                                            <span> ENTER THE LAB </span>
                                        </Button>{" "}
                                    </Grid>

                                </Grid>
                            </div>
                        
                    </>)}
                {pageIndex == 1 && ( <>
                    <div style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          width: "50%",
                          maxWidth: "600px",
                          paddingTop: "25px",  
                        }}>
                                  <img
                                      style={{
                                          width: "100%",
                                          marginTop: "0px",
                                          marginBottom: "20px",
                                          
                                      }}

                                      src={titleOrdinals}
                                  />
                                </div>
                                
                                <p
                                    style={{
                                        fontSize: matchesSmall ? "12px" : "14px",
                                        // margin: "12px 0",
                                        letterSpacing: 2,
                                        // fontWeight: 100,
                                        width: matchesMobile ? "80%" : "80%",
                                        maxWidth: "500px",
                                        fontFamily: "EurostilNormal",
                                        whiteSpace: "pre-line",
                                        textAlign: "center",
                                        fontStyle: "italic",
                                    }}
                                >
                                        In disbelief your eyes witness what you thought was impossible. <br/><br/>
                                        The reactor, brimming with radiating energy..<br/><br/>
                                        Ethercore is aligned once again!             
                                </p>
                                
                    
                        <div style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          width: "80%",
                          paddingTop: "20px",
                      
                        }}>

                            <Grid 
                                  item xs={12} sm={12} md={12}
                                  style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                        marginTop: "0px",
                                        marginBottom: "20px",
                                    }}>
                                
                                <div>
                                  <img
                                      style={{
                                          width: "100%",
                                          maxWidth: "500px",
                                          marginTop: "0px",
                                          marginBottom: "20px",
                                      }}

                                      src={ethercoreImage}
                                  />
                                </div>

                                <p
                                    style={{
                                        fontSize: matchesSmall ? "12px" : "14px",
                                        // margin: "12px 0",
                                        letterSpacing: 2,
                                        // fontWeight: 100,
                                        width: matchesMobile ? "80%" : "80%",
                                        maxWidth: "500px",
                                        fontFamily: "EurostilNormal",
                                        whiteSpace: "pre-line",
                                        textAlign: "center",
                                        fontStyle: "italic",
                                    }}
                                >
                                        You sense immense power from within, one step forward and you could be forever linked to this new dimension...<br/><br/>
                                        A new version of yourself.<br/><br/>
                                        (Your original Hero will not be altered) 
                                </p>
                                  
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
            
                                style={{ width: "100%", marginBottom: "150px"}}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    style={{ marginTop: "0px", display: 'flex', justifyContent: 'center' }}
                                >
                                    <Button
                                        style={{
                                            // background: (address && isConnected && isChainSupported && isSacrificeActive) ? "#ffb631" : "gray",
                                            backgroundImage: `url(${buttonLarge})`,
                                            backgroundSize: matchesMobile ? "300px auto" : "350px auto", // Add this line
                                            backgroundRepeat: 'no-repeat', // Add this line
                                            backgroundPosition: 'center', // Add this line
                                            color: "white",
                                            width: matchesMobile ? "300px" : "350px",
                                            padding: "10px 0",
                                            marginTop: "0px",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: 0,
                                            fontSize: matchesMobile ? "14px" : "20px",
                                            fontFamily: "EurostilDemi",
                                        }}
                                        // disabled={(address && isConnected && isChainSupported && isSacrificeActive) ? false : true}
                                        onClick={() => {
                                            handleNext();
                                        }}
                                    >
                                        <span> BECOME AN ORDINAL HERO </span>
                                    </Button>{" "}
                                </Grid>

                            </Grid>
                        </div>
                    
                </>)}
               
            </Grid>
        </Grid>
    );
};

export default OrdinalsDay;
