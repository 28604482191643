import React from "react";

import { Grid, useMediaQuery } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";

import { Link } from "react-router-dom";
import LeAnime from "../assets/LeAnime_sq.jpg";
import Souls from "../assets/Souls_sq.jpg";
import Spirits from "../assets/10049m.jpg";
import Wrapped from "../assets/Wrapped-LeAnime_sq.jpg";
import Heroes from "../assets/17m.jpg";
import Diavolo from "../assets/Diavolo_sq.jpg";
import Nemeses from "../assets/Nemeses_sq.jpg";
const StyledEntityOverlay = styled("div")({
  top: 0,
  left: 0,
  width: "100%",
  height: "10px",
  background:
    "linear-gradient(to right, rgba(255, 182, 49,1), rgba(219, 57, 57, 1))",
});

const StyledBlueEntityOverlay = styled("div")({
  top: 0,
  left: 0,
  width: "100%",
  height: "10px",
  background: "linear-gradient(to right, rgba(255,255,255,1), rgb(60,11,184))",
});
const Entities = () => {
  const matchesMedium = useMediaQuery("(max-width:1400px)");
  const matchesSmall = useMediaQuery("(max-width:1200px)");
  const matchesMobile = useMediaQuery("(max-width:850px)");
  const matchesSmallMobile = useMediaQuery("(max-width:450px)");
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        paddingTop: matchesSmall ? "100px" : "100px",
        marginBottom: "30px",
        width: "100%",
        fontFamily: "EurostilNormal",

        fontWeight: "100",
      }}
    >
      <div
        style={{
          width: matchesSmallMobile ? "70%" : "90%",
          marginBottom: "70px",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <span
          style={{
            fontSize: matchesSmall ? "25px" : "32px",
            fontFamily: "PPNeueMachina",
            marginBottom: "10px",
            fontWeight: "900",
            color: "#ffb631",
          }}
        >
          Entities
        </span>
        <Grid
          columnSpacing={matchesSmallMobile ? 0 : 2}
          rowSpacing={2}
          container
          direction="row"
          justifyContent="start"
          alignItems="center"
          style={{
            marginBottom: "30px",

            width: "100%",
            fontFamily: "EurostilNormal",
            fontWeight: "100",
          }}
        >
          <Grid
            item
            xs={
              matchesSmallMobile
                ? 12
                : matchesMobile
                ? 6
                : matchesSmall
                ? 4
                : matchesMedium
                ? 3
                : 3
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              <img style={{ width: "100%" }} src={LeAnime} />{" "}
              <div
                style={{
                  background: "rgba(255, 255, 255, 0.08)",
                  textAlign: "center",
                  height: "250px",
                  paddingBottom: "10px",
                }}
              >
                <StyledEntityOverlay></StyledEntityOverlay>
                <p
                  style={{
                    color: "#ffb631",
                    fontFamily: "PPNeueMachina",
                    fontSize: "20px",
                    margin: 8,
                  }}
                >
                  LE ANIME
                </p>{" "}
                <p
                  style={{
                    fontSize: "16px",
                    margin: "10px 5px",
                    padding: "0 10px",
                    textAlign: "left",
                  }}
                >
                  The original Le Anime 1573 Open Edition released on Nifty Gateway, March 5th 2021.

                    <br/><br/> OG Video loop + 1 Soul per token.
                </p>
              </div>
              <a
                style={{
                  background: "rgba(255, 255, 255, 0.03)",
                  width: "100%",
                  padding: "10px 0",
                  color: "rgb(255, 182, 49)",
                }}
                target="_blank"
                href="https://www.niftygateway.com/collections/toomuchlagoe"
              >
                link to collection
              </a>{" "}
            </div>
          </Grid>
          {/* <Grid
            item
            xs={
              matchesSmallMobile
                ? 12
                : matchesMobile
                ? 6
                : matchesSmall
                ? 4
                : matchesMedium
                ? 3
                : 3
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              <img style={{ width: "100%" }} src={Souls} />{" "}
              <div
                style={{
                  background: "rgba(255, 255, 255, 0.08)",
                  textAlign: "center",
                  height: "250px",
                  paddingBottom: "10px",
                }}
              >
                <StyledEntityOverlay></StyledEntityOverlay>
                <p
                  style={{
                    color: "#ffb631",
                    fontFamily: "PPNeueMachina",
                    fontSize: "18px",
                    margin: 8,
                  }}
                >
                  Souls
                </p>{" "}
                <p
                  style={{
                    fontSize: "14px",
                    margin: "10px 5px",
                  }}
                >
                  A soul for every token. Using custom contracts, each NFT from
                  Le Anime Open Edition has a respective Soul NFT. A
                  pre-generative randomized trait based character with no two
                  alike in the collection.
                </p>
              </div>
              <div
                style={{
                  background: "rgba(255, 255, 255, 0.03)",
                  width: "100%",
                  padding: "10px 0",
                  color: "rgba(255, 255, 255, 0.03)",
                }}
              >
                --
              </div>{" "}
            </div>
          </Grid> */}
          <Grid
            item
            xs={
              matchesSmallMobile
                ? 12
                : matchesMobile
                ? 6
                : matchesSmall
                ? 4
                : matchesMedium
                ? 3
                : 3
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              <img style={{ width: "100%" }} src={Souls} />{" "}
              <div
                style={{
                  background: "rgba(255, 255, 255, 0.08)",
                  textAlign: "center",
                  height: "250px",
                  paddingBottom: "10px",
                }}
              >
                <StyledEntityOverlay></StyledEntityOverlay>
                <p
                  style={{
                    color: "#ffb631",
                    fontFamily: "PPNeueMachina",
                    fontSize: "20px",
                    margin: 8,
                  }}
                >
                  LE ANIME SOULS
                </p>{" "}
                <p
                  style={{
                    fontSize: "16px",
                    margin: "10px 5px",
                    padding: "0 10px",
                    textAlign: "left",
                  }}
                >
                  Converted Le Anime, a token that binds the NG edition with its respective Soul Character.<br/><br/>

                  {/* This allows custom contract operations, previously limited by Nifty Gateway tokens. */}

                  Le Anime tokens are the main building blocks for Heroes.
                </p>
              </div>
              <a
                style={{
                  background: "rgba(255, 255, 255, 0.03)",
                  width: "100%",
                  padding: "10px 0",
                  color: "rgb(255, 182, 49)",
                }}
                target="_blank"
                href="https://opensea.io/collection/le-anime-by-toomuchlag?search[sortBy]=UNIT_PRICE&search[sortAscending]=true&search[stringTraits][0][name]=Archetype&search[stringTraits][0][values][0]=Le%20Anime"
              >
                link to collection
              </a>{" "}
            </div>
          </Grid>
          <Grid
            item
            xs={
              matchesSmallMobile
                ? 12
                : matchesMobile
                ? 6
                : matchesSmall
                ? 4
                : matchesMedium
                ? 3
                : 3
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              <img style={{ width: "100%" }} src={Spirits} />{" "}
              <div
                style={{
                  background: "rgba(255, 255, 255, 0.08)",
                  textAlign: "center",
                  height: "250px",
                  paddingBottom: "10px",
                }}
              >
                <StyledEntityOverlay></StyledEntityOverlay>
                <p
                  style={{
                    color: "#ffb631",
                    fontFamily: "PPNeueMachina",
                    fontSize: "20px",
                    margin: 8,
                  }}
                >
                  SPIRITS
                </p>{" "}
                <p
                  style={{
                    fontSize: "16px",
                    margin: "10px 5px",
                    padding: "0 10px",
                    textAlign: "left",
                  }}
                >
                  {/* Spirits, lesser souls with inherited traits from Le Anime.
                  These NFTs have the same traits as Le Anime but a lower score
                  per attribute. Spirits are beings without a form, they gather
                  together to create tangible Heroes. */}
                  The building blocks for Heroes. <br/><br/>

                  These NFTs have similar traits as Le Anime but a lower score per attribute.
                </p>
              </div>
              <a
                style={{
                  background: "rgba(255, 255, 255, 0.03)",
                  width: "100%",
                  padding: "10px 0",
                  color: "rgb(255, 182, 49)",
                }}
                target="_blank"
                href="https://opensea.io/collection/le-anime-by-toomuchlag?search[sortBy]=UNIT_PRICE&search[sortAscending]=true&search[stringTraits][0][name]=Archetype&search[stringTraits][0][values][0]=Spirit"
              >
                link to collection
              </a>{" "}
            </div>
          </Grid>
          <Grid
            item
            xs={
              matchesSmallMobile
                ? 12
                : matchesMobile
                ? 6
                : matchesSmall
                ? 4
                : matchesMedium
                ? 3
                : 3
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              <img style={{ width: "100%" }} src={Heroes} />{" "}
              <div
                style={{
                  background: "rgba(255, 255, 255, 0.08)",
                  textAlign: "center",
                  height: "250px",
                  paddingBottom: "10px",
                }}
              >
                <StyledEntityOverlay></StyledEntityOverlay>
                <p
                  style={{
                    color: "#ffb631",
                    fontFamily: "PPNeueMachina",
                    fontSize: "20px",
                    margin: 8,
                  }}
                >
                  HEROES
                </p>{" "}
                <p
                  style={{
                    fontSize: "16px",
                    margin: "10px 5px",
                    padding: "0 10px",
                    textAlign: "left",
                  }}
                >
                  {/* The living flesh beings of our journey. Heroes, created
                  merging Le Anime and Spirits, accumulate the more power the
                  more NFTs are merged. Heroes are customizable, gain experience
                  and evolve with time. */}
                  {/* The living flesh beings of our journey. <br/><br/> */}

                  Heroes, created merging Le Anime and Spirits thourgh the Builder.<br/><br/>

                  Accumulate the more power the more NFTs are merged. <br/><br/>

                  Heroes are customizable, gain experience and evolve with time.<br/><br/>
                </p>
              </div>
              <a
                style={{
                  background: "rgba(255, 255, 255, 0.03)",
                  width: "100%",
                  padding: "10px 0",
                  color: "rgb(255, 182, 49)",
                }}
                target="_blank"
                href="https://opensea.io/collection/le-anime-by-toomuchlag?search[sortBy]=UNIT_PRICE&search[sortAscending]=true&search[stringTraits][0][name]=Archetype&search[stringTraits][0][values][0]=Hero"
              >
                link to collection
              </a>{" "}
            </div>
          </Grid>
          {/* <Grid
            item
            xs={
              matchesSmallMobile
                ? 12
                : matchesMobile
                ? 6
                : matchesSmall
                ? 4
                : matchesMedium
                ? 3
                : 3
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              <img style={{ width: "100%" }} src={Nemeses} />{" "}
              <div
                style={{
                  background: "rgba(255, 255, 255, 0.08)",
                  textAlign: "center",
                  height: "250px",
                  paddingBottom: "10px",
                }}
              >
                <StyledBlueEntityOverlay></StyledBlueEntityOverlay>
                <p
                  style={{
                    color: "rgb(97,156,180)",
                    fontFamily: "PPNeueMachina",
                    fontSize: "18px",
                    margin: 8,
                  }}
                >
                  Underworld Crowns
                </p>{" "}
                <p
                  style={{
                    fontSize: "14px",
                    margin: "10px 5px",
                  }}
                >
                  Past the surface and deep in the murky shadows of the
                  underworld exists a place of darkness devoid of life, where
                  the Forbidden Kings have been biding time and gaining strength
                  undisturbed. Now, the Five Crowns have been awakened...
                </p>
              </div>
              <a
                style={{
                  background: "rgba(255, 255, 255, 0.03)",
                  width: "100%",
                  padding: "10px 0",
                  color: "rgb(97,156,180)",
                }}
                target="_blank"
                href="https://www.niftygateway.com/marketplace/collection/0x38cb271642fbd19a2592a15504420b1a78288a62?page=1"
              >
                link to collection
              </a>{" "}
            </div>
          </Grid>
          <Grid
            item
            xs={
              matchesSmallMobile
                ? 12
                : matchesMobile
                ? 6
                : matchesSmall
                ? 4
                : matchesMedium
                ? 3
                : 3
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              <img style={{ width: "100%" }} src={Diavolo} />{" "}
              <div
                style={{
                  background: "rgba(255, 255, 255, 0.08)",
                  textAlign: "center",
                  height: "250px",
                  paddingBottom: "10px",
                  width: "100%",
                }}
              >
                <StyledEntityOverlay></StyledEntityOverlay>
                <p
                  style={{
                    color: "#ffb631",
                    fontFamily: "PPNeueMachina",
                    fontSize: "18px",
                    margin: 8,
                  }}
                >
                  II Diavolo
                </p>{" "}
                <p
                  style={{
                    fontSize: "14px",
                    margin: "10px 5px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <span>??? </span> <span>??? </span> <span>??? </span>{" "}
                  <span>??? </span>
                </p>
              </div>
              <a
                style={{
                  background: "rgba(255, 255, 255, 0.03)",
                  width: "100%",
                  padding: "10px 0",
                  color: "#D16D3E",
                }}
                target="_blank"
                href="https://www.niftygateway.com/marketplace/collection/0xd8d1f01b836f5717bbc5639e91d78032ebae2571/4"
              >
                link to collection
              </a>{" "}
            </div>
          </Grid> */}
        </Grid>
      </div>
    </Grid>
  );
};
export default Entities;
