export const orderLayers = {}

orderLayers['bg'] = 1;
orderLayers['fullColorBg'] = 2;
orderLayers['frames'] = 3;

orderLayers['halo'] = Array(26).fill(21) //filled with layer 21 because all layers apart from specic ones are there
orderLayers['extra'] = Array(20).fill(0)
orderLayers['skin'] = 22
orderLayers['clA'] = 23
orderLayers['clB'] = 24
orderLayers['runes'] = 31
orderLayers['glowPass'] = 32


orderLayers['halo'][0] = 0 //invisible

orderLayers['extra'][0] = 0 //invisible

orderLayers['halo'][6] = 4 //Magic Tri   
orderLayers['halo'][7] = 5 //Rev Tri
orderLayers['halo'][8] = 5 //Hex

orderLayers['halo'][20] = 6 // Emerald Aurora cross
orderLayers['halo'][5] = 7 // Aur cross

orderLayers['extra'][3] = 8 //wings    
orderLayers['extra'][18] = 9 //gold wings

orderLayers['extra'][7] = 10 //bow   
orderLayers['extra'][13] = 11 //gold bow   

orderLayers['extra'][6] = 12 //lyre   
orderLayers['extra'][14] = 13 //gold lyre  

orderLayers['extra'][5] = 14 //scythe   
orderLayers['extra'][15] = 15 //gold scythe    

orderLayers['extra'][4] = 16 //staff   
orderLayers['extra'][16] = 17 //gold staff

orderLayers['extra'][2] = 18 //sword  
orderLayers['extra'][17] = 19 //gold sword

orderLayers['extra'][11] = 20 //lightsaber

// all the other halos are on of the previous extra so the layer is 21 for all of the rest of the halos
// 22, 23, 24 taken by skin, clA, clB

orderLayers['extra'][19] = 25 // 420 special extra! need to map layer extra 19 to 420 in file! 
orderLayers['extra'][9] = 26 // 69 
orderLayers['extra'][10] = 27 // 777
orderLayers['extra'][8] = 28 // upOnly
orderLayers['extra'][1] = 29 // Book
orderLayers['extra'][12] = 30 // Gold Book

//// END OF ORDER LAYERS RULES