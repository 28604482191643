export const traitsOrder = [
  [
    "TOKENID",
    "Score",
    "Hero Level",
    "Skin",
    "Clothes A",
    "Clothes B",
    "BG",
    "Halo",
    "Runes",
    "RunesLevel",
    "RunesVisualLevel",
  ],
  "Extra",
  "ExtraLVL",
  "ExtraVisualLVL",
];
export const traitsMappingReference = [
  "Skin",
  "Clothes A",
  "Clothes B",
  "Background",
  "Halo",
  "Runes",
  "Extra",
  "Background Color",
];
export const traitsMapping = [
  [
    "Invisible",
    "Midnight",
    "Crescent Pattern",
    "Pure Black",
    "Dark Lines",
    "Marble Lines",
    "Pure White",
    "Golden Ghost",
    "Pure Gold",
    "Golden Stripes",
    "Wide Stripes",
    "Ethereal Ghost",
    "Cobalt Lines",
    "Cobalt Spectre",
    "Starlight",
    "Cobalt Pattern",
    "Amber Spirit",
    "Wider Amber",
    "Enigma",
    "Emerald Lines",
    "Emerald Stripes",
    "Amethyst Ghost",
    "Amethyst Stripes",
    "Ivory Spectre",
    "Ivory  Stripes",
    "Gold Checker",
    "Dante",
    "Crimson",
  ],
  [
    "Invisible",
    "Midnight",
    "Crescent Pattern",
    "Pure Black",
    "Dark Lines",
    "Marble Lines",
    "Pure White",
    "Golden Ghost",
    "Pure Gold",
    "Golden Stripes",
    "Wide Stripes",
    "Ethereal Ghost",
    "Cobalt Lines",
    "Cobalt Spectre",
    "Starlight",
    "Cobalt Pattern",
    "Amber Spirit",
    "Wider Amber",
    "Enigma",
    "Emerald Lines",
    "Emerald Stripes",
    "Amethyst Ghost",
    "Amethyst Stripes",
    "Ivory  Spectre",
    "Ivory  Stripes",
    "Gold Checker",
    "Dante",
    "Crimson",
  ],
  [
    "Invisible",
    "Midnight",
    "Crescent Pattern",
    "Pure Black",
    "Dark Lines",
    "Marble Lines",
    "Pure White",
    "Golden Ghost",
    "Pure Gold",
    "Golden Stripes",
    "Wide Stripes",
    "Ethereal Ghost",
    "Cobalt Lines",
    "Cobalt Spectre",
    "Starlight",
    "Cobalt Pattern",
    "Amber Spirit",
    "Wider Amber",
    "Enigma",
    "Emerald Lines",
    "Emerald Stripes",
    "Amethyst Ghost",
    "Amethyst Stripes",
    "Ivory  Spectre",
    "Ivory  Stripes",
    "Gold Checker",
    "Dante",
    "Crimson",
  ],
  [
    "Invisible",
    "Three Pillars",
    "Pillars Frame",
    "Hell Gate",
    "Stargates",
    "Ruins",
    "Frostbite",
    "Inferno",
    "Gold Tree",
    "Cobalt Tree",
    "Death Field",
    "Obsidian",
    "Multi Rings",
    "Tilt",
  ],
  [
    "Invisible",
    "Thorns Crown",
    "Holy Crown",
    "Rune Circle",
    "Combo",
    "Aurora Cross",
    "Magic Triangle",
    "Reverse Triangle",
    "Hexagram",
    "Grazie Mauro",
    "Aurora Crown",
    "Trinity",
    "Skull Crown",
    "Triple Thorns",
    "Rings",
    "Kami",
    "Square",
    "Amethyst Combo",
    "Amethyst Thorns Crown",
    "Emerald Holy Crown",
    "Emerald Aurora Cross",
    "Amber Rune Circle",
    "Cobalt Thorns Crown",
    "Amber Thorns Crown",
    "Ivory Thorns Crown",
    "Crimson Thorns Crown",
  ],
  [
    "Invisible",
    "Fish",
    "R",
    "I",
    "Mother",
    "Up Only",
    "Burning S",
    "Daemon Face",
    "Up Only Fish",
    "Roman",
    "Hieroglyphs",
    "Andrea",
    "gm",
    "Loom",
    "Path",
    "Abana",
  ],
  [
    "Invisible",
    "Wisdom Manuscript",
    "Greatest Sword",
    "Laurel Wings",
    "Heart Staff",
    "Skull Scythe",
    "Magic Harp",
    "Crystal Bow",
    "Up Only",
    "69",
    "777",
    "Lightsaber",
    "Gold Book",
    "Gold Bow",
    "Gold Lyre",
    "Gold Scythe",
    "Gold Staff",
    "Gold Sword",
    "Gold Wings",
  ],
  [
    "None",
    "Obsidian",
    "Scarlet",
    "Antique Gold",
    "Elven Meadow",
    "Enchanted Ivy",
    "Mystic Mintleaf",
    "Draconic Azure",
    "Leviathan Blue",
    "Astral Sapphire",
    "Nightshade Navy",
    "Lilac Luminary",
    "Imperial Plum",
    "Radiant Orchid",
    "Fiery Carnelian",
    "Shadowed Steel",
    "Silver Serenity",
    "BLeU",
    "Kristy Glas"
  ]
];
