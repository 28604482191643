import React, { useEffect, useState, useCallback, useRef } from "react";

import { Link } from "react-router-dom";

import { ethers } from "ethers";
import { useAccount, useSigner, useProvider, useNetwork } from "wagmi";

import {
  SACRIFICE_ADDRESS,
  SACRIFICE_ABI,
} from "../../config/configSacrifice.js";
import {
    WRAPPER_ABI,
    WRAPPER_ADDRESS,
    SOULSLOCKER_ABI,
    SOULSLOCKER_ADDRESS,
} from "../../config/configMerging.js";
import {
    calculateRedeemableExp,
    spiritsToExp,
    approveSacrifice,
    isSacrificeApprovedCheck,
    getSacrificeCounter,
    sacrificeActive,
} from "../../helpers/sacrificeHelpers.js";

import {
  EXPERIENCE_ABI,
  EXPERIENCE_ADDRESS,
} from "../../config/configExperience";

import {
  MULTICALL2_ABI,
  MULTICALL2_ADDRESS
} from "../../config/configMulticall";


import "./style.css";
import {
    Grid,
    useMediaQuery,
    Input,
    Button,
    Modal,
    TextField,
    CircularProgress,
    Snackbar,
    Stack,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { listAnimeSpiritsHeroesOfOwner_separate } from "../../helpers/mergerHelpers.js";
import { getBatchHeroExpDataAndClaimableExp } from "../../helpers/leaderboardHelpers.js";
import Fade from "@mui/material/Fade";

import labImage from "../../assets/ordinalsDay/Lab_V0.jpg";
// import talanjiVideo from "../../assets/AE_Dark Talanji_LOOP_V2_50mb.mp4";
import exampleHeroes from "../../assets/ordinalsDay/Heroes_V0.png";
import titleOrdinals from "../../assets/ordinalsDay/Title_V0.png";

import buttonLarge from "../../assets/ordinalsDay/Button_L_V0.png"; 

const ExpRaffle = () => {
    //wagmi hooks for account, signer, provider and network
    let { address, isConnected } = useAccount();
    const provider = useProvider();

    const targetTimestamp = 1700502335;
    
    const { data: signer, isError, isLoading } = useSigner();
    const {chain} = useNetwork()

    const matchesExtraLarge = useMediaQuery("(max-width:1600px)");
    const matchesLarge = useMediaQuery("(max-width:1500px)");
    const matchesMedium = useMediaQuery("(max-width:1200px)");
    const matchesSmall = useMediaQuery("(max-width:1024px)");
    const matchesMobile = useMediaQuery("(max-width:600px)");

    const [isChainSupported, setIsChainSupported] = useState(false)

    const [raffleOpen, setRaffleOpen] = useState(true);

    const [allHeroes, setAllHeroes] = useState([]);
    const [allSpirits, setAllSpirits] = useState([]);

    const [expOfHeroes, setExpOfHeroes] = useState({});

    const [heroesInRaffle, setHeroesInRaffle] = useState([]);
    const [userEntries,  setUserEntries] = useState([]);

    const [raffleModalOpen, setRaffleModalOpen] = useState(false);
    const [selectedHero, setSelectedHero] = useState(null);

    const [editionsRemaining, setEditionsRemaining] = useState(0);
    const [editionsToMint, setEditionsToMint] = useState(0);

    const [confirmedSacrifice, setConfirmedSacrifice] = useState(false);
    const [isSacrificeActive, setIsSacrificeActive] = useState(false);

    const [dayLeft, setDayLeft] = useState(0);
    const [hourLeft, setHourLeft] = useState(0);
    const [minuteLeft, setMinuteLeft] = useState(0);

    // CONTRACTS
    const [wrapperContract, setWrapperContract] = useState(null);
    const [experienceContract, setExperienceContract] = useState(null);
    const [multicallContract, setMulticallContract] = useState(null);

    const [isSacrificeApproved, setIsSacrificeApproved] = useState(false);

    const [isApproveLoading, setIsApproveLoading] = useState(false);

    const [isMessageSignLoading, setIsMessageSignLoading] = useState(false);

    const [openCelebrationModal, setOpenCelebrationModal] = useState(false);

    const [notification, setNotification] = useState({});

    const [videoSrc, setVideoSrc] = useState(null);



    // Refresh sacrificeActive and sacrificeCounter every 30 seconds
    function useInterval(callback, delay) {
      const savedCallback = useRef();
    
      // Remember the latest callback.
      useEffect(() => {
        savedCallback.current = callback;
      }, [callback]);
    
      // Set up the interval.
      useEffect(() => {
        function tick() {
          savedCallback.current();
        }
        if (delay !== null) {
          let id = setInterval(tick, delay);
          return () => clearInterval(id);
        }
      }, [delay]);
    }
    
    useInterval(async () => {
      // if (signer && address && provider && isChainSupported) {
      //   const sacrificeActive_ = await sacrificeActive(sacrificeContract);
      //   setIsSacrificeActive(sacrificeActive_);
    
      //   const sacrificeCounter_ = await getSacrificeCounter(
      //     wrapperContract,
      //     SACRIFICE_ADDRESS
      //   );
    
      //   setSacrificeCounter(sacrificeCounter_);
      // }
      
      let timeLeft = (targetTimestamp - Math.floor(Date.now() / 1000));
      if (timeLeft > 0) {
        
        const days = Math.floor(timeLeft / 86400);
        timeLeft -= days * 86400;

        const hours = Math.floor(timeLeft / 3600) % 24;
        timeLeft -= hours * 3600;

        const minutes = Math.floor(timeLeft / 60) % 60;
        timeLeft -= minutes * 60;

        const seconds = timeLeft % 60;
        setDayLeft(days);
        setHourLeft(hours);
        setMinuteLeft(minutes);
      } else {
        setDayLeft(0);
        setHourLeft(0);
        setMinuteLeft(0);
      }
      
    }, 30000); // 60000 milliseconds = 60 seconds

    // USE EFFECTS
    useEffect(() => {
        //STYLE
        document.body.style.background = ` linear-gradient(rgba(0,0,0,0.93),rgba(0,0,0,0.93)), url(${labImage})`;
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundSize = "cover";
        document.body.style.backgroundColor = "#000000";

        let timeLeft = (targetTimestamp - Math.floor(Date.now() / 1000));
        // console.log(timeLeft)
        if (timeLeft > 0) {
          
          const days = Math.floor(timeLeft / 86400);
          timeLeft -= days * 86400;

          const hours = Math.floor(timeLeft / 3600) % 24;
          timeLeft -= hours * 3600;

          const minutes = Math.floor(timeLeft / 60) % 60;
          timeLeft -= minutes * 60;

          const seconds = timeLeft % 60;
          setDayLeft(days);
          setHourLeft(hours);
          setMinuteLeft(minutes);
        } else {
          setDayLeft(0);
          setHourLeft(0);
          setMinuteLeft(0);
        }


        // get data from http://localhost:5000/raffle/entries
        // fetch("http://localhost:5000/raffle/entries")
        //   .then(response => response.json())
        //   .then(data => {
        //     console.log("raffle data", data);
        //     setHeroesInRaffle(data);
        //   }).catch((err) => {
        //     console.log("api error", err);
        //   }
        // );
      
        
        
        return () => {
            document.body.style.background = "#111111";
            // Clear interval on component unmount
            // return () => clearInterval(interval);
        };
    }, []);


    useEffect(() => {
        if (signer && address && provider && isChainSupported) {

            const wrapperContract = new ethers.Contract(
                WRAPPER_ADDRESS,
                WRAPPER_ABI,
                signer
            );
            setWrapperContract(wrapperContract);

            const lockerContract = new ethers.Contract(
                SOULSLOCKER_ADDRESS,
                SOULSLOCKER_ABI,
                signer
            );
            setWrapperContract(wrapperContract);

            const experienceContract = new ethers.Contract(
              EXPERIENCE_ADDRESS,
              EXPERIENCE_ABI,
              provider
            );
            setExperienceContract(experienceContract);
            const multicallContract = new ethers.Contract(
                MULTICALL2_ADDRESS,
                MULTICALL2_ABI,
                provider
              );
            setMulticallContract(multicallContract);
  

            const setAllHeroesAndSpiritsFunction = async () => {
                let allTokens = await listAnimeSpiritsHeroesOfOwner_separate(
                    address,
                    wrapperContract,
                    lockerContract
                );

                let allHeroes = allTokens.heroIDs;

                const sortedallHeroes = allHeroes.sort((a, b) => {
                    return a.id - b.id;
                });

                setAllHeroes(sortedallHeroes);
                // let heroTemp = {
                //     id: "100414",
                //     soulNumber: 414,
                //     traits: "PLACEHOLDER",
                // };
                // let tempHeroes = [
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                //     heroTemp,
                // ];
                // setAllHeroes(tempHeroes);

                let allSouls = allTokens.tokenIDs;

                const sortedAllSouls = allSouls.sort((a, b) => {
                    return a.id - b.id;
                });

                // remove all ids < 1574 - Spirits Only
                const filteredAllSpirits = sortedAllSouls.filter((soul) => {
                    return soul.soulNumber >= 1574;
                });

                // sort filtered spirits by .traits[0][1] ascending
                filteredAllSpirits.sort((a, b) => {
                    return a.traits[0][1] - b.traits[0][1];
                });

                let currentTimestamp = Math.floor(Date.now() / 1000);

                // add experience to each spirit
                filteredAllSpirits.forEach((spirit) => {
                    spirit.exp = calculateRedeemableExp(
                        [spirit.soulNumber],
                        currentTimestamp
                    );
                });

                setAllSpirits(filteredAllSpirits);

                fetch("https://api.leanime.art/raffle/entries")
                .then(response => response.json())
                .then(data => {
                  console.log("raffle entires", data);
                  setHeroesInRaffle(data);

                  // check elements that are both in data and sortedallHeroes.soulNumber
                  let userHeroEntries = [];
                  sortedallHeroes.forEach((hero) => {
                    let found = data.find((obj) => obj === hero.soulNumber);
                    if (found) {
                      userHeroEntries.push(hero.soulNumber);
                    }
                  }
                  );

                  setUserEntries(userHeroEntries);
                  console.log("sorted Heroes", sortedallHeroes)
                  console.log("userHeroEntries", userHeroEntries);


                  let heroSoulNumbers = sortedallHeroes.map((hero) => hero.soulNumber);

                  // get exp of heroes
                  getBatchHeroExpDataAndClaimableExp(
                    multicallContract,
                    experienceContract,
                    heroSoulNumbers
                  ).then((data) => {
                    // map hero id to exp, expClaimable, expAvailable
                    let expOfHeroes = {};
                    data.forEach((hero) => {
                      expOfHeroes[hero.heroId] = {
                        exp: parseInt(hero.exp/1000000),
                        expClaimable: parseInt(hero.expClaimable/1000000),
                        expAvailable: parseInt(hero.expAvailable/1000000),
                      };
                    });
                    // console.log("expOfHeroes", expOfHeroes);
                    setExpOfHeroes(expOfHeroes);
                  }
                  ).catch((err) => {
                    console.log("expOfHeroes error", err);
                    setExpOfHeroes({});
                  }
                  );

                }).catch((err) => {
                  console.log("api error", err);
                }
              );

                
                setIsSacrificeActive(true);

            };

            setAllHeroesAndSpiritsFunction();


        }

        
    }, [signer, address, provider, isChainSupported]);

    useEffect(() => {
      if (typeof chain !== "undefined" && typeof chain.unsupported !== "undefined") {
        setIsChainSupported(!chain.unsupported)
      }
      // console.log("chain changed", chain)
    }, [chain])


    const openTalanji = () => {
        setRaffleOpen(true);
    };
    const handleImageClick = () => {
      // setVideoSrc(talanjiVideo);
    };
    // SACRIFICE MODAL FUNCTIONS
    const handleSetDefaultImage = (e, id) => {
        e.target.src = `https://leanime.art/heroes/m/${id}.jpg`;
    };

    const handleReload = () => {
      window.location.reload();
  };
    const handlesetSelectedHero = (heroID) => {
        setSelectedHero({
            id: heroID,
        });
    };

    const handleSignMessage = async () => {
      setIsMessageSignLoading(true);
      let message = "I am entering the Raffle and committing 1M EXP with my Hero #"+selectedHero.id;
      let signature = await signer.signMessage(message).then((signature) => {
        console.log("message", message)
        console.log("signature", signature);

        let apiJson = {
          "heroId": selectedHero.id,
          "address": address,
          "message": message,
          "signature": signature
        }

        console.log("apiJson", apiJson)

        // send apiJson to api
        fetch("https://api.leanime.art/raffle/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(apiJson),
        })
        .then(response => response.json())
        .then(data => {
          console.log("api data", data);
          if(data.status === "success") {
            setIsMessageSignLoading(false);
            setOpenCelebrationModal(true);
            setNotification({ state: true, message: "Message signed successfully", type: "success" });
          } else {
            setIsMessageSignLoading(false);
            setNotification({ state: true, message: data.message, type: "error" });
          }
          
        }).catch((err) => {
          console.log("api post error", err);
          setIsMessageSignLoading(false);
          setNotification({ state: true, message: err.message, type: "error" });
        });
      }).catch((err) => {
          setIsMessageSignLoading(false);
          setNotification({ state: true, message: err.message, type: "error" });
      })



      
    }


    const handleCloseSacrifice = () => {
        setRaffleModalOpen(false);
        setSelectedHero(null);
        setConfirmedSacrifice(false);
        setOpenCelebrationModal(false);
        handleReload();
    };

    const handleDismissSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setNotification({ status: false });
    };
    return (
        <Grid
            container
            direction="column"
            justifyContent="start"
            alignItems="center"
            style={{
                paddingTop: "100px",
                width: "100%",
                height: "100%",
            }}
        >
            <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                    autoHideDuration={2000}
                    open={notification.state && notification.type === "error"}
                    onClose={handleDismissSnackBar}
                    key={"error"}
                >
                    <MuiAlert
                        onClose={handleDismissSnackBar}
                        variant="filled"
                        severity="error"
                    >
                        {notification.message}
                    </MuiAlert>
                </Snackbar>
                <Snackbar
                    autoHideDuration={2000}
                    open={notification.state && notification.type === "success"}
                    onClose={handleDismissSnackBar}
                    key={"success"}
                >
                    <MuiAlert
                        onClose={handleDismissSnackBar}
                        variant="filled"
                        severity="success"
                    >
                        {notification.message}
                    </MuiAlert>
                </Snackbar>
            </Stack>
            <Modal
                disableScrollLock={true}
                style={{ border: "none" }}
                open={raffleModalOpen}
                onClose={handleCloseSacrifice}
            >
                <Fade in={raffleModalOpen}>
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: matchesMobile ? "100%" : "70%",
                            height: "auto",
                            padding: "40px 0",
                            maxHeight: "80%",
                            background: "#111111",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "center",
                            borderRadius: "10px",
                            outline: "none",
                            // paddingTop: "25px",
                            color: "white",
                            overflowY: "auto",
                            overflowX: "hidden",
                        }}
                    >
                        {!selectedHero && !openCelebrationModal && (
                            <div
                                style={{
                                    width: "90%",
                                    height: "100%",
                                    textAlign: "center",
                                    display: "flex",
                                    // padding: "20px",
                                    flexDirection: "column",
                                    justifyContent: "start",
                                    // alignItems: "center",
                                }}
                            >
                                {allHeroes.length > 0 ? (
                                    <>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            style={{
                                                width: "100%",
                                                marginBottom: "0px",
                                            }}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    fontFamily: "PPNeueMachina",
                                                    fontSize: matchesMobile? "14px" : matchesSmall? "16px" : "18px",
                                                    textAlign: "center",
                                                    color: "#ffb631",
                                                }}
                                            >
                                                STEP 1: SELECT THE HERO
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="start"
                                            style={{
                                                marginTop: "20px",
                                                width: "100%",
                                                height: "90%",
                                            }}
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="start"
                                                alignItems="start"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    overflowY: "auto",
                                                    overflowX: "hidden",
                                                }}
                                                spacing={2}
                                            >
                                                {allHeroes &&
                                                    allHeroes.map((t) => {
                                                        return (
                                                            <Grid
                                                                item
                                                                xs = {matchesMobile ? 6 : 3}
                                                                key={t.soulNumber}
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    flexDirection:
                                                                        "column",
                                                                    alignItems:
                                                                        "center",
                                                                    justifyContent:
                                                                        "center",
                                                                }}
                                                            >
                                                                <img
                                                                    onClick={() => {
                                                                      if (!userEntries.includes(t.soulNumber) && expOfHeroes[t.soulNumber] && (expOfHeroes[t.soulNumber].exp > 1 * 1000000)) {
                                                                        handlesetSelectedHero(
                                                                            t.soulNumber
                                                                        );
                                                                      }
                                                                    }}
                                                                    style={{
                                                                        width: "100%",
                                                                        cursor: (!userEntries.includes(t.soulNumber) && expOfHeroes[t.soulNumber] && (expOfHeroes[t.soulNumber].exp > 1 * 1000000)) ? "pointer" : "default",
                                                                        opacity: (!userEntries.includes(t.soulNumber) && expOfHeroes[t.soulNumber] && (expOfHeroes[t.soulNumber].exp > 1 * 1000000)) ? 1 : 0.5, 
                                                                    }}
                                                                    onError={(
                                                                        e
                                                                    ) => {
                                                                        handleSetDefaultImage(
                                                                            e,
                                                                            t.soulNumber
                                                                        );
                                                                    }}
                                                                    src={`https://api.leanime.art/heroes/images/s/${t.soulNumber}.jpg`}
                                                                />{" "}
                                                                <span
                                                                    style={{
                                                                        marginTop:
                                                                            "15px",
                                                                        color: "white",
                                                                        fontFamily:
                                                                            "PPNeueMachina",
                                                                        fontSize:
                                                                            "13px",
                                                                    }}
                                                                >
                                                                    HERO #
                                                                    {
                                                                        t.soulNumber
                                                                    }
                                                                    {/* if t.soulNumber is in nft entris print a text */}
                                                                    

                                                                </span>
                                                                <span style={{  
                                                                    // color: "#ffb631",
                                                                        fontFamily:
                                                                            "PPNeueMachina",
                                                                        fontSize:
                                                                            "13px",
                                                                  }}>
                                                                    {" "} {expOfHeroes[t.soulNumber] ? expOfHeroes[t.soulNumber].exp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'") : 0} EXP 
                                                                    </span>
                                                                    <span style={{  
                                                                        color: "rgb(255, 65, 54)",
                                                                        fontFamily:
                                                                            "PPNeueMachina",
                                                                        fontSize:
                                                                            "13px",
                                                                  }}>
                                                                    {" "} {expOfHeroes[t.soulNumber] && expOfHeroes[t.soulNumber].exp > 1 * 1000000 ? "" : "MIN 1M EXP REQUIRED"}
                                                                    </span>
                                                                {userEntries.includes(t.soulNumber) && (

                                                                  <span style={{  
                                                                    color: "#ffb631",
                                                                        fontFamily:
                                                                            "PPNeueMachina",
                                                                        fontSize:
                                                                            "13px",
                                                                  }}>
                                                                    {" "} ALREADY ENTERED

                                                                    </span>
                                                                  )}
                                                            </Grid>
                                                        );
                                                    })}
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <span
                                            style={{
                                                fontFamily: "PPNeueMachina",
                                                fontSize: matchesMobile? "16px" : "25px",
                                                color: "white",
                                            }}
                                        >
                                            YOU HAVE NO HEROES
                                        </span>
                                        {/* <span
                                            style={{
                                                fontFamily: "PPNeueMachina",
                                                fontSize: "20px",
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    background:
                                                        "#ffb631",

                                                    width: matchesMobile? "60%" : "30%",
                                                    // paddingLeft: "10px",
                                                    marginTop: "20px",
                                                    borderRadius: 0,
                                                    fontSize: matchesMobile? "16px" : "25px",
                                                    fontFamily: "EurostilDemi",
                                                }}
                                            >
                                                <Link
                                                    style={{
                                                        textDecoration: "none",
                                                        cursor: "pointer",
                                                        color: "black",
                                                    }}
                                                    to="/builder"
                                                >
                                                    CREATE NEW HERO
                                                </Link>
                                            </Button>
                                        </span> */}
                                    </>
                                )}
                            </div>
                        )}

                        {selectedHero && !openCelebrationModal && (
                            <div
                                style={{
                                    width: "90%",
                                    height: "100%",
                                    textAlign: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "start",
                                }}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        marginBottom: "20px",
                                    }}
                                >
                                    {/* <Grid item xs={3}></Grid> */}
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "PPNeueMachina",
                                            fontSize: matchesMobile? "14px" : matchesSmall? "16px" : "18px",
                                            textAlign: "center",
                                            color: "#ffb631",
                                        }}
                                    >
                                        STEP 2: REVIEW AND CONFIRM
                                    </Grid>
                                </Grid>
                                {/* <Grid
                                    container
                                    direction="row"
                                    justifyContent="start"
                                    alignItems="start"
                                    spacing={matchesMobile ? 1 : 2}
                                    style={{
                                        marginTop: "20px",
                                        marginBottom: "20px",
                                        width: "100%",
                                        maxHeight: matchesMobile ? "60%" : "70%",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                    }}
                                >
                                        {selectedSpirits &&
                                            selectedSpirits.map((t, i) => {
                                                return (
                                                    <Grid
                                                        item
                                                        xs = {matchesMobile ? 6 : matchesSmall? 3 : 1.5}
                                                        key={t}
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                width: "100%",
                                                                boxSizing:
                                                                    "border-box",
                                                            }}
                                                            src={`https://leanime.art/heroes/m/${t}.jpg`}
                                                        />{" "}
                                                        <span
                                                            style={{
                                                                marginTop:
                                                                    "5px",
                                                            }}
                                                        >
                                                            #{t}
                                                        </span>
                                                    </Grid>
                                                );
                                            })}
                                </Grid> */}
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        marginTop: "15px",
                                        marginBottom: "15px",
                                    }}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "PPNeueMachina",
                                            fontSize: matchesMobile? "14px" : matchesSmall? "16px" : "20px",
                                            textAlign: "center",
                                            // cursor: "pointer",
                                            color: "#ffb631",
                                        }}
                                    >
                                        ENTERING RAFFLE WITH HERO #
                                        {selectedHero.id}
                                        {/* {editionsToMint > 0 ? `+ ${editionsToMint} EDITION` : "+ 0 EDITIONS"}{editionsToMint > 1 ? "S" : ""} */}
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "PPNeueMachina",
                                            fontSize: matchesMobile? "12px" : "14px",
                                            textAlign: "center",
                                            // cursor: "pointer",
                                            color: "white",
                                        }}
                                    >
                                      SIGN A MESSAGE TO PROVE OWNERSHIP OF THE HERO AND ENTER THE RAFFLE
                                    </Grid>
                                    
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        marginBottom: "5px",
                                    }}
                                >
                                    <Grid item>
                                        <Button
                                            onClick={handleSignMessage}
                                            style={{
                                                background: "#ffb631",
                                                color: "black",
                                                width: "250px",
                                                padding: "10px 0",
                                                marginTop: "10px",
                                                // margin: "10px 0",
                                                borderRadius: 0,
                                                fontSize: "16px",
                                                fontFamily: "EurostilDemi",
                                            }}
                                        >
                                            {isMessageSignLoading ? (
                                                <CircularProgress size={28} />
                                            ) : "ENTER RAFFLE"}
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "PPNeueMachina",
                                            fontSize: "14px",
                                            textAlign: "center",
                                            // cursor: "pointer",
                                            color: "#ff4136",
                                            paddingTop: "20px",
                                            paddingBottom: "0px",
                                        }}
                                    >
                                        WARNING: YOU WILL BE CHARGED 1'000'000 EXP IN CASE OF WINNING
                                    </Grid>
                                </Grid>
                            </div>
                        )}

                        {openCelebrationModal && (
                            <div
                                style={{
                                    width: "90%",
                                    height: "100%",
                                    textAlign: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        marginBottom: "0px",
                                    }}
                                >
                                    {/* <Grid item xs={3}></Grid> */}
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "PPNeueMachina",
                                            fontSize: "22px",
                                            textAlign: "center",
                                            color: "#ffffff",
                                            marginBottom: "20px",
                                        }}
                                      >
                                        CONGRATULATIONS, YOUR RAFFLE ENTRY WAS SUCCESFUL <br/>
                                        </Grid>
                                    
                                    {/* <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontFamily: "EurostilNormal",
                                            fontSize: "15px",
                                            textAlign: "center",
                                            // italic
                                            fontStyle: "italic",
                                            color: "#ffffff",
                                            marginBottom: "20px",
                                        }}
                                    >
                                        Winning entries <br/>
                                    </Grid> */}
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        // marginBottom: "10px",
                                    }}
                                >
                                    
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                        marginBottom: "5px",
                                    }}
                                >
                                    <Grid item>
                                        <Button
                                            onClick={handleCloseSacrifice}
                                            style={{
                                                background: "#ffb631",
                                                color: "black",
                                                width: "280px",
                                                padding: "10px 0",
                                                marginTop: "15px",
                                                // margin: "10px 0",
                                                borderRadius: 0,
                                                fontSize: "20px",
                                                fontFamily: "EurostilDemi",
                                            }}
                                        >
                                            {"CLOSE"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        )}

                        
                    </div>
                </Fade>
            </Modal>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                    width: "100%",
                }}
            >
                
                
                    <>
                        {/* <span
                            style={{
                                fontSize: matchesMobile? "20px" : matchesMedium
                                    ? "25px"
                                    : matchesSmall
                                    ? "20px"
                                    : "32px",
                                fontFamily: "PPNeueMachina",
                                marginBottom: "5px",
                                fontWeight: "900",
                                color: "#ffb631",
                            }}
                        >
                            EXP Raffle
                        </span> */}
                        <div style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "50%",
                              maxWidth: "600px",
                              // paddingTop: "25px",  
                            }}>
                                      <img
                                          style={{
                                              width: "100%",
                                              marginTop: "0px",
                                              marginBottom: "20px",
                                              
                                          }}

                                          src={titleOrdinals}
                                      />
                                    </div>
                        
                        <p
                            style={{
                                fontSize: matchesSmall ? "12px" : "14px",
                                // margin: "12px 0",
                                letterSpacing: 2,
                                // fontWeight: 100,
                                width: matchesMobile ? "80%" : "80%",
                                fontFamily: "EurostilNormal",
                                whiteSpace: "pre-line",
                                textAlign: "center",
                                fontStyle: "italic",
                            }}
                        >
                                Your Hero will go through the space warp. <br/><br/>
                                Inter-dimensional alterations might occur and a new version of yourself will be created. <br/><br/>
                                Bound to this alternate dimension. <br/><br/> 
                                Here's an example of ordinal duplication results: <br/><br/> 

                        </p>
                        {raffleOpen && (
                            <div style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "80%",
                              paddingTop: "10px",
                            }}>

                                <Grid 
                                      item xs={12} sm={12} md={12}
                                      style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                            marginTop: "0px",
                                        }}>
                                    
                                    {/* <div
                                        style={{
                                           marginTop: "20px",
                                           fontSize: matchesMobile ? "16px" : "24px",
                                            fontFamily: "EurostilNormal",
                                            color: "#ff4136",
                                            textAlign: "center",

                                          }}
                                      >
                                       LIMITED EDITION SOLD OUT!
                                    </div> */}
                                    
                                    <div>
                                      <img
                                          style={{
                                              width: "100%",
                                              maxWidth: "500px",
                                              marginTop: "0px",
                                          }}

                                          src={exampleHeroes}
                                      />
                                    </div>
                                    {/* <div
                                        style={{
                                           marginTop: "20px",
                                           fontSize: matchesMobile ? "16px" : "20px",
                                            fontFamily: "EurostilDemi",
                                            color: "#ffb631",
                                            textAlign: "center",

                                          }}
                                      >
                                       Guardians of the Forgotten Vale 
                                    </div> */}
                                    
                                    <p
                                      style={{
                                          fontSize: matchesSmall ? "12px" : "14px",
                                          marginTop: "20px",
                                          letterSpacing: 2,
                                          // fontWeight: 100,
                                          width: "100%",
                                          fontFamily: "EurostilNormal",
                                          whiteSpace: "pre-line",
                                          textAlign: "center",
                                          fontStyle: "italic",
                                      }}
                                  >
                                    Take the leap.
                                        
                                    </p>
                                      
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                
                                    style={{ width: "100%", paddingTop: "30px", marginBottom: "150px"}}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ marginTop: "0px", display: 'flex', justifyContent: 'center' }}
                                    >
                                        <Button
                                            style={{
                                                // background: (address && isConnected && isChainSupported && isSacrificeActive) ? "#ffb631" : "gray",
                                                backgroundImage: `url(${buttonLarge})`,
                                                backgroundSize: matchesMobile ? "300px auto" : "500px auto", // Add this line
                                                backgroundRepeat: 'no-repeat', // Add this line
                                                backgroundPosition: 'center', // Add this line
                                                color: "white",
                                                width: matchesMobile ? "400px" : "500px",
                                                padding: "25px 0",
                                                marginTop: "0px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderRadius: 0,
                                                fontSize: matchesMobile ? "14px" : "20px",
                                                fontFamily: "EurostilDemi",
                                            }}
                                            disabled={true}
                                            onClick={() => {
                                                setRaffleModalOpen(true);
                                            }}
                                        >
                                            <span> {(address && isConnected && isChainSupported) ? "Raffle Closed" : "Raffle Closed"} </span>
                                        </Button>{" "}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ marginTop: "0px" }}
                                    >
                                      <p
                                      style={{
                                          fontSize: matchesSmall ? "12px" : "14px",
                                          marginTop: "0px",
                                          letterSpacing: 2,
                                          // fontWeight: 100,
                                          width: "100%",
                                          fontFamily: "EurostilNormal",
                                          whiteSpace: "pre-line",
                                          textAlign: "center",
                                          fontStyle: "italic",
                                      }}
                                  >
                                    (Enter the raffle to win a Bitcoin Ordinal Hero)
                                        
                                    </p>
                                    
                                      <div
                                        style={{
                                           marginTop: "50px",
                                            fontSize: matchesMobile ? "20px" : "26px",
                                            fontFamily: "EurostilDemi",
                                            color: "#ffffff",
                                            textAlign: "center",
                                            fontStyle: "italic", 
                                          }}
                                      >
                                      
                                        <span> 10 WINNING SPOTS </span>
                                      </div>
                                      {/* <div
                                        style={{
                                           marginTop: "20px",
                                            fontSize: matchesMobile ? "16px" : "20px",
                                            fontFamily: "EurostilDemi",
                                            color: "#ffffff",
                                            textAlign: "center",
                                            fontStyle: "italic", 
                                          }}
                                      >
                                      
                                        TOTAL RAFFLE ENTRIES:  <span style={{color: "#ffb631"}}> {heroesInRaffle.length} </span>
                                      </div> */}
                                      <div
                                        style={{
                                           marginTop: "20px",
                                            fontSize: matchesMobile ? "16px" : "20px",
                                            fontFamily: "EurostilDemi",
                                            color: "#ffffff",
                                            textAlign: "center",
                                            fontStyle: "italic", 
                                          }}
                                      >
                                      
                                        {/* HERO ENTRIES:  <span style={{color: "#ffb631"}}> {heroesInRaffle.join(', ')} </span> */}
                                      </div>

                                      <div
                                        style={{
                                           marginTop: "20px",
                                            fontSize: matchesMobile ? "16px" : "20px",
                                            fontFamily: "EurostilDemi",
                                            color: "#ffffff",
                                            textAlign: "center",
                                            fontStyle: "italic",
                                          }}
                                      >
                                      
                                        MY HEROES ENTRIES:  <span style={{color: "#ffb631"}}> {userEntries.map(entry => '#' + entry).join(', ')} </span>
                                      </div>
                                      <div
                                        style={{
                                          marginTop: "40px",
                                          fontSize: matchesMobile ? "20px" : "26px",
                                          fontFamily: "EurostilDemi",
                                          color: "#ffffff",
                                          textAlign: "center",
                                          fontStyle: "italic",
                                            color: "#ff4136",
                                        }}
                                    >
                                        Raffle closes Monday, 8th April 2024, 23:59 UTC
                                    </div>

                                    <div
                                        style={{
                                           marginTop: "20px",
                                            fontSize: matchesMobile ? "16px" : "16px",
                                            fontFamily: "EurostilDemi",
                                            color: "#ffffff",
                                            textAlign: "center",
                                            // fontStyle: "italic",
                                          }}
                                      >
                                        The 10 winners will receive a unique custom crafted Ordinal Hero, based on their current active traits. <br/>
                                        <br/>
                                        Winners will have to provide a valid ordinal wallet to receive their Ordinals (info provided later)  <br/>
                                        <br/>
                                        The original Heroes will remain untouched, Ordinal Heroes are a separate collection on BTC. <br/>

                                      


                                      
                                      </div>
                                      {/* <div
                                        style={{
                                           marginTop: "20px",
                                           fontSize: matchesMobile ? "16px" : "30px",
                                            fontFamily: "EurostilDemi",
                                            color: "#ffffff",
                                            textAlign: "center",
                                          }}
                                      >
                                      
                                       LIMITED EDITIONS REMAINING: <span style={{color: "#ffb631"}}>  {editionsRemaining}</span>
                                      </div> */}
                                    </Grid>
                                </Grid>
                            </div>
                        )}

                    </>
               
            </Grid>
        </Grid>
    );
};

export default ExpRaffle;
