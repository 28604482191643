import { orderLayers } from "./orderLayers";

export const fillAdditionalLayers = (path, preLayers, heroL, addLayer) => {

    if(addLayer[0] == 1) {
        if(addLayer[1] == 1) {
            // [1,1] is HALLOWEN SPECIAL SKIN
            preLayers[orderLayers['skin']] = {
                input: path + 'addLayers_lq/1/1/skin/' + heroL + '_skin_al_1_' + 1 + '.png'
            }

            preLayers[orderLayers['clA']] = {
                input: path + 'addLayers_lq/1/1/clA/' + heroL + '_clA_al_1_' + 1 + '.png'
            }

            preLayers[orderLayers['clB']] = {
                input: path + 'addLayers_lq/1/1/clB/' + heroL + '_clB_al_1_' + 1 + '.png'
            }

        }
    }
    else if(addLayer[0] == 2) {
        if(addLayer[1] == 1) {
            // [2,1] is FACELESS SPECIAL MASK
            if(heroL == 'H10' || heroL == 'H12' || heroL == 'H13' || heroL == 'H14' || heroL == 'H15') { 
              preLayers[33] = {
                  input: path + 'addLayers_lq/2/1/' + heroL + '_skin_al_2_1.png'
              }
            }
            else {
              preLayers[32] = {
                  input: path + 'addLayers_lq/2/1/' + heroL + '_skin_al_2_1.png'
              }
            }
        }
        else if(addLayer[1] == 2) {
          // [2,2] is XSULLO SPECIAL
          preLayers[32] = {
              input: path + 'addLayers_lq/2/2/' + heroL + '_xsullo_al_2_2.png'
          }
      }
    }
    else if(addLayer[0] == 4) {
        if(addLayer[1] == 1) { // layer order is the same one as top haloes: 20 (crown)
            // [4,1] is KING CROWN
            // console.log("Render Crown")

            // add the crown to the correct layer and remove haloes
            // the crown removes the halo layer in position 21 and keeps the other haloes (like triangle or aurora cross)
            // using orderLayers['halo'][1] that is 21 (in case this changes in the future)
            preLayers[orderLayers['halo'][1]] = 0

            if(heroL == 'H13') {
              preLayers[36] = {
                  input: path + 'addLayers_lq/4/1/' + heroL + '_crown_al_4_1.png'
              }
            } 
            else {
              preLayers[34] = {
                  input: path + 'addLayers_lq/4/1/' + heroL + '_crown_al_4_1.png'
              }
            }
        }
    }
    else if(addLayer[0] == 5) { 
        if(addLayer[1] == 1) { // layer order is the first one available: 33
            // [5,1] is SNAKES
            // console.log("Render Snakes")
            if(heroL == 'H6' || heroL == 'H7' || heroL == 'H12') {
              preLayers[36] = {
                  input: path + 'addLayers_lq/5/1/' + heroL + '_snakes_al_5_1.png'
              }
            }
            else if(heroL == 'H13') {
              preLayers[34] = {
                  input: path + 'addLayers_lq/5/1/' + heroL + '_snakes_al_5_1.png'
              }
            }
            else {
              preLayers[35] = {
                  input: path + 'addLayers_lq/5/1/' + heroL + '_snakes_al_5_1.png'
              }
            }
        }
    }
    else if(addLayer[0] == 3) { // move this one down the preLayers to avoid overwriting
        if(addLayer[1] == 1) { // layer order is the first one available: 34
            // [3,1] is GOLDEN CHAINS
            // console.log("Render Chains")
            if(heroL == 'H6' || heroL == 'H7' || heroL == 'H12') {
              preLayers[35] = {
                  input: path + 'addLayers_lq/3/1/' + heroL + '_chains_al_3_1.png'
              }
            }
            else if(heroL == 'H13') {
              preLayers[35] = {
                  input: path + 'addLayers_lq/3/1/' + heroL + '_chains_al_3_1.png'
              }
            }
            else {
                preLayers[36] = {
                  input: path + 'addLayers_lq/3/1/' + heroL + '_chains_al_3_1.png'
              }
            }
        }
    }
    else if(addLayer[0] == 6) {
      if(addLayer[1] == 1) {
          // [2,1] is Rarible Spectral Crystal BG
          preLayers[orderLayers['fullColorBg']] = {
              input: path + 'addLayers_lq/6/1/bg_al_6_1.png'
          }
      }
    }
    else if(addLayer[0] == 7) {
      if(addLayer[1] == 1) {
          // [7,1] is Frame for Le Anime
          preLayers[orderLayers['frames']] = {
              input: path + 'addLayers_lq/7/1/frames_al_7_1.png'
          }
      }
    }
    else if(addLayer[0] == 8) {
      if(addLayer[1] == 1) {
          // [8,1]..[8,4] are Frames for Le Anime
          preLayers[orderLayers['frames']] = {
              input: path + 'addLayers_lq/8/1/frames_al_8_1.png'
          }
      }
      else if(addLayer[1] == 2) {
          preLayers[orderLayers['frames']] = {
              input: path + 'addLayers_lq/8/2/frames_al_8_2.png'
          }
      }
      else if(addLayer[1] == 3) {
          preLayers[orderLayers['frames']] = {
              input: path + 'addLayers_lq/8/3/frames_al_8_3.png'
          }
      }
      else if(addLayer[1] == 4) {
          preLayers[orderLayers['frames']] = {
              input: path + 'addLayers_lq/8/4/frames_al_8_4.png'
          }
      }
    }
}