import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { experimentalStyled as styled } from "@mui/material/styles";

const StyledAccordionSummaryOverlay = styled("div")({
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  height: "4px",
  background:
    "linear-gradient(to right, rgba(255, 182, 49,1), rgba(219, 57, 57, 1))",
});

const FAQ = () => {
  const matches = useMediaQuery("(max-width:600px)");
  const faqs = [
    {
      title: "What is Le Anime?",
      description: (
        <p>
          toomuchlag's art & collectible project, you can find Le Anime via the
          secondary marker on{" "}
          <a
            target="_blank"
            style={{ color: "white" }}
            href="https://niftygateway.com/marketplace?collection=0x41113bce4659cb4912ed61d48154839f75131d7a&niftyType=1"
          >
            Nifty Gateway{" "}
          </a>
          and{" "}
          <a
            style={{ color: "white" }}
            target="_blank"
            href="https://opensea.io/collection/toomuchlag"
          >
            OpenSea
          </a>
        </p>
      ),
    },
    {
      title: "What is Le Anime Phase 2?",
      description:
        "The new chapter in the story of Le Anime, a fully custom coded platform with interactive NFT mechanics. Where Le Anime and Spirits NFTs can be merged into HEROES NFTs",
    },
    {
      title: "How does the auction work?",
      description:
        "Phase 2 auction will run over 3 days, each day is a separate auction with 7315, 6666, and 4444 Spirit NFTs auctioned. Losing bids from previous days will roll over to the consecutive day, until the end of the auction. Rolled over bids qualify at the previous day minimum price.",
    },
    {
      title: "How does bidding work?",
      description: (
        <>
          <p>
            You bid how many Spirits NFTs you want (quantity) and how much
            you're willing to pay for each Spirit (unit price). The TOTAL is
            your bid. After bidding you enter a ranking position based on your
            UNIT PRICE. You will be able to tell if you're winning an NFT or not
            via both the leaderboard and the bidding panel{" "}
          </p>
        </>
      ),
    },
    {
      title: "How to participate in the auction?",
      description:
        "Connect your MetaMask wallet to this page, a bidding input panel will appear, where you can place your desired bid at any time during the auctions opening windows. You will be asked to place a bid based on how many units you want to purchase and how much you are committing per unit. The panel will show you the total of your bid and this is the amount you will commit by accepting the transaction.",
    },
    {
      title: "How do I update my bid if I've been outranked?",
      description:
        "Using the input panel above (INCREASE YOUR BID). You can increase your ranking by: 1) increasing your Bid Per Unit or 2) increasing your Bid Per Unit and reducing the number of units asked, as long as the Total Bid is the same or higher than before. You may not at any time reduce or withdraw your TOTAL bid amount, until the end of the 3-days auctions.",
    },
    {
      title: "Can I withdraw or decrease my bid?",
      description:
        "No, bids are final. You will not, at any time, be able to reduce or withdraw your commited Eth. It is possible to reduce the number of Units requested, and increase the Bid per Unit, BUT the total bid amount cannot be lowered. Only in case of a losing bid after the 3 days auctions, you will be able to claim back the committed amount.",
    },
    {
      title: "Is there a minimum bid?",
      description:
        "Yes, to avoid spam bidding on the smart contract the minimum bid is 0.04 Eth the first day, 0.06 the second day and 0.08 eth the third day.",
    },
    {
      title: "Is the minting fair?",
      description:
        "Yes, traits and rarities of Spirits NFTs will be assigned randomly to the auction winners via provable randomness.",
    },
    {
      title: "Wen Heros?",
      description:
        "Wrap of Le Anime and claim of Spirits will take place in the days after the auction. Merge of Spirits and Le Anime into HEROS will follow after. Join our Discord for the latest updates.",
    },
    {
      title: "Does ranking higher give me extra benefits?",
      description: (
        <>
          <p>
            Yes. Ranking in the top 100 overall, will reward extra Spirit NFTs
            in this fashion:
          </p>{" "}
          <p>- 1 - 150 Extra NFTs</p>
          <p>- 2- 75 Extra NFTs </p>
          <p>- 3 - 50 Extra NFTs</p>
          <p>- 4 - 40 Extra NFTs </p>
          <p>- 5 - 30 Extra NFTs </p>
          <p>- 6 - 20 Extra NFTs</p>
          <p>- 7 - 10 Extra NFTs </p>
          <p>- 8/10 - 5 Extra NFTs </p>
          <p>- 11/16 - 3 Extra NFTs </p>
          <p>- 17/24 - 2 Extra NFTs </p>
          <p>- 25/100 - 1 Extra NFT</p>
        </>
      ),
    },
    {
      title: "Is the ranking top #100 over the 3 days?",
      description:
        "Yes the top 100 ranking is overall, meaning the 3 days of auction.",
    },
    {
      title: "I don’t see the connect wallet button?",
      description:
        "Make sure you are already logged in MetaMask, refresh the browser and enlarge window. If none of these works restart your browser and hit ctrl+shift+r to empty cache on website. Then log into MetaMask and try again.",
    },
    {
      title: "What happens to the not sold NFTs??",
      description:
        "They don’t get minted and total supply cap at the end of the auctions will be reflecting only the minted NFTs.",
    },
  ];
  const moreFaqs = [
    {
      title: "What are Le Anime Souls?",
      description: (
        <p>
          Le Anime's ghost characters from toomuchlag's drop on NG. Every Le
          Anime edition, has one unique character attached (the Soul) with
          different rarity traits that will come into play in phase 2. For
          extensive FAQs about Le Anime please join our{" "}
          <a
            style={{ color: "white" }}
            target="_blank"
            href="https://discord.com/invite/HuNTadkCdx"
          >
            Discord
          </a>
          ,
        </p>
      ),
    },
    {
      title: "What are Spirit NFTs?",
      description:
        "A new NFT release at the beginning of Phase 2, via the Auction in this website. All Spirits share the same visual but each spirit has different TRAITS ON-CHAIN. This means spirits can have very rare and totally unique combination of traits.",
    },
    {
      title: "Do Spirit have the same traits as Le Anime?",
      description:
        "The traits system is the same: Backgrounds, Skin, ClothingA, ClothingB, Halo, Extra, Rune. Le Anime will receive a multiplier.",
    },
    {
      title: "How long do I have each day to bid on the auction?",
      description: (
        <>
          <p>- First day you have 18 hours </p>
          <p>- Second day you have 12 hours </p>
          <p>- Third day you have 6 hours </p>
        </>
      ),
    },
    {
      title: "Is the bidding in ETH or $USD?",
      description:
        "ETH, via your wallet. You will need an ETH wallet (Metamask)",
    },
    {
      title: "The auction",
      description: (
        <>
          <p>- NFTs for sale: 18425 Units ("Spirits") </p>
          <p>
            - 3 Days, 3 English-style ranked auctions (modified for multiple
            items bid), limited hours for bidding{" "}
          </p>
          <p>- Day 1: 18 hours - 7315 Units </p>
          <p>- Day 2: 12 hours - 6666 Units </p>
          <p>- Day 3: 6 hours - 4444 Units </p>
          <p>
            - Spirit NFTs traits will be distributed to winners on a RANDOM
            basis, a user cannot bid on a specific NFT, with specific traits.{" "}
          </p>
        </>
      ),
    },
    {
      title: "Explain bidding again?",
      description: (
        <>
          <p>- Connect ethereum wallet to the auction page </p>
          <p>
            - Choose price per unit and number of units you want to purchase
          </p>
          <p>
            - Total Bid Amount will be equal to (price per unit) x (number of
            units){" "}
          </p>
          <p>
            - Place the bid by sending the Total Bid Amount via a ethereum
            wallet transaction.{" "}
          </p>
          <p>- Max units per bid: 1000 </p>
          <p>
            - When the bid is placed, your ETH will be locked into the auction
            contract until the conclusion of the 3rd and final auction in the
            3rd day. Bids will roll over to the next days auctions.
          </p>
        </>
      ),
    },
    {
      title: "Explain updating my bid again?",
      description: (
        <>
          <p>
            - You are able to update your bid at any time while the auctions are
            open.{" "}
          </p>
          <p>
            - "Increase your bid" can be used for:
            <li>you are losing and want to get back into a winning position</li>
            <li>you are winning and want to improve position</li>
          </p>
          <p>- The Total Bid Amount cannot be lowered, at any time.</p>
          <p>- Unit Price cannot be lowered, at any time.</p>
          <p>
            - Different ways to update your bid:
            <li>
              Increase your Bid Per Unit and keep the same number of Units. This
              will increase your Total Bid Amount, and will require you to send
              additional Eth to the auction contract, to match the difference
              ("Additional Eth Required")
            </li>
            <li>
              Increase your Bid Per Unit and reduce the number of Units, to keep
              the Total Bid Amount the same as before, to improve your position,
              without the need to send additional Eth. (Bid Transaction still
              needed)
            </li>
            <li>
              Increase both Unit Price and number of Units. Will require you to
              send additional Eth
            </li>
            <li>
              If you increase ONLY the number of Units, this will NOT improve
              your ranking, and actually lower it, since later bids with same
              Unit Price rank lower. Check the predict rank section to see what
              your rank will be.
            </li>
            <li>
              When increasing Unit Price, you can only increase by at least
              Minimum Increase Step (0.01 eth).
            </li>
          </p>
        </>
      ),
    },
    {
      title: "End of Auction?",
      description: (
        <>
          <p>
            - Each day, the auction will end at a random time within the hour
            announced. This is to reduce the possibility of last second bot
            sniping.{" "}
          </p>
          <p>
            - At the end of the auction, the winners will be picked and
            classified according to: highest Unit Price, and in case of same
            price, order of bidding (earliest bid wins. update bid counts as a
            new bid)
          </p>
          <p>
            - The distribution of the NFTs will happen at the end of the 3 days
            auction + buffer time to process.
          </p>
        </>
      ),
    },
    {
      title: "What is the total supply of this sale?",
      description: "18425 Spirit NFTs",
    },
    {
      title: "What if I lose on all 3 days?",
      description:
        "After the end of the 3 days auctions you will be able to claim back your bid amount.",
    },
    {
      title: "Want to know more in detail how the auction is structured?",
      description:
        "Please make sure you read and understand the terms of sale, and approve them prior to bidding (Also linked at the bottom of this page)",
    },
    {
      title: "I need further help!",
      description: (
        <p>
          Please reach out to the discord community and{" "}
          <a
            style={{ color: "white" }}
            target="_blank"
            href="https://discord.com/invite/HuNTadkCdx"
          >
            toomuchlag Discord
          </a>
          ,
        </p>
      ),
    },
  ];
  return (
    <div style={{ width: !matches ? "70%" : "90%" }}>
      <div
        style={{
          fontSize: "32px",
          fontFamily: "PPNeueMachina",
          fontWeight: "900",
          marginBottom: "2%",
        }}
      >
        FAQ
      </div>
      {faqs.map((faq, i) => {
        return (
          <Accordion
            style={{
              background:
                i % 2 === 0
                  ? "rgba(255, 255, 255, 0.03)"
                  : "rgba(255, 255, 255, 0.08)",
              color: "white",
              fontSize: !matches ? "20px" : "15px",
              lineHeight: "24px",
              fontFamily: "EurostilDemi",
              letterSpacing: "-0.01em",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              style={{
                height: "60px",
                padding: "2%",
              }}
            >
              <StyledAccordionSummaryOverlay></StyledAccordionSummaryOverlay>
              {faq.title}
            </AccordionSummary>
            <AccordionDetails
              style={{
                background: "rgba(255, 255, 255, 0.06)",
                fontSize: "16px",
                fontFamily: "EurostilNormal",
                padding: "1%",
              }}
            >
              {faq.description}
            </AccordionDetails>
          </Accordion>
        );
      })}

      <div
        style={{
          fontSize: "32px",
          fontFamily: "PPNeueMachina",
          fontWeight: "900",
          marginBottom: matches ? "4%" : "2%",
          marginTop: matches ? "4%" : "2%",
        }}
      >
        MORE FAQS
      </div>
      {moreFaqs.map((faq, i) => {
        return (
          <Accordion
            style={{
              background:
                i % 2 === 0
                  ? "rgba(255, 255, 255, 0.03)"
                  : "rgba(255, 255, 255, 0.08)",
              color: "white",
              fontSize: !matches ? "20px" : "15px",
              lineHeight: "24px",
              fontFamily: "EurostilDemi",
              letterSpacing: "-0.01em",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              style={{
                height: "60px",
                padding: "2%",
              }}
            >
              <StyledAccordionSummaryOverlay></StyledAccordionSummaryOverlay>
              {faq.title}
            </AccordionSummary>
            <AccordionDetails
              style={{
                background: "rgba(255, 255, 255, 0.06)",
                fontSize: "16px",
                fontFamily: "EurostilNormal",
                padding: "1%",
              }}
            >
              {faq.description}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default FAQ;
