import React, { useState, useEffect } from "react";
import {
  useMediaQuery,
} from "@mui/material";

import { Grid } from "@mui/material";
const extraRegex = /(Extra)\ \d/;
let heroMaxLevel = 15;
let runesLevels = [1, 10, 10, 10, 10, 10, 10, 1, 1, 10, 10, 1, 1, 1, 1, 1];

//there are 20 extras (including 0 and 420 which is a special case)
let extraLevels = [1, 8, 5, 5, 8, 10, 8, 9, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];


const TraitsLevels = (props) => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  
  const matchesMedium = useMediaQuery("(max-width:1400px)");
  const matchesSmall = useMediaQuery("(max-width:1024px)");
  const matchesMobile = useMediaQuery("(max-width:600px)");
  useEffect(() => {
    const flattenedData = [
      {
        trait_type: "Hero Level",
        value: "Hero Level",
        number: props.data.heroMaxLevel,
        max: props.data.heroMaxLevel,
      },
      ...props.data.extrasAndExtrasLevels.flat(),
      ...props.data.runesAndRunesLevels.flat(),
    ];
    setAllData(flattenedData);
  }, [props.data]);
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ width: "100%" }}
    >
      <span
        style={{
          marginTop: "10px",
          marginBottom: "20px",
          fontSize: matchesMobile ? "16px" : matchesSmall ? "18px" : "25px",
          fontFamily: "EurostilDemi",
        }}
      >
        CURRENT HERO TOTAL TRAITS
      </span>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ width: "70%" }}
      >
        {allData.map((trait) => {
          return (
            <Grid
              item
              xs={matchesMobile ? 12 : matchesSmall ? 4 : 4}
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: matchesMobile ? "20px" : matchesSmall ? "30px" : "50px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  marginBottom: "5px",
                  fontSize: matchesMobile ? "12px" : matchesSmall ? "14px" : "18px",
                  fontFamily: "EurostilDemi",
                }}
              >
                {trait.trait_type === "Hero Level"
                  ? "HERO LEVEL"
                  : trait.value.toUpperCase()}
              </span>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {Array(
                  trait.trait_type === "Hero Level"
                    ? heroMaxLevel
                    : trait.trait_type === "Runes"
                    ? runesLevels[trait.number]
                    : trait.trait_type === "Extra"
                    ? extraLevels[trait.number]
                    : 2
                )
                  .fill(0)
                  .map((cell, i) => {
                    return (
                      <div
                        style={{
                          width: matchesMobile? 5 : matchesSmall ? 5 : 10,
                          height: 20,
                          background: i < trait.max ? "#ffb631" : "grey",
                          transform: "skewX(-20deg)",
                          marginRight: "3px",
                        }}
                      ></div>
                    );
                  })}
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default TraitsLevels;
