import { ethers } from "ethers";
import metadataHeroes from "../assets/heroesData/HeroesMetaData.json";
import { SOULSLOCKER_ADDRESS } from "../config/configMerging.js";
import { TESTING } from "../config/configAuction.js";
const BigNumber = ethers.BigNumber;

////////////////////////////////////////////////////////////////

export const isMergingApproved = async (account, wrapperContract) => {
  let approved = await wrapperContract.isApprovedForAll(
    account,
    SOULSLOCKER_ADDRESS
  );
  return approved;
};

export const approveMerging = async (wrapperContract) => {
  let setApproval = await wrapperContract.setApprovalForAll(
    SOULSLOCKER_ADDRESS,
    true
  );

  return setApproval.wait();
};

export const revokeMerging = async (wrapperContract) => {
  let setApproval = await wrapperContract.setApprovalForAll(
    SOULSLOCKER_ADDRESS,
    false
  );

  return setApproval.wait();
};

export const getTokenURI = async (tokenId, wrapperContract) => {
  let uri = await wrapperContract.tokenURI(tokenId);
  return uri;
};

// returns {tokenIDs, HeroIDs} instead of tokenIDs
export const listAnimeSpiritsHeroesOfOwner_separate = async (
  account,
  wrapperContract,
  lockerContract
) => {
  // console.log(await token.name(), 'tokens owned by', account);

  const sentLogs = await wrapperContract.queryFilter(
    wrapperContract.filters.Transfer(account, null)
  );
  const receivedLogs = await wrapperContract.queryFilter(
    wrapperContract.filters.Transfer(null, account)
  );

  let startBlock = 14711684;
  if (TESTING) {
    startBlock = 1; // TESTNET
  }

  const currentBlock = await wrapperContract.provider.getBlockNumber();

  const heroLogs = await lockerContract.queryFilter(
    lockerContract.filters.DepositedSouls(null, null),
    // startBlock,
    // "latest"
  );


  const logs = sentLogs
    .concat(receivedLogs)
    .sort(
      (a, b) =>
        a.blockNumber - b.blockNumber || a.transactionIndex - b.TransactionIndex
    );
  const owned = new Set();

  for (const log of logs) {
    const { from, to, tokenId } = log.args;

    if (ethers.utils.getAddress(to) == ethers.utils.getAddress(account)) {
      //console.log(to)
      owned.add(tokenId.toString());
    } else if (
      ethers.utils.getAddress(from) == ethers.utils.getAddress(account)
    ) {
      owned.delete(tokenId.toString());
    }
  }

  const allHeroes = new Set();

  for (const log of heroLogs) {
    // console.log(log.args)
    const { heroId, tokenId } = log.args;
    // console.log(heroId, tokenId)
    if(tokenId.length > 0) { // this makes sure that empty deposits are not counted
      allHeroes.add((parseInt(heroId) + 100000).toString());
    }
  }

  //intersection between 2 sets
  const myHeroes = new Set([...owned].filter((x) => allHeroes.has(x)));
  // console.log("myheroes", myHeroes)
  //remove my heroes from owned

  for (const hero of myHeroes) {
    owned.delete(hero);
  }

  let tokenIDs = Array.from(owned).map((id) => {
    let offset = 0;

    if (parseInt(id) > 12800010000) {
      offset = 12800010000;
    } else if (parseInt(id) > 100000) {
      offset = 100000;
    }

    // console.log(id);
    const soulNumber = parseInt(id) - offset;
    return {
      id,
      soulNumber,
      traits: metadataHeroes[soulNumber - 1],
    };
    // return {id,soulNumber}
  });

  let heroIDs = Array.from(myHeroes).map((id) => {
    let offset = 100000;

    // console.log(id);
    const soulNumber = parseInt(id) - offset;
    return {
      id,
      soulNumber,
      traits: "PLACEHOLDER",
    };
    // return {id,soulNumber}
  });

  // tokenIDs = await Promise.all(
  //   tokenIDs.map(async (token) => {
  //     const metadata = await axios.get(token.metadata);
  //     token.traits = metadata.data.attributes;
  //     return token;
  //   })
  // );
  // console.log(tokenIDs);
  return { tokenIDs, heroIDs };
};

export const listAllHeroes = async (lockerContract) => {
  let startBlock = 14711684;
  const heroLogs = await lockerContract.queryFilter(
    lockerContract.filters.DepositedSouls(null, null),
    startBlock,
    "latest"
  );

  const heroes = new Set();

  for (const log of heroLogs) {
    const { heroId } = log.args;
    heroes.add(heroId.toString());
  }

  let heroIDs = Array.from(heroes).map((id) => {
    let offset = 100000;

    // if (parseInt(id) > 12800010000) {
    //   offset = 12800010000;
    // } else if (parseInt(id) > 100000) {
    //   offset = 100000;
    // }

    // console.log(id);
    const tokenId = parseInt(id) + offset;
    return {
      tokenId,
      // traits: metadataHeroes[soulNumber - 1],
    };
    // return {id,soulNumber}
  });

  return heroIDs;
};

// SOULS  DEPOSIT HERE

// input: heroId = 100001 tokenIDs = [100002,100003]
export const depositSouls = async (heroId, tokenIDs, lockerContract) => {
  let newTokenIDs = Array.from(tokenIDs).map((id) => {
    const newId = parseInt(id) + 100000;
    return newId;
  });

  let deposit = await lockerContract.depositSoulsBatch(heroId, newTokenIDs);

  return deposit.wait();
};

//input: heroId = 100001 / returns [100002,100003]
export const getSoulsInHero = async (heroId, lockerContract) => {
  let newHeroId = heroId;

  let souls = await lockerContract.getSoulsInHero(newHeroId);

  let newTokenIDs = Array.from(souls).map((id) => {
    const newId = parseInt(id) + 100000;
    return newId;
  });
  return newTokenIDs;
};

// HERO ENCODING SECTION

let dataHero1 = {
  level: 2,
  skin: 11,
  clA: 11,
  clB: 11,
  bg: 7,
  halo: 8,
  runes: [6, 2],
  extras: [[3, 2]],
  fullColorBg: 0,
};

// input: heroId = 100001 / returns object like dataHero1
// if mint is invalid it returns empty {} -> fallback to default
export const getHeroData = async (heroId, storageContract, mergeContract) => {
  let newHeroId = heroId;

  let data = await storageContract.getData(newHeroId);
  let validMint = await mergeContract.checkHeroValidity(newHeroId);

  if (validMint == 0) {
    return {};
  }

  let params = data.params;
  let fullBg = data.visibleBG;

  // additional layers
  let additional = data.extraLayers;

  // 0x01060b040201060005010101

  let heroLevel = parseInt(params.substr(2, 2), 16) + 1;
  let skin = parseInt(params.substr(4, 2), 16);
  let clA = parseInt(params.substr(6, 2), 16);
  let clB = parseInt(params.substr(8, 2), 16);
  let bg = parseInt(params.substr(10, 2), 16);
  let halo = parseInt(params.substr(12, 2), 16);

  let runes = [
    parseInt(params.substr(14, 2), 16),
    parseInt(params.substr(16, 2), 16) + 1,
  ];
  //loop remaining params into array
  let extras = [];
  for (let n = 18; n < params.length; n += 4) {
    extras.push([
      parseInt(params.substr(n, 2), 16),
      parseInt(params.substr(n + 2, 2), 16) + 1,
    ]);
  }

  let addLayers = [];

  //check length of additional layers
  for (let n = 2; n < additional.length; n += 8) {
    addLayers.push([
      parseInt(additional.substr(n, 4), 16), // layerMain
      parseInt(additional.substr(n + 4, 4), 16), // layerId
    ]);
  }

  let heroData = {
    level: heroLevel,
    skin: skin,
    clA: clA,
    clB: clB,
    bg: bg,
    halo: halo,
    runes: runes,
    extras: extras,
    fullBgColor: fullBg,
    additionalLayers: addLayers,
  };

  // let heroArray = [[heroLevel, skin, clA, clB, bg, halo, runes],extras]

  return heroData;
};

// input: like dataHero1
export const setHeroDataOldVersion = async (
  heroId,
  heroData,
  storageContract
) => {
  let hero = encodedHeroDataOld(heroData);

  let setTraits = await storageContract[
    "setDataOwner(uint256,bytes,uint16,uint8)"
  ](heroId, hero.params, 0, hero.fullColorBg);

  return setTraits.wait();

  //check if mint is valid!!!
};

// input: like dataHero1
export const setHeroData = async (heroId, heroData, storageContract) => {

  // console.log("hero", heroData.extras)

  // if [420,1] is in heroData.extras remove it from heroData.extras
  let index = heroData.extras.findIndex((n) => n[0] == 420 && n[1] == 1);
  if (index > -1) {
    heroData.extras.splice(index, 1);
  }

  // console.log("hero", heroData.extras)
  let hero = encodedHeroDataAdditional(heroData);

  let setTraits = await storageContract[
    "setDataOwner(uint256,bytes[3],uint16,uint8)"
  ](heroId, [hero.params, hero.extraLayers, hero.upper], 0, hero.fullColorBg);
  return setTraits.wait();

  //check if mint is valid!!!
};

//encodes hero data into bytes
const encodedHeroDataOld = (data) => {
  //0x010b0b0b070806010301
  //0x010b0b0b070806010301

  let fullColorBg = data.fullBgColor;

  let params = [
    data.level - 1,
    data.skin,
    data.clA,
    data.clB,
    data.bg,
    data.halo,
    data.runes[0],
    data.runes[1] - 1,
  ];
  for (let n = 0; n < data.extras.length; n++) {
    params.push(data.extras[n][0]);
    params.push(data.extras[n][1] - 1);
  }
  const hexParams =
    "0x" + params.map((n) => n.toString(16).padStart(2, "0")).join("");
  return { params: hexParams, fullColorBg: fullColorBg };
};

//encodes hero data into bytes
const encodedHeroDataAdditional = (data) => {
  //0x010b0b0b070806010301
  //0x010b0b0b070806010301

  let fullColorBg = data.fullBgColor;

  let params = [
    data.level - 1,
    data.skin,
    data.clA,
    data.clB,
    data.bg,
    data.halo,
    data.runes[0],
    data.runes[1] - 1,
  ];
  for (let n = 0; n < data.extras.length; n++) {
    params.push(data.extras[n][0]);
    params.push(data.extras[n][1] - 1);
  }

  const hexUpper = "0x"; //booster - not used
  const hexAddLayers =
    "0x" +
    data.additionalLayers
      .map((n) =>
        n[0]
          .toString(16)
          .padStart(4, "0")
          .concat(n[1].toString(16).padStart(4, "0"))
      )
      .join(""); //additional layers

  const hexParams =
    "0x" + params.map((n) => n.toString(16).padStart(2, "0")).join("");

  return {
    params: hexParams,
    extraLayers: hexAddLayers,
    upper: hexUpper,
    fullColorBg: fullColorBg,
  };
};

// check if merge is active
export const isMergeActive = async (mergeContract) => {
  let active = await mergeContract.mergeActive();
  return active;
};

// MERGE HEROES FUNCTIONS
export const mergeHeroes = async (mainHero, toMergeHero, lockerContract) => {
  let mergeHeroes = await lockerContract.mergeHeroes(mainHero, toMergeHero);
  return mergeHeroes.wait();
}
