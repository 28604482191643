import React from "react";

import { Grid, useMediaQuery } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import all from "../assets/leanimeSpiritsHeroes.png";

import { Link } from "react-router-dom";
import banner from "../assets/BuilderGuide.jpg";
import guide from "../assets/BuilderGuideInterface.jpg";
import locked from "../assets/Locked-NFTS.jpg";
const BuilderGuide = () => {
  const matchesMedium = useMediaQuery("(max-width:1400px)");
  const matchesSmall = useMediaQuery("(max-width:1200px)");
  const matchesMobile = useMediaQuery("(max-width:850px)");
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        paddingTop: "100px",
        marginBottom: "30px",
        width: "100%",
        fontFamily: "EurostilNormal",

        fontWeight: "100",
      }}
    >
      <div
        style={{
          width: matchesSmall ? "90%" : "60%",
          marginBottom: "60px",
        }}
      >
        <img style={{ width: "100%" }} src={banner} />
      </div>
      <div
        style={{
          width: matchesSmall ? "80%" : "50%",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <span
          style={{
            fontSize: matchesSmall ? "25px" : "32px",
            fontFamily: "PPNeueMachina",
            marginBottom: "10px",
            color: "#ffb631",
          }}
        >
          The Hero Builder
        </span>
        <p
          style={{
            fontSize: matchesSmall ? "18px" : "18px",
            // margin: "12px 0",
            letterSpacing: 1,
            fontFamily: "EurostilNormal",
          }}
        >
          Understanding the builder interface will help you have a better
          overview of what your NFT is made of and what options are available to
          you.
        </p>
      </div>
      <div
        style={{
          width: matchesSmall ? "80%" : "50%",
          marginBottom: "20px",
        }}
      >
        <img style={{ width: "100%" }} src={guide} />
      </div>

      <div
        style={{
          fontSize: "18px",
          width: matchesSmall ? "80%" : "50%",
          fontFamily: "EurostilNormal",
          fontWeight: 100,
        }}
      >
        {" "}
        <p>
          <span style={{ color: "rgb(219,57,57)" }}>(1) </span>
          First thing you will have to do is pick your main token which is
          reflected as your <span style={{ color: "#ffb631" }}>
            Hero #ID.
          </span>{" "}
          You can pick your favorite ID from your available tokens.
        </p>
        <p>
          <span style={{ color: "rgb(219,57,57)" }}>(2) </span>
          This is your Hero <span style={{ color: "#ffb631" }}>Score</span>,
          which shows the total amount of “power” your NFT has. The more NFTs
          you merge into the Hero, the higher the total score will be. Higher
          scores are rewarded more passive experience.{" "}
        </p>
        <p>
          <span style={{ color: "rgb(219,57,57)" }}>(3) </span>A preview of your
          current Hero build. This preview is a lower resolution of the final
          2160px x 2160px image but shows you in real time what traits you are
          displaying.
        </p>
        <p>
          <span style={{ color: "rgb(219,57,57)" }}>(4) </span>This is the core
          of the builder, here you{" "}
          <span style={{ color: "#ffb631" }}>select traits</span> you want to
          display for every available slot. The options you have depend on what
          NFTs you have merged, the more you add the more options unlock.
          displaying.
        </p>
        <p>Up to 7 extra traits can be shown if you have enough Score.</p>
        <p>
          Hero Levels unlock with higher Score, Extras and Runes levels unlock
          based on number of traits in possession. Info can be found{" "}
          <span style={{ color: "#ffb631" }}>here</span>.
        </p>
        <p>
          <span style={{ color: "rgb(219,57,57)" }}>(5) </span> Locked souls
          shows you what NFTs you have previously merged to create your current
          Hero. This means these NFTs are currently{" "}
          <span style={{ color: "#ffb631" }}>locked</span> inside the main
          token.
        </p>
        <div
          style={{
            width: "100%",
            marginBottom: "60px",
          }}
        >
          <img style={{ width: "100%" }} src={locked} />
        </div>
        <p>
          <span style={{ color: "rgb(219,57,57)" }}>(6) </span>Add NFTs allows
          you to{" "}
          <span style={{ color: "#ffb631" }}>
            {" "}
            select more Le Anime or Spirits
          </span>{" "}
          from your wallet to fuse these with your current Hero. You can always
          add more NFTs and unlock their traits, and increase your Score. The
          limit for a single Hero is currently 999 + the Hero token for a total
          of 1000 NFTs.
        </p>
        <p>
          <span style={{ color: "rgb(219,57,57)" }}>(7) </span>{" "}
          <span style={{ color: "#ffb631" }}> Collaborations</span> will unlock
          additional layers for your Heroes, as limited edition or events. Here
          is where you will have access to your additional layers unlocked.
        </p>
        <p>
          <span style={{ color: "rgb(219,57,57)" }}>(8) </span> Heroes made of
          2+ NFTs merged accumulate{" "}
          <span style={{ color: "#ffb631" }}> Experience</span> over time
          passively. Experience can be used to redeem limited rewards.
        </p>
        <p>
          <span style={{ color: "rgb(219,57,57)" }}>(9) </span>
          The best way to add more traits immediately is to{" "}
          <span style={{ color: "#ffb631" }}> acquire</span> more units, Spirit
          or Le Anime with traits you do not posses and merge them with your
          Hero to unlock their traits.
        </p>
        <p>
          <span style={{ color: "rgb(219,57,57)" }}>(10) </span>
          All the info regarding{" "}
          <span style={{ color: "#ffb631" }}> levelling</span> up, traits, and
          score can be found <span style={{ color: "#ffb631" }}> here</span>.
        </p>
        <p>
          <span style={{ color: "rgb(219,57,57)" }}>(11) </span>Once you are
          happy with your Hero you can{" "}
          <span style={{ color: "#ffb631" }}> mint</span> it yourself. When this
          process is done for the first time, 3 steps will be required: approve
          Builder smart contract, deposit NFTs into Hero, confirm Hero traits.
          Once this is done, your Hero will appear in your wallet automatically.
        </p>
        <p>
          <span style={{ color: "#ffb631" }}> Updating </span> the Hero is
          relatively cheap and gas efficient, so feel free to change your look
          whenever you want. Updating is the same as minting with only a confirm step. {" "}
        </p>
        <p>
          <span style={{ color: "rgb(219,57,57)" }}>(12) </span> This panel
          shows you the level of{" "}
          <span style={{ color: "#ffb631" }}> progress</span> of your Hero
          overall, not only your displayed traits but every trait that is
          included in the NFTs merged.
        </p>
      </div>
    </Grid>
  );
};
export default BuilderGuide;
