import { ethers } from "ethers";
// import { SACRIFICE_ADDRESS } from "../config/configMerging.js";
import metadataSouls from "../assets/heroesData/HeroesMetaData.json";

///////////
///// EXP SACRIFICE CALCULATION FUNCTIONS
///////////

// number of decimals to display for exp 1'000'000 = 1 EXP
const FORMAT_DIGITS = 6;

const expStart = 1651738261; // May-05-2022 08:11:01
const expEnd = 1735689599; // Dec-31-2024 23:59:59

const calculateBonusExp = (score) => {
    let bonus = 0;
    if (score >= 100000) bonus = 50;
    else if (score >= 50000) bonus = 45;
    else if (score >= 25000) bonus = 32;
    else if (score >= 10000) bonus = 25;
    else if (score >= 5000) bonus = 20;
    else if (score >= 2500) bonus = 16;
    else if (score >= 1000) bonus = 13;
    else if (score >= 500) bonus = 10;
    else if (score >= 250) bonus = 8;
    else if (score >= 100) bonus = 6;
    else if (score >= 50) bonus = 4;
    else if (score >= 25) bonus = 2;
    else bonus = 0;
    // else if(score > 10) bonus = 2;
    return bonus;
};

export const calculateRedeemableExp = (tokenIds, timestamp) => {
    let score = 0;

    for (let i = 0; i < tokenIds.length; i++) {
        let tokenId = tokenIds[i];
        let soulData = metadataSouls[tokenId - 1]; //remember the -1!!
        score += soulData[0][1];
    }

    let currentTimestamp = timestamp > expEnd ? expEnd : timestamp;

    let bonus = calculateBonusExp(score);

    let expMultiplier = score * (100 + bonus);

    // if (expMultiplier > 150000 * 100) {
    //     expMultiplier = 150000 * 100;
    // }

    let deltaT = expEnd - currentTimestamp;
    let deltaT2 = expEnd - expStart;
    let deltaT1 = currentTimestamp - expStart;
    let duration = expEnd - expStart;

    let expToClaim =
        expMultiplier * deltaT -
        (expMultiplier * (deltaT2 * deltaT2 - deltaT1 * deltaT1)) /
            duration /
            4;

    expToClaim = ethers.utils.formatUnits(parseInt(expToClaim), FORMAT_DIGITS);
    expToClaim = expToClaim.split(".")[0];
    expToClaim = expToClaim.replace(/\B(?=(\d{3})+(?!\d))/g, "'");

    return expToClaim;
};

///////////
/// SACRIFICE FUNCTIONS
///////////

export const spiritsToExp = async (sacrificeContract, heroId, tokenIDs) => {
    let sacrificeTx = await sacrificeContract.spiritsToExp(heroId, tokenIDs);
    return sacrificeTx.wait();
};

export const isSacrificeApprovedCheck = async (
    account,
    wrapperContract,
    sacrificeAddress
) => {
    let approved = await wrapperContract.isApprovedForAll(
        account,
        sacrificeAddress
    );
    return approved;
};

export const approveSacrifice = async (wrapperContract, sacrificeAddress) => {
    let setApproval = await wrapperContract.setApprovalForAll(
        sacrificeAddress,
        true
    );
    return setApproval.wait();
};

export const sacrificeActive = async (sacrificeContract) => {
    let approved = await sacrificeContract.sacrificeActive();

    // console.log("sacrificeActive", approved);
    // let approved = true;
    return approved;
};

///////////
/// SPIRITS SACRIFICED COUNTER FUNCTIONS
///////////

export const getSacrificeCounter = async (
    wrapperContract,
    sacrificeAddress
) => {
    let counterSpirits = await wrapperContract.balanceOf(sacrificeAddress);
    return counterSpirits.toString();
};