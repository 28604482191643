import React, { useEffect, useState, useCallback } from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Input,
  InputAdornment,
  Slider,
} from "@mui/material";
// import // Accordion,
// // AccordionItem,
// // AccordionItemHeading,
// // AccordionItemButton,
// // AccordionItemPanel,
// "react-accessible-accordion";

import debounce from "lodash.debounce";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import traitCountFile from "../assets/heroesData/TraitToCount_V5_Heroes.json";
import sum from "lodash.sum";
import clondeep from "lodash.clonedeep";
import SearchIcon from "@mui/icons-material/Search";
import { useMediaQuery } from "@mui/material";
const Filters = (props) => {
  const matchesMedium = useMediaQuery("(max-width:1200px)");
  const matchesSmall = useMediaQuery("(max-width:1024px)");
  const [traits, setTraits] = useState([]);
  const [filters, setFilters] = useState([]);
  const [traitCount, setTraitCount] = useState([{}]);
  const [heroLevel, setHeroLevel] = useState([0, 10]);
  const [heroLevelValues, setHeroLevelValues] = useState([10, 50]);
  const [expanded, setExpanded] = useState(null);
  useEffect(() => {
    let formattedTraitCount = clondeep(traitCountFile);
    const traitsIn = Object.keys(formattedTraitCount);
    setTraits(traitsIn);
    traitsIn.forEach((trait) =>
      Object.keys(formattedTraitCount[trait]).forEach((traitValue) => {
        formattedTraitCount[trait][traitValue] = [
          formattedTraitCount[trait][traitValue],
          false,
        ];
        if (Array.isArray(formattedTraitCount[trait][traitValue][0])) {
          formattedTraitCount[trait][traitValue][0].forEach((tv, i) => {
            formattedTraitCount[trait][traitValue][0][i] = [
              formattedTraitCount[trait][traitValue][0][i],
              false,
            ];
          });
        }
      })
    );

    setTraitCount(formattedTraitCount);
  }, []);

  const sumTraits = (arr) => {
    return arr.reduce((a, b) => {
      return a + b[0];
    }, 0);
  };

  useEffect(() => {
    if (heroLevel[0] === 0 && heroLevel[1] === 10) {
    } else {
      props.handleSetActiveFilters([
        ...filters,
        { "HERO LEVEL": [heroLevel[0], heroLevel[1]] },
      ]);
    }
  }, [heroLevel]);

  useEffect(() => {
    if (Object.keys(props.removedFilter).length > 0) {
      const key = Object.keys(props.removedFilter)[0];
      const value = Object.values(props.removedFilter)[0];
      if (!props.removedFilter["LVL"]) {
        const filtered = filters.filter((item) => item[key] !== value);

        setFilters(filtered);
        traitCount[Object.keys(props.removedFilter)[0]][
          Object.values(props.removedFilter)[0]
        ][1] = false;
      } else {
        const valueLevel = Object.values(props.removedFilter)[1];

        const found = filters.find(
          (item) => item[key] === value && item["LVL"] === valueLevel
        );
        const filtered = filters.filter((item) => item !== found);
        traitCount[Object.keys(props.removedFilter)[0]][
          Object.values(props.removedFilter)[0]
        ][0][props.removedFilter["LVL"] - 1][1] = false;

        const foundOtherSiblings = filtered.find(
          (item) => item[key] === value && item.hasOwnProperty("LVL")
        );

        if (!foundOtherSiblings) {
          setFilters([...filtered, { [key]: value }]);
          props.handleSetActiveFilters([...filtered, { [key]: value }]);
        } else {
          setFilters(filtered);
        }
      }
    }
  }, [props.removedFilter]);

  const filterByTokenId = (e) => {
    props.setSearchByTokenId(e.target.value);
  };
  const debounceSearch = useCallback(debounce(filterByTokenId, 500), []);
  const filterByTrait = (e, trait, traitValue, level = null) => {
    if (e.target.checked) {
      // level filters
      if (level !== null && level !== 0) {
        traitCount[trait][traitValue][0][level - 1][1] = true;

        if (traitCount[trait][traitValue][1]) {
          const foundLevelZeroFilter = filters.find(
            (item) => item[trait] === traitValue && !item.hasOwnProperty("LVL")
          );

          setFilters([
            ...filters.filter((item) => item !== foundLevelZeroFilter),
            { [trait]: traitValue, LVL: level },
          ]);
          props.handleSetActiveFilters([
            ...filters.filter((item) => item !== foundLevelZeroFilter),
            { [trait]: traitValue, LVL: level },
          ]);
        }
      } else {
        setFilters([...filters, { [trait]: traitValue }]);
        props.handleSetActiveFilters([...filters, { [trait]: traitValue }]);
        traitCount[trait][traitValue][1] = true;
        // if (level === 0) {
        //   traitCount[trait][traitValue][0] = traitCount[trait][
        //     traitValue
        //   ][0].map((v) => {
        //     return [v[0], true];
        //   });
        // }
      }
    } else {
      let filteredFilters;
      if (level !== null && level !== 0) {
        const foundMatch = filters.find(
          (item) => item[trait] === traitValue && item.LVL === level
        );
        filteredFilters = filters.filter((item) => {
          return item !== foundMatch;
        });
        traitCount[trait][traitValue][0][level - 1][1] = false;

        // last filter in children to activate the parent
        const foundOtherSiblings = filteredFilters.find(
          (item) => item[trait] === traitValue && item.hasOwnProperty("LVL")
        );
        if (!foundOtherSiblings) {
          filteredFilters = [...filteredFilters, { [trait]: traitValue }];
        }
      } else {
        filteredFilters = filters.filter((item) => item[trait] !== traitValue);

        traitCount[trait][traitValue][1] = false;
        if (level === 0) {
          traitCount[trait][traitValue][0] = traitCount[trait][
            traitValue
          ][0].map((v) => {
            return [v[0], false];
          });
        }
      }
      setFilters(filteredFilters);
      props.handleSetActiveFilters(filteredFilters);
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="start"
      style={{
        width: "100%",
        fontFamily: "PPNeueMachina",
        fontWeight: "900",
      }}
    >
      <span
        style={{
          fontSize: "32px",
          color: "#ffb631 ",
          margin: "1% 0",
        }}
      >
        FILTER
      </span>

      <div
        style={{
          overflowY: "auto",
          width: "100%",
          maxHeight: matchesMedium ? "100%" : "calc(100vh - 11.5rem)",
        }}
      >
        <Input
          type="number"
          disableUnderline
          onChange={debounceSearch}
          placeholder="Search by id"
          style={{
            width: "100%",
            color: "white",
            background: "rgba(255, 255, 255, 0.08)",
            padding: "5px",
            borderBottom: "1px solid #ffb631 ",
            fontFamily: "PPNeueMachina",
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon style={{ color: "white" }} />
            </InputAdornment>
          }
        />
        {/* <Slider
          getAriaLabel={() => "Hero Level"}
          value={heroLevelValues}
          onChange={(e, values) => {
            setHeroLevelValues(values);
          }}
          valueLabelDisplay="auto"
        /> */}
        <div>
          {traits.map((trait, i) => (
            <Accordion
              disableGutters={true}
              key={i + trait + Math.random() * 12312}
              expanded={expanded === i}
              onChange={() => setExpanded(expanded === i ? null : i)}
              style={{
                background:
                  i % 2 === 0
                    ? "rgba(255, 255, 255, 0.03)"
                    : "rgba(255, 255, 255, 0.08)",
                color: "white",
                fontSize: "15px",
                fontFamily: "PPNeueMachina",
                fontWeight: "900",
                width: "100%",
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    style={{
                      color: "white",
                    }}
                  />
                }
                style={{
                  height: "60px",
                  padding: "4%",
                }}
              >
                {trait.toUpperCase()}
              </AccordionSummary>
              <AccordionDetails
                style={{
                  background: "rgba(255, 255, 255, 0.09)",
                  fontSize: matchesMedium ? "10px" : "12px",
                  padding: "1%",
                  maxHeight: "300px",
                  overflowY: "auto",
                  lineHeight: "24px",
                  fontFamily: "EurostilNormal",
                  fontWeight: 100,
                  letterSpacing: "-0.01em",
                }}
              >
                {Object.keys(traitCount[trait]).map((traitValue, j) => {
                  return !Array.isArray(traitCount[trait][traitValue][0]) ? (
                    <Grid
                      container
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <Checkbox
                        onClick={(event) => {
                          filterByTrait(event, trait, traitValue);
                        }}
                        checked={traitCount[trait][traitValue][1]}
                        style={{ color: "#ffb631" }}
                        key={j + traitValue + trait}
                      />
                      <span style={{ marginRight: "2%" }}>
                        {traitValue.toUpperCase()}
                      </span>{" "}
                      <span> ({traitCount[trait][traitValue][0]})</span>
                    </Grid>
                  ) : (
                    <Accordion
                      disableGutters={true}
                      square={true}
                      elevation={0}
                      key={j + traitValue + Math.random() * 12312}
                      sx={{
                        "&:before": {
                          display: "none",
                        },
                      }}
                      style={{
                        color: "white",
                        background: "transparent",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            style={{
                              color: "white",
                            }}
                          />
                        }
                        style={{
                          height: "40px",
                          padding: "2%",
                          padding: 0,
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="start"
                          alignItems="center"
                        >
                          <Checkbox
                            onClick={(event) => {
                              filterByTrait(event, trait, traitValue, 0);
                            }}
                            key={j + traitValue + trait + 10000}
                            checked={traitCount[trait][traitValue][1]}
                            style={{ color: "#ffb631 ", zIndex: 1 }}
                          />
                          <span style={{ marginRight: "2%" }}>
                            {traitValue.toUpperCase()}
                          </span>{" "}
                          <span style={{ marginRight: "2%" }}>
                            ({sumTraits(traitCount[trait][traitValue][0])})
                          </span>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: 0 }}>
                        <Grid
                          container
                          direction="column"
                          justifyContent="start"
                          alignItems="start"
                        >
                          <ul style={{ width: "80%", listStyle: "none" }}>
                            {traitCount[trait][traitValue][0].map((tv, k) => (
                              <li style={{ width: "100%" }}>
                                <Checkbox
                                  onClick={(event) =>
                                    filterByTrait(
                                      event,
                                      trait,
                                      traitValue,
                                      k + 1
                                    )
                                  }
                                  checked={
                                    traitCount[trait][traitValue][0][k][1]
                                  }
                                  disabled={!traitCount[trait][traitValue][1]}
                                  style={{
                                    color: traitCount[trait][traitValue][1]
                                      ? "#ffb631 "
                                      : "grey",
                                  }}
                                />
                                <span style={{ marginRight: "2%" }}>
                                  LVL {k + 1}
                                </span>{" "}
                                <span> ({tv})</span>
                              </li>
                            ))}
                          </ul>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </Grid>
  );
};

export default React.memo(Filters);
