import { TESTING } from "./configAuction.js";

export const EXPERIENCE_ABI = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "wrapper_",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "locker_",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "pointerScores_",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "heroId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "expTotal",
				"type": "uint256"
			}
		],
		"name": "ExpClaimed",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "heroId",
				"type": "uint256"
			}
		],
		"name": "ExpSlashed",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "heroId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "expTotal",
				"type": "uint256"
			}
		],
		"name": "ExpSpent",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "minterAddress",
				"type": "address"
			}
		],
		"name": "addMinter",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spenderAddress",
				"type": "address"
			}
		],
		"name": "addSpender",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "heroId",
				"type": "uint256"
			}
		],
		"name": "afterDeposit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "mainHeroId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "mergedHeroId",
				"type": "uint256"
			}
		],
		"name": "afterMergeHeroes",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "heroId",
				"type": "uint256"
			}
		],
		"name": "afterWithdrawAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "heroId",
				"type": "uint256"
			}
		],
		"name": "afterWithdrawBatch",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "score",
				"type": "uint256"
			}
		],
		"name": "calculateBonusExp",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "bonus",
				"type": "uint256"
			}
		],
		"stateMutability": "pure",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "heroId",
				"type": "uint256"
			}
		],
		"name": "calculateClaimableExp",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "claimableExp",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "heroId",
				"type": "uint256"
			}
		],
		"name": "claimExp",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "heroId",
				"type": "uint256"
			},
			{
				"internalType": "uint64",
				"name": "expToCredit",
				"type": "uint64"
			},
			{
				"internalType": "uint256",
				"name": "minterIdx",
				"type": "uint256"
			}
		],
		"name": "creditExperience",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "expEnd",
		"outputs": [
			{
				"internalType": "uint64",
				"name": "",
				"type": "uint64"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "expExtension",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "expInitializeLock",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "expInitialized",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "expMinters",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "expSpenders",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "expStart",
		"outputs": [
			{
				"internalType": "uint64",
				"name": "",
				"type": "uint64"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "heroId",
				"type": "uint256"
			}
		],
		"name": "getHeroExpData",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint32",
						"name": "score",
						"type": "uint32"
					},
					{
						"internalType": "uint64",
						"name": "lastTimestamp",
						"type": "uint64"
					},
					{
						"internalType": "uint64",
						"name": "expAvailable",
						"type": "uint64"
					}
				],
				"internalType": "struct HeroExpData",
				"name": "",
				"type": "tuple"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "heroId",
				"type": "uint256"
			}
		],
		"name": "getHeroScoreEXP",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "totScore",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "heroExpData",
		"outputs": [
			{
				"internalType": "uint32",
				"name": "score",
				"type": "uint32"
			},
			{
				"internalType": "uint64",
				"name": "lastTimestamp",
				"type": "uint64"
			},
			{
				"internalType": "uint64",
				"name": "expAvailable",
				"type": "uint64"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "heroLock",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "heroWithdrawalsUnlocked",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "locker",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pointerScores",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "heroId",
				"type": "uint256"
			},
			{
				"components": [
					{
						"internalType": "uint32",
						"name": "score",
						"type": "uint32"
					},
					{
						"internalType": "uint64",
						"name": "lastTimestamp",
						"type": "uint64"
					},
					{
						"internalType": "uint64",
						"name": "expAvailable",
						"type": "uint64"
					}
				],
				"internalType": "struct HeroExpData",
				"name": "newHeroData",
				"type": "tuple"
			}
		],
		"name": "setHeroExpData",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "heroId",
				"type": "uint256[]"
			},
			{
				"internalType": "uint32[]",
				"name": "score",
				"type": "uint32[]"
			},
			{
				"internalType": "uint64[]",
				"name": "experience",
				"type": "uint64[]"
			},
			{
				"internalType": "uint64",
				"name": "lastTimestamp",
				"type": "uint64"
			}
		],
		"name": "setInitialHeroScoreBatch",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "state",
				"type": "bool"
			}
		],
		"name": "unlockAllWithdrawals",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "heroId",
				"type": "uint256"
			},
			{
				"internalType": "bool",
				"name": "state",
				"type": "bool"
			}
		],
		"name": "unlockHeroWithdrawals",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "expExtension_",
				"type": "address"
			}
		],
		"name": "updateExpExtension",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "heroLock_",
				"type": "address"
			}
		],
		"name": "updateHeroLock",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "minterAddress",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "minterIdx",
				"type": "uint256"
			}
		],
		"name": "updateMinter",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spenderAddress",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "spenderIdx",
				"type": "uint256"
			}
		],
		"name": "updateSpender",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "heroId",
				"type": "uint256"
			},
			{
				"internalType": "uint64",
				"name": "expUsed",
				"type": "uint64"
			},
			{
				"internalType": "uint256",
				"name": "spenderIdx",
				"type": "uint256"
			}
		],
		"name": "useExperience",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawalsUnlocked",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wrapper",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];
export let EXPERIENCE_ADDRESS = "0x55124b7C32Ab50932725ec6e34bDB53725e2bbd2"; // MAINNET

export const ADDLAYERS_ABI = [
    {
        inputs: [
            {
                internalType: "address",
                name: "locker_",
                type: "address",
            },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "heroId",
                type: "uint256",
            },
            {
                components: [
                    {
                        internalType: "uint128",
                        name: "layer",
                        type: "uint128",
                    },
                    {
                        internalType: "uint128",
                        name: "id",
                        type: "uint128",
                    },
                ],
                indexed: false,
                internalType: "struct AddLayer",
                name: "newLayer",
                type: "tuple",
            },
        ],
        name: "NewAddLayer",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "from",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "to",
                type: "uint256",
            },
        ],
        name: "TransferAllLayers",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "from",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "to",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "index",
                type: "uint256",
            },
        ],
        name: "TransferLayer",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "bool",
                name: "flag",
                type: "bool",
            },
        ],
        name: "activateTransfers",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "minterAddress",
                type: "address",
            },
        ],
        name: "addMinter",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "heroId",
                type: "uint256",
            },
        ],
        name: "getHeroLayers",
        outputs: [
            {
                components: [
                    {
                        internalType: "uint128",
                        name: "layer",
                        type: "uint128",
                    },
                    {
                        internalType: "uint128",
                        name: "id",
                        type: "uint128",
                    },
                ],
                internalType: "struct AddLayer[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "heroToAddLayers",
        outputs: [
            {
                internalType: "uint128",
                name: "layer",
                type: "uint128",
            },
            {
                internalType: "uint128",
                name: "id",
                type: "uint128",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "heroId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "layer",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "layerId",
                type: "uint256",
            },
        ],
        name: "isLayerInHero",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "heroId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "layer",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "layerId",
                type: "uint256",
            },
        ],
        name: "isLayerInHeroOrSouls",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "heroId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "layer",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "layerId",
                type: "uint256",
            },
        ],
        name: "isLayerInSouls",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "locker",
        outputs: [
            {
                internalType: "contract ISoulsLocker",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "heroId",
                type: "uint256",
            },
            {
                components: [
                    {
                        internalType: "uint128",
                        name: "layer",
                        type: "uint128",
                    },
                    {
                        internalType: "uint128",
                        name: "id",
                        type: "uint128",
                    },
                ],
                internalType: "struct AddLayer",
                name: "newLayer",
                type: "tuple",
            },
            {
                internalType: "uint256",
                name: "minterIdx",
                type: "uint256",
            },
        ],
        name: "mintAddLayer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256[]",
                name: "heroId",
                type: "uint256[]",
            },
            {
                components: [
                    {
                        internalType: "uint128",
                        name: "layer",
                        type: "uint128",
                    },
                    {
                        internalType: "uint128",
                        name: "id",
                        type: "uint128",
                    },
                ],
                internalType: "struct AddLayer",
                name: "newLayer",
                type: "tuple",
            },
            {
                internalType: "uint256",
                name: "minterIdx",
                type: "uint256",
            },
        ],
        name: "mintAddLayerBatch",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "minters",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "heroFromId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "heroToId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "minterIdx",
                type: "uint256",
            },
        ],
        name: "transferAllLayers",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "heroFromId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "heroToId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "index",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "minterIdx",
                type: "uint256",
            },
        ],
        name: "transferOneLayer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "transfersActive",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "minterAddress",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "minterIdx",
                type: "uint256",
            },
        ],
        name: "updateMinter",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
export let ADDLAYERS_ADDRESS = "0xe93d07a731fedf4f676aaa057bd534832d3012f0"; // MAINNET

export const EMPORIUM_ABI = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "wrapperAddress_",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "additionalLayers_",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "experience_",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "AL_MINTER_ID",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "EXP_SPENDER_ID",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "addLayersInventory",
		"outputs": [
			{
				"internalType": "uint64",
				"name": "layer",
				"type": "uint64"
			},
			{
				"internalType": "uint64",
				"name": "id",
				"type": "uint64"
			},
			{
				"internalType": "uint64",
				"name": "expCost",
				"type": "uint64"
			},
			{
				"internalType": "uint64",
				"name": "qtyAvailable",
				"type": "uint64"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint64",
				"name": "layer",
				"type": "uint64"
			},
			{
				"internalType": "uint64",
				"name": "id",
				"type": "uint64"
			},
			{
				"internalType": "uint64",
				"name": "expCost",
				"type": "uint64"
			},
			{
				"internalType": "uint64",
				"name": "maxQuantity",
				"type": "uint64"
			}
		],
		"name": "addNewItem",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint64[4][]",
				"name": "newItems",
				"type": "uint64[4][]"
			}
		],
		"name": "addNewItemBatch",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "additionalLayers",
		"outputs": [
			{
				"internalType": "contract IAdditionalLayers",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "delegateCash",
		"outputs": [
			{
				"internalType": "contract IDelegationRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "experience",
		"outputs": [
			{
				"internalType": "contract ExperienceManager",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getInventory",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint64",
						"name": "layer",
						"type": "uint64"
					},
					{
						"internalType": "uint64",
						"name": "id",
						"type": "uint64"
					},
					{
						"internalType": "uint64",
						"name": "expCost",
						"type": "uint64"
					},
					{
						"internalType": "uint64",
						"name": "qtyAvailable",
						"type": "uint64"
					}
				],
				"internalType": "struct Emporium.AddLayerItem[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "heroId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "itemIdx",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "vault",
				"type": "address"
			}
		],
		"name": "getNewItem",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "itemIdx",
				"type": "uint256"
			},
			{
				"internalType": "uint64",
				"name": "layer",
				"type": "uint64"
			},
			{
				"internalType": "uint64",
				"name": "id",
				"type": "uint64"
			},
			{
				"internalType": "uint64",
				"name": "expCost",
				"type": "uint64"
			},
			{
				"internalType": "uint64",
				"name": "maxQuantity",
				"type": "uint64"
			}
		],
		"name": "modifyItem",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wrapper",
		"outputs": [
			{
				"internalType": "contract IWrapper",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];
export let EMPORIUM_ADDRESS = "0x89604d98c5Ceb8e96acdDE125E9cEc8321F80DBC"; // MAINNET

if (TESTING) {
    EXPERIENCE_ADDRESS = "0x800d89c3231535c4bdcb76d0575fd53ba51ef04e"; // TESTNET
    ADDLAYERS_ADDRESS = "0xB7996CC6532f3Faa63e7CEA16Ee6DcD97D1EF6fD"; // TESTNET
    EMPORIUM_ADDRESS = "0xdc0c344ca8f2bb9961b6b840979a22218ab682e3"; // TESTNET
}
