// "use strict";

//// START OF ORDER LAYERS RULES
import { orderLayers } from "./orderLayers";
import { mergeP } from "./mergeParameters";
import {
  traitsOrder,
  traitsMapping,
} from "../../assets/heroesData/TraitEncodingHeroes";
import metadataSouls from "../../assets/heroesData/HeroesMetaData.json";

/*///////////////////////////////////////////////////////////////
                        "STATE VARIABLES"
//////////////////////////////////////////////////////////////*/

// USING

// hero max levels
let currentHeroMaxLevel = 15;

//extra slots per hero level (TO IMPLEMENT)
let heroExtraSlots = 1;

//there are 16 runes (including 0 invisible)
let runesLevels = [1, 10, 10, 10, 10, 10, 10, 1, 1, 10, 10, 1, 1, 1, 1, 1];

//there are 20 extras (including 0 and 420 which is a special case)
let extraLevels = [1, 8, 5, 5, 8, 10, 8, 9, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

var aSkin = traitsMapping[0];

var aClA = traitsMapping[1];

var aClB = traitsMapping[2];

var aBg = traitsMapping[3];

var aHalo = traitsMapping[4];

var aRunes = traitsMapping[5];

//new ones already
var aExtra = traitsMapping[6];

// process the selected souls from the soulsSelected list and update the dropdown list of traits according to the rules
export const processSouls = (soulsList) => {
  let traits = {};
  traits["score"] = 0;
  traits["skin"] = [];
  traits["clA"] = [];
  traits["clB"] = [];
  traits["bg"] = [];
  traits["halo"] = [];
  traits["runes"] = [];
  traits["extra"] = [];
  traits["multiplier"] = [];

  // let mult = mergeP.animeMultiplier

  //looping through all the selected tokens
  for (let idx = 0; idx < soulsList.length; idx++) {
    let selSoul = soulsList[idx];
    let mult = 1;
    if (selSoul <= 1573) {
      mult = 20;
    }

    let soulData = metadataSouls[selSoul - 1]; //remember the -1!!
    // let soulStringData = metadataOnchainToString(soulData)

    traits["score"] += soulData[0][1];

    traits["skin"].push(soulData[0][3]);
    traits["clA"].push(soulData[0][4]);
    traits["clB"].push(soulData[0][5]);
    traits["bg"].push(soulData[0][6]);
    traits["halo"].push(soulData[0][7]);
    traits["runes"].push(soulData[0][8][0]);
    traits["extra"].push(soulData[1][0][0]);
    traits["multiplier"].push(mult);

    // console.log('SoulData', soulData)
    // console.log(soulStringData)
  }

  // counts how many times all the runes and extra appear in your tokens - use the multiplier 20 for le anime an 1 for spirits
  const runesCounts = {};
  traits["runes"].forEach((x, index) => {
    runesCounts[x] = (runesCounts[x] || 0) + traits["multiplier"][index];
  });

  const extraCounts = {};
  traits["extra"].forEach((x, index) => {
    extraCounts[x] = (extraCounts[x] || 0) + traits["multiplier"][index];
  });

  // after counting remove duplicates from the set
  traits["skin"] = [...new Set(traits["skin"])];
  traits["clA"] = [...new Set(traits["clA"])];
  traits["clB"] = [...new Set(traits["clB"])];
  traits["bg"] = [...new Set(traits["bg"])];
  traits["halo"] = [...new Set(traits["halo"])];
  traits["runes"] = [...new Set(traits["runes"])];
  traits["extra"] = [...new Set(traits["extra"])];

  // console.log(mergeP)

  // //runes

  traits["runes"].forEach((val) => {
    //section to set the max level allowed for each trait
    let currCount = runesCounts[val];
    let currThresholds = mergeP.traitsLevelsCut[6][val];
    let runeMaxLevel;
    // console.log(val)
    if (currThresholds.length == 1) {
      runeMaxLevel = 1;
    } else if (currCount >= currThresholds[currThresholds.length - 1]) {
      runeMaxLevel = currThresholds.length;
    } else {
      const isLargeNumber = (element) => element > currCount;
      runeMaxLevel = currThresholds.findIndex(isLargeNumber);
    }
    //.AT(-1) not supported by all browsers
    runesLevels[val] = runeMaxLevel;
  });
  // heroLevels - needed before extras to figure out number og extra slots

  let totScore = traits["score"];
  let heroThresholds = mergeP.rankThresholds;
  let heroMaxLevel;

  if (totScore >= heroThresholds[heroThresholds.length - 1]) {
    heroMaxLevel = heroThresholds.length;
  } else {
    const isLargeNumber = (element) => element > totScore;
    heroMaxLevel = heroThresholds.findIndex(isLargeNumber);
  }

  currentHeroMaxLevel = heroMaxLevel;
  heroExtraSlots = 1 + mergeP.additionalExtrasForRank[currentHeroMaxLevel - 1]; // 1 extra slot always there + additiona extras given by hero level!

  // //extra

  traits["extra"].forEach((val) => {
    // var opt = document.createElement("option");
    // opt.value = val;
    // opt.innerHTML = aExtra[val];
    // newSelect.appendChild(opt);

    //section to set the max level allowed for each trait
    let currCount = extraCounts[val];
    let currThresholds = mergeP.traitsLevelsCut[7][val];
    // console.log('AAAAAA', currThresholds, currCount, val)
    let extraMaxLevel;

    if (currThresholds.length == 1) {
      extraMaxLevel = 1;
    } else if (currCount >= currThresholds[currThresholds.length - 1]) {
      extraMaxLevel = currThresholds.length;
    } else {
      const isLargeNumber = (element) => element > currCount;
      extraMaxLevel = currThresholds.findIndex(isLargeNumber);
    }

    extraLevels[val] = extraMaxLevel;
  });

  const runesAndRunesLevels = Object.keys(runesCounts).map((key) => {
    return {
      trait_type: "Runes",
      value: traitsMapping[5][key],
      number: parseInt(key),
      max: runesLevels[key],
    };
  });
  const extrasAndExtrasLevels = Object.keys(extraCounts).map((key) => {
    return {
      trait_type: "Extra",
      value: traitsMapping[6][key],
      number: parseInt(key),
      max: extraLevels[key],
    };
  });

  const extrasWithMaxLevels = traits.extra.map((extra) => {
    return [extra, extraLevels[extra]];
  });
  traits["extra"] = extrasWithMaxLevels;
  const runesWithMaxLevels = traits.runes.map((rune) => {
    return [rune, runesLevels[rune]];
  });
  traits["extra"] = extrasWithMaxLevels;
  // traits["extra"] = [[0, 0]].concat(traits["extra"]);
  traits["runes"] = runesWithMaxLevels;
  return {
    allTraits: traits,
    heroMaxLevel,
    runesAndRunesLevels,
    extrasAndExtrasLevels,
    heroExtraSlots: heroExtraSlots,
  };
};
