import React, { useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Grid, useMediaQuery, Modal } from "@mui/material";

import Fade from "@mui/material/Fade";
const StyledBanner = styled("div")`
  border-radius: 0;
  background: linear-gradient(
      119.13deg,
      rgba(150, 104, 34, 0.8) 22.09%,
      rgba(219, 57, 57, 0.25) 67.89%
    ),
    #181818;
  padding: 5px;
  width: 100%;
  font-size: 15px;
`;
const StyledTrait = styled("div")`
  border-radius: 1px;
  border: 1px solid white;
  padding: 2px;
  height: 60px;
`;
const Traits = (props) => {
  const matchesLarge = useMediaQuery("(max-width:1800px)");
  const matchesMedium = useMediaQuery("(max-width:1440px)");
  const matchesSmall = useMediaQuery("(max-width:1024px)");
  const matchesMobile = useMediaQuery("(max-width:600px)");
  const [openModal, setOpenModal] = useState(false);

  const [modalData, setModalData] = useState({});  
  const handleOpen = (id) => {
    setModalData({
      id,
    });
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
//   console.log(props.spirits);
  return (
    <Grid
      container
      direction="row"
      spacing={2}
      justifyContent="start"
      alignItems="center"
      style={{ width: "100%" }}
    >
      <Modal
        disableScrollLock={true}
        style={{ border: "none" }}
        open={openModal}
        onClose={handleClose}
      >
        <Fade in={openModal}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: matchesMedium ? "auto" : "70%",
              background: "#111111",
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
              outline: "none",
            }}
          >
            <img
              src={`https://leanime.art/heroes/m/${modalData.id}.jpg`}
              style={{
                height: "100%",
                maxWidth: "100%",
              }}
            />
          </div>
        </Fade>
      </Modal>
      {props.spirits.map((spirit, i) => {
        return (
          <Grid 
            item xs={matchesMobile ? 6 : matchesMedium ? 3 : 2.4} 
            key={spirit.id}
            
            >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img
                onClick={() => handleOpen(spirit.id - 100000)}
                style={{
                  width: "100%",
                  borderRadius: "1%",
                  marginBottom: "2%",
                  cursor: "pointer",
                }}
                id={spirit.id}
                src={`https://leanime.art/heroes/m/${spirit.id - 100000}.jpg`}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontFamily: "EurostilDemi",
                    width: "90%",
                    padding: "5px",
                    background: i % 2 === 0 ? " #2a2a2a" : "#181818",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  <a
                    href={`https://opensea.io/assets/ethereum/0x03bebcf3d62c1e7465f8a095bfa08a79ca2892a1/${spirit.id}`}
                    target="_blank"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    #{spirit.soulNumber}
                  </a>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "90%",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "start",
                    padding: "5px",
                    background: i % 2 === 0 ? "#181818" : " #2a2a2a",
                  }}
                >
                  {spirit.traits.map((trait, j) => {
                    const percent = (trait.trait_count / 10627) * 100;
                    return (
                      <div
                        key={trait.trait_type}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: matchesMobile
                            ? "100%"
                            : matchesSmall
                            ? "100%"
                            : matchesMedium
                            ? "40%"
                            : "43%",
                          justifyContent: "start",
                          alignItems: "start",
                          padding: "2px",
                          marginBottom: "3%",
                        }}
                      >
                        <span
                          style={{
                            fontSize: matchesSmall
                              ? "10px"
                              : matchesMedium
                              ? "7px"
                              : "9px",
                            fontFamily: "EurostilMedium",
                            fontWeight: 100,
                            color: "rgba(255,255,255,0.6)",
                          }}
                        > 
                          {trait.trait_type === "Spirit" && spirit.soulNumber <= 1573 ? 
                          "LE ANIME" : trait.trait_type === "Spirit" && spirit.soulNumber > 1573 ?
                          "SPIRIT" : trait.trait_type.toUpperCase()
                 
                          }

                        </span>
                        <span
                          style={{
                            fontSize: "10px",
                            fontFamily: "PPNeueMachina",
                            textAlign: "left",
                            lineHeight: "200%",
                            fontWeight: 900,
                            width: matchesSmall
                              ? "120px"
                              : matchesMedium
                              ? "70px"
                              : matchesLarge
                              ? "90px"
                              : "100px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {Number(trait.value)
                            ? trait.value
                            : trait.value.toUpperCase()}
                        </span>
                        <span
                          style={{
                            letterSpacing: "-0.02em",
                            lineHeight: "150%",
                            fontWeight: "900",
                            fontFamily: "PPNeueMachina",
                            fontSize: "15px",
                            color:
                              trait.trait_type === "Spirit"
                                ? "white"
                                : percent >= 50
                                ? "#ffffff"
                                : percent >= 25
                                ? "#fff0da"
                                : percent >= 10
                                ? "#ffe0b5"
                                : percent >= 5
                                ? "#ffd191"
                                : percent >= 1
                                ? "#ffc26c"
                                : percent >= 0.1
                                ? "#ffb444"
                                : percent > 0
                                ? "#ffa500"
                                : "ffffff",
                          }}
                        >
                          {trait.trait_type === "Spirit"
                            ? trait.trait_count
                            : percent > 1
                            ? `${percent.toFixed(0)}%`
                            : `${percent.toFixed(3)}%`}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Traits;
