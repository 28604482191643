import React from "react";
import { useMediaQuery } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { ConnectButton } from '@rainbow-me/rainbowkit';

const RightDiv = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  height: "100%",
  width: "200px",
  fontSize: "14px",
});

const LeftDiv = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  height: "100%",
  width: "200px",
  fontSize: "14px",
});

const Wallet = (props) => {

  if(props.mobile) {
    return (  
      <LeftDiv>
          <ConnectButton showBalance={false}/>
      </LeftDiv>
    );
  }
  else {
    return (  
      <RightDiv>
          <ConnectButton showBalance={false}/>
      </RightDiv>
    );
  }
};

export default Wallet;
