import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";

import axios from "axios";
import { experimentalStyled as styled } from "@mui/material/styles";
const StyledTag = styled("div")`
  border-radius: 32px;
  padding: 4px;
  color: #111111;
  background: #ffb631;
  min-width: 70px;
  min-height: 24px;
  text-align: center;
  margin-left: 10px;
  font-family: PPNeueMachina;
  font-weight: 900;
  font-size: 11px;
`;
export default function Leaderboard(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalWinningBids, setTotalWinningBids] = useState(null);
  const [rows, setRows] = useState([]);
  const [floorBid, setFloorBid] = useState({});
  const [userBid, setUserBid] = useState({});
  const [ensRows, setEnsRows] = useState([]);
  const [ensUserName, setEnsUserName] = useState([]);
  const [ensFloorName, setEnsFloorName] = useState([]);
  const [supply, setSupply] = useState({});
  const [previousSupply, setPreviousSupply] = useState(0);
  const fetchAndStoreLeaderBoard = async (page, limit) => {
    const params = { user: props.user, page, limit };
    if (Object.keys(userBid).length > 0) {
      props.setUserBid(userBid);
    }
    axios
      .get("https://api.leanime.art/leaderboard", { params })
      .then((response) => {
        setTotalWinningBids(response.data.leaderboard.winningBids.total);
        setFloorBid(response.data.leaderboard.floorBid);
        setUserBid(response.data.leaderboard.userBid);
        props.setUserBid(response.data.leaderboard.userBid);
        setRows(response.data.leaderboard.winningBids.data);
      });
  };
  const fetchSupplyGone = async () => {
    const params = { auctionId: props.day - 1 };

    axios
      .get("https://api.leanime.art/leaderboard/supplyBidded", { params })
      .then((response) => {
        setSupply(response.data.supplyResult);
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchAndStoreLeaderBoard(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newLimit = +event.target.value;

    setRowsPerPage(newLimit);
    setPage(0);
    fetchAndStoreLeaderBoard(1, newLimit);
  };

  useEffect(() => {
    let ensRows = [];
    let ensUserBid = [];
    let ensFloorBid = [];
    setEnsRows(ensRows);
    if (props.ensContract) {
      const ensNames = async () => {
        const data = rows;

        const addresses = data.map((d) => d.bidder);
        ensRows = await props.getEnsNames(addresses, props.ensContract);

        setEnsRows(ensRows);
        let userBidData;
        if (userBid?.bidder) {
          userBidData = [userBid.bidder];
          ensUserBid = await props.getEnsNames(userBidData, props.ensContract);
          setEnsUserName(ensUserBid);
        }

        let floorBidData;
        if (floorBid?.bidder) {
          floorBidData = [floorBid.bidder];
          ensFloorBid = await props.getEnsNames(
            floorBidData,
            props.ensContract
          );
          setEnsFloorName(ensFloorBid);
        }
      };
      ensNames();
    }
  }, [rows, props.ensContract, userBid, floorBid]);

  useEffect(() => {
    if (props.user) {
      fetchAndStoreLeaderBoard(page + 1, rowsPerPage);
      fetchSupplyGone();
      const getPreviousAuctionSupply = async () => {
        const auction1SupplyGone = await axios.get(
          "https://api.leanime.art/leaderboard/supplyBidded",
          { params: { auctionId: 0 } }
        );
        const auction2SupplyGone = await axios.get(
          "https://api.leanime.art/leaderboard/supplyBidded",
          { params: { auctionId: 1 } }
        );
        setPreviousSupply(
          auction1SupplyGone.data.supplyResult.supplyGone +
            auction2SupplyGone.data.supplyResult.supplyGone
        );
      };
      getPreviousAuctionSupply();
    }
  }, [props.user]);
  useEffect(() => {
    let interval;
    if (props.user) {
      interval = setInterval(() => {
        fetchAndStoreLeaderBoard(page + 1, rowsPerPage);
        fetchSupplyGone();
      }, 10000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [props.user, page, rowsPerPage]);
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {rows && rows.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              fontFamily: "PPNeueMachina",
              fontWeight: "900",
            }}
          >
            <span
              style={{
                fontSize: "32px",

                marginBottom: "1%",
              }}
            >
              DAY{" "}
              <span style={{ color: "rgba(255, 182, 49,1)" }}>
                {props.day}
              </span>{" "}
              LEADERBOARD
            </span>
            <span style={{ fontSize: "18px" }}>
              {" "}
              <span>SUPPLY FILLED</span>{" "}
              <span style={{ color: "rgba(255, 182, 49,1)" }}>
                {supply.supplyGone + 8373}
              </span>
            </span>
          </div>
        )}
      </div>
      {rows && rows.length > 0 && (
        <>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "90%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalWinningBids}
                rowsPerPage={rowsPerPage}
                page={page}
                style={{ color: "white" }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TableContainer style={{ width: "90%", overflow: "hidden" }}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      th: {
                        color: "rgba(255, 182, 49,1)",
                        border: "1px solid rgba(17, 17, 17, 1)",
                        background: "rgba(255, 255, 255, 0.08)",
                        fontSize: "15px",
                        fontFamily: "PPNeueMachina",
                        fontWeight: 900,
                      },
                    }}
                  >
                    <TableCell>RANK</TableCell>
                    <TableCell>BIDDER</TableCell>
                    <TableCell align="center">QUANTITY</TableCell>
                    <TableCell align="center">UNIT PRICE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userBid && Object.keys(userBid).length > 0 && (
                    <TableRow
                      sx={{
                        "td, th": {
                          color: "white",
                          border: "1px solid rgba(17, 17, 17, 1)",
                          fontSize: "14px",
                          lineHeight: "24px",
                          fontFamily: "EurostilDemi",
                          letterSpacing: "-0.01em",
                          padding: "1%",
                          background: "rgba(255, 255, 255, 0.03)",
                        },
                      }}
                    >
                      <TableCell
                        style={{
                          borderTop: "5px solid  #ffb631 ",
                          borderBottom: "5px solid  #ffb631 ",
                          borderLeft: "5px solid  #ffb631 ",
                        }}
                        component="th"
                        scope="row"
                      >
                        # {userBid.rank}
                      </TableCell>
                      <TableCell
                        style={{
                          borderTop: "5px solid  #ffb631 ",
                          borderBottom: "5px solid  #ffb631 ",
                        }}
                        component="th"
                        scope="row"
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {ensUserName.length > 0
                            ? ensUserName
                            : userBid.bidder}{" "}
                          <StyledTag>YOU</StyledTag>{" "}
                          {userBid.winningAllocation > 0 ? (
                            <StyledTag>
                              WINNING {userBid.winningAllocation}
                              {userBid.winningAllocation === 1
                                ? " UNIT"
                                : " UNITS"}
                            </StyledTag>
                          ) : (
                            <StyledTag
                              style={{
                                background: "rgb(211,47,47)",
                                color: "white",
                              }}
                            >
                              LOSING
                            </StyledTag>
                          )}
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          borderTop: "5px solid  #ffb631 ",
                          borderBottom: "5px solid  #ffb631 ",
                        }}
                        align="center"
                      >
                        {userBid.quantity}
                      </TableCell>
                      <TableCell
                        style={{
                          borderTop: "5px solid  #ffb631 ",
                          borderBottom: "5px solid  #ffb631 ",
                          borderRight: "5px solid  #ffb631 ",
                        }}
                        align="center"
                      >
                        {userBid.unitPrice}
                      </TableCell>
                    </TableRow>
                  )}
                  {rows.map((row, i) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "td, th": {
                          color: "white",
                          border: "1px solid rgba(17, 17, 17, 1)",
                          fontSize: "14px",
                          lineHeight: "24px",
                          fontFamily: "EurostilDemi",
                          letterSpacing: "-0.01em",
                          padding: "1%",
                          background:
                            Object.keys(userBid).length > 0
                              ? i % 2 !== 0
                                ? "rgba(255, 255, 255, 0.03)"
                                : "rgba(255, 255, 255, 0.08)"
                              : i % 2 !== 0
                              ? "rgba(255, 255, 255, 0.08)"
                              : "rgba(255, 255, 255, 0.03)",
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        # {row.rank}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {ensRows.length > 0
                            ? ensRows[i]
                              ? ensRows[i]
                              : row.bidder
                            : row.bidder}{" "}
                          <StyledTag>
                            {" "}
                            WINNING {row.quantity}
                            {row.quantity === 1 ? " UNIT" : " UNITS"}
                          </StyledTag>{" "}
                          {userBid && userBid.bidder === row.bidder && (
                            <StyledTag>YOU</StyledTag>
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="center">{row.quantity}</TableCell>
                      <TableCell align="center">{row.unitPrice}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {floorBid && Object.keys(floorBid).length > 0 && (
                  <TableRow
                    sx={{
                      "td, th": {
                        color: "white",
                        border: "1px solid rgba(17, 17, 17, 1)",
                        fontSize: "14px",
                        lineHeight: "24px",
                        fontFamily: "EurostilDemi",
                        letterSpacing: "-0.01em",
                        padding: "1%",
                        background: "rgba(255, 255, 255, 0.03)",
                      },
                    }}
                  >
                    <TableCell
                      style={{
                        borderTop: "5px solid  #ffb631 ",
                        borderBottom: "5px solid  #ffb631 ",
                        borderLeft: "5px solid  #ffb631 ",
                      }}
                      component="th"
                      scope="row"
                    >
                      # {floorBid.rank}
                    </TableCell>
                    <TableCell
                      style={{
                        borderTop: "5px solid  #ffb631 ",
                        borderBottom: "5px solid  #ffb631 ",
                      }}
                      component="th"
                      scope="row"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {floorBid.bidder} <StyledTag>FLOOR</StyledTag>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        borderTop: "5px solid  #ffb631 ",
                        borderBottom: "5px solid  #ffb631 ",
                      }}
                      align="center"
                    >
                      {floorBid.quantity}
                    </TableCell>
                    <TableCell
                      style={{
                        borderTop: "5px solid  #ffb631 ",
                        borderBottom: "5px solid  #ffb631 ",
                        borderRight: "5px solid  #ffb631 ",
                      }}
                      align="center"
                    >
                      {floorBid.unitPrice}
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>
          </div>
        </>
      )}
    </div>
  );
}
