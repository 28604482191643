import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import debounce from "lodash.debounce";
import {
  Button,
  Grid,
  Input,
  CircularProgress,
  Snackbar,
  Stack,
  Modal,
  Box,
  Checkbox,
  useMediaQuery,
} from "@mui/material";
import terms from "../assets/docs/Auction_Terms_and_Conditions.pdf";
import MuiAlert from "@mui/material/Alert";
import MetamaskContext from "../store/metamask-context";
import { experimentalStyled as styled } from "@mui/material/styles";
import { placeBid, getBid } from "../helpers/auctionHelpers";
import { ethers } from "ethers";
import axios from "axios";

const StyledTag = styled("div")`
  border-radius: 32px;
  padding: 2px;
  color: #111111;
  background: #ffb631;
  min-height: 24px;
  text-align: center;
  margin-left: 10px;
  font-family: PPNeueMachina;
  font-weight: 900;
`;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxHeight: "600px",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  letterSpacing: "-0.02em",
  lineHeight: "150%",
  fontFamily: "EurostilDemi",
  fontSize: "18px",
};
const StyledDisplay = styled("div")`
  padding: 2%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
  font-family: "DM Sans", sans-serif;
  span {
    &:last-child {
      color: rgba(255, 182, 49,1);
    }
  }
`;
const StyledWindow = styled("div")`
  background: #5ba323;
  padding: 10px;
  border-radius: 25px;
  /* font-size: 20px; */
  font-weight: 900;
  line-height: 150%;
  /* width: 37%; */
  text-align: center;
  font-family: PPNeueMachina;
  letter-spacing: -0.02em;
  border: 2px solid transparent;
`;

const StyledInput = styled(Input)`
  color: white;
  width: 100px;
  height: 50px;
  margin-right: 10px;
  border: 1px solid white;
  text-align: center;
  padding: 10px;
`;
const Bid = (props) => {
  const [unitPrice, setUnitPrice] = useState(props.unitPrice);
  const [quantity, setQuantity] = useState(props.quantity);
  const [currentMinBid, setCurrentMinBid] = useState(0);
  const [currentTotal, setCurrentTotal] = useState(0);
  const [newTotal, setTotal] = useState(0);
  const [canBid, setCanBid] = useState(true);
  const [difference, setDifference] = useState(0);
  const [notification, setNotification] = useState({});
  const [currentAuctionStatus, setAuctionStatus] = useState("");
  const [isAuctionOpen, setIsAuctionOpen] = useState(false);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [wrongDecimalForUP, setWrongDecimalForUP] = useState(false);
  const [wrongDecimalForQ, setWrongDecimalForQ] = useState(false);
  const [rankStatus, setRankStatus] = useState(false);
  const [predictedRank, setPredictedRank] = useState({});
  const [predictedRankLoading, setPredictedRankLoading] = useState(false);
  const [acceptDisabled, setAcceptDisabled] = useState(true);

  const ctx = useContext(MetamaskContext);
  const matches = useMediaQuery("(max-width:1800px)");
  const sendBid = () => {
    setTransactionLoading(true);
    setRankStatus(false);
    placeBid(ctx.accounts[0], quantity, unitPrice, props.auctionContract)
      .then((res) => {
        props.updateUnitPriceForProps(unitPrice);
        props.updateQuantityForProps(quantity);
        setTotal(0);
        setCanBid(false);
        setDifference(0);
        setNotification({
          state: true,
          message: "Bid Placed Successfully",
          type: "success",
        });

        // setUnitPrice(0);
        // setQuantity(0);
        setTransactionLoading(false);
      })
      .catch((err) => {
        setRankStatus(true);
        setTransactionLoading(false);
        setNotification({
          state: true,
          message: err.data ? err.data.message : err.message,
          type: "error",
        });
      });
  };

  useEffect(() => {
    if (!transactionLoading) setRankStatus(true);
  }, [props.userBid]);
  const predictRank = async (quantity, unitPrice) => {
    setPredictedRankLoading(true);
    if (props.auctionData) {
      const params = {
        unitPrice,
        quantity,
        auctionId: props.auctionData.currentAuctionId,
      };
      let results;

      try {
        results = await axios.get(
          "https://api.leanime.art/leaderboard/predictRank",
          { params }
        );

        setPredictedRank(results.data.predictedRank);
        setPredictedRankLoading(false);
      } catch (err) {
        setPredictedRankLoading(false);
      }
    }
  };

  const toggleAccept = () => {
    setAcceptDisabled(!acceptDisabled);
  };
  const setNewUnitPriceHandler = (e) => {
    setUnitPrice(parseFloat(e.target.value));
    setWrongDecimalForUP(false);
    const numberToString = e.target.value.toString();
    const splitNumber = numberToString.split(".");
    if (splitNumber.length === 2) {
      if (splitNumber[1].length > 2) {
        setWrongDecimalForUP(true);
      }
    }
  };

  const setNewQuantityHandler = (e) => {
    setQuantity(parseFloat(e.target.value));
    setWrongDecimalForQ(false);
    const numberToString = e.target.value.toString();
    const splitNumber = numberToString.split(".");
    if (splitNumber.length === 2) {
      setWrongDecimalForQ(true);
    } else {
      setWrongDecimalForQ(false);
    }
  };
  const debouncedPredictRank = useCallback(
    debounce(
      (quantityIn, unitPriceIn) => predictRank(quantityIn, unitPriceIn),
      400
    ),
    []
  );

  useEffect(() => {
    setCurrentTotal(parseFloat((props.unitPrice * props.quantity).toFixed(2)));
    setQuantity(props.quantity);
    setUnitPrice(props.unitPrice);
  }, [props.unitPrice, props.quantity]);

  useEffect(() => {
    if (props.initialBid) {
      setQuantity(1);
      if (props.auctionData.currentAuctionId === 0) {
        setUnitPrice(0.04);
        setCurrentMinBid(0.04);
      }
      if (props.auctionData.currentAuctionId === 1) {
        setUnitPrice(0.06);
        setCurrentMinBid(0.06);
      }
      if (props.auctionData.currentAuctionId === 2) {
        setUnitPrice(0.08);
        setCurrentMinBid(0.08);
      }
    } else {
      setQuantity(props.quantity);
      setUnitPrice(props.unitPrice);
      if (props.auctionData.currentAuctionId === 0) {
        setCurrentMinBid(0.04);
      }
      if (props.auctionData.currentAuctionId === 1) {
        setCurrentMinBid(0.06);
      }
      if (props.auctionData.currentAuctionId === 2) {
        setCurrentMinBid(0.08);
      }
    }
  }, [props.initialBid, props.auctionData.currentAuctionId]);
  useEffect(() => {
    const total =
      !unitPrice || !quantity
        ? 0
        : parseFloat((quantity * unitPrice).toFixed(2));
    setTotal(total);

    if (isAuctionOpen) {
      if (
        total > currentTotal &&
        unitPrice >= props.unitPrice &&
        unitPrice >= currentMinBid
      ) {
        setDifference(parseFloat((total - currentTotal).toFixed(2)));
        setCanBid(true);

        debouncedPredictRank(quantity, unitPrice);
      } else if (
        total === currentTotal &&
        unitPrice > props.unitPrice &&
        unitPrice >= currentMinBid
      ) {
        setDifference(parseFloat((total - currentTotal).toFixed(2)));
        setCanBid(true);
        debouncedPredictRank(quantity, unitPrice);
      } else {
        setDifference(0);
        setPredictedRank({});
        setCanBid(false);
      }
    } else {
      setTotal(0);
      setPredictedRank({});
    }
  }, [quantity, unitPrice, isAuctionOpen]);

  useEffect(() => {
    if (props.auctionData) {
      const { currentAuctionStatus, paused } = props.auctionData;

      if (currentAuctionStatus.started && !currentAuctionStatus.ended) {
        setAuctionStatus("IS OPEN");
        setIsAuctionOpen(true);
        if (paused) {
          setAuctionStatus("IS PAUSED");
          setIsAuctionOpen(false);
        }
      }
      if (currentAuctionStatus.started && currentAuctionStatus.ended) {
        setAuctionStatus("HAS ENDED");
        setIsAuctionOpen(false);
      }
      if (!currentAuctionStatus.started && !currentAuctionStatus.ended) {
        setAuctionStatus("HAS NOT STARTED");
        setIsAuctionOpen(false);
      }
    }
  }, [props.auctionData]);

  useEffect(() => {
    setQuantity(props.quantity);
    setUnitPrice(props.unitPrice);
  }, [ctx.accounts]);
  const handleDismissSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNotification({ status: false });
  };
  const handleOpen = () => {
    setAcceptDisabled(true);
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
    setAcceptDisabled(true);
  };
  const handleCloseAndAccept = () => {
    setOpenModal(false);
    setAcceptDisabled(true);
    sendBid();
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ width: "70%", minWidth: "1000px" }}
    >
      <Modal open={openModal} onClose={handleClose}>
        <Box sx={style}>
          <p>
            By checking this box you acknowledge to have thoroughly read and
            approved the following{" "}
            <a
              style={{ color: "rgb(25,118,210)" }}
              href={terms}
              download="TermsAndConditions"
            >
              terms & conditions
            </a>{" "}
            and accept them in order to proceed with bidding in this auction.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Checkbox
                style={{ padding: 0, margin: "10px 0" }}
                onClick={toggleAccept}
              />
              <span>I have read and accept the Terms and Conditions </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button variant="filled" onClick={handleClose}>
                close
              </Button>
              {acceptDisabled ? (
                <Button
                  style={{
                    marginLeft: "1%",
                  }}
                  variant="outlined"
                  disabled={acceptDisabled}
                >
                  accept
                </Button>
              ) : (
                <Button
                  style={{
                    marginLeft: "1%",
                    background: "rgb(211,47,47)",
                    color: "white",
                  }}
                  variant="outlined"
                  onClick={handleCloseAndAccept}
                >
                  accept
                </Button>
              )}
            </div>
          </div>
        </Box>
      </Modal>
      {ctx.isMetamaskConnected ? (
        ctx.network ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%" }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ margin: "1% 0 0.4% 0", width: "50%" }}
            >
              <div
                style={{
                  fontSize: !matches ? "32px" : "20px",
                  fontFamily: "PPNeueMachina",
                  fontWeight: "900",
                  lineHeight: "150%",
                  letterSpacing: "-0.02em",
                }}
              >
                {props.unitPrice && props.quantity ? (
                  <span>UPDATE YOUR BID</span>
                ) : (
                  <span>PLACE YOUR BID</span>
                )}
              </div>
              {props.auctionData && (
                <StyledWindow
                  style={{
                    fontSize: !matches ? "20px" : "15px",
                    background:
                      currentAuctionStatus === "IS OPEN"
                        ? "#5ba323"
                        : "rgba(219, 57, 57, 1)",
                  }}
                >
                  AUCTION {props.auctionData.currentAuctionId + 1}{" "}
                  {currentAuctionStatus}
                </StyledWindow>
              )}
            </Grid>
            {props.unitPrice && props.quantity ? (
              <Grid container direction="column" style={{ width: "50%" }}>
                <Grid
                  style={{ background: "#544021" }}
                  item
                  component={StyledDisplay}
                >
                  <span>YOUR CURRENT BID </span>
                  <span style={{ color: "white" }}>
                    {props.unitPrice} ETH X {props.quantity}{" "}
                    {props.quantity > 1 ? "UNITS" : "UNIT"}
                  </span>
                </Grid>
                <Grid
                  style={{ background: "#544021" }}
                  item
                  component={StyledDisplay}
                >
                  <span>TOTAL </span>{" "}
                  <span style={{ color: "white" }}> {currentTotal} ETH </span>
                </Grid>
                {props.userBid && Object.keys(props.userBid).length > 0 && (
                  <Grid
                    style={{ background: "#544021" }}
                    item
                    component={StyledDisplay}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        color: "white",
                      }}
                    >
                      <span style={{ color: "white" }}>YOUR CURRENT RANK </span>

                      {!transactionLoading && rankStatus ? (
                        props.userBid.winningAllocation > 0 ? (
                          <StyledTag
                            style={{
                              minWidth: !matches ? "120px" : "70px",
                              fontSize: !matches ? "11px" : "9px",
                            }}
                          >
                            WINNING {props.userBid.winningAllocation}{" "}
                            {props.userBid.winningAllocation > 1
                              ? " UNITS"
                              : " UNIT"}{" "}
                          </StyledTag>
                        ) : (
                          <StyledTag
                            style={{
                              background: "rgb(211,47,47)",
                              color: "white",
                              minWidth: !matches ? "120px" : "70px",
                              fontSize: !matches ? "11px" : "9px",
                            }}
                          >
                            LOSING
                          </StyledTag>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                    <span style={{ color: "white" }}>
                      {rankStatus ? `#${props.userBid.rank}` : "loading.."}
                    </span>
                  </Grid>
                )}
                {/* NEW BID */}
                <Grid item style={{ width: "100%", margin: "10px 0" }}>
                  <Grid
                    style={{ background: "#111111", margin: "8px 0 " }}
                    item
                    component={StyledDisplay}
                  >
                    <span>NEW BID PER UNIT </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "160px",
                      }}
                    >
                      <StyledInput
                        id="unit"
                        value={unitPrice}
                        type="number"
                        error={wrongDecimalForUP}
                        helperText={
                          wrongDecimalForUP
                            ? "number must be 2 decimal places"
                            : ""
                        }
                        inputProps={{
                          step: "0.01",
                        }}
                        disabled={!isAuctionOpen}
                        onChange={setNewUnitPriceHandler}
                      />
                      <span>ETH</span>
                    </div>
                  </Grid>
                  <Grid
                    style={{ background: "#111111", margin: "8px 0 " }}
                    item
                    component={StyledDisplay}
                  >
                    <span>NEW UNITS ASKED </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "160px",
                      }}
                    >
                      <StyledInput
                        id="quantity"
                        type="number"
                        value={quantity}
                        error={wrongDecimalForQ}
                        inputProps={{
                          step: "1",
                        }}
                        helpertext={
                          wrongDecimalForQ
                            ? "number must be not have decimal places"
                            : ""
                        }
                        disabled={!isAuctionOpen}
                        style={{ color: "white" }}
                        onChange={(event) => {
                          setNewQuantityHandler(event);
                        }}
                      />
                      <span>UNITS</span>
                    </div>
                  </Grid>
                  <Grid
                    style={{ margin: "2px 0 " }}
                    item
                    component={StyledDisplay}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span style={{ color: "white" }}>PREDICTED RANK</span>
                      {!predictedRankLoading &&
                      unitPrice >= currentMinBid &&
                      quantity >= 1 &&
                      Object.keys(predictedRank).length > 0 ? (
                        predictedRank.allocation > 0 ? (
                          <StyledTag
                            style={{
                              minWidth: !matches ? "120px" : "70px",
                              fontSize: !matches ? "11px" : "9px",
                            }}
                          >
                            WINNING {predictedRank.allocation}{" "}
                            {predictedRank.allocation > 1 ? "UNITS" : "UNIT"}{" "}
                          </StyledTag>
                        ) : (
                          <StyledTag
                            style={{
                              background: "rgb(211,47,47)",
                              color: "white",
                              minWidth: !matches ? "120px" : "70px",
                              fontSize: !matches ? "11px" : "9px",
                            }}
                          >
                            LOSING
                          </StyledTag>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <span>
                        {!predictedRankLoading ? (
                          Object.keys(predictedRank).length > 0 &&
                          unitPrice >= currentMinBid &&
                          quantity >= 1 ? (
                            `# ${predictedRank.predictedRank}`
                          ) : (
                            "..."
                          )
                        ) : (
                          <CircularProgress
                            style={{ width: "15px", height: "15px" }}
                          />
                        )}
                      </span>
                    </div>
                  </Grid>

                  <Grid
                    style={{ margin: "5px 0 " }}
                    item
                    component={StyledDisplay}
                  >
                    <span>TOTAL NEW BID VALUE</span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <span>{newTotal} ETH</span>
                    </div>
                  </Grid>
                  <Grid
                    style={{ margin: "4px 0 " }}
                    item
                    component={StyledDisplay}
                  >
                    <span>ADDITIONAL ETH REQUIRED </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <span>{difference} ETH</span>
                    </div>
                  </Grid>

                  {canBid &&
                  !transactionLoading &&
                  isAuctionOpen &&
                  quantity <= 1000 &&
                  !wrongDecimalForQ &&
                  !wrongDecimalForUP ? (
                    <Button
                      style={{
                        width: "100%",
                        height: 62,
                        borderRadius: 0,
                        fontSize: "30px",
                        fontWeight: "900",
                        fontFamily: "PPNeueMachina",
                        lineHeight: "23px",
                        letterSpacing: "-.0.02em",
                        marginTop: "1%",
                        padding: "5%",
                      }}
                      variant="contained"
                      onClick={sendBid}
                      color="error"
                    >
                      INCREASE BID
                    </Button>
                  ) : (
                    <Button
                      style={{
                        width: "100%",
                        height: 62,
                        borderRadius: 0,
                        fontSize: "30px",
                        fontFamily: "PPNeueMachina",
                        fontWeight: "900",
                        lineHeight: "23px",
                        letterSpacing: "-.0.02em",
                        marginTop: "1%",
                        padding: "5%",
                        color: "white",
                      }}
                      disabled
                      variant="contained"
                    >
                      {transactionLoading ? (
                        <CircularProgress />
                      ) : (
                        "INCREASE BID"
                      )}
                    </Button>
                  )}
                  <div
                    style={{
                      margin: "3% 0",
                      textAlign: "center",
                      fontSize: "13px",
                      fontWeight: 400,
                    }}
                  >
                    * Minimum Bid per Unit:{" "}
                    {props.auctionData.currentAuctionId === 0
                      ? 0.04
                      : props.auctionData.currentAuctionId === 1
                      ? 0.06
                      : 0.08}{" "}
                    ETH - Minimum Bid Increment: 0.01 ETH - Max Units per Bid:
                    1000 <br />
                    ** Bids can be increased by increasing Eth per Unit or
                    number of units (or a combination of both). New bid total
                    must be higher than or equal to the previous bid total.{" "}
                    <br />
                    *** Once the bidding transaction is confirmed in the
                    blockchain, your current rank will appear shortly after, and
                    updates approximately every 30s.
                    <br />
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Grid container direction="column" style={{ width: "50%" }}>
                <Grid item style={{ width: "100%", margin: "10px 0" }}>
                  <Grid
                    style={{ background: "#111111", margin: "8px 0 " }}
                    item
                    component={StyledDisplay}
                  >
                    <span> BID PER UNIT </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "160px",
                      }}
                    >
                      <StyledInput
                        id="unitNew"
                        error={wrongDecimalForUP}
                        value={unitPrice}
                        helpertext={
                          wrongDecimalForUP
                            ? "number must be 2 decimal places"
                            : ""
                        }
                        disabled={!isAuctionOpen}
                        type="number"
                        onChange={(event) => {
                          setNewUnitPriceHandler(event);
                        }}
                        inputProps={{
                          step: "0.01",
                        }}
                        style={{ color: "white" }}
                      />
                      <span>ETH</span>
                    </div>
                  </Grid>
                  <Grid
                    style={{ background: "#111111", margin: "8px 0 " }}
                    item
                    component={StyledDisplay}
                  >
                    <span>UNITS ASKED </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "160px",
                      }}
                    >
                      <StyledInput
                        id="quantityNew"
                        type="number"
                        value={quantity}
                        disabled={!isAuctionOpen}
                        error={wrongDecimalForQ}
                        helpertext={
                          wrongDecimalForQ
                            ? "number must be not have decimal places"
                            : ""
                        }
                        style={{ color: "white", width: "100px" }}
                        inputProps={{
                          step: "1",
                        }}
                        onChange={(event) => {
                          setNewQuantityHandler(event);
                        }}
                      />
                      <span>UNITS</span>
                    </div>
                  </Grid>

                  <Grid
                    style={{ margin: "2px 0 " }}
                    item
                    component={StyledDisplay}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span style={{ color: "white" }}>PREDICTED RANK</span>
                      {!predictedRankLoading &&
                      unitPrice >= currentMinBid &&
                      quantity >= 1 &&
                      Object.keys(predictedRank).length > 0 ? (
                        predictedRank.allocation > 0 ? (
                          <StyledTag
                            style={{
                              minWidth: !matches ? "120px" : "70px",
                              fontSize: !matches ? "11px" : "9px",
                            }}
                          >
                            WINNING {predictedRank.allocation}{" "}
                            {predictedRank.allocation > 1 ? "UNITS" : "UNIT"}{" "}
                          </StyledTag>
                        ) : (
                          <StyledTag
                            style={{
                              background: "rgb(211,47,47)",
                              color: "white",
                              minWidth: !matches ? "120px" : "70px",
                              fontSize: !matches ? "11px" : "9px",
                            }}
                          >
                            LOSING
                          </StyledTag>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <span>
                        {!predictedRankLoading ? (
                          Object.keys(predictedRank).length > 0 &&
                          unitPrice >= currentMinBid &&
                          quantity >= 1 ? (
                            `# ${predictedRank.predictedRank}`
                          ) : (
                            "..."
                          )
                        ) : (
                          <CircularProgress
                            style={{ width: "15px", height: "15px" }}
                          />
                        )}
                      </span>
                    </div>
                  </Grid>
                  <Grid
                    style={{ margin: "2px 0 " }}
                    item
                    component={StyledDisplay}
                  >
                    <span>TOTAL BID</span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <span>{newTotal} ETH</span>
                    </div>
                  </Grid>

                  {!transactionLoading &&
                  !wrongDecimalForUP &&
                  !wrongDecimalForQ &&
                  isAuctionOpen &&
                  newTotal > 0 &&
                  newTotal >= currentMinBid * quantity &&
                  quantity <= 1000 ? (
                    <>
                      <div
                        style={{
                          letterSpacing: "-0.02em",
                          lineHeight: "150%",
                          fontFamily: "EurostilDemi",
                          fontSize: "18px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      ></div>
                      <Button
                        style={{
                          width: "100%",
                          height: 62,
                          borderRadius: 0,
                          fontSize: "25px",
                          fontFamily: "PPNeueMachina",
                          fontWeight: "900",
                          lineHeight: "23px",
                          letterSpacing: "-.0.02em",
                          marginTop: "1%",
                          padding: "4%",
                        }}
                        variant="contained"
                        onClick={handleOpen}
                        color="error"
                      >
                        PLACE BID
                      </Button>
                    </>
                  ) : (
                    <Button
                      style={{
                        width: "100%",
                        height: 62,
                        borderRadius: 0,
                        fontSize: "25px",
                        fontFamily: "PPNeueMachina",
                        fontWeight: "900",
                        lineHeight: "23px",
                        letterSpacing: "-.0.02em",
                        marginTop: "1%",
                        padding: "4%",
                      }}
                      variant="contained"
                      disabled
                      color="error"
                    >
                      {transactionLoading ? <CircularProgress /> : "PLACE BID"}
                    </Button>
                  )}
                  <div
                    style={{
                      margin: "3% 0",
                      textAlign: "center",
                      fontSize: "13px",
                      fontWeight: 400,
                    }}
                  >
                    * Minimum Bid per Unit: {currentMinBid} ETH - Minimum Bid
                    Increment: 0.01 ETH - Max Units per Bid: 1000 <br />
                    ** Predicted Rank gives you an indication of the rank and
                    amount of units you will qualify for the chosen bid amount,
                    and at current time. Actual rank once the transaction is
                    confimed may vary due to other users bidding simultaneusly.{" "}
                    <br />
                    *** Once the bidding transaction is confirmed in the
                    blockchain, your current rank will appear shortly after, and
                    updates approximately every 30s.
                  </div>
                </Grid>
              </Grid>
            )}
          </Grid>
        ) : (
          <p
            style={{
              fontSize: "20px",
              lineHeight: "24px",
              fontFamily: "EurostilDemi",
              letterSpacing: "-0.01em",
            }}
          >
            Incorrect network detected
          </p>
        )
      ) : (
        <p
          style={{
            fontSize: "20px",
            lineHeight: "24px",
            fontFamily: "EurostilDemi",
            letterSpacing: "-0.01em",
          }}
        >
          Please connect your wallet to be able to place bids{" "}
        </p>
      )}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          autoHideDuration={3000}
          open={notification.state && notification.type === "error"}
          onClose={handleDismissSnackBar}
          key={"error"}
        >
          <MuiAlert
            onClose={handleDismissSnackBar}
            variant="filled"
            severity="error"
          >
            {notification.message}
          </MuiAlert>
        </Snackbar>
        <Snackbar
          autoHideDuration={3000}
          open={notification.state && notification.type === "success"}
          onClose={handleDismissSnackBar}
          key={"success"}
        >
          <MuiAlert
            onClose={handleDismissSnackBar}
            variant="filled"
            severity="success"
          >
            {notification.message}
          </MuiAlert>
        </Snackbar>
      </Stack>
    </Grid>
  );
};

export default Bid;
