import React, { useRef, useEffect, useState, createRef } from "react";
import {
    Grid,
    Button,
    useMediaQuery,
    Modal,
    CircularProgress,
    Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { fillAdditionalLayers } from "../../helpers/builderHelpers/processAddLayers";

import thelabimage from "../../assets/thelab.jpeg";
import { SelectTrait, TraitsLevels } from "../../components";
import { orderLayers } from "../../helpers/builderHelpers/orderLayers";
import { processSouls } from "../../helpers/builderHelpers/processValues";
import isEqual from "lodash.isequal";
import { ethers } from "ethers";
import { useAccount, useSigner, useProvider, useNetwork } from "wagmi";

import metadata from "../../assets/heroesData/HeroesMetaData.json";
import {
    ANIMEOE_ABI,
    ANIMEOE_ADDRESS,
    ANIMEV2_ABI,
    ANIMEV2_ADDRESS,
} from "../../config/configWrapping";
import { getHeroAdditionalLayers } from "../../helpers/experienceHelpers";
import {
    WRAPPER_ABI,
    WRAPPER_ADDRESS,
    SOULSLOCKER_ABI,
    SOULSLOCKER_ADDRESS,
    STORAGE_ABI,
    STORAGE_ADDRESS,
    MERGER_ABI,
    MERGER_ADDRESS,
} from "../../config/configMerging";
import {
    EXPERIENCE_ABI,
    EXPERIENCE_ADDRESS,
    ADDLAYERS_ABI,
    ADDLAYERS_ADDRESS,
} from "../../config/configExperience";
import { useNavigate } from "react-router-dom";
import { listAnimeSpiritsHeroesOfOwner } from "../../helpers/wrappingHelpers";
import {
    listAnimeSpiritsHeroesOfOwner_separate,
    isMergingApproved,
    approveMerging,
    depositSouls,
    setHeroData,
    getHeroData,
    isMergeActive,
} from "../../helpers/mergerHelpers";
import Fade from "@mui/material/Fade";
import {
    traitsMapping,
    traitsMappingReference,
} from "../../assets/heroesData/TraitEncodingHeroes";
import clonedeep from "lodash.clonedeep";
import "./style.css";
const extraRegex = /(Extra)\ \d/;
const fakeArray = new Array(22).fill(0);
const DemoBuilder = () => {
    const navigate = useNavigate();
    //wagmi hooks for account, signer, provider and network
    const { address, isConnected } = useAccount();
    const provider = useProvider();
    const { data: signer, isError, isLoading } = useSigner();

    const { chain } = useNetwork();

    const [isChainSupported, setIsChainSupported] = useState(false);

    const [hero, setHero] = useState([]);
    const [confirmedHero, setConfirmedHero] = useState([]);
    const [allTokens, setAllTokens] = useState([]);
    const [mainTokenUnformatted, setMainTokenUnformatted] = useState({});
    const [allFormattedTokens, setAllFormattedTokens] = useState([]);
    const [mainToken, setMainToken] = useState(null);
    const [heroesOfUser, setHeroesOfUser] = useState([]);
    const [initialData, setInitialData] = useState(null);
    const [allSelectedNfts, setAllSelectedNfts] = useState([]);
    const [allModalSelectedNfts, setAllModalSelectedNfts] = useState([]);
    const [canConfirmSelectedNfts, setCanConfirmSelectedNfts] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [editOrCreateModalOpen, setEditOrCreateModalOpen] = useState(false);
    const [openMintModal, setOpenMintModal] = useState(false);
    const [openCelebrationModal, setOpenCelebrationModal] = useState(false);
    const [openSelectNFTs, setOpenSelectNFTs] = useState(false);
    const [openAdditionalLayers, setOpenAdditionalLayers] = useState(false);
    ///// HERO TRAITS //////
    const [heroL, setHeroL] = useState(null);
    const [skin, setSkin] = useState(null);
    const [bg, setBG] = useState(null);
    const [fullBgColor, setFullBgColor] = useState(null);
    const [halo, setHalo] = useState(null);
    const [clA, setClA] = useState(null);
    const [clB, setClB] = useState(null);
    const [runes, setRunes] = useState({});
    ///// EXTRAS ///
    const [extras, setExtras] = useState([]);
    const [extra1, setExtra1] = useState([]);
    const [extra2, setExtra2] = useState([]);
    const [extra3, setExtra3] = useState([]);
    const [extra4, setExtra4] = useState([]);
    const [extra5, setExtra5] = useState([]);
    const [extra6, setExtra6] = useState([]);
    const [extra7, setExtra7] = useState([]);
    const [score, setScore] = useState(0);
    const [scoreTooLowExtras, setScoreTooLowExtras] = useState([]);
    const [allPossibleTraits, setAllPossibleTraits] = useState([]);
    const [isSelectedAllNfts, setIsSelectedAllNfts] = useState(false);
    const [chosenExtras, setChosenExtras] = useState(Array(18).fill(false));
    const [heroExtraSlots, setHeroExtraSlots] = useState(0);
    const [heroMaxLevel, setHeroMaxLevel] = useState(1);
    const [isAnimeInHero, setIsAnimeInHero] = useState(false);
    /// ADDITIONAL LAYERS ///
    const [allAdditionalLayers, setAllAdditionalLayers] = useState([]);
    const [selectedAdditionalLayers, setSelectedAdditionalLayers] = useState(
        []
    );
    const [
        selectedAndActiveAdditionalLayers,
        setSelectedAndActiveAdditionalLayers,
    ] = useState([]);
    ///// CONTRACTS ////
    const [animeV2Contract, setAnimeV2Contract] = useState(null);
    const [wrapperContract, setWrapperContract] = useState(null);
    const [lockerContract, setLockerContract] = useState(null);
    const [storageContract, setStorageContract] = useState(null);
    const [mergeContract, setMergeContract] = useState(null);
    const [experienceContract, setExperienceContract] = useState(null);
    const [addLayersContract, setAddLayersContract] = useState(null);
    ////// MERGING STATES /////
    const [mergingApproved, setIsMergingApprove] = useState(false);
    const [isApproveLoading, setIsApproveLoading] = useState(false);
    const [isMintActive, setIsMintActive] = useState(null);
    ///// DEPOSITING STATES ////
    const [isDepositLoading, setIsDepositLoading] = useState(false);
    const [canDeposit, setCanDeposit] = useState(false);
    const [isDeposited, setIsDeposited] = useState(false);
    const [depositedSouls, setDepositedSouls] = useState([]);
    const [openConfirmLockedSouls, setOpenConfirmLockedSouls] = useState(false);
    //// CONFIRM TRAITS STATES ////
    const [isConfirmTraitsLoading, setIsConfirmTraitsLoading] = useState(false);
    const [isConfirmedTraits, setIsConfirmedTraits] = useState(false);
    const [canConfirmTraits, setCanConfirmTraits] = useState(true);

    useState(false);
    ///// REFS //////
    const imageRefs = useRef([]);

    imageRefs.current = Array(22).map(
        (ref, index) => (imageRefs.current[index] = createRef())
    );
    const matchesMedium = useMediaQuery("(max-width:1400px)");
    const matchesSmall = useMediaQuery("(max-width:1024px)");
    const matchesMobile = useMediaQuery("(max-width:600px)");
    const processHero = (
        heroL,
        skin,
        clA,
        clB,
        bg,
        fullColorBg,
        halo,
        runes,
        runesL,
        extras,
        activeAddLayers
    ) => {
        // check if all inputs are defined
        if (
            heroL
        ) {
          // extras is an array - extras = [ [1,1], [10,1] ]

          // let path = './0_LEA2_LQ/'
          let path = "https://leanime.art/heroes/builder/0_LEA2_LQ/";

          for (let i = 3; i <= 21; i++) {
              // ???
              let imageLayer = imageRefs.current[i];

              imageLayer.style.backgroundImage = null;
          }

          //BG here

          let bg_layer = path + "bg_lq/" + "bg_" + bg + ".png";

          let layer0 = imageRefs.current[0];

          layer0.style.backgroundImage = "url(" + bg_layer + ")";

          let preLayers = Array(128).fill(0);

          heroL = "H" + heroL;

          // bgFullColor here only if != 0

          if (fullColorBg != 0) {
              preLayers[orderLayers["fullColorBg"]] = {
                  input:
                      path +
                      "fullColorBg_lq/" +
                      "fullColorBg_" +
                      fullColorBg +
                      ".png",
              };
          }

          // 0 = skin
          if (skin != 0) {
              // exception for 100 soul / invisible
              preLayers[orderLayers["skin"]] = {
                  input:
                      path +
                      "skin_lq/" +
                      heroL +
                      "_skin_" +
                      skin +
                      "_" +
                      1 +
                      ".png",
              };
          }

          // 1 = clA
          preLayers[orderLayers["clA"]] = {
              input: path + "clA_lq/" + heroL + "_clA_" + clA + "_" + 1 + ".png",
          };

          // 2 = clB
          preLayers[orderLayers["clB"]] = {
              input: path + "clB_lq/" + heroL + "_clB_" + clB + "_" + 1 + ".png",
          };

          // 4 halos - halos have some special case where they are made of 2 layers in different positions
          if (halo == 10) {
              //Aurora Crown
              preLayers[orderLayers["halo"][1]] = {
                  //thorns
                  input:
                      path + "halo_lq/" + heroL + "_halo_" + "1" + "_1" + ".png",
              };

              preLayers[orderLayers["halo"][5]] = {
                  //aurora cross
                  input:
                      path + "halo_lq/" + heroL + "_halo_" + "5" + "_1" + ".png",
              };
          } else if (halo == 11) {
              //Trinity
              preLayers[orderLayers["halo"][1]] = {
                  //thorns
                  input:
                      path + "halo_lq/" + heroL + "_halo_" + "1" + "_1" + ".png",
              };

              preLayers[orderLayers["halo"][6]] = {
                  //magic triangle
                  input:
                      path + "halo_lq/" + heroL + "_halo_" + "6" + "_1" + ".png",
              };
          } else if (halo == 9) {
              //Grazie Mauro
              preLayers[orderLayers["halo"][9]] = {
                  input:
                      path + "halo_lq/" + heroL + "_halo_" + "9" + "_1" + ".png",
              };

              preLayers[orderLayers["halo"][5]] = {
                  //aurora cross
                  input:
                      path + "halo_lq/" + heroL + "_halo_" + "5" + "_1" + ".png",
              };
          } else if (halo == 0) {
              //exception for 100 soul
              //nothing here
          } else {
              preLayers[orderLayers["halo"][halo]] = {
                  input:
                      path +
                      "halo_lq/" +
                      heroL +
                      "_halo_" +
                      halo +
                      "_" +
                      1 +
                      ".png",
              };
          }

          // EXTRAS
          // REMINDER: NEED TO CHECK THAT EXTRAS ARE DIFFERENT AND REMOVE DUPLICATES!

          extras.forEach((extra) => {
              if (extra && extra[0] != 0) {
                  // exception for 100 soul, extra = 0 is invisible
                  if (extra[0] != 420) {
                      preLayers[orderLayers["extra"][extra[0]]] = {
                          input:
                              path +
                              "extra_lq/" +
                              heroL +
                              "_extra_" +
                              extra[0] +
                              "_" +
                              extra[1] +
                              ".png",
                      };
                  } else {
                      //420 is an extra layer but it's not an official extra in traits and appears when ID of main token is 420
                      preLayers[orderLayers["extra"][19]] = {
                          input:
                              path +
                              "extra_lq/" +
                              heroL +
                              "_extra_" +
                              "420" +
                              "_1" +
                              ".png",
                      };
                  }
              }
          });
          activeAddLayers &&
              activeAddLayers.forEach((layer) => {
                  fillAdditionalLayers(path, preLayers, heroL, layer);
              });

          // removes all the 0 empty layers
          let layers = preLayers.filter(function (value, index, arr) {
              return value != 0;
          });

          layers.forEach((layer, idx) => {
              let imageLayer = imageRefs.current[idx + 1];
              imageLayer.style.backgroundImage = "url(" + layer["input"] + ")";

              // if(layer['blend'] == 'screen') {
              //     imageLayer.style['mix-blend-mode'] = "screen"
              // }
          });
          // console.log(layers)

          // ADD RUNES AND FLOW PASS IN SCREEN BLEND MODE

          if (runes != 0) {
              // exception for 100 soul
              let imageLayer = imageRefs.current[20];
              imageLayer.style.backgroundImage =
                  "url(" +
                  path +
                  "runes_lq/" +
                  "runes_" +
                  runes +
                  "_" +
                  runesL +
                  ".png" +
                  ")";
          }

          let imageLayer = imageRefs.current[21];
          imageLayer.style.backgroundImage =
              "url(" + path + "glow_lq/" + heroL + "_glow" + ".png" + ")";
        }
    };
    const fillHeroToMax = (max) => {
        let array = [];
        for (let index = 0; index < max; index++) {
            array.push(index + 1);
        }

        return array;
    };
    const generateAllPossibleTraits = (
        traits,
        heroMaxLevelFeeded,
        heroExtraSlots
    ) => {
        let finalTraits = [];
        finalTraits.push({
            trait: "Hero Level",
            options: fillHeroToMax(15),
        });

        finalTraits.push({
            trait: "Skin",
            options: [0, ...fillHeroToMax(27)],
        });

        finalTraits.push({
            trait: "Clothes A",
            options: fillHeroToMax(27),
        });

        finalTraits.push({
            trait: "Clothes B",
            options: fillHeroToMax(27),
        });

        finalTraits.push({
            trait: "Background",
            options: fillHeroToMax(13),
        });

        finalTraits.push({
            trait: "Halo",
            options: [0, ...fillHeroToMax(25)],
        });

        finalTraits.push({
            trait: "Background Color",
            options: [0, ...fillHeroToMax(18)],
        });
        // console.log("traits", traits)

        finalTraits.push({
            trait: "Runes",
            options: [
                [0, 1],
                [1, 10],
                [2, 10],
                [3, 10],
                [4, 10],
                [5, 10],
                [6, 10],
                [7, 1],
                [8, 1],
                [9, 10],
                [10, 10],
                [11, 1],
                [12, 1],
                [13, 1],
                [14, 1],
                [15, 1],
            ],
        });

        // [19,1] does not work beacuse it's the special 420 case
        let extraLevels = [
            [0, 1],
            [1, 8],
            [2, 5],
            [3, 5],
            [4, 8],
            [5, 10],
            [6, 8],
            [7, 9],
            [8, 1],
            [9, 1],
            [10, 1],
            [11, 1],
            [12, 1],
            [13, 1],
            [14, 1],
            [15, 1],
            [16, 1],
            [17, 1],
            [18, 1],
            // [19, 1],
        ];

        // heroExtraSlots = 3
        for (let index = 0; index < heroExtraSlots; index++) {
            finalTraits.push({
                trait: `Extra ${index + 1}`,
                options: extraLevels, //index === 0 ? traits[trait] : [[0, 0], ...traits[trait]],
            });
        }

        setAllAdditionalLayers([[1,1],[2,1],[3,1],[4,1],[5,1],[6,1],[7,1],[8,1],[8,2],[8,3],[8,4]]);

        // Object.keys(traits).forEach((trait) => {
        //   switch (trait) {
        //     // case "skin":
        //     //   finalTraits.push({ trait: "Skin", options: traits[trait] });
        //     //   break;
        //     // case "bg":
        //     //   finalTraits.push({ trait: "Background", options: traits[trait] });
        //     //   break;
        //     // case "clA":
        //     //   finalTraits.push({ trait: "Clothes A", options: traits[trait] });
        //     //   break;
        //     // case "clB":
        //       // finalTraits.push({ trait: "Clothes B", options: traits[trait] });
        //       // break;

        //     // case "halo":
        //       // finalTraits.push({ trait: "Halo", options: traits[trait] });
        //       // break;
        //     // case "runes":
        //     //   finalTraits.push({ trait: "Runes", options: traits[trait] });
        //     //   break;

        //     case "extra":

        //       for (let index = 0; index < heroExtraSlots; index++) {
        //         finalTraits.push({
        //           trait: `Extra ${index + 1}`,
        //           options: index === 0 ? traits[trait] : [[0, 0], ...traits[trait]],
        //         });
        //       }
        //       break;

        //     default:
        //       break;
        //   }
        // });

        // console.log(finalTraits);

        const numberOfExtras = finalTraits.filter((obj) =>
            obj.trait.match(extraRegex)
        );
        const newExtras = extras.slice(0, numberOfExtras.length);
        setExtras(newExtras);

        let scoreTooLow = [];
        for (let index = heroExtraSlots; index < 7; index++) {
            scoreTooLow.push({
                trait: `Extra ${index + 1}`,
                options: [],
            });
        }
        setScoreTooLowExtras(scoreTooLow);
        setAllPossibleTraits(finalTraits);
    };

    const handleCloseInitialModal = () => {
        setOpenModal(false);
        navigate("/", { replace: false });
    };

    const handleCloseInitialModalTokenSelected = () => {
        setOpenModal(false);
    };

    const handleCloseEditOrCreateModal = () => {
        setEditOrCreateModalOpen(false);
        navigate("/", { replace: false });
    };
    const handleCloseAndConfirmSelectNFTsModal = () => {
        const allSelectedTokens = [mainToken, ...allModalSelectedNfts];
        const allSelectedTokensWithDeposited = [
            mainToken,
            ...allModalSelectedNfts,
            ...depositedSouls,
        ];
        const { allTraits, heroMaxLevel, heroExtraSlots } = processSouls(
            allSelectedTokensWithDeposited
        );

        setScore(allTraits["score"]);
        generateAllPossibleTraits(allTraits, heroMaxLevel, heroExtraSlots);
        setHeroMaxLevel(heroMaxLevel);
        setHeroExtraSlots(heroExtraSlots);
        setCanConfirmTraits(false);
        setIsDeposited(false);
        setAllSelectedNfts(allSelectedTokens);
        setOpenSelectNFTs(false);
    };
    const handleCloseSelectNFTsModal = () => {
        // setOpenSelectNFTs(false);
        // setAllModalSelectedNfts([]);
    };

    const handleCloseMintModal = () => {
        setOpenMintModal(false);
    };

    // SET EXTRAS

    const handleSetExtra1 = (value, level = 1) => {
        setExtra1([value, level]);
    };
    const handleSetExtra2 = (value, level = 1) => {
        setExtra2([value, level]);
    };
    const handleSetExtra3 = (value, level = 1) => {
        setExtra3([value, level]);
    };
    const handleSetExtra4 = (value, level = 1) => {
        setExtra4([value, level]);
    };
    const handleSetExtra5 = (value, level = 1) => {
        setExtra5([value, level]);
    };
    const handleSetExtra6 = (value, level = 1) => {
        setExtra6([value, level]);
    };
    const handleSetExtra7 = (value, level = 1) => {
        setExtra7([value, level]);
    };
    const handleSelectTrait = (data) => {
        const { trait, traitValue, index, level } = data;

        switch (trait) {
            case "Hero Level":
                setHeroL(traitValue);
                break;

            case "Background Color":
                setFullBgColor(traitValue);
                break;
            case "Skin":
                setSkin(index);
                break;
            case "Background":
                setBG(index);
                break;
            case "Clothes A":
                setClA(index);
                break;
            case "Clothes B":
                setClB(index);
                break;
            case "Halo":
                setHalo(index);
                break;
            case "Runes":
                setRunes({ number: index, level });
                break;
            case trait.match(extraRegex)?.input:
                const extranumb = trait.match(/\d/g).join("");

                if (parseInt(extranumb) === 1) {
                    handleSetExtra1(index, level);
                }
                if (parseInt(extranumb) === 2) {
                    handleSetExtra2(index, level);
                }
                if (parseInt(extranumb) === 3) {
                    handleSetExtra3(index, level);
                }
                if (parseInt(extranumb) === 4) {
                    handleSetExtra4(index, level);
                }
                if (parseInt(extranumb) === 5) {
                    handleSetExtra5(index, level);
                }
                if (parseInt(extranumb) === 6) {
                    handleSetExtra6(index, level);
                }
                if (parseInt(extranumb) === 7) {
                    handleSetExtra7(index, level);
                }
                break;
            default:
                break;
        }
    };

    const handleSelectMainToken = (id, traits) => {
        handleCloseInitialModalTokenSelected();

        // const { allTraits, heroMaxLevel, heroExtraSlots } = processSouls([id]);
        // define here
        const allTraits = {
            score: 4180,
            skin: [11],
            clA: [11],
            clB: [11],
            bg: [7],
            halo: [8],
            runes: [[6, 5]],
            extra: [[3, 3]],
            multiplier: [20],
        };

        const heroMaxLevel = 10;
        const heroExtraSlots = 7;

        // console.log("AllTraits", allTraits, heroMaxLevel, heroExtraSlots);
        // heroMaxLevel = 15;

        setScore(allTraits["score"]);
        generateAllPossibleTraits(allTraits, heroMaxLevel, heroExtraSlots);
        setAllSelectedNfts([id]);
        setMainTokenUnformatted({ id, traits });
        setMainToken(id);

        setHeroMaxLevel(heroMaxLevel);
        setHeroExtraSlots(heroExtraSlots);
        // const filteredAllToken = allTokens.filter((obj) => obj.soulNumber !== id);

        // setAllTokens(filteredAllToken);
    };
    const handleSwapMainToken = (id, traits) => {
        handleCloseInitialModalTokenSelected();
        //because we removed the maintoken from alltokens previously
        const allTokensWithAddedPreviousMainToken = clonedeep(allTokens);
        allTokensWithAddedPreviousMainToken.push({
            id: `${mainTokenUnformatted.id + 1000000}`,
            soulNumber: mainTokenUnformatted.id,
            traits: mainTokenUnformatted.traits,
        });

        const sortedAllTokens = allTokensWithAddedPreviousMainToken.sort(
            (a, b) => {
                return a.soulNumber - b.soulNumber;
            }
        );
        const filteredAllTokens = sortedAllTokens.filter(
            (obj) => obj.soulNumber !== id
        );
        setAllTokens(filteredAllTokens);

        setIsSelectedAllNfts(false);
        //removing the swapped main token if it was selected
        let replacedMainTokeninSelectedNfts = clonedeep(allSelectedNfts);
        replacedMainTokeninSelectedNfts =
            replacedMainTokeninSelectedNfts.filter((obj) => obj !== id);

        replacedMainTokeninSelectedNfts[0] = id;

        const { allTraits, heroMaxLevel, heroExtraSlots } = processSouls(
            replacedMainTokeninSelectedNfts
        );
        setScore(allTraits["score"]);
        generateAllPossibleTraits(allTraits, heroMaxLevel, heroExtraSlots);

        setHeroMaxLevel(heroMaxLevel);
        setHeroExtraSlots(heroExtraSlots);
        setAllSelectedNfts(replacedMainTokeninSelectedNfts);
        const replacedFilteredModal = replacedMainTokeninSelectedNfts.slice(
            1,
            replacedMainTokeninSelectedNfts.length
        );
        setAllModalSelectedNfts(replacedFilteredModal);
        setMainTokenUnformatted({ id, traits });
        setMainToken(id);
    };
    const handleSelectNFTs = (id, traits) => {
        const found = allModalSelectedNfts.find((obj) => obj === id);
        if (!found) {
            // all selected + maintoken [0]
            setAllModalSelectedNfts([...allModalSelectedNfts, id]);
        } else {
            //deslecting when all selected
            if (isSelectedAllNfts) {
                setIsSelectedAllNfts(false);
            }

            const filteredAllSelectedNfts = allModalSelectedNfts.filter(
                (obj) => obj !== id
            );

            setAllModalSelectedNfts(filteredAllSelectedNfts);
        }
    };
    const selectAllNFTs = () => {
        const state = !isSelectedAllNfts;
        setIsSelectedAllNfts(state);
        // empty anyways

        setAllModalSelectedNfts(
            state ? allTokens.map((obj) => obj.soulNumber) : []
        );
    };

    const handleCloseConfirmLockedSouls = () => {
        setOpenConfirmLockedSouls(false);
    };
    // Additional Layers
    const handleSelectAddLayer = (layer) => {
        const found =
            selectedAdditionalLayers &&
            selectedAdditionalLayers.find((arr) => isEqual(arr, layer));

        
        if (!found) {         
            // check if layer is [X,Y] where X = 8 or X = 7 && Y = 1 - handles the case of the 2 layers of the same "frames" type
            if (layer[0] === 8 || (layer[0] == 7 && layer[1] === 1)) {
              
              // removes the other layer of the same type, if present - only 1 layer of the same type can be selected
              const filterSelectedAdditionalLayers =
              selectedAdditionalLayers.filter((arr) => !isEqual(arr[0], 8) && !(isEqual(arr[0], 7) && isEqual(arr[1], 1)));
              setSelectedAdditionalLayers([...filterSelectedAdditionalLayers, layer]);
            }
            else
            if (layer[0] === 2 && (layer[1] === 1 || layer[1] === 2)) { // handles the case of Grassetti mask and Xsullo mask
              // check if hero level is not 10,12,13,14,15
              if (heroL < 10 || heroL === 11) {
                // removes the other layer of the same type, if present - only 1 layer of the same type can be selected
                const filterSelectedAdditionalLayers =
                selectedAdditionalLayers.filter((arr) => !(isEqual(arr[0], 2) && (isEqual(arr[1], 1) || isEqual(arr[1], 2))));
                setSelectedAdditionalLayers([...filterSelectedAdditionalLayers, layer]);
              }
              else {
                setSelectedAdditionalLayers([...selectedAdditionalLayers, layer]);
              }
            }
            else {
              setSelectedAdditionalLayers([...selectedAdditionalLayers, layer]);
            }
        } else {
            const filterSelectedAdditionalLayers =
                selectedAdditionalLayers.filter((arr) => !isEqual(arr, layer));

            setSelectedAdditionalLayers(filterSelectedAdditionalLayers);
        }
    };
    const handleConfirmAdditionalLayers = () => {
        setOpenAdditionalLayers(false);
        setSelectedAndActiveAdditionalLayers(selectedAdditionalLayers);
    };
    /////////////// MERGING FUNCTIONS /////////////
    const handleApproveMerging = () => {
        setIsApproveLoading(true);
        approveMerging(wrapperContract)
            .then(() => {
                setIsMergingApprove(true);
                setIsApproveLoading(false);
            })
            .catch((err) => {
                setIsApproveLoading(false);
                setIsMergingApprove(false);
            });
    };
    const handleDepositSouls = () => {
        setOpenConfirmLockedSouls(false);
        const toBeDepositedSouls = allModalSelectedNfts; //remove main token
        setIsDepositLoading(true);
        depositSouls(mainToken, toBeDepositedSouls, lockerContract)
            .then(() => {
                setIsDepositLoading(false);
                setIsDeposited(true);
                setDepositedSouls(toBeDepositedSouls);
                setCanDeposit(false);
                setCanConfirmTraits(!isEqual(hero, confirmedHero));
                const getNewTokens = async () => {
                    let allTokens =
                        await listAnimeSpiritsHeroesOfOwner_separate(
                            address,
                            wrapperContract,
                            lockerContract
                        );
                    allTokens = allTokens.tokenIDs;
                    const sortedAllTokens = allTokens.sort((a, b) => {
                        return a.id - b.id;
                    });
                    const allTokensWithoutMain = sortedAllTokens.filter(
                        (item) => item.soulNumber !== mainToken
                    );
                    setAllTokens(allTokensWithoutMain);
                    const formattedAllTokens = allTokensWithoutMain.map(
                        (token) => {
                            return { id: token.soulNumber };
                        }
                    );
                    setAllFormattedTokens(formattedAllTokens);
                };
                getNewTokens();

                setAllSelectedNfts([]);
                setAllModalSelectedNfts([]);
            })
            .catch((err) => {
                setIsDepositLoading(false);
                setIsDeposited(false);
            });
    };
    const handleConfirmTraits = () => {
        console.log("ASDASD");
        setIsConfirmTraitsLoading(true);
        setHeroData(mainToken, hero, storageContract)
            .then(() => {
                setConfirmedHero(hero);
                setIsConfirmTraitsLoading(false);
                setIsConfirmedTraits(true);
                setCanConfirmTraits(false);
                setOpenCelebrationModal(true);
                handleCloseMintModal();
            })
            .catch((err) => {
                setIsConfirmTraitsLoading(false);
                setIsConfirmedTraits(false);
            });
    };
    /////////////// USE EFFECTS /////////////
    // useEffect(() => {
    //   if (signer && address && isConnected && isChainSupported) {
    //     const setContractAndTokens = async () => {
    //       // contracts
    //       const wrapperContract = new ethers.Contract(
    //         WRAPPER_ADDRESS,
    //         WRAPPER_ABI,
    //         signer
    //       );
    //       setWrapperContract(wrapperContract);
    //       const lockerContract = new ethers.Contract(
    //         SOULSLOCKER_ADDRESS,
    //         SOULSLOCKER_ABI,
    //         signer
    //       );
    //       setLockerContract(lockerContract);
    //       const storageContract = new ethers.Contract(
    //         STORAGE_ADDRESS,
    //         STORAGE_ABI,
    //         signer
    //       );
    //       setStorageContract(storageContract);
    //       const mergeContract = new ethers.Contract(
    //         MERGER_ADDRESS,
    //         MERGER_ABI,
    //         signer
    //       );
    //       setMergeContract(mergeContract);
    //       const addLayersContract = new ethers.Contract(
    //           ADDLAYERS_ADDRESS,
    //           ADDLAYERS_ABI,
    //           signer
    //         );
    //       setAddLayersContract(addLayersContract);
    //       const experienceContract = new ethers.Contract(
    //         EXPERIENCE_ADDRESS,
    //         EXPERIENCE_ABI,
    //         signer
    //       );
    //       setExperienceContract(experienceContract);

    //       // isMergeActive(mergeContract).then((data) => {
    //       //   setIsMintActive(data);
    //       // });
    //       setIsMintActive(true);

    //       let allTokens = await listAnimeSpiritsHeroesOfOwner_separate(
    //         address,
    //         wrapperContract,
    //         lockerContract
    //       );

    //       let currentHeroes = allTokens.heroIDs.map((hero) => {
    //         return hero.soulNumber;
    //       });
    //       setHeroesOfUser(currentHeroes);

    //       // check if mainToken is included in currentHeroes

    //       // if (mainToken && !currentHeroes.includes(mainToken)) {
    //       //   navigate("/", { replace: false })
    //       // }

    //       allTokens = allTokens.tokenIDs;
    //       const sortedAllTokens = allTokens.sort((a, b) => {
    //         return a.id - b.id;
    //       });
    //       setAllTokens(sortedAllTokens);
    //       console.log("sorted", sortedAllTokens)
    //       const formattedAllTokens = allTokens.map((token) => {
    //         return { id: token.soulNumber };
    //       });
    //       setAllFormattedTokens(formattedAllTokens);
    //       const isMergingApprovedBool = await isMergingApproved(
    //         address,
    //         wrapperContract
    //       );

    //       setIsMergingApprove(isMergingApprovedBool);
    //     };

    //     setContractAndTokens();

    //     if (isConnected) {
    //       setMainToken(null);
    //       setEditOrCreateModalOpen(true);
    //     }
    //   }
    //   else {
    //     setOpenModal(false);
    //     setEditOrCreateModalOpen(false);
    //     setOpenMintModal(false);
    //     setOpenCelebrationModal(false);
    //     setOpenSelectNFTs(false);
    //     setOpenAdditionalLayers(false);
    //   }
    // }, [signer, address, isConnected, isChainSupported]);
    useEffect(() => {
        const allSoFar = [extra2, extra3, extra4, extra5, extra6, extra7];
        const foundDuplicate = allSoFar
            .slice(1, 7)
            .find((item) => item[0] === extra1[0]);

        if (foundDuplicate) {
        }
        let availableExtras = [extra1];
        for (let index = 0; index < heroExtraSlots - 1; index++) {
            if (allSoFar[index][0] !== extra1[0]) {
                availableExtras.push(allSoFar[index]);
            } else {
                availableExtras.push([0, 0]);
            }
        }
        const chosenExtraxCloned = Array(18).fill(false);
        availableExtras.forEach((extra) => {
            if (extra && extra[0] > 0) chosenExtraxCloned[extra[0]] = true;
        });
        setChosenExtras(chosenExtraxCloned);
        setExtras(availableExtras);
    }, [extra1, extra2, extra3, extra4, extra5, extra6, extra7]);
    useEffect(() => {
        if (mainToken) {
            const runesLevel = runes?.level ? runes.level : 1;
            const runesNumber = runes?.number;
            //remove invisible from Extra 2+ and remove undefined
            let formattedExtras = extras.map((extra, i) => {
                if (i === 0) {
                    return extra;
                }
                if (i >= 1 && extra && extra[0] !== 0) {
                    return extra;
                }
            });
            formattedExtras = formattedExtras.filter(
                (item) => item !== undefined
            );

            const newHero = {
                level: heroL,
                skin,
                clA,
                clB,
                bg,
                fullBgColor,
                halo,
                runes: [runesNumber, runesLevel],
                additionalLayers: selectedAndActiveAdditionalLayers,
                extras: formattedExtras,
            };
            setHero(newHero);


            // console.log("newHero", newHero);
            setCanConfirmTraits(!isEqual(newHero, confirmedHero));
            if (mainToken === 420) {
                formattedExtras.push([420, 1]);
            }
            processHero(
                heroL,
                skin,
                clA,
                clB,
                bg,
                fullBgColor,
                halo,
                runesNumber,
                runesLevel,
                formattedExtras,
                selectedAndActiveAdditionalLayers
            );
        }
    }, [
        heroL,
        fullBgColor,
        skin,
        bg,
        clA,
        clB,
        halo,
        runes,
        extras,
        selectedAndActiveAdditionalLayers,
    ]);

    // useEffect(() => {
    //   if (
    //     allTokens.length > 0 &&
    //     allModalSelectedNfts.length === allTokens.length
    //   ) {
    //     setIsSelectedAllNfts(true);
    //   }
    // }, [allModalSelectedNfts]);
    // useEffect(() => {
    //   if (
    //     (storageContract && mergeContract && lockerContract && addLayersContract &&
    //     signer &&
    //     mainToken)
    //   ) {
    //     (async () => {
    //       await getHeroAdditionalLayers(
    //         addLayersContract,
    //         lockerContract,
    //         mainToken
    //       )
    //         .then((data) => {
    //           setAllAdditionalLayers(data);
    //         })
    //         .catch((err) => {});

    //       await getHeroData(mainToken, storageContract, mergeContract)
    //         .then((data) => {
    //           setSelectedAdditionalLayers(data.additionalLayers || []);
    //           setSelectedAndActiveAdditionalLayers(data.additionalLayers || []);
    //           setCanConfirmTraits(false);
    //           setAllModalSelectedNfts([]);
    //         })
    //         .catch((err) => {});
    //     })();
    //   }
    // }, [storageContract, mergeContract, lockerContract, addLayersContract, signer, mainToken]);

    useEffect(() => {
        document.body.style.background = `url(${thelabimage})`;
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundAttachment = "fixed";

        setOpenModal(true);

        let tokenTraits = [
            [5, 1240, 6, 6, 6, 6, 1, 2, [3, 5, 5]],
            [5, 4, 4],
        ];

        let tokens = [{ id: "100001", soulNumber: 1, traits: tokenTraits }];

        handleSelectMainToken(tokens[0].soulNumber, tokens[0].traits);

        setOpenModal(false);

        // let allTokens = [{ id: "100666", soulNumber: 666, traits: tokenTraits }];
        // setAllTokens(allTokens);

        const newHero = {
          "level": 12,
          "skin": 3,
          "clA": 3,
          "clB": 3,
          "bg": 1,
          "fullBgColor": 0,
          "halo": 3,
          "runes": [
              3,
              1
          ],
          "additionalLayers": [],
          "extras": [
              [
                  1,
                  1
              ]
          ]
        }

        setHero(newHero);
        setInitialData(newHero);
        setConfirmedHero(newHero);

        document.body.style.backgroundColor = "#000000";
        return () => {
            document.body.style.background = "#111111";
        };
    }, []);

    useEffect(() => {
        if (
            typeof chain !== "undefined" &&
            typeof chain.unsupported !== "undefined"
        ) {
            setIsChainSupported(!chain.unsupported);
        }
    }, [chain]);

    // handle additional layers deselection depending on hero level
    useEffect(() => {
      // handle grassetti mask and xsullo mask
      if (heroL < 10 || heroL === 11) {
        // check if both [2,1] and [2,2] are in selectedAdditionalLayers
        const found1 = selectedAndActiveAdditionalLayers.find((arr) => isEqual(arr, [2,1]));
        const found2 = selectedAndActiveAdditionalLayers.find((arr) => isEqual(arr, [2,2]));

        // console.log(selectedAdditionalLayers);

        // if both are present, remove [2,2]
        if (found1 && found2) {
          const filterSelectedAdditionalLayers =
          selectedAdditionalLayers.filter((arr) => !isEqual(arr, [2,2]));
          setSelectedAdditionalLayers(filterSelectedAdditionalLayers);

          const filterSelectedAndActiveAdditionalLayers =
          selectedAndActiveAdditionalLayers.filter((arr) => !isEqual(arr, [2,2]));
          setSelectedAndActiveAdditionalLayers(filterSelectedAndActiveAdditionalLayers);
        }
      }

    }, [heroL]);

    if (matchesMobile) {
      return <div style={{ textAlign: "center",
      fontSize: "40px",
      paddingTop: "200px",
      paddingLeft: "5px",
      paddingRight: "5px",
       }}>
      Please rotate your device to landscape mode to use this page.</div>;
    }

    return (
        <>
            {" "}
            <Modal
                id={"mainTokenModal"}
                disableScrollLock={true}
                style={{ border: "none" }}
                open={editOrCreateModalOpen}
                onClose={handleCloseEditOrCreateModal}
            >
                <Fade in={editOrCreateModalOpen}>
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            background: "#111111",
                            display: "flex",
                            width: "30%",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            alignItems: "center",

                            border: "1px solid #ffb631",
                            outline: "none",
                            padding: "20px",
                            color: "white",
                        }}
                    >
                        <Button
                            style={{
                                fontSize: "25px",
                                fontFamily: "PPNeueMachina",

                                fontWeight: "900",
                                color: "white ",
                            }}
                            onClick={() =>
                                navigate("/wallet", { replace: false })
                            }
                        >
                            EDIT HERO
                        </Button>
                        <Button
                            style={{
                                fontSize: "25px",
                                fontFamily: "PPNeueMachina",

                                fontWeight: "900",
                                color: "#ffb631",
                            }}
                            onClick={() => {
                                setEditOrCreateModalOpen(false);
                                setOpenModal(true);
                            }}
                        >
                            CREATE NEW HERO
                        </Button>
                    </div>
                </Fade>
            </Modal>
            <Modal
                id={"mainTokenModal"}
                disableScrollLock={true}
                style={{ border: "none" }}
                open={openModal}
                onClose={handleCloseInitialModal}
            >
                <Fade in={openModal}>
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "70%",
                            height: "80%",
                            background: "#111111",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent:
                                allTokens.length > 0 ? "start" : "center",
                            alignItems:
                                allTokens.length > 0
                                    ? "space-around"
                                    : "center",
                            borderRadius: "10px",
                            outline: "none",
                            paddingTop: "20px",
                            color: "white",
                            fontFamily: "EurostilNormal",
                        }}
                    >
                        <div
                            style={{
                                width: "90%",
                                height: "100%",
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            {allTokens.length > 0 ? (
                                <>
                                    <span
                                        style={{
                                            marginBottom: "30px",
                                            fontFamily: "PPNeueMachina",
                                            fontSize: "25px",
                                        }}
                                    >
                                        {mainToken ? "SWAP" : "SELECT"} MAIN
                                        TOKEN{" "}
                                    </span>

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="start"
                                        style={{
                                            marginTop: "20px",
                                            width: "100%",
                                            height: "90%",
                                        }}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="start"
                                            alignItems="start"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                overflowY: "auto",
                                                overflowX: "hidden",
                                            }}
                                            spacing={2}
                                        >
                                            {allTokens &&
                                                allTokens.map((t) => {
                                                    return (
                                                        <Grid
                                                            item
                                                            xs={3}
                                                            style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                    "column",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                            }}
                                                        >
                                                            <img
                                                                onClick={() => {
                                                                    if (
                                                                        mainToken
                                                                    ) {
                                                                        handleSwapMainToken(
                                                                            t.soulNumber,
                                                                            t.traits
                                                                        );
                                                                    } else {
                                                                        handleSelectMainToken(
                                                                            t.soulNumber,
                                                                            t.traits
                                                                        );
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: "100%",
                                                                    cursor: "pointer",
                                                                }}
                                                                src={`https://leanime.art/heroes/m/${t.soulNumber}.jpg`}
                                                            />{" "}
                                                            <span
                                                                style={{
                                                                    marginTop:
                                                                        "10px",
                                                                }}
                                                            >
                                                                #{t.soulNumber}
                                                            </span>
                                                        </Grid>
                                                    );
                                                })}
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <span
                                        style={{
                                            fontFamily: "PPNeueMachina",
                                            fontSize: "25px",
                                        }}
                                    >
                                        YOU HAVE NO TOKENS TO MERGE
                                    </span>
                                    <span
                                        style={{
                                            fontFamily: "PPNeueMachina",
                                            fontSize: "20px",
                                        }}
                                    >
                                        WANT SOME?{" "}
                                        <a
                                            href={
                                                "https://opensea.io/collection/le-anime-by-toomuchlag"
                                            }
                                            target="_blank"
                                            style={{ color: "#ffb631" }}
                                        >
                                            CLICK HERE
                                        </a>
                                    </span>
                                </>
                            )}
                        </div>
                        {allTokens.length > 0 && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "50%",
                                    marginTop: "40px",
                                    fontSize: "25px",
                                    fontFamily: "EurostilDemi",
                                    textAlign: "center",
                                    color: "#ffb631",
                                }}
                            >
                                <span style={{ marginBottom: "20px" }}>
                                    The Main Token is the Hero NFT that will
                                    contain all the other tokens merged into it.
                                </span>
                                <span style={{ marginBottom: "20px" }}>
                                    Your HERO NFT ID # is based on your "MAIN"
                                    Token selected.
                                </span>
                                <span>
                                    Changing the Main Token ID after minting,
                                    will require the hero to be destroyed,
                                    umerged and rebuilt, and will result in a
                                    lot of transactions and gas, so choose
                                    carefully before finalizing the mint.
                                </span>
                            </div>
                        )}
                    </div>
                </Fade>
            </Modal>
            <Modal
        id={"add layers"}
        disableScrollLock={true}
        style={{ border: "none" }}
        ModalProps={{
          keepMounted: true,
        }}
        open={openAdditionalLayers}
        onClose={() => {
          setOpenAdditionalLayers(false);
          setSelectedAdditionalLayers(selectedAndActiveAdditionalLayers);
        }}
      >
        <Fade in={openAdditionalLayers}>
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "70%",
                            height: "100%",
                            maxHeight: "800px",
                            background: "#111111",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "center",
                            borderRadius: "10px",
                            outline: "none",
                            paddingTop: "20px",
                            // overflowY: "auto",

                            color: "white",
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                                width: "100%",
                                marginBottom: matchesSmall ? "0px" : "25px",
                                fontFamily: "PPNeueMachina",
                                fontSize: matchesSmall ? "16px" : "25px",
                            }}
                        >
                            ADDITIONAL LAYERS
                        </Grid>
                        
                        <div
                            style={{
                                padding: "10px",
                                alignSelf: "start",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                          {!matchesSmall && (
                            <>
                            <span
                                style={{
                                    fontSize: matchesSmall ? "10px" : "14px",
                                    fontFamily: "EurostilDemi",
                                    color: "#ffb631",
                                    marginBottom: "20px 0",
                                    marginLeft: "20px",
                                }}
                            >
                                • Additional Layers are special visual-only
                                attributes that can be activated on your Hero.
                            </span>
                            <span
                                style={{
                                    fontSize: matchesSmall ? "10px" : "14px",
                                    fontFamily: "EurostilDemi",
                                    color: "#ffb631",
                                    marginBottom: "20px 0",
                                    marginLeft: "20px",
                                }}
                            >
                                • Additional Layers are not traits and do not
                                affect original rarities.
                            </span>
                            <span
                                style={{
                                    fontSize: matchesSmall ? "10px" : "14px",
                                    fontFamily: "EurostilDemi",
                                    color: "#ffb631",
                                    marginBottom: "20px 0",
                                    marginLeft: "20px",
                                }}
                            >
                                • Additional Layers are bound to the NFT, not
                                your wallet and cannot be traded individually.
                            </span>
                            <span
                                style={{
                                    fontSize: matchesSmall ? "10px" : "14px",
                                    fontFamily: "EurostilDemi",
                                    color: "#ffb631",
                                    marginBottom: "20px 0",
                                    marginLeft: "20px",
                                }}
                            >
                                • Additional Layers are exclusively unlocked
                                through events, challenges, airdrops or by
                                spending Experience.
                            </span>
                            <span
                                style={{
                                    fontSize: matchesSmall ? "10px" : "14px",
                                    fontFamily: "EurostilDemi",
                                    color: "#ffb631",
                                    marginBottom: "20px 0",
                                    marginLeft: "20px",
                                }}
                            >
                                <br />
                                ACTIVE layers are currently displayed on your
                                Hero.
                            </span>
                            <span
                                style={{
                                    fontSize: matchesSmall ? "10px" : "14px",
                                    fontFamily: "EurostilDemi",
                                    color: "#ffb631",
                                    marginBottom: "20px 0",
                                    marginLeft: "20px",
                                }}
                            >
                                <br />
                                Click below to choose which layers you want to
                                activate:
                            </span>
                            </>
                          )}
                        </div> 
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                                width: "100%",
                                // height: "60%",
                                // padding: matchesSmall ? "0 10px" : "0 20px",
                                // marginLeft: "20px",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: matchesSmall ? "14px" : "24px",
                                    fontFamily: "EurostilDemi",
                                    color: "#ffb631",
                                    marginBottom: "10px",
                                }}
                            >
                                Your Available Layers
                            </span>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justifyContent="start"
                            alignItems="start"
                            style={{
                                width: "100%",
                                // height: "100%",
                                // overflowY: "auto",
                                overflowX: "hidden",
                                paddingRight: "15px",
                                paddingLeft: "15px",
                                paddingBottom: "10px",
                            }}
                            spacing={matchesSmall ? 1 : 2}
                        >
                            {allAdditionalLayers.length > 0 &&
                                allAdditionalLayers.map((layer, i) => {
                                    const isSelected =
                                        selectedAdditionalLayers &&
                                        selectedAdditionalLayers.find(
                                            (arr) => isEqual(arr, layer)
                                        );

                                    return (
                                        <Grid
                                            item
                                            xs={2}
                                            key={i + layer[0] + layer[1]}
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                position: "relative",
                                                justifyContent: "center",
                                                userSelect: "none",
                                            }}
                                        >
                                            <img
                                                onClick={() =>
                                                    handleSelectAddLayer(
                                                        layer
                                                    )
                                                }
                                                style={{
                                                    width: "100%",
                                                    cursor: "pointer",
                                                    zIndex: 2,
                                                    border: "2px solid transparent",
                                                    borderColor: isSelected
                                                        ? "#ffb631"
                                                        : "transparent",
                                                    boxSizing: "border-box",
                                                }}
                                                src={`https://leanime.art/heroes/additional_layers/previews/${layer[0]}_${layer[1]}.jpg`}
                                            />
                                            {isSelected && (
                                                <div
                                                    onClick={() =>
                                                        handleSelectAddLayer(
                                                            layer
                                                        )
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                        position:
                                                            "absolute",
                                                        width: "100%",
                                                        height: "100%",
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        alignItems:
                                                            "center",
                                                        top: 0,
                                                        zIndex: 3,
                                                        marginTop: "5px",
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            background:
                                                                "rgba(255, 182, 49,0.8)",
                                                            color: "black",
                                                            width: "80%",
                                                            padding:
                                                                "5px 0",
                                                            fontFamily:
                                                                "EurostilDemi",
                                                            textAlign:
                                                                "center",
                                                            fontSize: matchesSmall? "12px" : "16px",
                                                        }}
                                                    >
                                                        ACTIVE
                                                    </span>
                                                </div>
                                            )}
                                            {/* <span
                                                
                                            >
                                                {layer[0]}
                                            </span> */}
                                        </Grid>
                                    );
                                })}
                        </Grid>
                        {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100%' }}> */}
                        <Button
                            onClick={handleConfirmAdditionalLayers}
                            style={{
                                background: "#ffb631",
                                color: "black",
                                width: "250px",
                                padding: matchesSmall ? "5px 0" : "10px 0",
                                margin: "20px 0 40px 0",
                                borderRadius: 0,
                                fontSize: matchesSmall ? "14px" : "20px",
                                fontFamily: "EurostilDemi",
                            }}
                        >
                            CONFIRM
                        </Button>
                        {/* </div> */}
                        
                        
                    </div>
                </Fade>
            </Modal>
            <Modal
                id={"selectNFTS"}
                disableScrollLock={true}
                style={{ border: "none" }}
                ModalProps={{
                    keepMounted: true,
                }}
                open={openSelectNFTs}
                onClose={handleCloseSelectNFTsModal}
            >
                <Fade in={openSelectNFTs}>
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "70%",
                            height: "700px",
                            background: "#111111",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "center",
                            borderRadius: "10px",
                            outline: "none",
                            paddingTop: "20px",

                            color: "white",
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ width: "100%", marginBottom: "15px" }}
                        >
                            <Grid item xs={3}></Grid>
                            <Grid
                                item
                                xs={6}
                                style={{
                                    fontFamily: "PPNeueMachina",
                                    fontSize: "25px",
                                    textAlign: "center",
                                }}
                            >
                                SELECT NFTS TO MERGE
                            </Grid>
                            <Grid
                                item
                                xs={3}
                                onClick={selectAllNFTs}
                                style={{
                                    fontFamily: "PPNeueMachina",
                                    fontSize: "13px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    color: "#ffb631",
                                }}
                            >
                                {isSelectedAllNfts && "UN"}SELECT ALL
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="start"
                            style={{ width: "100%", height: "80%" }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="start"
                                alignItems="start"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                }}
                                spacing={2}
                            >
                                {allTokens &&
                                    allTokens.map((t, i) => {
                                        const isSelected =
                                            allModalSelectedNfts.find(
                                                (obj) => obj === t.soulNumber
                                            );
                                        return (
                                            <Grid
                                                item
                                                xs={2}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <img
                                                    onClick={() =>
                                                        handleSelectNFTs(
                                                            t.soulNumber,
                                                            t.traits
                                                        )
                                                    }
                                                    style={{
                                                        width: "100%",
                                                        cursor: "pointer",
                                                        border: isSelected
                                                            ? "2px solid #ffb631"
                                                            : "none",
                                                    }}
                                                    src={`https://leanime.art/heroes/m/${t.soulNumber}.jpg`}
                                                />{" "}
                                                <span
                                                    
                                                >
                                                    #{t.soulNumber}
                                                </span>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        </Grid>
                        <Button
                            onClick={handleCloseAndConfirmSelectNFTsModal}
                            style={{
                                background: "#ffb631",
                                color: "black",
                                width: "250px",
                                padding: "10px 0",
                                marginTop: "10px",
                                margin: "10px 0",
                                borderRadius: 0,
                                fontSize: "20px",
                                fontFamily: "EurostilDemi",
                            }}
                        >
                            CONFIRM
                        </Button>
                    </div>
                </Fade>
            </Modal>
            <Modal
                id={"mintModal"}
                disableScrollLock={true}
                style={{ border: "none" }}
                ModalProps={{
                    keepMounted: true,
                }}
                open={openMintModal}
                onClose={handleCloseMintModal}
            >
                <Fade in={openMintModal}>
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "50%",
                            height: "600px",
                            background: "#111111",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "center",
                            borderRadius: "10px",
                            outline: "none",
                            paddingTop: "20px",

                            color: "white",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {" "}
                            <span
                                style={{
                                    fontSize: "18px",
                                    fontFamily: "EurostilDemi",

                                    color: "white",
                                }}
                            >
                                COMPLETE THE FOLLOWING{" "}
                                <span style={{ color: "#ffb631" }}>
                                    {" "}
                                    {allSelectedNfts.length > 1
                                        ? !mergingApproved
                                            ? 3
                                            : 2
                                        : 1}{" "}
                                </span>
                                TRANSACTION
                                {allSelectedNfts.length > 1
                                    ? !mergingApproved
                                        ? "S"
                                        : "S"
                                    : ""}{" "}
                                TO FINALISE YOUR HERO
                            </span>
                            <span
                                style={{
                                    fontSize: "12px",
                                    fontFamily: "EurostilDemi",
                                    color: "#ffb631",
                                }}
                            >
                                YOU WILL ONLY BE ABLE TO SEE YOUR HERO ONCE THE
                                PROCESS IS COMPLETED
                            </span>
                        </div>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                                width: "100%",
                                height: "100%",
                                marginBottom: "15px",
                            }}
                        >
                            {!mergingApproved && allSelectedNfts.length > 1 && (
                                <>
                                    <Button
                                        disabled={isApproveLoading}
                                        onClick={handleApproveMerging}
                                        style={{
                                            background: "#ffb631",
                                            color: "black ",
                                            width: "350px",
                                            padding: "20px 0",
                                            marginTop: "10px",
                                            margin: "10px 0",
                                            borderRadius: 0,
                                            fontSize: "20px",
                                            fontFamily: "EurostilDemi",
                                        }}
                                    >
                                        {isApproveLoading ? (
                                            <CircularProgress />
                                        ) : (
                                            "approve merging"
                                        )}
                                    </Button>
                                </>
                            )}
                            {allSelectedNfts.length > 1 && (
                                <>
                                    <Button
                                        disabled={
                                            !mergingApproved && !canDeposit
                                        }
                                        onClick={() =>
                                            setOpenConfirmLockedSouls(true)
                                        }
                                        style={{
                                            background:
                                                !mergingApproved && !canDeposit
                                                    ? "rgba(255, 182, 49,0.3)"
                                                    : "#ffb631",
                                            color: "black",
                                            width: "350px",
                                            padding: "20px 0",
                                            marginTop: "10px",
                                            margin: "10px 0",
                                            borderRadius: 0,
                                            fontSize: "20px",
                                            fontFamily: "EurostilDemi",
                                        }}
                                    >
                                        {isDepositLoading ? (
                                            <CircularProgress />
                                        ) : (
                                            " merge tokens"
                                        )}
                                    </Button>
                                </>
                            )}
                            <Button
                                disabled={
                                    allSelectedNfts.length > 1
                                        ? isMergingApproved &&
                                          isDeposited &&
                                          canConfirmTraits
                                            ? false
                                            : true
                                        : canConfirmTraits
                                        ? false
                                        : true
                                }
                                onClick={handleConfirmTraits}
                                style={{
                                    background:
                                        allSelectedNfts.length > 1
                                            ? isMergingApproved &&
                                              isDeposited &&
                                              canConfirmTraits
                                                ? "#ffb631"
                                                : "rgba(255, 182, 49,0.3)"
                                            : canConfirmTraits
                                            ? "#ffb631"
                                            : "rgba(255, 182, 49,0.3)",

                                    color: "black",
                                    width: "350px",
                                    padding: "20px 0",
                                    marginTop: "10px",
                                    margin: "10px 0",
                                    borderRadius: 0,
                                    fontSize: "20px",
                                    fontFamily: "EurostilDemi",
                                }}
                            >
                                {isConfirmTraitsLoading ? (
                                    <CircularProgress />
                                ) : (
                                    " confirm hero traits"
                                )}
                            </Button>
                        </Grid>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "16px",
                                    fontFamily: "EurostilDemi",
                                    color: "#ffb631",
                                }}
                            >
                                AFTER THE TRANSACTIONS ARE CONFIRMED, YOUR HERO
                                WILL BE AVAILABLE SHORTLY AFTER.
                            </span>
                            <span
                                style={{
                                    fontSize: "13px",
                                    fontFamily: "EurostilObq",
                                }}
                            >
                                {" "}
                                Refresh Metadata on OpenSea to speed up the
                                process!
                            </span>
                        </div>{" "}
                    </div>
                </Fade>
            </Modal>
            <Modal
                id={"selectNFTS"}
                disableScrollLock={true}
                style={{ border: "none" }}
                ModalProps={{
                    keepMounted: true,
                }}
                open={openConfirmLockedSouls}
                onClose={handleCloseConfirmLockedSouls}
            >
                <Fade in={openConfirmLockedSouls}>
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "70%",
                            height: "700px",
                            background: "#111111",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "center",
                            borderRadius: "10px",
                            outline: "none",
                            paddingTop: "20px",

                            color: "white",
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                                width: "100%",
                                marginBottom: "15px",
                                fontFamily: "PPNeueMachina",
                                fontSize: "25px",
                            }}
                        >
                            THE SELECTED TOKENS WILL BE MERGED INTO THE MAIN
                            TOKEN
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="start"
                            style={{ width: "100%", height: "80%" }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="start"
                                alignItems="start"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                }}
                                spacing={2}
                            >
                                {allModalSelectedNfts &&
                                    allModalSelectedNfts.map((t, i) => {
                                        return (
                                            <Grid
                                                item
                                                xs={2}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    src={`https://leanime.art/heroes/m/${t}.jpg`}
                                                />{" "}
                                                <span>
                                                    #{t}
                                                </span>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        </Grid>
                        <Button
                            onClick={handleDepositSouls}
                            style={{
                                background: "#ffb631",
                                color: "black",
                                width: "250px",
                                padding: "10px 0",
                                marginTop: "10px",
                                margin: "10px 0",
                                borderRadius: 0,
                                fontSize: "20px",
                                fontFamily: "EurostilDemi",
                            }}
                        >
                            CONFIRM
                        </Button>
                    </div>
                </Fade>
            </Modal>
            <Modal
                id={"celebration"}
                disableScrollLock={true}
                style={{ border: "none" }}
                ModalProps={{
                    keepMounted: true,
                }}
                open={openCelebrationModal}
                onClose={() => {
                    setOpenCelebrationModal(false);
                }}
            >
                <Fade in={openCelebrationModal}>
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "50%",
                            height: "600px",
                            background: "#111111",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "10px",
                            outline: "none",
                            paddingTop: "20px",

                            color: "white",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "10px",
                                textAlign: "center",
                                width: "80%",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "32px",
                                    fontFamily: "EurostilDemi",
                                    color: "#ffb631",
                                    marginBottom: "10px",
                                }}
                            >
                                Your HERO is finalised!
                            </span>
                            <span
                                style={{
                                    fontSize: "24px",
                                    fontFamily: "EurostilDemi",
                                    color: "#ffb631",
                                    marginBottom: "10px",
                                }}
                            >
                                Your Hero is currently being forged and will be
                                available shortly!
                            </span>
                            <span
                                style={{
                                    fontSize: "18px",
                                    fontFamily: "EurostilObq",
                                    color: "white",
                                }}
                            >
                                Check the{" "}
                                <Link
                                    style={{
                                        cursor: "pointer",
                                        color: "#ffb631",
                                    }}
                                    to="/wallet"
                                    target="_blank"
                                >
                                    My NFTs{" "}
                                </Link>
                                section to see your hero or find it in your
                                OpenSea account!
                            </span>
                        </div>{" "}
                    </div>
                </Fade>
            </Modal>
            <>
                {" "}               
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        paddingTop: matchesSmall ? "75px" : "100px",
                        paddingBottom: "100px",
                        width: "100%",
                    }}
                >
                    <span
                        style={{
                            fontSize: matchesMobile? "25px" : matchesSmall?  "30px" : "35px",
                            fontFamily: "EurostilExtended",
                            marginBottom: "5px",
                            letterSpacing: "2px",
                        }}
                    >
                        {" "}
                        HERO #XXX 
                        {/* {mainToken} */}
                    </span>
                    <span
                        style={{
                            fontSize: "20px",
                            marginBottom: "10px",
                            letterSpacing: "2px",
                            fontFamily: "PPNeueMachina",
                        }}
                    >
                        {" "}
                        SCORE:{" "}
                        <span style={{ color: "#ffb631" }}>
                          25000
                            {/* {score} */}
                        </span>
                    </span>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="start"
                        style={{ width: "90%" }}
                    >
                        <Grid
                            item
                            xs={4}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            {" "}
                            <span
                                style={{
                                    fontSize:  matchesMobile? "14px" : matchesSmall?  "14px" : "20px",
                                    marginBottom: "20px",
                                    fontFamily: "EurostilDemi",
                                }}
                            >
                                {" "}
                                SELECT DISPLAYED TRAITS
                            </span>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: matchesMedium
                                        ? "start"
                                        : "center",
                                    alignItems: "start",
                                    width: matchesMedium ? "100%" : "50%",
                                }}
                            >
                                {mainToken &&
                                    allPossibleTraits.map((trait, i) => {
                                        return (
                                            <SelectTrait
                                                initialData={initialData}
                                                setSelectedTrait={
                                                    handleSelectTrait
                                                }
                                                chosenExtras={chosenExtras}
                                                isActivated={true}
                                                extra1={extra1}
                                                data={allPossibleTraits.find(
                                                    (obj) =>
                                                        obj.trait ===
                                                        trait.trait
                                                )}
                                                title={trait.trait}
                                                key={i}
                                            />
                                        );
                                    })}
                                {mainToken &&
                                    scoreTooLowExtras.map((cell) => {
                                        return (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "end",
                                                    alignItems: "end",
                                                    marginBottom: "8px",
                                                }}
                                            >
                                                <div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "row",
                                                            justifyContent:
                                                                "center",
                                                            alignItems: "end",
                                                        }}
                                                    >
                                                        {" "}
                                                        <svg
                                                            style={{
                                                                cursor: "default",
                                                                marginRight:
                                                                    "5px",
                                                            }}
                                                            height="30"
                                                            width="30"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill={"grey"}
                                                            viewBox="0 0 10 20"
                                                        >
                                                            {" "}
                                                            <path d="m14 7-5 5 5 5V7z"></path>
                                                        </svg>{" "}
                                                        <svg
                                                            style={{
                                                                cursor: "default",
                                                            }}
                                                            height="30"
                                                            width="30"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill={"grey"}
                                                            viewBox="0 0 20 20"
                                                        >
                                                            {" "}
                                                            <path d="m8 17 5-5-5-5v10z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent:
                                                            "center",
                                                        alignContent: "start",
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: matchesSmall ? "14px" : "18px",
                                                            fontFamily:  "EurostilObq",
                                                        }}
                                                    >
                                                        {cell.trait}:
                                                    </span>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "row",
                                                            justifyContent:
                                                                "start",
                                                            alignItems:
                                                                "center",
                                                            width: "300px",
                                                        }}
                                                    >
                                                        {" "}
                                                        <span
                                                            style={{
                                                                color: "grey",
                                                                fontFamily:
                                                                    "EurostilDemi",
                                                                fontSize: matchesSmall ? "14px" : "18px",
                                                                padding: 0,
                                                                textAlign:
                                                                    "left",
                                                            }}
                                                        >
                                                            Score too low
                                                        </span>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                    "row",
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                                fontSize: matchesSmall ? "14px" : "16px",
                                                            }}
                                                        >
                                                            {" "}
                                                            <svg
                                                                style={{
                                                                    marginRight:
                                                                        "5px",
                                                                }}
                                                                height="30"
                                                                width="30"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="grey"
                                                                viewBox="0 0 10 20"
                                                            >
                                                                {" "}
                                                                <path d="m14 7-5 5 5 5V7z"></path>
                                                            </svg>{" "}
                                                            0
                                                            <svg
                                                                style={{
                                                                    cursor: "default",
                                                                }}
                                                                height="30"
                                                                width="30"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="grey"
                                                                viewBox="0 0 20 20"
                                                            >
                                                                {" "}
                                                                <path d="m6 17 5-5-5-5v10z"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "center",
                                height: matchesSmall? "400px" : matchesMedium? "600px" : "800px",
                            }}
                        >
                            <div className="isolate" id="imageRender">
                                {fakeArray.map((el, i) => {
                                    return (
                                        <div
                                            key={i}
                                            ref={(el) =>
                                                (imageRefs.current[i] = el)
                                            }
                                            className={`image image-${i}`}
                                        ></div>
                                    );
                                })}
                            </div>
                            <span
                                style={{
                                    color: "#ffb631",
                                    marginTop: "10px",
                                    fontFamily: "EurostilObq",
                                    fontSize: matchesMobile? "12px" : matchesSmall?  "12px" : "15px",
                                }}
                            >
                                This is a low resolution preview
                            </span>
                            <Button
                                disabled={!isMintActive}
                                style={{
                                    background: isMintActive
                                        ? "#ffb631"
                                        : "rgba(255, 182, 49,0.3)",
                                    color: "black",
                                    padding: "5px 30px",
                                    marginTop: "15px",
                                    borderRadius: 0,
                                    fontWeight: 900,
                                    fontSize: matchesMobile ? "12px" : matchesSmall? "12px" : "20px",
                                    fontFamily: "PPNeueMachina",
                                    width: "60%",
                                }}
                                onClick={() => {
                                    setOpenMintModal(true);
                                }}
                            >
                                MINT/UPDATE
                            </Button>
                        </Grid>

                        <Grid
                            item
                            xs={4}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            {" "}
                            <div
                                style={{
                                    width: matchesMobile ? "80%" : matchesSmall? "80%" : "250px",
                                    textAlign: "center",
                                    // fontSize: "17px",
                                    fontFamily: "EurostilDemi",
                                    color: "#ffb631",
                                }}
                            >
                                <Button
                                    style={{
                                        background: "#ffb631",
                                        color: "black",
                                        width: "100%",
                                        padding: "10px 0",
                                        margin: "10px 0",
                                        borderRadius: 0,
                                        fontSize: matchesMobile ? "20px" : matchesSmall? "14px" : "20px",
                                        fontFamily: "EurostilDemi",
                                    }}
                                >
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            cursor: "pointer",
                                            color: "black",
                                        }}
                                        to="/builder-guide"
                                        target="_blank"
                                    >
                                        Builders Guide
                                    </Link>
                                </Button>
                                <Button disabled
                                    style={{
                                        // background: "#ffb631",
                                        // color: "black",
                                        background: "rgba(255, 182, 49,0.3)",
                                        color: "grey",
                                        width: "100%",
                                        padding: "10px 0",
                                        // marginTop: "10px",
                                        borderRadius: 0,
                                        fontSize: matchesMobile ? "20px" : matchesSmall? "14px" : "20px",
                                        fontFamily: "EurostilDemi",
                                    }}
                                    onClick={() => {
                                        setOpenSelectNFTs(true);
                                    }}
                                >
                                    add NFTs
                                </Button>{" "}
                                <Button
                                    onClick={() =>
                                        setOpenAdditionalLayers(true)
                                    }
                                    style={{
                                        background: "#ffb631",
                                        color: "black",
                                        width: "100%",
                                        padding: "10px 0",
                                        marginTop: "10px",
                                        borderRadius: 0,
                                        fontSize: matchesMobile ? "20px" : matchesSmall? "14px" : "20px",
                                        fontFamily: "EurostilDemi",
                                    }}
                                >
                                    Additional Layers
                                </Button>{" "}
                                <Button
                                    disabled
                                    style={{
                                        background: "rgba(255, 182, 49,0.3)",
                                        color: "grey",
                                        width: "100%",
                                        padding: "10px 0",
                                        marginTop: "10px",
                                        borderRadius: 0,
                                        fontSize: matchesMobile ? "20px" : matchesSmall? "14px" : "20px",
                                        fontFamily: "EurostilDemi",
                                    }}
                                >
                                    Experience
                                </Button>{" "}
                                <Button
                                    style={{
                                        background: "#ffb631",
                                        color: "black",
                                        width: "100%",
                                        padding: "10px 0",
                                        marginTop: "10px",
                                        borderRadius: 0,
                                        fontSize: matchesMobile ? "20px" : matchesSmall? "14px" : "20px",
                                        fontFamily: "EurostilDemi",
                                    }}
                                >
                                    {" "}
                                    <a
                                        href={
                                            "https://opensea.io/collection/le-anime-by-toomuchlag"
                                        }
                                        target="_blank"
                                        style={{
                                            color: "black",
                                            textDecoration: "none",
                                        }}
                                    >
                                        GET MORE TRAITS
                                    </a>
                                </Button>
                                <Button
                                    style={{
                                        background: "#ffb631",
                                        color: "black",
                                        width: "100%",
                                        padding: "10px 0",
                                        margin: "10px 0",
                                        borderRadius: 0,
                                        fontSize: matchesMobile ? "20px" : matchesSmall? "14px" : "20px",
                                        fontFamily: "EurostilDemi",
                                    }}
                                >
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            cursor: "pointer",
                                            color: "black",
                                        }}
                                        to="/levels-info"
                                        target="_blank"
                                    >
                                        Levels INFO
                                    </Link>
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                
            </>
        </>
    );
};

export default DemoBuilder;
