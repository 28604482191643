import { ethers } from "ethers";

import {AUCTION_STATUS} from '../config/configAuction'
const BigNumber = ethers.BigNumber;



//this gets the initial settings from the contract
export const initialAuctionConfig = async (auctionContract) => {
 
    const initialData = await Promise.all([
        40000000000000000,
        auctionContract.minimumBidIncrement(),
        auctionContract.unitPriceStepSize(),
        auctionContract.minimumQuantity(),
        auctionContract.maximumQuantity(),
        auctionContract.numberOfAuctions(),
        auctionContract.getCurrentAuctionID(),
        auctionContract.paused(),
        auctionContract.currentAuctionStatus(), //(false,false)
        auctionContract.itemsPerDay(0),
        auctionContract.itemsPerDay(1),
        auctionContract.itemsPerDay(2),
    ])
    const contractSettings = {
        minimumUnitPrice: ethers.utils.formatEther(initialData[0].toString()),
        minimumBidIncrement: ethers.utils.formatEther(initialData[1].toString()),
        unitPriceStepSize: ethers.utils.formatEther(initialData[2].toString()),
        // minimumUnitPrice: ethers.utils.parseEther(initialData[0].toString()),
        // minimumBidIncrement: ethers.utils.parseEther(initialData[1].toString()),
        // unitPriceStepSize: ethers.utils.parseEther(initialData[2].toString()),
        minimumQuantity: parseInt(initialData[3], 10),
        maximumQuantity: parseInt(initialData[4], 10),
        itemsPerDay: [parseInt(initialData[9], 10),parseInt(initialData[10], 10),parseInt(initialData[11], 10)],
    }

    const auctionData = {   
        numberOfAuctions: parseInt(initialData[5], 10),
        currentAuctionId: parseInt(initialData[6], 10),
        paused: initialData[7],
        currentAuctionStatus: initialData[8],
    }
    
    //calculate AuctionState to know if auction is before, started, paused or ended
    const auctionState = calculateAuctionStatus(
        auctionData.currentAuctionId,
        auctionData.numberOfAuctions,
        auctionData.paused,
        auctionData.currentAuctionStatus,
    )

return {contractSettings, auctionData ,auctionState}
   
}
//refresh auction DAta
export const refreshAuctionData = async (auctionContract) => {
  const newAuctionData = await Promise.all([
      auctionContract.getCurrentAuctionID(),
      auctionContract.currentAuctionStatus(),
      auctionContract.paused()
  ])
  

  const currentAuctionId = parseInt(newAuctionData[0], 10) //BN
  const currentAuctionStatus = newAuctionData[1]
  const paused = newAuctionData[2]

  return{currentAuctionId, currentAuctionStatus, paused , numberOfAuctions:3}

}

//gets the current bid of an account(BN)
export const getBid = async (bidder, auctionContract) => {
 
  let bid = await auctionContract.getBid(bidder)
  const unitPrice = parseFloat(ethers.utils.formatEther(bid[0]))
  const quantity = parseInt(bid[1].toString())
  return{bid, unitPrice, quantity}
}

// Place BID
export const placeBid = async (bidder, quantity, unitPrice, auctionContract) => {
  let currentBid = await auctionContract.getBid(bidder)
  // console.log(currentBid)
  // let currentTotalBid = currentBid[0] * currentBid[1]
  let currentTotalBid = BigNumber.from(currentBid[0]).mul(currentBid[1])
  // console.log(currentTotalBid)
  // console.log(currentBid['quantity'].eq(0))
  let unitPriceWei = ethers.utils.parseEther(unitPrice.toString())
  if(!currentTotalBid) { //this to do a new bid
      
      let totalBid = BigNumber.from(quantity).mul(ethers.utils.parseEther(unitPrice.toString()))
      // console.log(totalBid)
      let overrides = {
          value: totalBid.toString()
          //add gas estimate? 
      };
      
      //ethers.utils.parseUnits("1.0", 18);
      
      let bidTx = await auctionContract.placeBid(quantity, unitPriceWei, overrides)
      // console.log(bidTx)

      return bidTx.wait()

      
  } 
  else {
      let totalBid = BigNumber.from(quantity).mul(ethers.utils.parseEther(unitPrice.toString()))
      
      let newEthAmount = BigNumber.from(totalBid).sub(currentTotalBid)

      let overrides = {
          value: newEthAmount.toString()   
          //add gas estimate? 
      };
      // console.log("ETH", newEthAmount)
      //ethers.utils.parseUnits("1.0", 18);
      
      let bidTx = await auctionContract.placeBid(quantity, unitPriceWei, overrides)
      return bidTx.wait()

      // await bidTx.wait().then(function(receipt) {
      //     console.log(receipt)
      //     // do whatever you wanna do with `receipt`
      // });

  }   
}

// Get BID refund (after auction is over and refunds are unlocked by owner)
export const getRefund = async (auctionContract) => {
  let bidTx = await auctionContract.claimRefund()
  return bidTx.wait()
}

// calculates Auction State from contract data
export const calculateAuctionStatus = (
  currentAuctionId,
  numberOfAuctions,
  paused,
  currentAuctionStatus,
) => {
  let status = 0
  if (currentAuctionId === 0 && !currentAuctionStatus.started) {
    status = AUCTION_STATUS.NOTSTARTED
  } else if (
    currentAuctionId === numberOfAuctions - 1 &&
    currentAuctionStatus.ended
  ) {
    status = AUCTION_STATUS.CLOSED
  } else if (paused) {
    status = AUCTION_STATUS.PAUSED
  } else {
    status = AUCTION_STATUS.OPEN
  }
  return status
}

//getReverseEns Names
export const getEnsNames = async (addresses, ensContract) => {
 
  let ensList = await ensContract.getNames(addresses)
  
  return ensList
}