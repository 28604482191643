import React, { useState, useEffect } from "react";
import image1 from "../assets/LEA2_EX (1).jpg";
import image2 from "../assets/LEA2_EX (2).jpg";
import image3 from "../assets/LEA2_EX (3).jpg";
import image4 from "../assets/LEA2_EX (4).jpg";
import image5 from "../assets/LEA2_EX (5).jpg";
import image6 from "../assets/LEA2_EX (6).jpg";
import image7 from "../assets/LEA2_EX (7).jpg";
import image8 from "../assets/LEA2_EX (8).jpg";
import ItemsCarousel from "react-items-carousel";
import { useMediaQuery } from "@mui/material";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
const NewArt = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const matches = useMediaQuery("(max-width:600px)");
  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      setActiveItemIndex((prevActiveItemIndex) => prevActiveItemIndex + 1);
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div style={{ width: "90%" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: matches ? "22px" : "32px",
            fontWeight: "900",
            marginBottom: "2%",
            fontFamily: "PPNeueMachina",
          }}
        >
          NEW HEROES EXAMPLES
        </div>
      </div>

      <div style={{ padding: "0 20px", maxWidth: "100%", margin: "0 auto" }}>
        <ItemsCarousel
          infiniteLoop={true}
          gutter={12}
          activePosition={"center"}
          chevronWidth={60}
          disableSwipe={false}
          alwaysShowChevrons={true}
          numberOfCards={matches ? 1 : 4}
          slidesToScroll={1}
          outsideChevron={false}
          showSlither={false}
          firstAndLastGutter={false}
          activeItemIndex={activeItemIndex}
          requestToChangeActive={(value) => setActiveItemIndex(value)}
          rightChevron={<KeyboardArrowRightIcon style={{ fontSize: "50px" }} />}
          leftChevron={<KeyboardArrowLeftIcon style={{ fontSize: "50px" }} />}
        >
          <img style={{ width: "100%", borderRadius: "1%" }} src={image1} />
          <img style={{ width: "100%", borderRadius: "1%" }} src={image2} />
          <img style={{ width: "100%", borderRadius: "1%" }} src={image3} />
          <img style={{ width: "100%", borderRadius: "1%" }} src={image4} />
          <img style={{ width: "100%", borderRadius: "1%" }} src={image7} />
          <img style={{ width: "100%", borderRadius: "1%" }} src={image5} />
          <img style={{ width: "100%", borderRadius: "1%" }} src={image6} />
          <img style={{ width: "100%", borderRadius: "1%" }} src={image8} />
        </ItemsCarousel>
      </div>
    </div>
  );
};

export default NewArt;
